import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MapFieldsComponent } from './map-fields/map-fields.component';
import { UploadFileComponent } from './upload-file/upload-file.component';

const routes: Routes = [
  { path: '', component: UploadFileComponent, pathMatch: 'full' },
  { path: ':jobId/map', component: MapFieldsComponent },
];

@NgModule({ imports: [RouterModule.forChild(routes)], providers: [], exports: [RouterModule] })
export class UploadRoutingModule {}
