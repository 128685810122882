<div class="actionContainer">
  <button
    mat-raised-button
    [class.spinner]="refreshing"
    [disabled]="refreshing"
    (click)="refreshData()">
    Refresh
  </button>
  <button mat-raised-button (click)="inspectPerson()">Inspect DW Record</button>
  <button
    [class.spinner]="downloading"
    [disabled]="downloading || !latestQuery || viewCount > 10000"
    matTooltip="Only available for 10K or fewer records"
    mat-raised-button
    color="primary"
    (click)="downloadData()">
    Download all results as CSV
  </button>
  <button mat-raised-button (click)="copyToClipboard()">Copy Moves</button>
  <mat-checkbox [formControl]="hideStaleDataControl">Hide Stale Records</mat-checkbox>

  <span class="ledgerSize"
    ><mat-progress-spinner
      mode="indeterminate"
      diameter="20"
      *ngIf="countUpdating; else contactCount"></mat-progress-spinner
    ><ng-template #contactCount>{{viewCount | number}}</ng-template> Matching Contacts</span
  >
</div>
<div class="container  ag-theme-balham" width="100%">
  <ag-grid-angular
    style="height: 100%; width: 100%"
    [rowData]="rowData"
    [tooltipShowDelay]="tooltipShowDelay"
    [columnDefs]="columnDefs"
    [rowModelType]="rowModelType"
    [infiniteInitialRowCount]="infiniteInitialRowCount"
    [components]="components"
    [rowSelection]="rowSelection"
    [cacheBlockSize]="cacheBlockSize"
    [defaultColDef]="defaultColDef"
    (gridReady)="onGridReady($event)"
    [sideBar]="sideBar"
    [masterDetail]="masterDetail"
    [getRowId]="getRowId"
    enableCellTextSelection="true"
    ensureDomOrder="true"
    [serverSideDatasource]="serverSideDatasource"
    (displayedColumnsChanged)="saveColumnState()">
  </ag-grid-angular>
</div>
