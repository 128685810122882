import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AdministrativeService } from 'ldt-identity-service-api';

@Component({
  selector: 'app-add-employee-dialog',
  templateUrl: './add-employee-dialog.component.html',
  styleUrls: ['./add-employee-dialog.component.scss']
})
export class AddEmployeeDialogComponent implements OnInit {

  form: UntypedFormGroup;
  employeeEmail:string; 

  constructor(
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<AddEmployeeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data:any,
    private adminService: AdministrativeService
  ) { 
  }

  ngOnInit() { 
    this.form = this.fb.group({
      employeeEmail: [this.employeeEmail, [Validators.required, Validators.email]],
    })
  }

  save() {
    this.dialogRef.close(this.form.value);
  }

  close() {
    this.dialogRef.close();
  }

}
