<div class="actionContainer">
  <button
    class="tw-m-2.5"
    [class.spinner]="streamsService.refreshing$ | async"
    [disabled]="streamsService.refreshing$ | async"
    mat-raised-button
    (click)="refreshStreams()">
    Refresh
  </button>
</div>

<div class="tw-grid tw-h-full md:tw-grid-cols-3 tw-gap-4">
  <div class="container tw-h-full tw-col-span-3 tw-p-4">
    <ag-grid-angular
      style="height: 100%;"
      class="ag-theme-balham"
      [rowData]="rowData"
      [columnDefs]="columnDefs"
      accentedSort="true"
      [defaultColDef]="defaultColDef"
      enableCellTextSelection="true"
      ensureDomOrder="true"
      (firstDataRendered)="onFirstDataRendered($event)"
      [readOnlyEdit]="true"
      (cellEditRequest)="onCellEditRequest($event)"
      (gridReady)="onGridReady($event)">
    </ag-grid-angular>
  </div>
</div>
