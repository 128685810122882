// Chart Headcount
export const arrDepChartNoSeries: Highcharts.Options = {
  chart: {
    backgroundColor: '#ffffff',
    events: {
      load: function () {
        const chart = this as any;
        const logoUrl = 'assets/img/imagotype2.svg';
        chart.customLogo = chart.renderer
          .image(logoUrl, chart.chartWidth - 131, chart.chartHeight - 34, 121, 24)
          .add();
      },
      redraw: function () {
        const chart = this as any; // Use 'any' type to add custom properties
        const logo = chart.customLogo;
        let logoWidth = 121;
        let logoHeight = 24;
        if (chart.chartWidth < 480) {
          logoWidth = 90; // Smaller width for smaller containers
          logoHeight = logoWidth / 5.04; // Maintain aspect ratio
        }
        if (logo) {
          logo.attr({
            x: chart.chartWidth - (logoWidth + 10),
            y: chart.chartHeight - (logoHeight + 10),
            width: logoWidth,
            height: logoHeight,
          });
        }
      },
    },
  },
  credits: {
    enabled: false,
  },
  title: {
    text: 'Headcount',
    align: 'left',
    style: {
      fontWeight: '600',
    },
  },
  xAxis: {
    type: 'datetime',
    dateTimeLabelFormats: {
      day: '%e %b %Y', // format for the dates on the xAxis
    },
    title: {
      style: {
        fontFamily: '"Montserrat", sans-serif',
      },
    },
  },
  yAxis: [
    {
      allowDecimals: false,
      title: {
        text: 'Number of Employees',
      },
      stackLabels: {
        enabled: false,
      },
    },
  ],
  tooltip: {},
  plotOptions: {
    series: {
      label: {
        connectorAllowed: false,
      },
      cursor: 'pointer',
      states: {
        hover: {
          // enabled: false, // Disable default hover
        },
      },
      marker: {
        enabled: false, // Disable data points
      },
      dataLabels: {
        enabled: false,
      },
      linecap: 'round', // Make the lines rounded instead of angular
    },
    column: {
      stacking: 'normal',
    },
  },
  legend: {
    layout: 'horizontal',
    align: 'left',
    verticalAlign: 'bottom',
    width: '80%',
    itemStyle: {
      fontFamily: '"Montserrat", sans-serif',
    },
  },
};

// Chart Tenure
export const tenureByMonthChartNoSeries: Highcharts.Options = {
  chart: {
    backgroundColor: '#ffffff',
    type: 'line',
    events: {
      load: function () {
        const chart = this as any;
        const logoUrl = 'assets/img/imagotype2.svg';
        chart.customLogo = chart.renderer
          .image(logoUrl, chart.chartWidth - 131, chart.chartHeight - 34, 121, 24)
          .add();
      },
      redraw: function () {
        const chart = this as any; // Use 'any' type to add custom properties
        const logo = chart.customLogo;
        let logoWidth = 121;
        let logoHeight = 24;
        if (chart.chartWidth < 480) {
          logoWidth = 90; // Smaller width for smaller containers
          logoHeight = logoWidth / 5.04; // Maintain aspect ratio
        }
        if (logo) {
          logo.attr({
            x: chart.chartWidth - (logoWidth + 10),
            y: chart.chartHeight - (logoHeight + 10),
            width: logoWidth,
            height: logoHeight,
          });
        }
      },
    },
  },
  credits: {
    enabled: false,
  },
  title: {
    text: 'Tenure by Month',
    align: 'left',
    style: {
      fontWeight: '600',
    },
  },
  xAxis: {
    type: 'datetime',
    dateTimeLabelFormats: {
      day: '%e %b %Y', // format for the dates on the xAxis
    },
    title: {
      style: {
        fontFamily: '"Montserrat", sans-serif',
      },
    },
  },
  yAxis: [
    {
      allowDecimals: false,
      tickAmount: 10,
      title: {
        text: 'Average Tenure (months)',
      },
      stackLabels: {
        enabled: false,
      },
    },
  ],
  tooltip: {
    valueDecimals: 2,
    pointFormat: '{series.name}<br/><b>{point.y}</b> (N={point.sampleSize})',
  },
  plotOptions: {
    column: {
      stacking: 'normal',
    },
    series: {
      label: {
        connectorAllowed: false,
      },
      cursor: 'pointer',
      states: {
        hover: {
          // enabled: false, // Disable default hover
        },
      },
      marker: {
        enabled: false, // Disable data points
      },
      dataLabels: {
        enabled: false,
      },
      linecap: 'round', // Make the lines rounded instead of angular
    },
  },
  exporting: {
    sourceWidth: 1200,
  },
  legend: {
    layout: 'horizontal',
    align: 'left',
    verticalAlign: 'bottom',
    width: '80%',
    itemStyle: {
      fontFamily: '"Montserrat", sans-serif',
    },
  },
};

// Chart Sankey
export const sankeyChartNoSeries: Highcharts.Options = {
  chart: {
    backgroundColor: '#ffffff',
    events: {
      load: function () {
        const chart = this as any;
        const logoUrl = 'assets/img/imagotype2.svg';
        const logoWidth = 121;
        const logoHeight = logoWidth / 5.05;
        const x = chart.chartWidth / 2 - logoWidth / 2;

        // Add the logo image and store the reference in customLogo
        chart.customLogo = chart.renderer
          .image(logoUrl, x, chart.chartHeight - logoHeight - 10, logoWidth, logoHeight)
          .add();
      },
      redraw: function () {
        const chart = this as any;
        const logo = chart.customLogo;

        if (!logo) return; // in case the logo is not initialized

        // Ensure the logo stays centered during a redraw (resize)
        let logoWidth = 121;
        let logoHeight = logoWidth / 5.05;

        if (chart.chartWidth < 480) {
          logoWidth = 90; // for smaller containers
          logoHeight = logoWidth / 5.05;
        }

        // Recalculate the x position to keep it centered
        const x = chart.chartWidth / 2 - logoWidth / 2;

        // Update the logo position and size
        logo.attr({
          x: x,
          y: chart.chartHeight - logoHeight - 10,
          width: logoWidth,
          height: logoHeight,
        });
      },
    },
  },
  credits: {
    enabled: false,
  },
  title: {
    text: 'All Employee Movement',
    style: {
      fontWeight: '600',
    },
  },
  legend: { enabled: false },
  yAxis: { labels: { enabled: false } },
  plotOptions: {
    sankey: {
      dataLabels: {
        enabled: true,
        style: {},
      },
    },
  },
  series: [
    {
      type: 'sankey',
      keys: ['from', 'to', 'weight'],
      data: [],
    },
  ],
  exporting: {
    sourceWidth: 1200,
  },
};

// Chart Poaching
export const wheelChartNoSeries: Highcharts.Options = {
  chart: {
    backgroundColor: '#ffffff',
    events: {
      load: function () {
        const chart = this as any;
        const logoUrl = 'assets/img/imagotype2.svg';
        chart.customLogo = chart.renderer
          .image(logoUrl, chart.chartWidth - 131, chart.chartHeight - 34, 121, 24)
          .add();
      },
      redraw: function () {
        const chart = this as any; // Use 'any' type to add custom properties
        const logo = chart.customLogo;
        let logoWidth = 121;
        let logoHeight = 24;
        if (chart.chartWidth < 480) {
          logoWidth = 90; // Smaller width for smaller containers
          logoHeight = logoWidth / 5.04; // Maintain aspect ratio
        }
        if (logo) {
          logo.attr({
            x: chart.chartWidth - (logoWidth + 10),
            y: chart.chartHeight - (logoHeight + 10),
            width: logoWidth,
            height: logoHeight,
          });
        }
      },
    },
  },
  credits: {
    enabled: false,
  },
  title: {
    text: 'Inter-company Movement',
    style: {
      fontWeight: '600',
    },
  },
  legend: { enabled: false },
  yAxis: { labels: { enabled: false } },
  plotOptions: {
    sankey: {
      dataLabels: {
        enabled: true,
      },
    },
  },
  series: [
    {
      type: 'dependencywheel',
      keys: ['from', 'to', 'weight'],
      data: [],
    },
  ],
  exporting: {
    sourceWidth: 1200,
  },
};
