import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/service/auth.service';

@Component({
  selector: 'app-people',
  templateUrl: './people.component.html',
  styleUrls: ['./people.component.scss'],
})
export class DWPeopleComponent {
  isAdmin: Observable<boolean>;

  constructor(private auth: AuthService) {}

  ngOnInit() {
    this.isAdmin = this.auth.$isAdmin;
  }
}
