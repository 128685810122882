import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CompaniesService } from 'ldt-dw-reader-service-api';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss'],
})
export class CompaniesComponent {
  companyJson: any;
  inputFormGroup: UntypedFormGroup;
  refreshing: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private companiesService: CompaniesService,
    private notify: NotificationService,
    private _formBuilder: UntypedFormBuilder,
    private router: Router
  ) {
    this.inputFormGroup = this._formBuilder.group({
      id: ['', [Validators.required]],
    });

    if (this.route.snapshot.queryParams.id) {
      this.getCompany(this.route.snapshot.queryParams.id);
    }
  }

  getCompany(id: string) {
    if (!id) {
      return;
    }

    let url = '/datawarehouse/companies?id=' + id;
    this.router.navigateByUrl(url);

    this.refreshing = true;
    this.companiesService.getCompanyByLookup(id).subscribe({
      next: (company) => {
        this.companyJson = company;
      },
      error: (err) => {
        console.error(err);
        this.notify.error('Failed to load company: ' + id);
      },
    });

    this.refreshing = false;
  }
}
