import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-job-function-dropdown',
  templateUrl: './job-function-dropdown.component.html',
  styleUrls: ['./job-function-dropdown.component.scss'],
})
export class JobFunctionDropdownComponent {
  @Input() availableJobFunctions: string[] = [];
  @Input() selectedJobFunction: string | undefined = undefined;
  @Output() jobFunctionSelected = new EventEmitter<string | undefined>();

  isSelect: boolean = false;
  highlight: string | undefined;

  get selectedJobFunctionLabel(): string {
    return this.selectedJobFunction === undefined ? 'All' : this.selectedJobFunction;
  }

  toggleSelect() {
    this.isSelect = !this.isSelect;
  }

  select(item: string) {
    this.jobFunctionSelected.emit(item);
    this.toggleSelect();
  }

  setHighlight(item: string) {
    this.highlight = item;
  }
}
