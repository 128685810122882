import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from '../auth/service/auth.service';
import { Org } from 'ldt-identity-service-api';

@Injectable({
  providedIn: 'root'
})
export class NgLedgerService {
  $ledgerApiV2:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  $canAccessV2:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  constructor(
    private auth: AuthService,
  ) { 
    const currentOrg = this.auth.getOrgsValue.find((org: Org) => org.id === this.auth.getSelectedOrgIdValue);
    this.updateSettings(currentOrg);

    this.auth.$getSelectedOrg.subscribe({
      next: (org) => {
        this.updateSettings(org);
      }
    });
  }

  updateSettings(org: Org|undefined) {
    if( org ) {
      let settings:any = org.settings;
      if( settings?.ledger?.uiApiVersion === '2' ) {
        this.setApiVersion(true);
      } else {
        this.setApiVersion(false);
      }

      if( settings?.ledger?.uiApiVersionSelector ) {
        this.setCanAccessV2(true);
      } else {
        this.setCanAccessV2(false);
      }      
    }    
  }

  // Accessor for ledgerApiV2
  getApiVersion(): Observable<boolean> {
    return this.$ledgerApiV2.asObservable();
  }
  getApiVersionValue(): boolean {
    return this.$ledgerApiV2.getValue();
  }
  setApiVersion(value: boolean) {
    this.$ledgerApiV2.next(value);
  }

  getCanAccessV2(): Observable<boolean> {
    return this.$canAccessV2.asObservable();
  }
  getCanAccessV2Value(): boolean {
    return this.$canAccessV2.getValue();
  }
  setCanAccessV2(value: boolean) {
    this.$canAccessV2.next(value);
  }

}
