<div class="content-title">Data Deliveries</div>
<nav mat-tab-nav-bar>
  <a mat-tab-link routerLinkActive="active-tab-nav" routerLink="./report-deliveries"
    >Report Deliveries</a
  >
  <a mat-tab-link routerLinkActive="active-tab-nav" routerLink="./scheduled-reports"
    >Scheduled Reports</a
  >
  <a mat-tab-link routerLinkActive="active-tab-nav" routerLink="./report-definitions"
    >Report Defs</a
  >
  <a mat-tab-link routerLinkActive="active-tab-nav" routerLink="./destinations">Destinations</a>
  <a mat-tab-link routerLinkActive="active-tab-nav" routerLink="./destination-types"
    >Destination Types</a
  >
</nav>
<router-outlet></router-outlet>
