<div class="content-title">
  {{org.name}} Team
  <button mat-raised-button class="button invite-button" (click)="showInviteDialog()">
    <div class="invite-button-text"><mat-icon>add</mat-icon>Invite Users</div>
  </button>  
</div>
<div class="team-tabs">
  <mat-tab-group class="mat-tab-fill-height">
    <mat-tab label="Users">
      <app-team-users [canAdmin]="canAdmin" [org]="org" ></app-team-users>
    </mat-tab>
    <mat-tab label="Invitations">
      <ng-template matTabContent>
      <app-team-invitations [canAdmin]="canAdmin" [org]="org" ></app-team-invitations>
      </ng-template>
    </mat-tab>
    <mat-tab label="Service Accounts">
      <ng-template matTabContent>
      <app-team-service-accounts [canAdmin]="canAdmin" [org]="org" ></app-team-service-accounts>
      </ng-template>
    </mat-tab>        
  </mat-tab-group>
</div>