<div
  class="badge-container tw-flex tw-items-center"
  [ngClass]="{
    'tw-bg-gray-600 tw-text-white': color === 'dark',
    'tw-bg-gray-200 tw-text-gray-800': color === 'light'
  }">
  <span class="tw-font-13">{{ value }}</span>
  <button
    class="delete-icon-btn tw-relative"
    [ngClass]="{
      'tw-text-white hover:tw-border-white': color === 'dark',
      'tw-text-gray-500 hover:tw-bg-gray-300 hover:tw-text-gray-700 hover:tw-border-transparent': color === 'light'
    }"
    (click)="removeBadge()">
    <mat-icon class="delete-icon tw-absolute">close</mat-icon>
  </button>
</div>
