<div class="available-integrations-container tw-mt-6">
  <div *ngIf="loading" class="tw-mt-10">
    <mat-spinner></mat-spinner>
  </div>

  <!-- display error message if error fetching data -->
  <div *ngIf="showErrorMsg">
    <div
      class="tw-flex tw-items-center tw-p-4 tw-mb-4 tw-text-sm tw-text-red-800 tw-border tw-border-red-300 tw-rounded-lg tw-bg-red-50"
      role="alert">
      <mat-icon class="material-icons-outlined tw-text-[1.2rem] tw-h-5"> info </mat-icon>
      <div>Something went wrong. Please try again later.</div>
    </div>
  </div>

  <!-- no integrations yet UI -->
  <div class="tw-mt-10" *ngIf="noData && !loading">
    <div
      class="tw-border tw-border-dashed tw-rounded tw-p-5 tw-mt-6 tw-mr-6 tw-flex-col tw-items-center tw-flex">
      <mat-icon class="tw-text-gray-600 tw-text-4xl tw-w-9 tw-h-8">local_shipping</mat-icon>
      <h3 class="tw-mb-0 tw-text-gray-800">No integrations added</h3>
      <p>You have not added any integrations. Add one below.</p>

      <a mat-raised-button color="primary" (click)="goToAvailableIntegrations()">
        Add Integration
      </a>
    </div>
  </div>

  <!-- Ag-grid integrations table -->
  <div *ngIf="!noData && !loading">
    <div class="tw-flex tw-justify-between tw-items-center">
      <p class="tw-my-0">
        You have <span class="badge-checked-items">{{ rowData.length}}</span> currently configured
        data delivery integrations.
      </p>

      <button
        mat-raised-button
        class="tw-ml-auto tw-mr-3"
        [class.spinner]="refreshing"
        [disabled]="refreshing"
        (click)="refreshTable()">
        Refresh
      </button>
      <a mat-raised-button color="primary" (click)="goToAvailableIntegrations()">
        Add Integration
      </a>
    </div>

    <div id="myGrid" class="tw-mt-5">
      <ag-grid-angular
        #agGrid
        style="width: 100%; height: 100%"
        class="ag-theme-quartz"
        [rowData]="rowData"
        [gridOptions]="gridOptions"
        [columnDefs]="colDefs"
        (cellClicked)="onCellClicked($event)"
        [defaultColDef]="defaultColDef"
        [rowSelection]="'multiple'"
        [animateRows]="true"
        [domLayout]="'autoHeight'"></ag-grid-angular>
    </div>
  </div>
</div>
