<div class="tw-flex tw-justify-between tw-items-center tw-relative">
  <h1 mat-dialog-title class="tw-mb-0">Test Destination Status and Details</h1>
  <button
    mat-icon-button
    aria-label="Close dialog"
    (click)="closeDialog()"
    class="tw-text-gray-500 tw-bg-gray-100 hover:tw-text-gray-800 hover:tw-bg-gray-200 tw-absolute tw-w-8 tw-h-8 tw-top-[-10px] tw-right-[-10px] tw-leading-5">
    <mat-icon>close</mat-icon>
  </button>
</div>

<div mat-dialog-content class="tw-max-h-none">
  <div class="tw-flex tw-flex-col tw-h-full tw-justify-between tw-stretch">
    <div class="tw-flex tw-flex-col tw-mt-5 tw-h-[600px]">
      <p class="tw-mb-2 tw-flex tw-flex-col">
        <span class="tw-font-semibold tw-mr-2">Destination Name: </span>
        <span class="tw-inline-block code-font-direct">{{destinationData.name}}</span>
      </p>

      <!-- type_id = 1 -->
      <div *ngIf="destinationTypeId === 1">
        <app-config-info
          [itemName]="'Role ARN'"
          [itemValue]="destinationConfig.role_arn"></app-config-info>

        <app-config-info
          [itemName]="'S3 Bucket Location'"
          [itemValue]="getFormattedS3Uri(destinationData.id)"></app-config-info>
      </div>

      <!-- type_id = 2 -->
      <div *ngIf="destinationTypeId === 2" class="tw-mb-2 tw-flex tw-flex-col">
        <app-config-info
          [itemName]="'Bucket Identifier'"
          [itemValue]="destinationConfig.bucket_identifier"></app-config-info>

        <app-config-info
          [itemName]="'Role ARN'"
          [itemValue]="destinationConfig.role_arn"></app-config-info>

        <app-config-info
          [itemName]="'External ID'"
          [itemValue]="destinationConfig.external_id"></app-config-info>
      </div>

      <!-- type_id = 3 -->
      <div *ngIf="destinationTypeId === 3" class="tw-mb-2 tw-flex tw-flex-col">
        <app-config-info
          [itemName]="'Bucket Identifier'"
          [itemValue]="destinationConfig.bucket_identifier"></app-config-info>
      </div>

      <div
        *ngIf="destinationTypeId !== 1"
        class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-48">
        <div class="tw-mb-5">
          <app-test-status-badge
            [testStatus]="destinationData.status"
            [isLoading]="isLoading"
            [timeStampFromConfig]="destinationData.tested_at"></app-test-status-badge>
        </div>

        <button
          mat-raised-button
          color="primary"
          [disabled]="isLoading"
          (click)="testDestinationStatus(orgId, destinationData)">
          Test Again
        </button>
      </div>

      <div class="tw-flex tw-justify-between tw-mt-auto tw-ml-0 tw-mr-0 tw-pb-3">
        <button mat-raised-button class="tw-ml-4" (click)="closeDialog()">Close</button>
      </div>
    </div>
  </div>
</div>
