<div class="app-ledger-container" width="100%">
  <div class="content-title">
    <a [routerLink]="['/' + orgId + '/ledgers']">Ledgers</a> 
    <mat-icon class="navArrow">arrow_forward_ios</mat-icon>    
    <div 
      class="ledgerName" 
      contenteditable="true"
      (blur)="onLedgerNameChange($event.target)" 
      [textContent]="ledger.name"
      (keydown)="ignoreCR($event)">
    </div>
    <div *ngIf="($canAccessV2 | async) || (isAdmin | async)">
      v1 <mat-slide-toggle [checked]="$ledgerApiV2 | async" (change)="setLedgerVersion($event)"></mat-slide-toggle> v2
    </div>

    <flatfile-button *ngIf="!atContactLimit" class="button add-button" [disabled]="atContactLimit" [licenseKey]="licenseKey" [settings]="settings" [customer]="customer"
    [onData]="onData.bind(this)" [onRecordChange]="onRecordChange.bind(this)" >
      <div class="add-file-button-text"><mat-icon>add</mat-icon>  Add Contacts</div>
    </flatfile-button>
    <mat-checkbox class="v2ImportCheckbox" *ngIf="isAdmin | async" [(ngModel)]="useV2Import">V2 Import</mat-checkbox>
  </div>
  <div 
    class="content-description" 
    contenteditable="true"
    attr.placeholder="{{placeholder}}"
    (blur)="onLedgerDescriptionChange($event.target)"
    (keydown)="ignoreCR($event)"
    [textContent]="ledger.description">
  </div>
  <nav class="ledger-tabs" mat-tab-nav-bar>
    <a *ngIf="!($ledgerApiV2 | async)" mat-tab-link routerLinkActive="active-tab-nav" routerLink="./overview">Overview</a>
    <a *ngIf="$ledgerApiV2 | async" mat-tab-link routerLinkActive="active-tab-nav" routerLink="./overview-v2">Overview</a>
    <a *ngIf="!($ledgerApiV2 | async)" mat-tab-link routerLinkActive="active-tab-nav" routerLink="./details">Details</a>
    <a *ngIf="$ledgerApiV2 | async" mat-tab-link routerLinkActive="active-tab-nav" routerLink="./details-v2">Details</a>
    <a mat-tab-link routerLinkActive="active-tab-nav" routerLink="./imports">Imports
      <mat-progress-spinner *ngIf="(importsInProgress$ | async)?.processing || (importsInProgress$ | async)?.importing" matTooltip="Imports are currently in progress" mode="indeterminate" diameter="20"></mat-progress-spinner>      
    </a>
    <a *ngIf="isAdmin | async" mat-tab-link routerLinkActive="active-tab-nav" routerLink="./compare">Compare</a>
    <a *ngIf="isAdmin | async" mat-tab-link routerLinkActive="active-tab-nav" routerLink="./audit">Audit</a>
  </nav>
  <div class="noContactsContainer" *ngIf="ledger.size == 0 && !hasImports">
    <div *ngIf="!atContactLimit" class="content-description">
      It looks like you don't have any contacts in this ledger yet. Press the button below to get started.
    </div>      
    <flatfile-button *ngIf="!atContactLimit" class="button add-button noContactsButton" [licenseKey]="licenseKey" [settings]="settings" [customer]="customer"
    [onData]="onData.bind(this)" [onRecordChange]="onRecordChange.bind(this)" >
      <div class="add-file-button-text"><mat-icon>add</mat-icon>  Add Contacts</div>
    </flatfile-button>
    <div *ngIf="atContactLimit" class="content-description">
      It looks like you don't have any contacts in this ledger yet, but you're also at your limit. Contact sales to increase your limit and add contacts to this ledger.
    </div>      
  </div>
  <router-outlet *ngIf="ledger?.size > 0 || hasImports" width="100%"></router-outlet>  
</div>
