<div class="actionContainer" *ngIf="canAdmin">
    <button mat-raised-button color="warn" [disabled]="!rowsSelected" (click)="removeSelected()">Remove Selected Service Accounts</button>
    <button mat-raised-button color="primary" (click)="showCreateDialog()">Create Service Account</button>
    <button mat-raised-button [class.spinner]="refreshing" [disabled]="refreshing" (click)="refreshOrgServiceAccounts()">Refresh</button>
    <span class="orgId"><b>Org ID:</b> {{org.id}}</span>
</div>
<div class="container" width="100%">
    <ag-grid-angular style="height: 100%;" class="ag-theme-alpine" [rowData]="rowData"
        [columnDefs]="columnDefs"
        [components]="components" [rowSelection]="rowSelection" [defaultColDef]="defaultColDef"
        [tooltipShowDelay]="tooltipShowDelay" enableCellTextSelection="true" ensureDomOrder="true"
        (gridReady)="onGridReady($event)" (selectionChanged)='onSelectionChanged($event)'>
    </ag-grid-angular>
</div>