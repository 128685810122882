import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/service/auth.service';


@Component({
    selector: 'app-account-management',
    templateUrl: './account-management.component.html',
    styleUrls: ['./account-management.component.scss']
})
export class AccountManagementComponent implements OnInit {
    constructor(private authService: AuthService, private fb: UntypedFormBuilder, private router: Router) { }
    isChangePass = false;
    isForgetPass = false;
    passwordChangeSuccessful = false;
    passwordMatchError = false;
    invalidAttempt = false;
    filledBtnTitle = 'Login';
    submitButtonValue = 'Reset';
    title = 'Account details';
    placeholder = 'john.doe@corp.com';
    currentEmail: string;
    name: string;
    email: string | undefined;
    password = '';
    oldPassword = '';
    emailSent = false;
    enterPassword = false;
    validationError = false;
    isValidating = false;
    lengthCriteriaMet: boolean;
    unvalidated: '';
    pValidation = [
        {
            func: this.hasLength,
            text: 'Between 8 and 64 characters long'
        }
    ];
    get borderedBtnTitle(): string {
        return this.isChangePass ? 'Cancel' : 'Change Password';
    }
    form: UntypedFormGroup | undefined;

    ngOnInit() {
        this.createForm();
        if (this.authService.getUserValue) {
            this.currentEmail = this.authService.getUserValue.email || '';
            this.name = this.authService.getUserValue.name || '';
        }
    }

    toggleForm(): void {
        this.isChangePass = !this.isChangePass;
        this.updateFormState();
    }
    goBack() {
        if (!this.isChangePass) {
            this.router.navigate(['/main']);
        } else {
            this.isChangePass = !this.isChangePass;
        }
    }
    update(event: any) {
        this.unvalidated = event.target.value;
    }
    showValidationTooltip() {
        this.validationError = false;
        this.isValidating = true;
    }
    hasLength(group: UntypedFormGroup) {
        const pass = group.get('passwordNew')!.value;
        this.lengthCriteriaMet = pass.length >= 8 && pass.length <= 64;
        return this.lengthCriteriaMet;
    }


    async onSubmit(): Promise<void> {
        if (this.form && this.form.value) {
            this.lengthCriteriaMet = this.hasLength(this.form);

            const oldPassword: string = this.form.controls.password.value;
            const newPassword: string = this.form.controls.passwordNew.value;
            const confirmPassword: string = this.form.controls.passwordConfirm.value;
            if (confirmPassword === newPassword) {
                this.passwordMatchError = false;
            } else {
                this.passwordMatchError = true;
                return;
            }
            if (oldPassword === newPassword) {
                this.invalidAttempt = true;
            }
            if (!this.lengthCriteriaMet) {
                this.validationError = true;
                return;
            }
            if (oldPassword !== '' && newPassword !== '' && (oldPassword !== newPassword)) {
                // TODO - change to new reset service
                // const res = await this.authService.updatePassword(oldPassword, newPassword);
                // this.passwordChangeSuccessful = res.success;
                // if (!this.passwordChangeSuccessful) {
                //     this.invalidAttempt = true;
                // } else {
                //     this.router.navigate(['/main']);
                //     this.invalidAttempt = false;
                // }
            }
        }
    }
    checkPasswords(group: UntypedFormGroup) { // here we have the 'passwords' group
        const pass = group.get('passwordNew')!.value;
        const confirmPass = group.get('passwordConfirm')!.value;
        return pass === confirmPass ? null : { notSame: true };
    }
    createForm(): void {
        this.form = this.fb.group({
            email: new UntypedFormControl(this.currentEmail, Validators.required),
            password: new UntypedFormControl('', Validators.required),
            passwordNew: new UntypedFormControl('', Validators.required),
            passwordConfirm: new UntypedFormControl('')
        }, { validator: ConfirmedValidator('passwordNew', 'passwordConfirm') });
    }

    updateFormState(): void {
        if (!this.form) return;

        if (this.isChangePass) {
            this.form = this.fb.group({
                email: new UntypedFormControl('', Validators.required),
                password: new UntypedFormControl('', Validators.required),
                passwordNew: new UntypedFormControl('', Validators.required),
                passwordConfirm: new UntypedFormControl('')
            }, { validator: ConfirmedValidator('passwordNew', 'passwordConfirm') });
        } else {
            this.form.removeControl('password');
            this.form.removeControl('password-new');
            this.form.removeControl('password-confirm');
            this.passwordChangeSuccessful = false;
            this.invalidAttempt = false;
        }
    }

    get f() {
        return this.form!.controls;
    }
    forgotPassword() {
        this.router.navigate(['/reset-password']);
    }
}

export function ConfirmedValidator(controlName: string, matchingControlName: string) {
    return (formGroup: UntypedFormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];
        if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
            return;
        }
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ confirmedValidator: true });
        } else {
            matchingControl.setErrors(null);
        }
    };
}
