import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
    UrlTree
} from '@angular/router';

import { AuthService } from '../auth/service/auth.service';

@Injectable({ providedIn: 'root' })
export class ActiveOrgGuard implements CanActivate {
    constructor(private auth: AuthService, private router: Router) { }

    async canActivate(_next: ActivatedRouteSnapshot,
        _state: RouterStateSnapshot): Promise<boolean | UrlTree> {

        if( this.auth.isOrgActiveValue ) {
            return true;
        } else {
            this.router.navigate(['/invitations']);
            return false;
        }
    }
}
