import {
  Component,
  Input,
  HostBinding,
  Output,
  EventEmitter,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { DWColumn } from 'src/app/data-warehouse/dw-column';
import { UIFilter } from '../filters.component';
import { SimpleFilterComponent } from '../simple-filter/simple-filter.component';

@Component({
  selector: 'app-category-filter',
  templateUrl: './category-filter.component.html',
  styleUrls: ['./category-filter.component.scss'],
})
export class CategoryFilterComponent {
  @Input() filters: DWColumn[] = [];
  @Input() category: string = '';
  @Input() groupIndex?: number;
  @Output() categoryFilterAdded = new EventEmitter<{ category: string; groupIndex?: number }>();
  @Output() remove = new EventEmitter<{ filter: UIFilter }>();
  @Output() removeCategory = new EventEmitter<void>();
  @Output() update = new EventEmitter<{ filter: UIFilter }>();
  @HostBinding('class') class = 'category-filter-component query-item tw-relative tw-flex tw-mb-3';
  @ViewChildren(SimpleFilterComponent)
  private simpleFilterComponents: QueryList<SimpleFilterComponent>;

  get simpleFilters(): SimpleFilterComponent[] {
    return this.simpleFilterComponents.toArray();
  }

  ngOnInit() {
    if (this.category === 'current job') {
      this.categoryName = 'Current Job';
    } else if (this.category === 'past jobs') {
      this.categoryName = 'Previous Jobs';
    }
  }

  categoryName: string = '';

  addCategoryFilter(category: string) {
    this.categoryFilterAdded.emit({ category, groupIndex: this.groupIndex }); // Emit group index
  }

  onRemove(filter: UIFilter) {
    this.remove.emit({ filter: filter });
  }

  onUpdate(event: { filter: UIFilter }) {
    this.update.emit(event);
  }

  onDeleteCategory() {
    this.removeCategory.emit();
  }
}
