<div class="actionContainer">
  <button mat-raised-button color="primary" (click)="showCreateDialog()">Create Employee</button>
  <button
    [class.spinner]="refreshing"
    [disabled]="refreshing"
    mat-raised-button
    (click)="updateEmployees()">
    Refresh
  </button>
  <div>
    <mat-chip-list>
      <mat-chip color="primary" selected>viewer</mat-chip>
      <mat-chip color="accent" selected>editor</mat-chip>
      <mat-chip color="warn" selected>admin</mat-chip>
    </mat-chip-list>
  </div>
</div>
<div class="container" width="100%">
  <ag-grid-angular
    style="height: 100%;"
    class="ag-theme-balham"
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    accentedSort="true"
    [components]="components"
    [defaultColDef]="defaultColDef"
    enableCellTextSelection="true"
    ensureDomOrder="true"
    (gridReady)="onGridReady($event)">
  </ag-grid-angular>
</div>
