import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CellClassParams,
  ColDef,
  ColGroupDef,
  GridReadyEvent,
  ITextFilterParams,
  SideBarDef,
  ValueGetterParams,
} from 'ag-grid-community';
import { Contact, Ledger, LedgerContactsService } from 'ldt-ledger-service-api';
import { forkJoin, Observable } from 'rxjs';
import { AuthService } from 'src/app/auth/service/auth.service';
import { LedgerContactsService as LedgerContactsServiceV2 } from 'ldt-ledger-service-api-v2';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';

@Component({
  selector: 'app-ledger-version-compare',
  templateUrl: './ledger-version-compare.component.html',
  styleUrls: ['./ledger-version-compare.component.scss'],
})
export class LedgerVersionCompareComponent implements OnInit {
  ledger: Ledger;
  orgId: string;
  isAdmin: Observable<boolean>;
  v1Data: any;
  v2Data: any;
  rowData: any;

  constructor(
    private ledgerService: LedgerContactsService,
    private ledgerServiceV2: LedgerContactsServiceV2,
    private route: ActivatedRoute,
    private auth: AuthService
  ) {}
  ngOnInit(): void {
    // Get the ledger from the resolver and org ID from the route
    this.ledger = this.route.parent?.snapshot.data.userdata;
    let routeOrg = this.route.parent?.snapshot.paramMap.get('orgId');
    if (routeOrg) {
      this.orgId = routeOrg;
    }
    this.isAdmin = this.auth.$isAdmin;

    forkJoin([this.getV1Contacts(), this.getV2Contacts()]).subscribe((r) => {
      let v2c = r[1].contacts as unknown as Contact[];
      this.rowData = r[0].contacts?.map((c, i) => {
        const rd: any = {
          id: c.id,
          v1Data: this.flattenObject(c),
          v2Data: this.flattenObject(v2c.find((v2) => v2.id === c.id)),
        };
        console.log(rd.v2Data['current_position.company.name']);
        return rd;
      });
      console.log(this.rowData);
      console.log(v2c.map((c) => c.id));
    });

    this.getV1Contacts();
    this.getV2Contacts();
  }

  getV1Contacts() {
    return this.ledgerService.getLedgerContacts(
      this.ledger.id,
      this.orgId,
      undefined,
      undefined,
      undefined,
      'id',
      1000
    );
  }

  getV2Contacts() {
    return this.ledgerServiceV2.getLedgerContacts(this.orgId, this.ledger.id, 'id', 1000);
  }

  flattenObject(ob: any) {
    var toReturn: any = {};

    for (var i in ob) {
      if (!ob.hasOwnProperty(i)) continue;

      if (typeof ob[i] == 'object' && ob[i] !== null) {
        var flatObject = this.flattenObject(ob[i]);
        for (var x in flatObject) {
          if (!flatObject.hasOwnProperty(x)) continue;

          toReturn[i + '.' + x] = flatObject[x];
        }
      } else {
        toReturn[i] = ob[i];
      }
    }
    return toReturn;
  }

  compareGetter(params: ValueGetterParams) {
    return this.comparator(params).value;
  }

  getCellColor(params: CellClassParams) {
    return { backgroundColor: this.comparator(params).color };
  }

  comparator(params: any) {
    const field = params.colDef.headerName || '';
    const fieldV2 = this.fieldMappings[field];

    let valv1 = params.data.v1Data[field];
    let valv2 = params.data.v2Data[fieldV2];

    if (field.endsWith('At')) {
      if (valv1) valv1 = new Date(valv1).toLocaleString('en-us', { timeZone: 'UTC' });
      if (valv2) valv2 = new Date(valv2).toLocaleString('en-us', { timeZone: 'UTC' });
    }

    if (!valv1 && valv2) {
      return { value: valv2, color: 'green' };
    } else if (valv1 && !valv2) {
      return { value: valv1 + ' => [EMPTY]', color: 'tomato' };
    } else if (valv1 && valv2 && valv1 !== valv2) {
      return { value: valv1 + ' => ' + valv2, color: 'yellow' };
    } else {
      return { value: valv1, color: 'white' };
    }
  }

  gridApi: any;
  gridColumnApi: any;
  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.columnApi.sizeColumnsToFit();
  }

  private simpleFilterParams: ITextFilterParams = {
    maxNumConditions: 1,
  };
  defaultColDef: ColDef = {
    sortable: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    filterParams: this.simpleFilterParams,
    resizable: true,
    flex: 1,
    minWidth: 100,
    enablePivot: false,
    menuTabs: ['generalMenuTab', 'filterMenuTab'],
  };
  sideBar: SideBarDef = {
    defaultToolPanel: 'columns',
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressColumnFilter: true,
          suppressColumnSelectAll: true,
        },
      },
    ],
  };

  columnDefs: (ColDef | ColGroupDef)[] = [
    {
      headerName: 'id',
      valueGetter: (params: any) => {
        return params.data.v1Data['id'];
      },
      cellStyle: this.getCellColor.bind(this),
    },
    {
      headerName: 'company',
      valueGetter: this.compareGetter.bind(this),
      cellStyle: this.getCellColor.bind(this),
    },
    {
      headerName: 'isVerified',
      valueGetter: this.compareGetter.bind(this),
      cellStyle: this.getCellColor.bind(this),
    },
    {
      headerName: 'companyInfo.domain',
      valueGetter: this.compareGetter.bind(this),
      cellStyle: this.getCellColor.bind(this),
    },
    {
      headerName: 'companyInfo.industry',
      valueGetter: this.compareGetter.bind(this),
      cellStyle: this.getCellColor.bind(this),
    },
    {
      headerName: 'companyInfo.linkedin',
      valueGetter: this.compareGetter.bind(this),
      cellStyle: this.getCellColor.bind(this),
    },
    {
      headerName: 'companyInfo.location',
      valueGetter: this.compareGetter.bind(this),
      cellStyle: this.getCellColor.bind(this),
    },
    {
      headerName: 'companyInfo.size',
      valueGetter: this.compareGetter.bind(this),
      cellStyle: this.getCellColor.bind(this),
    },
    {
      headerName: 'contactInfo.email',
      valueGetter: this.compareGetter.bind(this),
      cellStyle: this.getCellColor.bind(this),
    },
    {
      headerName: 'contactInfo.emailStatus',
      valueGetter: this.compareGetter.bind(this),
      cellStyle: this.getCellColor.bind(this),
    },
    {
      headerName: 'createdAt',
      valueGetter: this.compareGetter.bind(this),
      cellStyle: this.getCellColor.bind(this),
    },
    {
      headerName: 'importCompany',
      valueGetter: this.compareGetter.bind(this),
      cellStyle: this.getCellColor.bind(this),
    },
    {
      headerName: 'importTitle',
      valueGetter: this.compareGetter.bind(this),
      cellStyle: this.getCellColor.bind(this),
    },
    {
      headerName: 'importTitleDomain',
      valueGetter: this.compareGetter.bind(this),
      cellStyle: this.getCellColor.bind(this),
    },
    {
      headerName: 'importTitleLevel',
      valueGetter: this.compareGetter.bind(this),
      cellStyle: this.getCellColor.bind(this),
    },
    {
      headerName: 'jobStartedAt',
      valueGetter: this.compareGetter.bind(this),
      cellStyle: this.getCellColor.bind(this),
    },
    {
      headerName: 'lastInfoChangedAt',
      valueGetter: this.compareGetter.bind(this),
      cellStyle: this.getCellColor.bind(this),
    },
    {
      headerName: 'lastJobChangedAt',
      valueGetter: this.compareGetter.bind(this),
      cellStyle: this.getCellColor.bind(this),
    },
    {
      headerName: 'ledgerImportId',
      valueGetter: this.compareGetter.bind(this),
      cellStyle: this.getCellColor.bind(this),
    },
    {
      headerName: 'linkedin',
      valueGetter: this.compareGetter.bind(this),
      cellStyle: this.getCellColor.bind(this),
    },
    {
      headerName: 'name',
      valueGetter: this.compareGetter.bind(this),
      cellStyle: this.getCellColor.bind(this),
    },
    {
      headerName: 'referenceId',
      valueGetter: this.compareGetter.bind(this),
      cellStyle: this.getCellColor.bind(this),
    },
    {
      headerName: 'title',
      valueGetter: this.compareGetter.bind(this),
      cellStyle: this.getCellColor.bind(this),
    },
    {
      headerName: 'titleDomain',
      valueGetter: this.compareGetter.bind(this),
      cellStyle: this.getCellColor.bind(this),
    },
    {
      headerName: 'titleLevel',
      valueGetter: this.compareGetter.bind(this),
      cellStyle: this.getCellColor.bind(this),
    },
  ];

  fieldMappings: any = {
    company: 'current_position.company.name',
    'companyInfo.domain': 'current_position.company.domain',
    'companyInfo.industry': 'current_position.company.industry',
    'companyInfo.linkedin': 'current_position.company.linkedin',
    'companyInfo.location': 'current_position.company.location',
    'companyInfo.size': 'current_position.company.employee_count',
    'contactInfo.email': 'current_position.email',
    'contactInfo.emailStatus': 'current_position.email_status',
    createdAt: 'created_at',
    id: 'id',
    importCompany: 'imported.company',
    importTitle: 'imported.title',
    importTitleDomain: 'imported.derived_domain',
    importTitleLevel: 'imported.derived_level',
    isVerified: 'is_verified',
    jobStartedAt: 'current_position.started_at',
    lastInfoChangedAt: 'last_info_changed_at',
    lastJobChangedAt: 'last_company_changed_at',
    ledgerImportId: 'imported.batch_id',
    linkedin: 'linkedin',
    name: 'name',
    referenceId: 'reference_id',
    title: 'current_position.title',
    titleDomain: 'current_position.function',
    titleLevel: 'current_position.level',
    'imported company': 'importCompany',
  };
}
