import { formatNumber } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { GridApi } from 'ag-grid-community';
import { ManagedJobsService } from 'ldt-clean-service-api';
import { DeleteConfirmationComponent } from 'src/app/delete-confirmation/delete-confirmation.component';
import { BtnCellRenderer } from 'src/app/shared/ag-grid-button/button-cell-renderer.component';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';
import { MjCreateDialogComponent } from './mj-create-dialog/mj-create-dialog.component';

@Component({
  selector: 'app-admin-clean-managed-jobs',
  templateUrl: './managed-jobs.component.html',
  styleUrls: ['./managed-jobs.component.scss']
})
export class ManagedJobsComponent implements OnInit {

  /// AG -GRID --------------
  private simpleFilterParams: any = {
    filterOptions: ['contains'],
    suppressAndOrCondition: true    
  }    
  defaultColDef = {
    sortable: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    resizable: true,
    filterParams: this.simpleFilterParams,
    menuTabs: ['generalMenuTab','filterMenuTab']
  }
  // TODO: priority field and ordering
  columnDefs = [
    { field: 'clean_job_id', cellRenderer: 'loadingRenderer', maxWidth: 150, rowDrag: true },
    { field: 'org.name' },
    { field: 'job.name', headerName: 'Name' },
    { field: 'job.status', cellRenderer: 'activatingRenderer', headerName: 'State', filter: true},
    { field: 'job.line_count', headerName: 'Size',
      valueFormatter: (params: any) => { return formatNumber(params.value, this.locale)}
     },
    { field: 'job.date', headerName: 'Date' },
    { headerName: 'Delete Job',
      filter: false,
      maxWidth: 150,
      sortable: false,
      cellRenderer: BtnCellRenderer, 
      cellRendererParams: {
        onClick: this.deleteManagedJob.bind(this),
        label: 'Delete'
    }}    
  ];
  // TODO: type this
  // rowData: ManagedJob[];
  rowData: any[];

  private gridApi: GridApi;

  components = {
    loadingRenderer: function (params:any) {
      if (params.value !== undefined) {
        return params.value;
      } else {
        return "<img src=\"https://www.ag-grid.com/example-assets/loading.gif\">";
      }
    },
    activatingRenderer: function (params:any) {
      if (params.value === 'activating') {
        return params.value + " <img src=\"https://www.ag-grid.com/example-assets/loading.gif\"> <a href='https://grafana.gotlivedata.io/d/M8JotBV7z/jobs-metrics?orgId=1&refresh=1m&var-JobId=" + params.data.clean_job_id + "' target='_blank'>view in Grafana</a>";
      } else {
        return params.value;
      }
    },    
  };  
  onGridReady(params: any) {
    this.gridApi = params.api;
      
    // params.api.setDatasource(this);
    this.gridApi.sizeColumnsToFit();
  };

  refreshing:boolean = true;
  status:string;

  constructor(@Inject(LOCALE_ID) private locale: string, private dialog: MatDialog, private notify: NotificationService, private clean: ManagedJobsService) { }

  ngOnInit() {
    this.updateJobs();
    this.updateStatus();
  }

  updateStatus() {
    this.clean.getInfo().subscribe(r => {
      this.status = r.managedJobsStatus;
    })
  }

  updateJobs() {
    this.gridApi?.showLoadingOverlay();
    this.refreshing = true;
    this.clean.getManagedJobs().subscribe({
      next: res => {
        this.rowData = res;
        this.refreshing = false;
        this.gridApi?.hideOverlay();
      }, 
      error: () => {
        this.refreshing = false;
        this.notify.error("Error getting managd job data");
      }
    })
  }

  onRowDragEnd($event:any) {
    if ($event.overIndex === 0) {
      this.confirmActiveJobChange();
    } else {
      this.setJobPriorities();
    }
  }

  rowClassRules = {
    'activeJob': function (params:any) {
      return params.data.job.status === 'activating';
    }
  };

  confirmActiveJobChange() {
    const confirmDialog = this.dialog.open(DeleteConfirmationComponent, {
      data: {
        title: 'Confirm Active Job Change',
        message: 'This will change the currently active job. Are you sure you want to do this?'
      }
    });
    confirmDialog.afterClosed().subscribe((result: boolean) => {
      if (result === true) {
        this.notify.success("Active Job Changed - it may take a few minutes to be reflected here.")
        this.setJobPriorities();
      } else {
        this.updateJobs();
      }
    });    
  }  

  setJobPriorities() {
    let priorities:string[] = [];
    this.gridApi.forEachNode(n => {
      priorities.push(n.data.clean_job_id)
    })
    this.clean.putManagedJobsQueue(priorities).subscribe({
      next: res => {
        this.notify.success("Job priority set");
      }, 
      error: () => {
        this.notify.error("Error changing job priorities - order reset");
        this.updateJobs();
      }
    });
  }

  pauseManagedJobs() {
    const confirmDialog = this.dialog.open(DeleteConfirmationComponent, {
      data: {
        title: 'Confirm Pausing Managed Jobs',
        message: 'Pausing managed jobs will prevent any managed job from running. Are you sure you want to do this?'
      }
    });
    confirmDialog.afterClosed().subscribe((result: boolean) => {
      if (result === true) {
        this.clean.pauseManagedJobs().subscribe(() => {
          this.notify.success("Managed jobs paused");
          this.updateStatus();
        }, () => {
          this.notify.error("Error pausing managed jobs");
        })
      }
    });    
  } 

  resumeManagedJobs() {
    this.clean.resumeManagedJobs().subscribe({
      next: () => {
        this.notify.success("Managed jobs resumed");
        this.updateStatus();
      }, 
      error: () => {
        this.notify.error("Error resuming managed jobs");
      }
    })
  }   

  showCreateDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "500px";
    const dialogRef = this.dialog.open(MjCreateDialogComponent, dialogConfig)
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        let mjData:any = {
          jobId: data.jobId
        };
        this.clean.createManagedJob(mjData).subscribe(() => {
          this.notify.success("Set job " + data.jobId + " as a managed job")
          this.updateJobs();
        }, () => {
          this.notify.error("Error creating managed job");
        })
      }
    })
  }

  deleteManagedJob(evt: any) {
    const confirmDialog = this.dialog.open(DeleteConfirmationComponent, {
      data: {
        title: 'Confirm Deleting Job',
        message: 'This job will be removed from the queue. Are you sure you want to do this?'
      }
    });
    confirmDialog.afterClosed().subscribe((result: boolean) => {
      if (result === true) {
        this.clean.deleteManagedJob(evt.rowData.clean_job_id).subscribe(() => {
          this.notify.success("Managed job deleted");
          this.updateJobs();
        }, () => {
          this.notify.error("Error deleting managed job");
        })
      }
    });    
  }
}
