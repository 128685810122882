import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { CellClassParams, ColDef, ColGroupDef, GridApi, GridReadyEvent } from 'ag-grid-community';
import { PersonSearchFilter, QueryDwRequest, SearchPersonsDataRequestData, SearchService } from 'ldt-dw-reader-service-api';
import * as moment from 'moment';
import { ClipboardService } from 'ngx-clipboard';
import { debounceTime, map, switchMap } from 'rxjs';
import { NotificationService } from '../shared/notification-service/notification.service';

@Component({
  selector: 'app-techno-target',
  templateUrl: './techno-target.component.html',
  styleUrls: ['./techno-target.component.scss']
})
export class TechnoTargetComponent implements OnInit {

  techSearchControl = new UntypedFormControl();
  searchTechs:any;

  companiesUsing:number;
  advocateOpps:number;
  transferOpps:number; 

  companiesUsingLipids:string[];  
  destCompanyLipids:string[];

  selectedLevels:string[] = [];
  selectedDepartments:string[] = [];

  loading:boolean = false;

  jobLevels = [
    "C-Team",
    "VP",
    "Director",
    "Manager",
    "Staff",
    "Consultant",
    "Other"
  ];

  departments = [
    "Banking and Wealth Management",
    "Business Management",
    "Consulting",
    "Education",
    "Engineering",
    "Finance and Administration",
    "Healthcare",
    "Human Resources",
    "Information Technology",
    "Legal",
    "Marketing and Product",
    "Operations",
    "Program and Project Management",
    "Publishing, Editorial and Reporting",
    "Quality",
    "Real Estate",
    "Risk, Safety, Compliance",
    "Sales and Support",
    "Other"
  ];  

  // AG-Grid
  rowData:any[] = [];
  tooltipShowDelay = 200;
  columnDefs: (ColDef | ColGroupDef)[]  = [
    { field: 'id', headerName: '', width: 40, maxWidth: 40, checkboxSelection: true, sortable: false, 
      filter: false, cellRenderer: () => { return '';},
      headerTooltip: 'Unique ID of this contact in the Live Data system', suppressColumnsToolPanel: true
    },
    { field: 'name', headerName: 'Name', 
      cellRenderer: (params:any) => {
        return '<a href="https://www.linkedin.com/in/' + params.data.linkedin + '" target=_blank>' + params.data.name + '</a>';
      },
    },    
    { field: 'position.started_at', headerName: 'Date Started'}, 
    { headerName: 'New Company', children: [
      { valueGetter: this.doesDestCompanyUse.bind(this), 
        field: 'params?.data?.position?.company.linkedin', headerName: 'In Use?', cellStyle: this.getCellColor.bind(this)}, 
      { field: 'position.company.name', headerName: 'Name'}, 
      { field: 'position.title', headerName: 'Title'}, 
      { field: 'position.company.employee_count', headerName: 'Size', filter: 'agNumberColumnFilter'}, 
      { field: 'position.company.industry', headerName: 'Industry'}, 
    ]},
    { headerName: 'Last Company', children: [
      { valueGetter: (params:any) => params?.data?.jobs[1]?.ended_at, field: 'position.started_at', headerName: 'Date Ended'}, 
      { valueGetter: (params:any) => params?.data?.jobs[1]?.company.name, field: 'jobs[1].company.name', headerName: 'Name'}, 
      { valueGetter: (params:any) => params?.data?.jobs[1]?.title, field: 'jobs[1].title', headerName: 'Title'}, 
      { valueGetter: (params:any) => params?.data?.jobs[1]?.company.employee_count, field: 'jobs[1].company.employee_count', headerName: 'Size', filter: 'agNumberColumnFilter',}, 
      { valueGetter: (params:any) => params?.data?.jobs[1]?.company.industry, field: 'jobs[1].company.industry', headerName: 'Industry'}, 
    ]},
  ];
  rowSelection = 'multiple';
  defaultColDef = {
    sortable: true,
    filter: true,
    floatingFilter: true,
    resizable: true,
    flex: 1,
    minWidth: 100,
    enablePivot: false,
    menuTabs: ['generalMenuTab','filterMenuTab']
  };
  gridApi: GridApi;

  constructor(
    private _clipboardService: ClipboardService,
    private dwService: SearchService, 
    private notify: NotificationService
  ) { }

  ngOnInit(): void {
    // Auto-complete on company name
    this.searchTechs = this.techSearchControl.valueChanges.pipe(
      debounceTime(200), // When someone is typing, don't submit on every single keypress
      switchMap((c:any) => {
        let query = this.autocompleteQuery;
        query.query.match_phrase_prefix['technologies'] = c;

        let body:QueryDwRequest = {
          query: query,
          index: 'companies'
        }
        return this.dwService.queryDw(body)
      }),
      map((d:any) => {
        // Do some client-side filtering on the tech here to make sure we only show what the user is looking for, since bucketing works weird in this case
        return d.aggregations.names.buckets.map((b:any) => b.key).filter((v:any) => v.toLowerCase().startsWith(this.techSearchControl.value.toLowerCase()))
      })
    );
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }  

  getCellColor(params: CellClassParams) {
    if (params.value === null) return null;
    return {backgroundColor: params.value === true ? 'green' : 'red'};
  }    

  doesDestCompanyUse(params:any) {
    // If destCompanyLipids has it, then we have techno for this company, so return whether or
    //  not the company is in the list of companies using the techno. Otherwise, return null.
    if( this.destCompanyLipids.includes(params?.data?.jobs[0]?.company.linkedin) ) {
      return this.companiesUsingLipids.includes(params?.data?.jobs[0]?.company.linkedin);
    }
    return null;
  }

  getTechCompanies() {
    this.loading = true;

    // First get all the copmany lipids that use the selected technology
    let query = this.companyQuery;
    query.query.term['technologies.keyword'].value = this.techSearchControl.value;

    let body:QueryDwRequest = {
      query: query,
      index: 'companies'
    }
    this.dwService.queryDw(body).subscribe({
      next: (companiesResults:any) => {

        // Now that we have the company lipids, get all the people who have left those copmanies
        //  and started somewhere else recently

        this.companiesUsing = companiesResults.hits.total.value;
        this.companiesUsingLipids = companiesResults.hits.hits.map((c:any) => c._source.linkedin);

        // This is how we do it with filters, once job_group is ready and we can set the response size
        //===========================================================
        let filters:PersonSearchFilter[] = [];
        filters.push({
          match_type: PersonSearchFilter.MatchTypeEnum.Fuzzy,
          type: PersonSearchFilter.TypeEnum.Must,
          field: 'jobs.company.linkedin.keyword',
          string_values: this.companiesUsingLipids,
          job_group: 1
        });
        filters.push({
          type: PersonSearchFilter.TypeEnum.Must,
          match_type: PersonSearchFilter.MatchTypeEnum.Exists,
          field: 'position.company.linkedin'
        });   
        filters.push({
          match_type: PersonSearchFilter.MatchTypeEnum.Fuzzy,
          type: PersonSearchFilter.TypeEnum.MustNot,
          field: 'position.company.linkedin.keyword',
          string_values: this.companiesUsingLipids,
        });        
        filters.push({
          match_type: PersonSearchFilter.MatchTypeEnum.Fuzzy,
          type: PersonSearchFilter.TypeEnum.MustNot,
          field: 'position.metadata.started_at_inferred',
          boolean_value: true,
        });           
        filters.push({
          match_type: PersonSearchFilter.MatchTypeEnum.Fuzzy,
          type: PersonSearchFilter.TypeEnum.Must,
          field: 'position.started_at',
          date_from: moment().subtract(60, 'day').format('YYYY-MM-DD'),
        });        
        filters.push({
          match_type: PersonSearchFilter.MatchTypeEnum.Fuzzy,
          type: PersonSearchFilter.TypeEnum.MustNot,
          field: 'position.title.company.name.keyword',
          string_values: ['None']
        });
        if( this.selectedDepartments.length > 0 ) {
          filters.push({
            match_type: PersonSearchFilter.MatchTypeEnum.Fuzzy,
            type: PersonSearchFilter.TypeEnum.Must,
            field: 'position.function.keyword',
            string_values: this.selectedDepartments
          });
        }
        if( this.selectedLevels.length > 0 ) {
          filters.push({
            match_type: PersonSearchFilter.MatchTypeEnum.Fuzzy,
            type: PersonSearchFilter.TypeEnum.Must,
            field: 'position.level.keyword',
            string_values: this.selectedLevels
          });
        }
        let searchQuery:SearchPersonsDataRequestData = {
          filters: filters,
          return_fields: ['name', 'id', 'linkedin', 'position.started_at', 'position.company.name', 'position.title', 'position.company.employee_count', 'position.company.industry', 'jobs.company.name', 'jobs.company.linkedin', 'jobs.title', 'jobs.company.employee_count', 'jobs.company.industry', 'jobs.ended_at'],
          past_jobs: 1,
          size: 10000
        }

        this.dwService.searchPersonsData(undefined, searchQuery).subscribe({
          next: (personsResults:any) => {
            // Hit the OS limit for what we'll get in an interactive query. Let the user know and bail.
            if( personsResults.count > 10000 ) {
              this.notify.error("Too many results. Please narrow your search.");
              this.loading = false;
              return;
            }
            personsResults = personsResults.results;
            let contacts = personsResults.hits.hits.map((d:any) => d._source);

            // Only include where the previous job was using the tech
            contacts = contacts.filter((c:any) => this.companiesUsingLipids.includes(c.jobs[1].company.linkedin))

            // Figure out if we have techno for all the destination companies for these people
            let destLipids:string[] = [...new Set(contacts.map((c:any) => c.jobs[0].company.linkedin))] as string[];
            let query = this.destCompanyQuery;
            query.query.bool.must[0].terms['linkedin.keyword'] = destLipids.filter((l:string) => l !== null);
            let body2:QueryDwRequest = {
              query: query,
              index: 'companies'
            }
            this.dwService.queryDw(body2).subscribe({
              next: (destCompaniesResults:any) => {
                this.destCompanyLipids = destCompaniesResults.hits.hits.map((c:any) => c._source.linkedin);

                this.rowData = contacts;
                this.loading = false;
              },
              error: () => {
                this.loading = false;
                this.notify.error("Error loading data");
              }
            })
          },
          error: () => {
            this.loading = false;
            this.notify.error("Error loading data");
          }          
        })

      },
      error: () => {
        this.loading = false;
        this.notify.error("Error loading data");
      }  
    })

  }

  copyToClipboard() {
    var textToCopy = '';
    this.gridApi.getSelectedRows().forEach(r => {
      textToCopy += r.name + ', ' + r.jobs[1].title + ' at ' + r.jobs[1].company.name + ' is now the ' + r.position.title + ' at ' + r.position.company.name + '\n';
    })
    this._clipboardService.copyFromContent(textToCopy);
  }

  // OS Query for company autocomplete search
  autocompleteQuery = {
    "query": {
      "match_phrase_prefix": {
        "technologies": "placeholder"
      }
    },
    "size": 0,
    "aggs": {
      "names": {
        "terms": {
          "field": "technologies.keyword",
          "size": 250,
          "order": {
            "_count": "desc"
          }
        }
      }
    }
  };  

  // OS Query for companies using the tech
  companyQuery = {
    "query": {"term": {
      "technologies.keyword": {
        "value": "Expensify|Accounting"
      }
    }},
    "_source": ["linkedin"],
    "size": 10000
  };    

  destCompanyQuery = {
    "query": {
      "bool": {
        "filter": [
          {"exists": {
            "field": "technologies"
          }}
        ],
        "must": [
          {"terms": {
            "linkedin.keyword": ["placeholder"]
          }}
        ]
      }
    },
    "size": 10000
  }
}
