<div class="app-identity-container">
    <div class="content-title">
        Identity Service Administration
    </div>    
    <mat-tab-group class="identity-tabs mat-tab-fill-height" >
      <mat-tab label="Orgs">
        <app-identity-orgs ></app-identity-orgs>
      </mat-tab>
      <mat-tab label="Users">
        <ng-template matTabContent>      
          <app-identity-users ></app-identity-users>
        </ng-template>
      </mat-tab>
      <mat-tab label="Employees">
        <ng-template matTabContent>      
          <app-admin-identity-employees ></app-admin-identity-employees>
        </ng-template>
      </mat-tab>
      <mat-tab label="Service Accounts">
        <ng-template matTabContent>      
          <app-admin-identity-service-accounts></app-admin-identity-service-accounts>
        </ng-template>
      </mat-tab>          
    </mat-tab-group>
  </div>