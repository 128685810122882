import { Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router, RouterStateSnapshot } from '@angular/router';
import {
  FlatfileButtonComponent,
  FlatfileCustomer,
  FlatfileMethods,
  FlatfileResults,
  IDataHookResponse,
  RecordInitOrChangeCallback,
  ScalarDictionaryWithCustom,
} from '@flatfile/angular';
import {
  FlatfileSettings,
  IValidatorOtherDictionary,
  IValidatorRegexDictionary,
  IValidatorRequiredWithSimpleDictionary,
} from '@flatfile/angular/lib/interfaces/settings';
import {
  ContactImportsService,
  ImportContactsFlatfileRequest,
  Ledger,
  LedgersService,
} from 'ldt-ledger-service-api';
import { BehaviorSubject, merge, Observable, Subject, timer } from 'rxjs';
import { delay, map, retryWhen, share, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { AuthService } from '../auth/service/auth.service';
import { NotificationService } from '../shared/notification-service/notification.service';
import { LedgerContactsComponent } from './ledger-contacts/ledger-contacts.component';
import { LedgerImportComponent } from './ledger-import/ledger-import.component';
import { LedgerOverviewComponent } from './ledger-overview/ledger-overview.component';
import { NgLedgerService } from './ledger.service';

@Component({
  selector: 'app-ledger',
  templateUrl: './ledger.component.html',
  styleUrls: ['./ledger.component.scss'],
})
export class LedgerComponent implements OnInit, FlatfileMethods, OnDestroy {
  // Get the imports component so we can trigger a refresh of imports grid after an upload
  @ViewChild(LedgerImportComponent) importComponent: LedgerImportComponent;
  @ViewChild(FlatfileButtonComponent) ffComponent: FlatfileButtonComponent;
  @ViewChild(LedgerContactsComponent) contactsComponent: LedgerContactsComponent;
  @ViewChild(LedgerOverviewComponent) overviewComponent: LedgerOverviewComponent;
  currentTabIndex = 0;
  ledger: Ledger;
  orgId: string;
  $ledgerApiV2: Observable<boolean>;
  useV2Import: boolean = false;

  @Input() placeholder: string = 'Description';

  // TODO make this async - may not be necessary since the component is reloaded for every ledger
  canEdit: boolean = false;

  //----------------- FLATFILE CONFIGURATION -----------------
  // For some reason, definiing the required validator inline in the fields was complaining, so
  //  define it here and use it in the fields
  req: IValidatorOtherDictionary = { validate: 'required' };
  nreq: IValidatorRequiredWithSimpleDictionary = {
    validate: 'required_without',
    fields: ['first_name', 'last_name'],
  };
  fnreq: IValidatorRequiredWithSimpleDictionary = {
    validate: 'required_without',
    fields: ['full_name'],
  };
  idreq: IValidatorRegexDictionary = {
    validate: 'regex_excludes',
    regex: '^(LDLC|LDP).*',
    regexFlags: { ignoreCase: true },
    error: "Reference IDs may not begin with 'LDLC' or 'LDP'",
  };
  licenseKey = '1c127492-8da2-4793-9b7b-742709ac0e9f';
  customer: FlatfileCustomer;
  // flatfileImporter = "foo";
  settings: FlatfileSettings = {
    title: 'Upload your business contacts to start tracking their employment',
    type: 'ledger_import',
    allowCustom: false,
    fields: [
      {
        label: 'Reference ID',
        key: 'reference_id',
        description:
          'A reference ID that can be used to match records back to your source data (optional)',
        validators: [this.idreq],
      },
      {
        label: 'First Name',
        key: 'first_name',
        description: 'First name of the employee',
        validators: [this.fnreq],
      },
      {
        label: 'Last Name',
        key: 'last_name',
        description: 'Last name of the employee',
        validators: [this.fnreq],
      },
      {
        label: 'Full Name',
        key: 'full_name',
        description: 'Full name of the employee',
        validators: [this.nreq],
      },
      {
        label: 'Company Name',
        key: 'company',
        description: 'Company name',
        validators: [this.req],
      },
      {
        label: 'Title',
        key: 'title',
        description: 'Title of the employee (optional)',
      },
      {
        label: 'LinkedIn Profile',
        key: 'linkedin_url',
        description: 'LinkedIn Profile URL (optional)',
      },
    ],
    managed: true,
  };

  importsInProgress$: BehaviorSubject<any> = new BehaviorSubject<any>({
    importing: false,
    processing: false,
  });
  private stopPolling = new Subject<void>();
  private lastPoll = false;
  private manualTrigger = new Subject<void>();
  atContactLimit: boolean = false;

  constructor(
    private ledgerService: LedgersService,
    private importService: ContactImportsService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private auth: AuthService,
    private router: Router,
    private notify: NotificationService,
    private ngLedgerService: NgLedgerService
  ) {
    const thisOrg = this.auth.getSelectedOrg();
    let thisOrgName: string = '';
    if (!thisOrg) {
      // In theory this should never happen cause if there are no orgs the OrgsGuard will redirect to a diff page
      this.router.navigateByUrl('/main');
    } else {
      thisOrgName = thisOrg.name;
      let settings: any = thisOrg.settings;
      let limit = settings?.ledger?.contactLimit;
      if (limit) {
        let rem = limit - (thisOrg.contactCount || 0);
        this.settings.maxRecords = rem;
        if (rem <= 0) {
          this.atContactLimit = true;
        }
      }
    }
    this.customer = {
      userId: this.auth.getUserValue.id || '',
      email: this.auth.getUserValue.email,
      name: this.auth.getUserValue.name,
      companyId: this.auth.getSelectedOrgIdValue,
      companyName: thisOrgName,
    };
    this.canEdit = this.auth.userHasRole('editor');
  }

  isAdmin: Observable<boolean>;
  hasImports: boolean = false;
  $canAccessV2: Observable<boolean>;

  async ngOnInit() {
    this.ledger = this.route.snapshot.data.userdata;
    this.orgId = this.auth.getSelectedOrgIdValue;
    this.isAdmin = this.auth.$isAdmin;
    this.$canAccessV2 = this.ngLedgerService.$canAccessV2;
    this.$ledgerApiV2 = this.ngLedgerService.getApiVersion();
    this.$ledgerApiV2.subscribe({
      next: (v2: boolean) => {
        const url = this.router.url;
        if (v2) {
          if (url.endsWith('overview') || url.endsWith('details')) {
            this.router.navigateByUrl(url + '-v2');
          }
        } else {
          if (url.endsWith('-v2')) {
            this.router.navigateByUrl(url.replace('-v2', ''));
          }
        }
      },
    });

    this.isAdmin.subscribe({
      next: (isAdmin) => {
        if (isAdmin) {
          this.useV2Import = true;
        }
      },
    });

    // Check every 30s to see if there are active imports. If so, show a notification and
    //  update the contacts and imports lists while imports are running. Also do one last
    //  update when it flips from true -> false, and have a manual trigger when a user does an import
    //  Might want to change this to return the imports[] instead of a boolean if we want to do more with them
    merge(timer(1, 60000), this.manualTrigger.asObservable())
      .pipe(
        switchMap(() => this.importService.getImportJobs(this.ledger.id, this.orgId)),
        tap((res) => {
          this.hasImports = res.imports.length > 0;
        }),
        map((res) => {
          return {
            importing: res.imports.some((i) => i.status === 'importing'),
            processing: res.imports.some((i) => i.status === 'processing'),
          };
        }),
        retryWhen((errors) => errors.pipe(delay(30000), take(10))),
        share(),
        takeUntil(this.stopPolling),
        tap((x) => {
          this.importsInProgress$.next(x);
          if (x.importing || x.processing || this.lastPoll) {
            this.triggerContactsRefresh();
            this.triggerImportRefresh();
            this.updateLedger();
          }
          this.lastPoll = x.importing || x.processing;
        })
      )
      .subscribe();
  }

  ngAfterViewInit() {
    // TODO : Remove the query param otherwise a reload pops up the uploader again
    this.route.queryParams.subscribe((params) => {
      if (params['showAddContacts']) {
        this.ffComponent.launch();
      }
    });
  }

  triggerImportRefresh(): void {
    if (this.importComponent) {
      this.importComponent.refreshData();
    }
  }

  triggerContactsRefresh(): void {
    if (this.contactsComponent) {
      this.contactsComponent.refreshData(true);
    }
    if (this.overviewComponent) {
      this.overviewComponent.refreshData();
    }
  }

  // Flatfile processing
  onData(results: FlatfileResults): Promise<string> {
    let errorState = false;

    return new Promise((resolve, reject) => {
      if (errorState) {
        reject('There was an error during upload. Please try again.');
        errorState = false;
      } else {
        let fileName: string;
        let contactsNum: number;
        if (results.fileName) {
          fileName = results.fileName;
        }
        if (results.stats.acceptedRows) {
          contactsNum = results.stats.acceptedRows;
        }
        // Use |any here since we intentionally obfuscate the should_find parameter in the API spec
        let batch: ImportContactsFlatfileRequest | any = {
          fileName: results.fileName === null ? undefined : results.fileName,
          contactsNum: results.stats.acceptedRows === null ? undefined : results.stats.acceptedRows,
          batchId: results.batchId,
          originalRows: results.stats.originalRows,
          manual: results.manual,
        } as any;
        if (this.useV2Import) batch['should_find'] = true;
        this.importService.importContactsFlatfile(this.orgId, this.ledger.id, batch).subscribe(
          (r) => {
            resolve(
              'Your contacts have been uploaded. Please allow time for them to be imported into your ledger.'
            );
            this.manualTrigger.next();
            // Fool the UI into hiding the Add Contacts overlay
            this.ledger.size = 1;
          },
          (err) => {
            if (err.status === 400) {
              reject(err.error?.message || 'You are over your contact limit');
            } else if (err.status === 429) {
              // This has already been imported - UI must have double-clicked or something
              resolve(
                'Your contacts have been uploaded. Please allow time for them to be imported into your ledger.'
              );
            } else {
              reject('There was an error during upload. Please try again.');
            }
          }
        );
      }
    });
  }
  updateLedgerSettings(orgSettings: Ledger) {
    this.ledger = orgSettings;
  }

  onLedgerNameChange(newName: any) {
    let updateBody = { name: newName.textContent, description: this.ledger.description };
    this.ledgerService.updateLedger(this.ledger.id, this.orgId, updateBody).subscribe(
      (res) => {
        if (res) {
          this.updateLedger();
        }
      },
      () => {
        this.notify.error('Oops. There was an error during your request. Please try again later.');
      }
    );
  }

  onLedgerDescriptionChange(newDesc: any) {
    let updateBody = { description: newDesc.textContent, name: this.ledger.name };
    this.ledgerService.updateLedger(this.ledger.id, this.orgId, updateBody).subscribe(
      (res) => {
        if (res) {
          this.updateLedger();
        }
      },
      () => {
        this.notify.error('Oops. There was an error during your request. Please try again later.');
      }
    );
  }

  ignoreCR(e: any) {
    if (e.keyCode === 13) {
      return false;
    }
    return true;
  }

  updateLedger() {
    this.ledgerService.getLedger(this.ledger.id, this.orgId).subscribe(
      (res) => {
        this.ledger = res;
      },
      () => {
        // Do nothing yet
      }
    );
  }

  disallowedCharsRe = /[\\\"]/gi;

  setLedgerVersion(evt: any) {
    this.ngLedgerService.setApiVersion(evt.checked);
  }

  onRecordChange(
    record: ScalarDictionaryWithCustom,
    index: number
  ): IDataHookResponse | Promise<IDataHookResponse> {
    return {
      reference_id: {
        value:
          typeof record.reference_id === 'string'
            ? record.reference_id.replace(this.disallowedCharsRe, '')
            : undefined,
      },
      first_name: {
        value:
          typeof record.first_name === 'string'
            ? record.first_name.replace(this.disallowedCharsRe, '')
            : undefined,
      },
      last_name: {
        value:
          typeof record.last_name === 'string'
            ? record.last_name.replace(this.disallowedCharsRe, '')
            : undefined,
      },
      full_name: {
        value:
          typeof record.full_name === 'string'
            ? record.full_name.replace(this.disallowedCharsRe, '')
            : undefined,
      },
      company: {
        value:
          typeof record.company === 'string'
            ? record.company.replace(this.disallowedCharsRe, '')
            : undefined,
      },
      title: {
        value:
          typeof record.title === 'string'
            ? record.title.replace(this.disallowedCharsRe, '')
            : undefined,
      },
      email: {
        value:
          typeof record.email === 'string'
            ? record.email.replace(this.disallowedCharsRe, '')
            : undefined,
      },
      linkedin_url: {
        value:
          typeof record.linkedin_url === 'string'
            ? record.linkedin_url.replace(this.disallowedCharsRe, '')
            : undefined,
      },
    };
  }
  ngOnDestroy() {
    // Stop the imports polling
    this.stopPolling.next();
  }
}
