import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ColDef, ColGroupDef, GridApi, GridReadyEvent, IGetRowsParams } from 'ag-grid-community';
import { Contact, Ledger, LedgerContactsService } from 'ldt-ledger-service-api';
import { BehaviorSubject } from 'rxjs';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';
import { LedgerJobCellRendererComponent } from '../ledger-contacts/ledger-job-cell-renderer/ledger-job-cell-renderer.component';

@Component({
  selector: 'app-ledger-overview',
  templateUrl: './ledger-overview.component.html',
  styleUrls: ['./ledger-overview.component.scss'],
  providers: [DatePipe]
})
export class LedgerOverviewComponent implements OnInit {
  @Input() ledger: Ledger;
  @Input() orgId: string;
  @Input() importsInProgress$: BehaviorSubject<any>;
  
  changeCount: number;
  verifiedCount: number;
  contactCount: number;
  
  constructor(private ledgerService: LedgerContactsService, private notify: NotificationService, private datePipe: DatePipe, private route: ActivatedRoute ) { }

  ngOnInit(): void {
    // Get the ledger from the resolver and org ID from the route
    this.ledger = this.route.parent?.snapshot.data.userdata;
    let routeOrg = this.route.parent?.snapshot.paramMap.get('orgId');
    if( routeOrg ) {
      this.orgId = routeOrg;
    }

    this.getContactCount();
    this.getChangeCount();
    this.getVerifiedCount();
  }

  // Refresh the data grid. If the request was manual (user hit the button) we always do it. If it was requested
  //  automatically (usually from polling), then we only refresh if the user has no active filters (https://app.clickup.com/t/1wzer4f)
  refreshData() {
    this.getContactCount();
    this.getChangeCount();
    this.getVerifiedCount();    
    if (this.gridApi) {
      this.gridApi.purgeInfiniteCache();
    }
  }  

  private getContactCount() {
    // Get the counts
    this.ledgerService.getLedgerContacts(this.ledger.id, this.orgId, 
      undefined, // company
      undefined, // title
      undefined, // name
      undefined, // sort by
      undefined, // limit 
      undefined, // offset
      undefined, // job change from
      undefined, // job change to
      undefined, // verified
      undefined, // reference id
      undefined, // import company
      undefined, // import title
      undefined, // linkedin
      "count", // view
      undefined, // email // email status // info change from // info change to
      ).subscribe(res => {
        res.count ? this.contactCount = res.count as number : this.contactCount = 0;
      }, () => {
        this.notify.error("Oops. There was an error during your request. Please try again later.")
        this.contactCount = 0;
      })
  }
  private getChangeCount() {
    // Get the counts
    this.ledgerService.getLedgerContacts(this.ledger.id, this.orgId, 
      undefined, // company
      undefined, // title
      undefined, // name
      undefined, // sort by
      undefined, // limit 
      undefined, // offset
      '2000-01-01 00:00:00', // job change from
      undefined, // job change to
      undefined, // verified
      undefined, // reference id
      undefined, // import company
      undefined, // import title
      undefined, // linkedin
      "count", // view
      undefined, // email // email status // info change from // info change to
      ).subscribe(res => {
        res.count ? this.changeCount = res.count as number : this.changeCount = 0;
      }, () => {
        this.notify.error("Oops. There was an error during your request. Please try again later.")
        this.changeCount = 0;
      })
  }
  private getVerifiedCount() {
    // Get the counts
    this.ledgerService.getLedgerContacts(this.ledger.id, this.orgId, 
      undefined, // company
      undefined, // title
      undefined, // name
      undefined, // sort by
      undefined, // limit 
      undefined, // offset
      undefined, // job change from
      undefined, // job change to
      true, // verified
      undefined, // reference id
      undefined, // import company
      undefined, // import title
      undefined, // linkedin
      "count", // view
      undefined, // email // email status // info change from // info change to
      ).subscribe(res => {
        res.count ? this.verifiedCount = res.count as number : this.verifiedCount = 0;
      }, () => {
        this.notify.error("Oops. There was an error during your request. Please try again later.")
        this.verifiedCount = 0;
      })
  }

  getRows(params: IGetRowsParams): any {
    this.gridApi.showLoadingOverlay();

    var sortBy;
    if( params.sortModel.length > 0 ){
      var dir = '';
      if (params.sortModel[0].sort == "desc") {
        dir = '-';
      }
      sortBy = dir + params.sortModel[0].colId;
    }

    // Get the data
    this.ledgerService.getLedgerContacts(this.ledger.id, this.orgId, 
      params.filterModel['company'] ? params.filterModel['company'].filter : undefined,
      params.filterModel['title'] ? params.filterModel['title'].filter : undefined, 
      params.filterModel['name'] ? params.filterModel['name'].filter : undefined,
      sortBy, 
      params.endRow - params.startRow, 
      params.startRow, 
      params.filterModel['lastJobChangedAt'] ? params.filterModel['lastJobChangedAt'].dateFrom : undefined,
      undefined,
      params.filterModel['isVerified'] ? params.filterModel['isVerified'].type : undefined,
      params.filterModel['referenceId'] ? params.filterModel['referenceId'].filter : undefined,
      params.filterModel['importCompany'] ? params.filterModel['importCompany'].filter : undefined, 
      params.filterModel['importTitle'] ? params.filterModel['importTitle'].filter : undefined,
      params.filterModel['linkedin'] ? params.filterModel['linkedin'].filter : undefined,
      undefined,
      params.filterModel['contactInfo.email'] ? params.filterModel['contactInfo.email'].filter : undefined,
      params.filterModel['contactInfo.emailStatus'] ? params.filterModel['contactInfo.emailStatus'].type : undefined,
      params.filterModel['lastInfoChangedAt'] ? params.filterModel['lastInfoChangedAt'].dateFrom : undefined,
      undefined,
      ).subscribe(res => {    
        let lastRow:number = -1;
        let clength = 0;
        if( res.contacts ) {
          clength = res.contacts.length;
        }
        // No matching rows, show overlay
        if (params.startRow === 0 && clength === 0) {
          this.gridApi.showNoRowsOverlay();
          lastRow = 0;
        }

        // Now we know what the last row is...
        if (clength < (params.endRow - params.startRow)) {
          lastRow = clength + params.startRow;
        }

        this.gridApi.hideOverlay();
        params.successCallback(res.contacts as Contact[], lastRow);
      }, () => {
        this.notify.error("Oops. There was an error during your request. Please try again later.")
        this.gridApi.hideOverlay();
        params.failCallback();
      })    
  };  


  //////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////// A G GRID /////////////
  ////////////////////////////////////

  private gridApi: GridApi;
  rowModelType: any = 'infinite';  
  infiniteInitialRowCount = 100;  
  cacheBlockSize = 500;
  rowSelection = 'none';
  components = {
    loadingRenderer: function (params:any) {
      if (params.value !== undefined) {
        return params.value;
      } else {
        return "<img src=\"https://www.ag-grid.com/example-assets/loading.gif\">";
      }
    },
  };  
  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    // this.gridColumnApi = params.columnApi;
    // this.gridColumnApi.setColumnState(JSON.parse(localStorage.getItem('ag-grid-columns-contacts') || '{}'));
    // this.gridApi.resetRowHeights();
    params.api.setDatasource(this);
  };

  /// AG -GRID --------------
  private simpleFilterParams: any = {
    filterOptions: ['contains'],
    suppressAndOrCondition: true    
  }
  
  defaultColDef = {
    sortable: true,
    filter: 'agTextColumnFilter',
    floatingFilter: false,
    filterParams: this.simpleFilterParams,
    resizable: true,
    flex: 1,
    minWidth: 100,
    enablePivot: false,
    menuTabs: ["filterMenuTab"]
  }
  columnDefs: (ColDef | ColGroupDef)[]  = [
    { field: 'lastJobChangedAt', headerName: 'Job Change Detected',
      sort: 'desc', maxWidth: 210, menuTabs: [], filter: false,
      valueFormatter: (params: any) => { return this.datePipe.transform(params.value, 'yyyy-MM-dd h:mm a', 'UTC') || ''},
      headerTooltip: 'The last time (in UTC) Live Data updated this persons job on this ledger. A blank value indicates there has been no change from the imported data. The filter shows those that were changed on or after the provided date.'      
    },    
    { headerName: 'Person', field: 'name', cellRenderer: LedgerJobCellRendererComponent, maxWidth: 275,
      autoHeight: true},    
    { headerName: 'Current Job', field: 'company', cellRenderer: LedgerJobCellRendererComponent,
      autoHeight: true},
    { headerName: 'Imported Job', field: 'importCompany', cellRenderer: LedgerJobCellRendererComponent,
      autoHeight: true},    
  ];
  rowData: Contact[];
  rowCount: any = null;
  rowsSelected = false;
  tooltipShowDelay = 200;   
}
