import { Component, OnInit } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { AdminService, ReportDelivery } from 'ldt-data-deliveries-api';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';

@Component({
  selector: 'app-report-deliveries',
  templateUrl: './report-deliveries.component.html',
  styleUrls: ['./report-deliveries.component.scss'],
})
export class ReportDeliveriesComponent implements OnInit {
  refreshing: boolean = false;
  reportDeliveries: ReportDelivery[] = [];
  public colDefs: ColDef[] = [
    { field: 'id', maxWidth: 60 },
    { field: 'org_id', maxWidth: 110 },
    { field: 's3_url', headerName: 'Delivered To' },
    { field: 'created_at', maxWidth: 230 },
  ];

  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    floatingFilter: true,
    resizable: true,
    flex: 1,
  };

  constructor(
    private reportDeliveriesService: AdminService,
    private notify: NotificationService
  ) {}

  ngOnInit(): void {
    this.getReportDeliveries();
  }

  getReportDeliveries(): void {
    this.refreshing = true;
    this.reportDeliveriesService.listAllReportDeliveries().subscribe({
      next: (data) => {
        this.reportDeliveries = data;
        this.refreshing = false;
      },
      error: (error) => {
        this.notify.error('Error fetching report deliveries');
        this.refreshing = false;
      },
    });
  }
}
