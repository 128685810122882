<div id="filters-panel" class="tw-mb-5">
  <!-- quick builds -->
  <app-quick-builds
    [uiFilters]="uiFilters"
    [columns]="columns"
    [PAST_JOBS]="PAST_JOBS"
    (quickBuildSelected)="loadQuickBuild($event)">
  </app-quick-builds>

  <div
    id="filters-expanded"
    *ngIf="showFilters"
    class="tw-overflow-x-auto tw-text-sm tw-text-gray-800 tw-rounded tw-bg-gray-50 tw-border tw-border-solid tw-border-gray-300 tw-relative tw-p-4"
    [class.no-active-filters]="noActiveFilters">
    <div>
      <!-- Container for simple filters -->
      <ng-container *ngFor="let filter of getSimpleFilters()">
        <app-simple-filter
          [filter]="filter"
          (remove)="removeFilter(filter)"
          (update)="updateFilter($event)"
          (badgeAdded)="addBadgeToFilter($event)"
          (badgeRemoved)="removeBadgeFromFilter($event)"
          class="standalone-filter"></app-simple-filter>
      </ng-container>

      <!-- Container for current position category -->
      <ng-container *ngIf="getPositionFilters().length > 0">
        <app-category-filter
          [filters]="getPositionFilters()"
          [category]="CURRENT_JOB"
          (categoryFilterAdded)="addCategoryFilter($event)"
          (remove)="removeFilter($event.filter)"
          (removeCategory)="removeCategoryFilter(CURRENT_JOB)"
          (update)="updateFilter($event)">
        </app-category-filter>
      </ng-container>

      <!-- Containers for past jobs category -->
      <ng-container *ngFor="let groupNum of getAllJobGroups()">
        <app-category-filter
          [filters]="getFiltersForJobGroup(groupNum)"
          [category]="PAST_JOBS"
          [groupIndex]="groupNum"
          (categoryFilterAdded)="addCategoryFilter($event)"
          (remove)="removeFilter($event.filter)"
          (removeCategory)="removeCategoryFilter(groupNum)"
          (update)="updateFilter($event)">
        </app-category-filter>
      </ng-container>

      <div class="filter-row query-item tw-relative tw-flex">
        <div class="field-wrapper field-wrapper-btn tw-relative tw-mx-3">
          <button mat-stroked-button color="primary" (click)="showSelectorModal(null)">
            <mat-icon class="tw-text-[20px]">add</mat-icon>
            Add Filter
          </button>
        </div>
      </div>
    </div>

    <button
      class="icon-btn-hover tw-absolute tw-border tw-border-gray-200 tw-border-solid hover:tw-border-gray-400 tw-bottom-4 tw-right-2 tw-bg-white"
      (click)="deleteAllFilters()">
      <mat-icon class="tw-text-gray-600">delete_outline</mat-icon>
    </button>
  </div>

  <!-- filter chips -->
  <div
    id="filters-collapsed"
    *ngIf="!showFilters"
    class="tw-flex tw-items-center tw-text-sm tw-text-gray-800 tw-rounded tw-bg-gray-50 tw-border tw-border-solid tw-border-gray-300 tw-p-2">
    <button
      mat-stroked-button
      color="primary"
      (click)="showSelectorModal(null)"
      class="tw-shadow-sm tw-px-2 tw-shrink-0 tw-ml-[6px] tw-mr-3 lg:tw-mr-5">
      Add Filter
    </button>

    <div *ngIf="uiFilters.length > 0; else noFilters" class="tw-flex tw-items-center tw-flex-wrap">
      <!-- filter chips grouped by category -->
      <div
        class="tw-flex tw-flex-wrap tw-items-center tw-mr-3 tw-mb-1"
        *ngFor="let group of getGroupedFilters(); trackBy: trackByGroupName">
        <span class="tw-font-medium tw-mr-2">{{ getCategoryDisplayName(group[0]) }}:</span>
        <ng-container *ngFor="let filter of group[1]">
          <div class="badge-selected-items tw-flex tw-items-center">
            <!-- conditional symbol span, only add if filter name is repeated -->
            <button
              *ngIf="isFilterNameRepeated(filter, group[1])"
              class="operator-symbol"
              [matTooltip]="getFilterSymbol(filter).tooltip"
              matTooltipPosition="above">
              <span class="tw-absolute tw-left-[4px] tw-bottom-[11px]">
                {{ getFilterSymbol(filter).symbol }}
              </span>
            </button>
            <span class="tw-font-semibold">{{ filter.field.displayName }}</span>
            <mat-icon
              class="close-inside-badge hover:tw-font-semibold hover:tw-bg-primary_purple-800"
              (click)="removeFilter(filter)"
              tabindex="0"
              aria-hidden="false">
              close
            </mat-icon>
          </div>
        </ng-container>
      </div>
    </div>
    <ng-template #noFilters>
      <div class="tw-font-semibold">No Active Filters</div>
    </ng-template>
  </div>

  <div
    class="tw-w-100 tw-flex tw-justify-center"
    [ngClass]="{ 'tw-mt-[-16px]': showFilters, 'tw-mt-[-8px]': !showFilters }">
    <button
      *ngIf="showFilters"
      mat-stroked-button
      color="primary"
      class="tw-bg-white"
      matTooltip="Hide Filters Toolbar"
      matTooltipPosition="right"
      (click)="toggleFiltersContent()">
      <mat-icon class="tw-font-bold">keyboard_arrow_up</mat-icon>
    </button>
    <button
      *ngIf="!showFilters"
      mat-icon-button
      color="primary"
      class="tw-text-primary_purple-600 tw-border tw-border-solid tw-border-gray-300 tw-h-[20px] tw-rounded tw-bg-white"
      style="line-height: 20px;"
      matTooltip="Show Filters Toolbar"
      matTooltipPosition="right"
      (click)="toggleFiltersContent()">
      <mat-icon class="tw-font-bold" style="line-height: 20px;">keyboard_arrow_down</mat-icon>
    </button>
  </div>
</div>

<div class="tw-flex tw-flex-wrap tw-gap-3 tw-items-center tw-mt-5">
  <button
    mat-raised-button
    class="tw-shrink-0"
    [class.spinner]="refreshing"
    [disabled]="refreshing"
    (click)="runSearch()">
    Search
  </button>
  <button
    mat-raised-button
    class="tw-shrink-0"
    [class.spinner]="downloading"
    [disabled]="downloading"
    (click)="download()">
    Download
  </button>
  <button mat-raised-button class="tw-shrink-0" (click)="copyFiltersToClipboard()">
    Copy Filter Schema
  </button>

  <div class="count tw-flex tw-items-center">
    <mat-progress-spinner
      mode="indeterminate"
      class="tw-mr-3"
      diameter="20"
      *ngIf="countUpdating; else contactCount">
    </mat-progress-spinner>
    <ng-template #contactCount>
      <span class="badge-checked-items tw-mr-2 tw-rounded">{{searchCount | number}}</span>
    </ng-template>
    Matching Contacts
  </div>
</div>
