import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { GridApi } from 'ag-grid-community';
import { AdministrativeService, CreateInternalServiceAccountRequest, GetServices200ResponseInner, UpdateInternalServiceAccountRoleRequest } from 'ldt-identity-service-api';
import { forkJoin, map, mergeMap, switchMap, tap } from 'rxjs';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';
import { SaNewSecretDialogComponent } from 'src/app/team/team-service-accounts/sa-new-secret-dialog/sa-new-secret-dialog.component';
import { ServiceAccountCreateDialogComponent } from './service-account-create-dialog/service-account-create-dialog.component';

@Component({
  selector: 'app-admin-identity-service-accounts',
  templateUrl: './service-accounts.component.html',
  styleUrls: ['./service-accounts.component.scss']
})
export class ServiceAccountsComponent implements OnInit {

  /// AG -GRID --------------
  private simpleFilterParams: any = {
    filterOptions: ['contains'],
    suppressAndOrCondition: true    
  }     
  defaultColDef = {
    sortable: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    resizable: true,
    filterParams: this.simpleFilterParams,
    menuTabs: ['generalMenuTab','filterMenuTab']      
  }
  columnDefs = [
    { field: 'internalServiceAccount.clientId', headerName: 'Client ID', cellRenderer: 'loadingRenderer', maxWidth: 150 },
    { field: 'internalServiceAccount.name', headerName: 'Name', sort: 'asc' },
    { field: 'internalServiceAccount.createdAt', headerName: 'Created' },
    { field: 'internalServiceAccount.description', headerName: 'Description' },
    { field: 'service.name', headerName: 'Service'},
    { field: 'role.name', filter: true, editable: true,
      cellEditor: 'agRichSelectCellEditor',
      cellEditorPopup: true,
      cellEditorParams: {
        values: ['viewer', 'editor', 'admin'],
        cellEditorPopup: true,
      },    
      headerName: 'Role' },  
  ];
  rowData: any[];

  private gridApi: GridApi;

  components = {
    loadingRenderer: function (params:any) {
      if (params.value !== undefined) {
        return params.value;
      } else {
        return "<img src=\"https://www.ag-grid.com/example-assets/loading.gif\">";
      }
    },
  };  
  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  };

  refreshing:boolean = true;

  constructor(
    private isaService: AdministrativeService,
    private dialog: MatDialog, 
    private notify: NotificationService) { }

  ngOnInit() {
    this.updateISAs();
  }

  showCreateDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "500px";
    const dialogRef = this.dialog.open(ServiceAccountCreateDialogComponent, dialogConfig)
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.createISA(data.name, data.serviceId, data.role, data.description);
      }
    })
  }

  createISA(name: string, serviceId: number, role: string, description: string) {
    let isaBody:CreateInternalServiceAccountRequest = {
      name: name.trim(),
      serviceId: serviceId,
      description: description.trim(),
      role: role
    }
    this.isaService.createInternalServiceAccount(isaBody).subscribe({
      next: (r:any) => {

        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = "500px";
        dialogConfig.data = {
          orgId: "n/a",
          clientId: r.internalServiceAccount.id,
          clientSecret: r.internalServiceAccount.clientSecret
        }
        this.dialog.open(SaNewSecretDialogComponent, dialogConfig);
        
        this.updateISAs();
      },
      error: (err) => {
        this.notify.error('Failed to create service account: ' + err.error)
      }
    })
  }  

  updateServiceAccount(evt:any) {
    const body:UpdateInternalServiceAccountRoleRequest = {
      clientId: evt.data.internalServiceAccount.clientId,
      role: evt.data.role.name
    }
    this.isaService.updateInternalServiceAccountRole(evt.data.service.id, body).subscribe({
      next: () => {
        this.notify.success("Role updated");
      },
      error: () => {
        this.notify.error("Oops. There was an error during your request. Please try again later.");
      }
    });
  }

  updateISAs() {
    this.refreshing = true;
    this.isaService.getServices().pipe(
      map((r:any) => r.map((s:any) => this.isaService.getInternalServiceAccounts(s.id))),
      mergeMap((r:any) => forkJoin(r))
    ).subscribe({
      next: (res:any) => {
        let flattened:any[] = [];
        res.forEach((r:any) => flattened = flattened.concat(r))
        this.rowData = flattened;
        this.refreshing = false;
      },
      error: () => {
        this.notify.error("Oops. There was an error during your request. Please try again later.");
        this.refreshing = false;
      }
    })
  }

}
