import { Component, Input } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';
import { ICellRendererParams } from 'ag-grid-community';
import { Destination } from 'ldt-data-deliveries-api';

interface DetailsCellRendererParams {
  params: ICellRendererParams;
  orgId: string;
  value: {};
  data: Destination;
}

@Component({
  selector: 'app-details-tooltip-cell',
  template: `
  <div *ngIf="params">
    <ng-container *ngIf="params.data.destination_type_id === 2 || params.data.destination_type_id === 3; else otherContent">
      <div class="tw-leading-4 tw-mt-2 tw-mb-2" matTooltip="Copy {{displayKey}}" matTooltipPosition="above" (click)="copyToClipboard(displayValue)">
        {{ displayKey }}: <span class="tw-inline-block code-font-direct">{{ displayValue }}</span>
      </div>
    </ng-container>
    <ng-template #otherContent>
      <ul class="config-info-list tw-list-none tw-ml-0 tw-my-2 tw-pl-0">
        <li class="tw-leading-5 tw-flex tw-items-center tw-relative" matTooltip="Copy {{displayKey}}" matTooltipPosition="above" (click)="copyToClipboard(displayValue)">
          <span class="tw-inline-block tw-mr-2">{{ displayKey }}:</span>
          <span class="tw-inline-block code-font-direct tw-truncate tw-max-w-[200px]">
            {{ displayValue }}
          </span>
        </li>
        <li class="tw-leading-5 tw-flex tw-items-center tw-relative" matTooltip="Copy S3 Bucket Location" matTooltipPosition="above" (click)="copyToClipboard(s3Uri)">
          <span class="tw-inline-block tw-mr-2">S3 Bucket Location:</span>
          <span class="tw-inline-block code-font-direct tw-truncate tw-max-w-[200px]">
            {{ s3Uri }}
          </span>
        </li>
      </ul>
    </ng-template>
  </div>
  `,
  styles: [],
})
export class DetailsTooltipCellComponent {
  @Input() params: DetailsCellRendererParams;
  @Input() orgId: string;
  displayKey: string = 'No data';
  displayValue: string = '';
  s3Uri: string = '';

  constructor(
    private clipboard: Clipboard,
    private notify: NotificationService
  ) {}

  agInit(params: DetailsCellRendererParams): void {
    this.params = params;
    this.orgId = params.orgId;
    this.generateContent(params);
  }

  generateContent(params: DetailsCellRendererParams): void {
    const keyNameMapping: Record<string, string> = {
      role_arn: 'Role ARN',
      bucket_identifier: 'Bucket ID',
      external_id: 'External ID',
    };

    if (params.value) {
      const entries = Object.entries(params.value);

      if (params.data.destination_type_id === 2 || params.data.destination_type_id === 3) {
        const entry = entries.find(([key]) => key === 'bucket_identifier');
        if (entry) {
          const [key, value] = entry;
          this.displayKey = keyNameMapping[key] || key;
          this.displayValue = value !== null && value !== undefined ? value.toString() : '';
        }
      } else if (params.data.destination_type_id === 1) {
        const roleArnEntry = entries.find(([key]) => key === 'role_arn');
        if (roleArnEntry) {
          const [key, value] = roleArnEntry;
          this.displayKey = keyNameMapping[key] || key;
          this.displayValue = value !== null && value !== undefined ? value.toString() : '';
          this.s3Uri = this.getFormattedS3Uri(params.data.id, this.orgId);
        }
      }
    }
  }

  copyToClipboard(value: string): void {
    this.clipboard.copy(value);
    this.notify.success('Copied to clipboard!');
  }

  getFormattedS3Uri(destinationId: number, orgId: string): string {
    const formattedOrgId = orgId.replace('_', '-');
    return `s3://arn:aws:s3:us-east-1:100811426269:accesspoint/${formattedOrgId}-${destinationId}/${this.orgId}/`;
  }
}
