import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
    UrlTree
} from '@angular/router';

import { AuthService } from '../auth/service/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(private auth: AuthService, private router: Router, private location: Location) { }

    async canActivate(next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Promise<boolean | UrlTree> {
        if (this.auth.isLoggedInValue) {
            return true;
        } else {
            this.auth.setRedirectUrl(this.location.path());
            let stateParams:any = {};

            if (state.url.startsWith('/invite') ) {
                stateParams = next.queryParams;
            }
            return this.router.navigateByUrl('/login', { state: stateParams });
        }
    }
}
