<h2 mat-dialog-title>Create Ledger</h2>

<mat-dialog-content [formGroup]="form">
    <mat-form-field>
        <input matInput required placeholder="Name" formControlName="name">
    </mat-form-field>
    <mat-form-field>
        <input matInput placeholder="Description" formControlName="description">
    </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button (click)="close()">Cancel</button>
    <button mat-raised-button color="primary" [disabled]="!form.valid" (click)="save()">Create</button>
</mat-dialog-actions>