import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate, Router, RouterStateSnapshot, UrlTree
} from '@angular/router';
import { AuthService } from '../auth/service/auth.service';
import { NotificationService } from '../shared/notification-service/notification.service';

@Injectable({ providedIn: 'root' })
export class AntiAuthGuard implements CanActivate {

    constructor(private auth: AuthService, private router: Router, private notify: NotificationService) { }

    async canActivate(next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Promise<boolean | UrlTree> {

        if (!this.auth.isLoggedInValue) return true;
        if (state.url === '/reset-password') {
            this.notify.info('Log out to finish resetting your password')
            return this.router.parseUrl('/main');
        }
        this.notify.info('Page not available when logged in')
        return this.router.parseUrl('/main');
    }
}
