import { Component, OnInit } from '@angular/core';
import { SearchService } from 'ldt-dw-reader-service-api';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';
import * as FileSaver from 'file-saver';
import * as moment from 'moment';

@Component({
  selector: 'app-os-query',
  templateUrl: './os-query.component.html',
  styleUrls: ['./os-query.component.scss']
})
export class OsQueryComponent implements OnInit {

  osRequest: string;
  downloading: boolean = false;

  constructor(
    private dwSearch: SearchService,
    private notify: NotificationService
    ) { }

  ngOnInit(): void {
  }

  downloadOsQuery() {
    if( this.osRequest ) {
      this.downloading = true;
      this.dwSearch.opensearchDownload({request: [this.osRequest]}).subscribe((res:any) => {
          var data = new Blob([res], {type: "text/csv"});
          FileSaver.saveAs(data, "opensearch-download-" + moment().format("YYYYMMDD-HHmmss") + ".csv");
          this.downloading = false;
        }, (e) => {
          this.notify.error(e.message)
          this.downloading = false;
        })

    } else {
      this.notify.error('Missing request content');
    }
  }  
}
