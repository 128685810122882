import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { ColumnType, DWColumn } from 'src/app/data-warehouse/dw-column';
import { PersonSearchFilter } from 'ldt-people-api';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';
import { SearchService as DWSearchService } from 'ldt-dw-reader-service-api'; // TEMP TODO prototype quick builds
import { SearchFilter, SearchReport } from 'ldt-dw-reader-service-api';
import { UIFilter } from '../filters.component';
import * as dayjs from 'dayjs';

@Component({
  selector: 'app-quick-builds',
  templateUrl: './quick-builds.component.html',
  styles: [],
})
export class QuickBuildsComponent implements OnInit {
  @Input() uiFilters: UIFilter[] = [];
  @Input() columns: DWColumn[] = [];
  @Input() PAST_JOBS: string;
  @Output() quickBuildSelected = new EventEmitter<UIFilter[]>();
  quickBuilds: SearchReport[] = [];
  showQuickBuilds: boolean = true;

  constructor(
    private notify: NotificationService,
    private dwReader: DWSearchService // TEMP: TODO: prototype for quick builds
  ) {}

  ngOnInit(): void {
    this.loadQuickBuilds();
  }

  loadQuickBuilds(): void {
    this.dwReader.getSearchReports().subscribe({
      next: (r: any[]) => {
        this.quickBuilds = r.slice(-16); // For demo purposes
      },
      error: () => {
        this.notify.error('Error getting saved searches');
      },
    });
  }

  setQuickBuildFilters(quickBuildId: number) {
    const saved = this.quickBuilds.find((s) => s.id == quickBuildId);
    if (saved && saved.parameters) {
      try {
        const uiFilters = this.quickBuildFiltersToUIFilters(JSON.parse(saved.parameters));
        this.quickBuildSelected.emit(uiFilters);
      } catch (e) {
        console.error(e);
        this.notify.error('Error loading Quick Build.');
      }
    }
  }

  quickBuildFiltersToUIFilters(params: any): UIFilter[] {
    let newFilters: UIFilter[] = [];
    let pastJobGroupIndex = 1;

    params.filters.forEach((f: any) => {
      const col: DWColumn | undefined = this.columns.find((c) => c.name === f.field);
      if (!col) {
        this.notify.warning(
          'Error getting column for field ' + f.field + '. (maybe an internal column?)'
        );
        return;
      }

      let filter: PersonSearchFilter = {
        field: f.field,
        type: SearchFilter.TypeEnum.Must,
        match_type: f.match_type,
      };

      // Current "quick builds" as saved in saved reports are a mess of untyped data, hence the weird
      // checking and use of f.value and f.string_values below. When we improve quick builds, we'll fix that
      switch (col.type) {
        case ColumnType.joblevel:
        case ColumnType.jobfunction:
          filter.string_values = f.value ?? f.string_values;
          break;
        case ColumnType.string:
        case ColumnType.boolean:
          filter.string_values = f.value ? f.value.split(',') : f.string_values || [];
          break;
        case ColumnType.number:
          filter.number_min = f.value;
          break;
        case ColumnType.date:
          filter.date_from =
            f.date_from ?? f.value ? dayjs(f.value?.startDate).format('YYYY-MM-DD') : '';
          filter.date_to = f.date_to ?? f.value ? dayjs(f.value?.endDate).format('YYYY-MM-DD') : '';
          break;
      }

      const uiFilter: UIFilter = {
        field: col,
        filter: filter,
      };

      if (col.group === this.PAST_JOBS) {
        if (!uiFilter.filter.job_group) {
          uiFilter.filter.job_group = pastJobGroupIndex++;
        }
      }

      newFilters.push(uiFilter);
    });

    return newFilters;
  }

  toggleQuickBuilds() {
    this.showQuickBuilds = !this.showQuickBuilds;
  }
}
