import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth/service/auth.service';
import { NotificationService } from '../shared/notification-service/notification.service';

interface DashboardAction {
    title: string;
    text: string;
    buttonId: string;
    buttonTitle: string;
    buttonIcon: string;
    buttonClass: string;
    link: string;
}

@Component({
    selector: 'app-main-page',
    templateUrl: './main-page.component.html',
    styleUrls: ['./main-page.component.scss']
})

export class MainPageComponent implements OnInit {

    // tslint:disable-next-line: max-line-length
    constructor(private router: Router, private authService: AuthService, private route: ActivatedRoute, private notify: NotificationService) { 
    }

    ngOnInit() {
        this.route.data.subscribe(data => {
            if (data.pageNotFound) {
                this.notify.error("Requested page not found")
            }
        });

        // If the user has access to an org, redirect to a capability the have access to
        const orgCapabilites = this.authService.getOrgCapabilitesValue;
        if (this.authService.getSelectedOrgIdValue) {
            if( orgCapabilites.includes('ledger') ) {
                this.router.navigateByUrl(this.authService.getSelectedOrgIdValue + '/ledgers')
            } else if (orgCapabilites.includes('moneyball')) {
                this.router.navigateByUrl('/moneyball')
            } else if (orgCapabilites.includes('clean')) {
                this.router.navigateByUrl(this.authService.getSelectedOrgIdValue + '/jobs')
            }
        } else {
            this.router.navigate(["invitations"]);
        }
    }

}
