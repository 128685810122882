<mat-card class="searchCard">
    <mat-card-content>
        <div class="searchFields">
            <mat-form-field class="techSearch">
                <mat-label>Technology</mat-label>
                <input matInput placeholder="Search for a technology" [formControl]="techSearchControl" required [matAutocomplete]="auto">
                <mat-autocomplete #auto="matAutocomplete">
                  <mat-option *ngFor="let tech of searchTechs | async" [value]="tech">
                        {{ tech }}
                  </mat-option>
                </mat-autocomplete>
            </mat-form-field>            
            <mat-form-field appearance="fill">
                <mat-label>Departments</mat-label>
                <mat-select multiple [(value)]="selectedDepartments">
                    <mat-option *ngFor="let dpt of departments" [value]="dpt">
                        {{dpt}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>Levels</mat-label>
                <mat-select multiple [(value)]="selectedLevels">
                    <mat-option *ngFor="let lvl of jobLevels" [value]="lvl">
                        {{lvl}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div>
                <button mat-raised-button color="primary" (click)="getTechCompanies()" [class.spinner]="loading" [disabled]="loading" >Get Targets</button>
            </div>            
        </div>
    </mat-card-content>
</mat-card>
<div *ngIf="rowData.length > 0 && !loading" class="actionContainer">
    <button mat-raised-button [disabled]="rowData.length < 1" (click)="copyToClipboard()">Copy to Clipboard</button>
    <span class="companyMatchCount"><b>Total</b> companies using: {{companiesUsing | number}}</span>
    <span class="ledgerSize">{{rowData.length | number}} Matching Persons</span>
</div>
<ag-grid-angular *ngIf="rowData.length > 0 && !loading" style="height: 100%; width: 100%" class="ag-theme-alpine" [rowData]="rowData" [tooltipShowDelay]="tooltipShowDelay"
    [columnDefs]="columnDefs" [rowSelection]="rowSelection" [defaultColDef]="defaultColDef" (gridReady)="onGridReady($event)"
    enableCellTextSelection="true" ensureDomOrder="true">
</ag-grid-angular>