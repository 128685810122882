import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FlatfileButtonComponent, FlatfileCustomer, FlatfileResults } from '@flatfile/angular';
import {
  FlatfileSettings,
  IValidatorOtherDictionary,
  IValidatorRegexDictionary,
  IValidatorRequiredWithSimpleDictionary,
} from '@flatfile/angular/lib/interfaces/settings';
import {
  LedgerOnboardingService,
  LedgerOnboardingTask,
  MoneyballOnboardingService,
  StartLedgerOnboardingRequest,
  StartMoneyballOnboardingRequest,
} from 'ldt-onboarding-service-api';
import { StepRequest } from 'ldt-onboarding-service-api/model/stepRequest';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import {
  concatMap,
  delay,
  of,
  retryWhen,
  share,
  Subject,
  switchMap,
  take,
  takeUntil,
  takeWhile,
  tap,
  throwError,
  timer,
} from 'rxjs';
import { environment } from 'src/environments/environment';
import { NotificationService } from '../shared/notification-service/notification.service';
import { StepperComponent } from './stepper/stepper.component';

@Component({
  selector: 'app-onboardingv2',
  templateUrl: './onboardingv2.component.html',
  styleUrls: ['./onboardingv2.component.scss'],
})
export class Onboardingv2Component implements OnInit {
  @ViewChild('cdkStepper') private stepper: StepperComponent;
  @ViewChild(FlatfileButtonComponent) ffComponent: FlatfileButtonComponent;

  contactFormGroup: UntypedFormGroup;
  personaFormGroup: UntypedFormGroup;
  goalFormGroup: UntypedFormGroup;
  activeForm: UntypedFormGroup;
  dataFormGroup: UntypedFormGroup;
  sampleFormGroup: UntypedFormGroup;
  nextEnabled: boolean = false;
  complete: boolean = false;
  continuing: boolean = false;
  preselectedPersona: string | null = null;

  linear: boolean = false;
  currentStep: string = 'profile';
  private commonStepSequence = ['profile', 'persona', 'goals', 'details', 'summary'];
  private personas = [
    {
      name: 'gtm',
      headline: 'I am a GTM team member',
      goal: 'Looking for revenue opportunities',
    },
    {
      name: 'investor',
      headline: 'I am an investor',
      goal: 'Looking for investment opportunities',
    },
    {
      name: 'data',
      headline: 'I am a developer or data vendor',
      goal: 'Looking to improve my data or product offering',
    },
  ];
  selectedPersona: any;

  private investorGoals = [
    {
      name: 'public',
      headline: 'Public Markets & Hedge Funds',
      goal: "I'm looking to use human capital data to generate returns",
    },
    {
      name: 'private',
      headline: 'Venture Capital & Private Equity',
      goal: "I'm looking to source deals and find great talent",
    },
    {
      name: 'other',
      headline: 'Something Else',
      goal: "I'm looking to make human capital informed investment decisions",
    },
  ];
  investorGoal: any;

  private gtmGoals = [
    {
      name: 'sample',
      headline: 'I want to build a sample set of data',
      goal: 'Show me how it works',
    },
    {
      name: 'upload',
      headline: 'I want to upload my own data sample',
      goal: "Let's see how many of my contacts have changed jobs",
    },
  ];
  gtmGoal: any;

  private functions: string[];
  private industries: string[];
  private levels: string[];

  //----------------- FLATFILE CONFIGURATION -----------------
  // For some reason, definiing the required validator inline in the fields was complaining, so
  //  define it here and use it in the fields
  req: IValidatorOtherDictionary = { validate: 'required' };
  nreq: IValidatorRequiredWithSimpleDictionary = {
    validate: 'required_without',
    fields: ['first_name', 'last_name'],
  };
  fnreq: IValidatorRequiredWithSimpleDictionary = {
    validate: 'required_without',
    fields: ['full_name'],
  };
  idreq: IValidatorRegexDictionary = {
    validate: 'regex_excludes',
    regex: '^(LDLC|LDP).*',
    regexFlags: { ignoreCase: true },
    error: "Reference IDs may not begin with 'LDLC' or 'LDP'",
  };
  licenseKey: string = '1c127492-8da2-4793-9b7b-742709ac0e9f';
  customer: FlatfileCustomer = {
    userId: 'New-Ledger-Onboarding',
  };
  settings: FlatfileSettings = {
    title: 'Upload your business contacts to start tracking their employment',
    type: 'ledger_onboarding_v2',
    allowCustom: false,
    maxRecords: 500,
    fields: [
      {
        label: 'Reference ID',
        key: 'reference_id',
        description:
          'A reference ID that can be used to match records back to your source data (optional)',
        validators: [this.idreq],
      },
      {
        label: 'First Name',
        key: 'first_name',
        description: 'First name of the employee',
        validators: [this.fnreq],
      },
      {
        label: 'Last Name',
        key: 'last_name',
        description: 'Last name of the employee',
        validators: [this.fnreq],
      },
      {
        label: 'Full Name',
        key: 'full_name',
        description: 'Full name of the employee',
        validators: [this.nreq],
      },
      {
        label: 'Company Name',
        key: 'company',
        description: 'Company name as a name, domain, or website',
        validators: [this.req],
      },
      {
        label: 'Title',
        key: 'title',
        description: 'Title of the employee (optional)',
      },
      {
        label: 'LinkedIn Profile',
        key: 'linkedin_url',
        description: 'LinkedIn Profile URL (optional)',
      },
    ],
    managed: true,
  };

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private ledgerOnboarding: LedgerOnboardingService,
    private recaptcha: ReCaptchaV3Service,
    private notify: NotificationService,
    private moneyballOnboarding: MoneyballOnboardingService,
    private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute
  ) {
    this.contactFormGroup = this._formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(2)]],
      company: ['', [Validators.required, Validators.minLength(2)]],
      emailAddress: ['', [Validators.required, Validators.email]],
    });

    this.personaFormGroup = this._formBuilder.group({
      persona: [null, [Validators.required]],
    });

    this.goalFormGroup = this._formBuilder.group({
      goal: [null, [Validators.required]],
    });

    this.dataFormGroup = this._formBuilder.group({
      description: [''],
    });

    this.sampleFormGroup = this._formBuilder.group({
      functions: ['', []],
      industries: ['', []],
      levels: ['', []],
    });
  }

  ngOnInit(): void {
    this.activeForm = this.contactFormGroup;
    this.nextEnabled = this.contactFormGroup.valid;

    this.http.get('../assets/data/functions.json').subscribe((data: any) => {
      this.functions = data.functions;
    });

    this.http.get('../assets/data/industries.json').subscribe((data: any) => {
      this.industries = data.industries;
    });

    this.http.get('../assets/data/levels.json').subscribe((data: any) => {
      this.levels = data.levels;
    });

    this.route.paramMap.subscribe((params) => {
      this.preselectedPersona = params.get('persona');
    });
  }

  gotoStep(step: number): void {
    this.currentStep = this.commonStepSequence[step];
  }

  nextStep(): void {
    // Send the pipedream notification if we're not on the first step
    if (this.currentStep !== 'profile') {
      let body: StepRequest = {
        name: this.contactFormGroup.get('name')?.value,
        company: this.contactFormGroup.get('company')?.value,
        email: this.contactFormGroup.get('emailAddress')?.value,
        recaptchaToken: '',
        details: {
          persona: this.personaFormGroup.get('persona')?.value?.name,
          goal: this.goalFormGroup.get('goal')?.value?.name,
          description: this.dataFormGroup.get('description')?.value,
          sampleFunctions: this.sampleFormGroup.get('functions')?.value,
          sampleLevels: this.sampleFormGroup.get('levels')?.value,
          sampleIndustries: this.sampleFormGroup.get('industries')?.value,
          currentStep: this.currentStep,
        },
      };

      this.recaptcha.execute('homepage').subscribe({
        next: (token: string) => {
          body.recaptchaToken = token;

          this.moneyballOnboarding.step(body).subscribe({
            next: () => {
              // Nothing to do here
            },
            error: () => {
              // Nothing to do here
            },
          });
        },
        error: () => {
          this.continuing = false;
          this.notify.error('There was an error with the reCAPTCHA. Please try again.');
        },
      });
    }

    if (this.currentStep === 'profile') {
      // Once they give us their profile data, check the email address against current users
      //  and our email blacklist
      this.continuing = true;
      this.recaptcha.execute('homepage').subscribe({
        next: (token: string) => {
          let req: StartMoneyballOnboardingRequest = {
            name: this.contactFormGroup.get('name')?.value,
            company: this.contactFormGroup.get('company')?.value,
            email: this.contactFormGroup.get('emailAddress')?.value,
            recaptchaToken: token,
          };
          this.moneyballOnboarding.init(req).subscribe({
            next: () => {
              switch (this.preselectedPersona) {
                case 'moneyball':
                  // Go to finish step
                  this.personaFormGroup.patchValue({ persona: this.personas[1] });
                  this.continuing = false;
                  this.stepper.selectStepByIndex(2);
                  this.complete = true;
                  this.onboardToMoneyball();
                  return;
                default:
                  this.selectedPersona = null;
              }

              // Continue to the next step
              this.currentStep =
                this.commonStepSequence[this.commonStepSequence.indexOf(this.currentStep) + 1];
              this.setActiveForm();
              this.continuing = false;

              this.customer.userId = this.contactFormGroup.get('emailAddress')?.value;
              (window as any)['Intercom']('update', {
                app_id: environment.intercomAppId,
                email: this.contactFormGroup.get('emailAddress')?.value,
                name: this.contactFormGroup.get('name')?.value,
              });
            },
            error: (error: any) => {
              this.continuing = false;
              if (error.status === 409) {
                this.notify.error(
                  'You already have an account with Live Data. Try logging in or resetting your password.'
                );
                this.router.navigate(['/login']);
              } else if (error.status === 400) {
                this.notify.error(
                  'You submitted an invalid, unsupported, or free email address. Please use your business email address.'
                );
              } else if (error.status === 429) {
                this.notify.error(
                  "You've exceeded the number of attempts. Please try again later."
                );
              } else {
                this.notify.error(
                  "Oops! We've got a problem here. Please try again later. Thanks."
                );
              }
            },
          });
        },
        error: () => {
          this.continuing = false;
          this.notify.error('There was an error with the reCAPTCHA. Please try again.');
        },
      });
    } else if (
      this.currentStep === 'goals' &&
      this.personaFormGroup.get('persona')?.value?.name === 'investor'
    ) {
      // For investors, the fun ends here and we send them an onboarding email to continue in the platform
      this.onboardToMoneyball();
    } else if (
      this.currentStep === 'goals' &&
      this.personaFormGroup.get('persona')?.value?.name === 'data'
    ) {
      // For the data vendors, we end here and tell them we'll be in touch
      // Send a notification to pipedream that they've completed the step
      this.complete = true;
      this.stepper.selectStepByIndex(2);
      return;
    } else if (
      this.currentStep === 'goals' &&
      this.personaFormGroup.get('persona')?.value?.name === 'gtm' &&
      this.goalFormGroup.get('goal')?.value?.name === 'upload'
    ) {
      // Continue to the next step and launch the flatfile component
      this.currentStep =
        this.commonStepSequence[this.commonStepSequence.indexOf(this.currentStep) + 1];
      this.stepper.nextStep(this.currentStep);
      this.ffComponent.launch();
    } else if (
      this.currentStep === 'details' &&
      this.personaFormGroup.get('persona')?.value?.name === 'gtm'
    ) {
      this.complete = true;
      this.stepper.selectStepByIndex(2);
    } else {
      // Otherwise, continue to the next step
      this.currentStep =
        this.commonStepSequence[this.commonStepSequence.indexOf(this.currentStep) + 1];
      this.stepper.nextStep(this.currentStep);
    }
    this.setActiveForm();
  }

  // Sets the currently active form, and resets future forms
  setActiveForm() {
    switch (this.currentStep) {
      case 'persona':
        this.activeForm = this.personaFormGroup;
        this.goalFormGroup.reset();
        this.dataFormGroup.reset();
        this.sampleFormGroup.reset();
        break;
      case 'goals':
        if (this.personaFormGroup.get('persona')?.value?.name === 'data') {
          this.activeForm = this.dataFormGroup;
        } else {
          this.activeForm = this.goalFormGroup;
        }
        this.dataFormGroup.reset();
        this.sampleFormGroup.reset();
        break;
      case 'details':
        if (
          this.personaFormGroup.get('persona')?.value?.name === 'gtm' &&
          this.goalFormGroup.get('goal')?.value?.name === 'sample'
        ) {
          this.activeForm = this.sampleFormGroup;
        } else {
          this.nextEnabled = true;
        }
        break;
      default:
        this.nextEnabled = false;
    }
  }

  prevStep(): void {
    if (this.currentStep == 'profile') return;
    this.complete = false;

    this.currentStep =
      this.commonStepSequence[this.commonStepSequence.indexOf(this.currentStep) - 1];
    this.stepper.nextStep(this.currentStep);
    this.setActiveForm();
  }

  onboardingId: string;
  onboardingStatus: LedgerOnboardingTask;
  private stopPolling = new Subject<void>();
  uploadActive: boolean = false;

  onData(results: FlatfileResults): Promise<string> {
    let errorState = false;

    return new Promise((resolve, reject) => {
      if (errorState) {
        reject('There was an error during upload. Please try again.');
        errorState = false;
      } else {
        this.recaptcha.execute('onboardingSubmit').subscribe({
          next: (token: any) => {
            const obData: StartLedgerOnboardingRequest = {
              name: this.contactFormGroup.get('name')?.value.trim(),
              company: this.contactFormGroup.get('company')?.value.trim(),
              email: this.contactFormGroup.get('emailAddress')?.value.trim(),
              batchId: results.batchId,
              recaptchaToken: token,
              fileName: results.fileName === null ? '' : results.fileName,
              contactsNum: results.stats.acceptedRows === null ? 0 : results.stats.acceptedRows,
              originalRows: results.stats.originalRows,
              manual: results.manual,
            };
            this.ledgerOnboarding.startLedgerOnboarding(obData).subscribe({
              next: (res: any) => {
                this.onboardingId = res.onboardingId || '';
                localStorage.setItem('onboardingId', this.onboardingId);

                // Start our 10s polling with a 5s delay
                this.startPolling(5000, results.stats.originalRows);
                this.currentStep = 'summary';
                this.stepper.nextStep(this.currentStep);
                this.uploadActive = true;

                resolve('Upload completed successfully. Press OK to see your results.');
              },
              error: (error: any) => {
                if (error.status === 409) {
                  resolve(
                    'Error uploading your sample -- your email address already exists. Please login or use a different email address'
                  );
                } else if (error.status === 429) {
                  reject('Too many requests from this address. Please try again later.');
                } else {
                  reject('Error during onboarding process. Please try again.');
                }
              },
            });
          },
          error: () => {
            reject('Error during onboarding process. Please try again.');
            this.notify.error(
              'Captcha failed. Please ensure your browser settings do not block Javascript to Google and try again'
            );
          },
        });
      }
    });
  }

  onboardToMoneyball() {
    this.complete = true;
    this.continuing = true;
    this.recaptcha.execute('homepage').subscribe({
      next: (token: string) => {
        let request: StartMoneyballOnboardingRequest = {
          name: this.contactFormGroup.get('name')?.value,
          company: this.contactFormGroup.get('company')?.value,
          email: this.contactFormGroup.get('emailAddress')?.value,
          recaptchaToken: token,
        };
        this.moneyballOnboarding.startMoneyballOnboarding(request).subscribe({
          next: () => {
            // Go to finish step
            this.continuing = false;
            this.stepper.selectStepByIndex(2);
            this.complete = true;
          },
          error: (error: any) => {
            this.continuing = false;
            // Capture 409 response
            if (error.status === 409) {
              this.notify.error(
                'You already have an account with Live Data. Try logging in or resetting your password.'
              );
              this.router.navigate(['/login']);
            } else {
              this.notify.error("Oops! We've got a problem here. Please try again later. Thanks.");
              this.prevStep();
            }
          },
        });
      },
      error: () => {
        this.continuing = false;
        this.notify.error('There was an error with the reCAPTCHA. Please try again.');
        this.prevStep();
      },
    });
  }

  pollCount = 0;
  startPolling(initialDelay: number, originalRows: number) {
    // These errors are for when we are polling for onboarding status
    let errorRetryDelay = 5000;
    let errorRetryCount = 6;

    // Initialize the UI with the "importing" view
    this.onboardingStatus = {
      status: 'importing',
      stats: {
        totalContacts: originalRows,
        totalContactsFound: 0,
        totalNewJobs: 0,
      },
    };
    timer(initialDelay, 10000)
      .pipe(
        tap((count) => (this.pollCount = count)),
        switchMap(() => this.ledgerOnboarding.getLedgerOnboarding(this.onboardingId)),
        tap((status: LedgerOnboardingTask) => {
          this.onboardingStatus = status;

          // On the first poll, we want to use the response for a couple things. First, we update the Intercom session
          //  with the user/org data we get back
          if (this.pollCount === 0) {
            (window as any)['Intercom']('update', {
              app_id: environment.intercomAppId,
              email: status.email,
              name: this.contactFormGroup.get('name')?.value,
              user_id: status.userId,
              company: {
                company_id: status.orgId,
                name: this.contactFormGroup.get('company')?.value,
              },
            });
          }
        }),
        share(),
        // Stop polling when the onboarding is complete, or when we exit this page
        takeWhile((status) => status.status !== 'completed'),
        takeUntil(this.stopPolling),
        // Retry polling as specified by the variables at the top. Throw an error if we never succeeded
        retryWhen((errors) =>
          errors.pipe(
            delay(errorRetryDelay),
            take(errorRetryCount),
            concatMap((error, index) => {
              if (index === errorRetryCount - 1) {
                return throwError(() => error);
              }
              return of(null);
            })
          )
        )
        // If all our polling and retries fail, trigger the UI failure message
      )
      .subscribe({
        error: () => (this.onboardingStatus.status = 'failed'),
      });
  }
}
