import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
})
export class AdminStreamsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
