// import { MaterialModule } from './../material/material.module';
import { FullMaterialModule } from 'src/app/material/material-module';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { SharedModule } from 'src/app/shared/shared.module';
// import { NgxSpinnerModule } from 'ngx-spinner';
import { MatIconModule } from '@angular/material/icon';

import { MapFieldsComponent } from './map-fields/map-fields.component';
import { UploadFileComponent } from './upload-file/upload-file.component';
import { UploadRoutingModule } from './upload-routing.module';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';

@NgModule({
  declarations: [UploadFileComponent, MapFieldsComponent],
  imports: [
    CommonModule,
    UploadRoutingModule,
    SharedModule,
    FormsModule,
    HttpClientModule,
    // NgxSpinnerModule,
    FullMaterialModule,
    MatSlideToggleModule,
    MatIconModule,
  ],
})
export class UploadModule {}
