import { Injectable } from '@angular/core';
import { AdministrativeService, SetMaintenanceModeRequest } from 'ldt-ledger-service-api';
import { merge, BehaviorSubject, Observable, Subject, timer, throwError, of } from 'rxjs';
import {
  concatMap,
  delay,
  map,
  retry,
  retryWhen,
  share,
  shareReplay,
  switchMap,
  take,
  takeUntil,
} from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class MxService {
  constructor(private ledgers: AdministrativeService) {
    // Check every 5m to see if we're in mx mode
    this.$getMxMode = merge(timer(1, 300000), this.mxMode).pipe(
      switchMap(() => this.ledgers.getMaintenanceMode()),
      map((res) => res.ledgerMaintenance === SetMaintenanceModeRequest.MaintenanceEnum.On),
      // Cache and share result for 2 minutes
      shareReplay(1, 120000),
      takeUntil(this.stopPolling),
      // If this call fails, try every minute for 10 minutes and then give up
      retryWhen((errors) =>
        errors.pipe(
          delay(60000),
          take(10),
          concatMap((error, index) => {
            if (index === 9) {
              return throwError(error);
            }
            return of(null);
          })
        )
      )
    );
  }

  private mxMode = new BehaviorSubject<boolean>(false);
  $getMxMode: Observable<boolean>;
  public get getMxModeValue(): boolean {
    return this.mxMode.value;
  }
  public setMxMode(val: boolean) {
    this.mxMode.next(val);
  }

  private stopPolling = new Subject<void>();

  ngOnDestroy() {
    // Stop the mx mode polling
    this.stopPolling.next();
  }
}
