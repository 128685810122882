import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { QueryDwRequest } from 'ldt-dw-reader-service-api';
import { CompaniesService, SearchService } from 'ldt-dw-reader-service-api';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';
import { GridApi, CellClickedEvent, GetRowIdFunc, GetRowIdParams } from 'ag-grid-community';
import { formatNumber } from '@angular/common';
import { AliasTooltip } from './aliases.tooltip.component';
import { forkJoin, map } from 'rxjs';
import { ITooltipParams } from 'ag-grid-community';

@Component({
  selector: 'app-aliases',
  templateUrl: './aliases.component.html',
  styleUrls: ['./aliases.component.scss'],
})
export class AliasesComponent implements OnInit {
  inputFormGroup: UntypedFormGroup;
  searching: boolean = false;
  creatingAliases: boolean = false;
  primaryRow: any;

  private simpleFilterParams: any = {
    filterOptions: ['contains'],
    suppressAndOrCondition: true,
  };

  defaultColDef = {
    sortable: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    resizable: true,
    filterParams: this.simpleFilterParams,
    menuTabs: ['generalMenuTab', 'filterMenuTab'],
  };

  columnDefs = [
    {
      headerName: '',
      width: 40,
      maxWidth: 40,
      checkboxSelection: true,
      sortable: true,
      filter: false,
      suppressColumnsToolPanel: true,
      headerCheckboxSelection: true,
    },
    {
      field: 'name',
      headerName: 'Name',
      tooltipComponent: AliasTooltip,
      tooltipValueGetter: (params: ITooltipParams) => {
        return params.data.orgTooltip;
      },
    },
    { field: 'normalizedName', headerName: 'Normalized' },
    {
      field: 'linkedin',
      headerName: 'LinkedIn',
      cellStyle: (params: any) => {
        // TODO: Change default when we have code that checks linkedin urls
        // let style = { color: 'default', cursor: 'default', textDecoration: 'none' };
        let style = { color: 'blue', cursor: 'pointer', textDecoration: 'none' };
        if (params.value) {
          if (params.data.linkedinStatus === 'valid') {
            style.color = 'blue';
            style.cursor = 'pointer';
          } else if (params.data.linkedinStatus === 'invalid') {
            style.color = 'light-gray';
            style.cursor = 'pointer';
            style.textDecoration = 'line-through';
          }
        }
        return style;
      },
      onCellClicked: (event: CellClickedEvent) => {
        // I couldn't figure out how to style the anchor tag so
        // just using text and this click handler
        if (event.data.linkedin) {
          window.open('https://www.linkedin.com/company/' + event.data.linkedin, '_blank');
        }
      },
    },
    { field: 'companyCount', headerName: 'LDC Count', maxWidth: 150 },
    {
      field: 'aliasType',
      headerName: 'Alias',
      maxWidth: 100,
      tooltipComponent: AliasTooltip,
      tooltipValueGetter: (params: ITooltipParams) => {
        return params.data.aliasTooltip;
      },
      cellRenderer: (params: any) => {
        // Display an icon to reflect the alias type (target, source, none)
        if (params.value) {
          var icon: string = '';
          if (params.value == 'target') {
            icon = 'alias_target';
          } else if (params.value == 'source') {
            if (this.primaryRow) {
              if (params.data.aliasTo == this.primaryRow.ldc) {
                icon = 'alias_to_primary';
              } else {
                icon = 'alias_to_other';
              }
            } else {
              icon = 'alias_source';
            }
          }
          if (icon) {
            return `<img src="../assets/icons/${icon}.png" alt="alias" height="32px" />`;
          }
        }
        return '';
      },
    },
    {
      field: 'count',
      headerName: 'Count',
      valueFormatter: (params: any) => {
        return formatNumber(params.value, this.locale);
      },
    },
  ];

  rowData: any[] = [];

  normalizeCompanyName(n: string): string {
    const companyNamePrefixes = ['a', 'the'];
    const companyNameSuffixes = [
      'association',
      'co',
      'company',
      'corp',
      'ctr',
      'corporation',
      'corporate',
      'firm',
      'gmbh',
      'inc',
      'incorporated',
      'limited',
      'llc',
      'ltd',
      'llp',
      'lp',
      'pa',
      'pllc',
      'pty',
      'pcs',
      'plc',
      'pc',
    ];
    let cleaned = n.toLocaleLowerCase();
    cleaned = cleaned.replace(/[^\w ]/, ' ');
    cleaned = cleaned.replace(/(\s+)(a|an|and|the|of)(\s+)/, ' ');
    companyNamePrefixes.forEach((str) => {
      const re = new RegExp(`^${str} `, 'g');
      cleaned = cleaned.replace(re, '').trim();
    });
    while (true) {
      const old_cleaned = cleaned;
      companyNameSuffixes.forEach((str) => {
        const re = new RegExp(` ${str}$`, 'g');
        cleaned = cleaned.replace(re, '').trim();
      });
      if (cleaned == old_cleaned) {
        break;
      }
    }
    return cleaned.toUpperCase();
  }

  generateOrgTooltip(data: any): string {
    const possibleFields = [
      ['Domain', data.domain],
      ['Ticker', data.ticker],
      ['Industry', data.industry],
      ['Type', data.type],
      ['Address', data.address],
      ['Country', data.country],
      ['Location', data.location],
      ['Employee Count', data.employee_count],
    ];
    let tooltip = possibleFields
      .filter((f) => f[1])
      .map((f) => `<p><span>${f[0].toUpperCase()}: </span>${f[1]}</p>`)
      .join('');
    return tooltip.length > 0 ? tooltip : '<p>No additional information available</p>';
  }

  generateAliasTooltip(data: any): string {
    if (data.aliasType == 'target') {
      return '<p>Target of an alias</p>';
    } else if (data.aliasType == 'source') {
      return `<p>Alias to: <span>${data.aliasToName} (${data.aliasToLinkedin})</span></p>`;
    }
    return '';
  }

  private gridApi: GridApi;
  public getRowId: GetRowIdFunc = (params: GetRowIdParams) => params.data.hash;

  gridComponents = {
    loadingRenderer: function (params: any) {
      if (params.value !== undefined) {
        return params.value;
      } else {
        return '<img src="https://www.ag-grid.com/example-assets/loading.gif">';
      }
    },
  };

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.setColumnDefs(this.columnDefs);
    this.gridApi.sizeColumnsToFit();
  }

  constructor(
    @Inject(LOCALE_ID)
    private locale: string,
    private _formBuilder: UntypedFormBuilder,
    private notify: NotificationService,
    private queryService: SearchService,
    private companiesService: CompaniesService // private http: HttpClient
  ) {
    this.inputFormGroup = this._formBuilder.group({
      ref: ['', [Validators.required]],
      env: [''],
      staging: [false],
    });
  }

  ngOnInit(): void {}

  getSelectedRows(): any[] {
    try {
      return this.gridApi
        .getSelectedNodes()
        .filter((node) => node.displayed)
        .map((node) => node.data);
    } catch {
      return [];
    }
  }

  searchCompanyName() {
    const companyName = this.inputFormGroup.get('ref')?.value;
    const query = {
      size: 0,
      aggs: {
        data: {
          nested: {
            path: 'jobs',
          },
          aggs: {
            data: {
              filter: {
                bool: {
                  must: [
                    {
                      match: {
                        'jobs.company.name': companyName,
                      },
                    },
                  ],
                },
              },
              aggs: {
                data: {
                  terms: {
                    field: 'jobs.company.id.keyword',
                    size: 1000,
                    order: {
                      _count: 'desc',
                    },
                  },
                  aggs: {
                    data: {
                      top_hits: {
                        size: 1,
                        _source: {
                          includes: [
                            'jobs.company.name',
                            'jobs.company.linkedin',
                            'jobs.company.address',
                            'jobs.company.country',
                            'jobs.company.employee_count',
                            'jobs.company.industry',
                            'jobs.company.location',
                            'jobs.company.ticker',
                            'jobs.company.type',
                            'jobs.company.domain',
                          ],
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    };

    let body: QueryDwRequest = {
      query: query,
    };

    this.rowData = [];
    this.searching = true;
    this.queryService.queryDw(body).subscribe({
      next: (r: any) => {
        let rows = r.aggregations.data.data.data.buckets.map((b: any) => {
          return {
            ldc: b.key,
            count: b.doc_count,
            data: b.data.hits.hits[0]._source.company,
          };
        });
        // Aggregate by case-insensitive name and linkedin because that's how aliases work
        const aggregatedData: any = {};
        rows.forEach((r: any) => {
          const hash = `${r.data.name.toLowerCase()}_${r.data.linkedin}`.replace(/ /g, '_');
          if (aggregatedData[hash]) {
            aggregatedData[hash].ldc = undefined;
            aggregatedData[hash].count += r.count;
            aggregatedData[hash].companyCount += 1;
            aggregatedData[hash].orgTooltip = '<p>Multiple companies with the same name</p>';
          } else {
            aggregatedData[hash] = {
              hash: hash,
              ldc: r.ldc,
              count: r.count,
              name: r.data.name.toUpperCase(),
              normalizedName: this.normalizeCompanyName(r.data.name),
              linkedin: r.data.linkedin,
              linkedinStatus: 'unknown',
              orgTooltip: this.generateOrgTooltip(r.data),
              aliasKey: r.data.linkedin ? r.data.linkedin : r.data.name,
              aliasKeyType: r.data.linkedin ? 'linkedin' : 'name',
              companyCount: 1,
            };
          }
        });
        rows = Object.values(aggregatedData);
        const targetsChunkSize = 100;
        // Kick off processes to find if the company is an alias target or source.
        // Only check the first batch for targets (for performance)
        let findTargetRequests: any[] = [];
        let findSourceRequests: any[] = [];
        rows
          .filter((r: any) => r.ldc)
          .slice(0, targetsChunkSize)
          .forEach((r: any) => {
            findTargetRequests.push(
              this.companiesService
                .listCompanyAliases(r.ldc)
                .pipe(map((value) => ({ type: 'target', value: value, hash: r.hash })))
            );
          });
        // Check for sources in batches so we don't overload the server
        const sourcesChunkSize = 100;
        for (let i = 0; i < rows.length; i += sourcesChunkSize) {
          const chunk = rows.slice(i, i + sourcesChunkSize);
          const payload = chunk.map((r: any) => {
            return { key: r.aliasKey, key_type: r.aliasKeyType };
          });
          const hashes = chunk.map((r: any) => r.hash);
          findSourceRequests.push(
            this.companiesService
              .findCompanyAliases({ keys: payload })
              .pipe(map((value) => ({ type: 'source', value: value, hashes: hashes })))
          );
        }
        forkJoin([...findTargetRequests, ...findSourceRequests]).subscribe({
          next: (responseList: any[]) => {
            for (let resp of responseList) {
              if (resp.value) {
                if (resp.type == 'target') {
                  if (resp.value.companyAliases) {
                    const hash = resp.hash;
                    let r = aggregatedData[hash];
                    r.aliasType = 'target';
                    r.aliasTo = undefined;
                    r.aliasTooltip = this.generateAliasTooltip(r);
                  }
                } else if (resp.type == 'source') {
                  for (let i = 0; i < resp.value.length; i++) {
                    const alias = resp.value[i];
                    if (alias.id) {
                      const hash = resp.hashes[i];
                      let r = aggregatedData[hash];
                      if (r.aliasType != 'target') {
                        r.aliasType = 'source';
                        r.aliasTo = alias.id;
                        r.aliasToName = alias.name;
                        r.aliasToLinkedin = alias.linkedin;
                        r.aliasTooltip = this.generateAliasTooltip(r);
                      }
                    }
                  }
                }
              }
            }
            this.rowData = Object.values(aggregatedData);
            this.searching = false;
          },
          error: () => {
            this.notify.error('Error querying alias info');
            this.searching = false;
          },
        });
      },
      error: () => {
        this.notify.error('Error querying companies');
        this.searching = false;
      },
    });
  }

  canSetAsPrimary(): boolean {
    const selectedRows = this.getSelectedRows();
    return selectedRows.length == 1 && selectedRows.every((r: any) => r.aliasType != 'source');
  }

  setAsPrimary() {
    if (this.getSelectedRows().length != 1) {
      this.notify.error('Please select one row to set as primary');
      return;
    }
    this.primaryRow = this.gridApi.getSelectedRows()[0];
    this.gridApi.deselectAll();
    this.rowData = this.rowData.filter((r: any) => r.ldc != this.primaryRow.ldc);
    this.gridApi.refreshCells({ force: true, columns: ['aliasType'] });
  }

  canCreateAlias(): boolean {
    if (this.primaryRow) {
      const selectedRows = this.getSelectedRows();
      if (selectedRows.length > 0) {
        return selectedRows.every((r: any) => !r.aliasType);
      }
    }
    return false;
  }

  createAlias() {
    if (!this.canCreateAlias()) {
      return;
    }

    const selectedRows = this.getSelectedRows();
    const ldc = this.primaryRow.ldc;

    this.gridApi.deselectAll();
    this.creatingAliases = true;

    const payload = selectedRows.map((r: any) => {
      return r.linkedin ? { linkedin: r.linkedin } : { name: r.name };
    });
    this.companiesService.createCompanyAlias(ldc, payload).subscribe({
      next: (resp) => {
        for (let i = 0; i < selectedRows.length; i++) {
          let r = selectedRows[i];
          r.aliasType = 'source';
          r.aliasTo = ldc;
          this.gridApi.getRowNode(r.hash)?.setData(r);
        }
        this.creatingAliases = false;
      },
      error: () => {
        this.notify.error('Error creating alias');
      },
    });
  }

  canDeleteAliasFrom(): boolean {
    const selectedRows = this.getSelectedRows();
    return selectedRows.length > 0 && selectedRows.every((r: any) => r.aliasType == 'source');
  }

  deleteAliasFrom() {
    if (!this.canDeleteAliasFrom()) {
      return;
    }

    const selectedRows = this.getSelectedRows();
    this.gridApi.deselectAll();

    const payload = selectedRows.map((r: any) => {
      return { id: r.aliasTo, key: r.aliasKey, key_type: r.aliasKeyType };
    });
    this.companiesService.deleteCompanyAliases({ keys: payload }).subscribe({
      next: () => {
        selectedRows.forEach((r: any) => {
          r.aliasType = undefined;
          r.aliasTo = undefined;
          this.gridApi.getRowNode(r.hash)?.setData(r);
        });
        // If we deleted the last alias for a target, it's not a target anymore
        const targets = this.rowData.filter((r: any) => r.aliasType == 'target');
        targets.forEach((r: any) => {
          this.companiesService.listCompanyAliases(r.ldc).subscribe({
            next: (resp: any) => {
              if (!resp.companyAliases) {
                r.aliasType = undefined;
                r.aliasTo = undefined;
                this.gridApi.getRowNode(r.hash)?.setData(r);
              }
            },
          });
        });
      },
      error: () => {
        this.notify.error('Error deleting alias');
      },
    });
  }

  canDeleteAliasTo(): boolean {
    const selectedRows = this.getSelectedRows();
    return selectedRows.length > 0 && selectedRows.every((r: any) => r.aliasType == 'target');
  }

  deleteAliasTo() {
    if (!this.canDeleteAliasTo()) {
      return;
    }

    const selectedRows = this.getSelectedRows();
    this.gridApi.deselectAll();

    selectedRows.forEach((r: any) => {
      const ldc = r.ldc;
      this.companiesService.deleteAllCompanyAliases(ldc).subscribe({
        next: () => {
          selectedRows.forEach((r: any) => {
            r.aliasType = undefined;
            r.aliasTo = undefined;
            this.gridApi.getRowNode(r.hash)?.setData(r);
          });
          // Update any sources that were pointing to this target
          const targets = this.rowData.filter(
            (r: any) => r.aliasType == 'source' && r.aliasTo == ldc
          );
          targets.forEach((r: any) => {
            r.aliasType = undefined;
            r.aliasTo = undefined;
            this.gridApi.getRowNode(r.hash)?.setData(r);
          });
        },
        error: () => {
          this.notify.error('Error deleting aliases for: ' + ldc);
        },
      });
    });
  }

  resetForm() {
    this.rowData = [];
    this.primaryRow = null;
    this.inputFormGroup.reset();
  }
}
