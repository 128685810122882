import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-invitations-create-dialog',
  templateUrl: './invitations-create-dialog.component.html',
  styleUrls: ['./invitations-create-dialog.component.scss']
})
export class InvitationsCreateDialogComponent implements OnInit {

  form: UntypedFormGroup;
  emails: string;
  role: string;

  constructor(
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<InvitationsCreateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data:any
  ) { 
  }

  ngOnInit() {
    this.form = this.fb.group({
      emails: [this.emails, [Validators.required]],
      role: [this.role, [Validators.required]]
    })
  }

  save() {
    this.dialogRef.close(this.form.value);
  }

  close() {
    this.dialogRef.close();
  }
}
