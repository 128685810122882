<h1 class="content-title tw-mb-0 tw-mt-0">Moneyball Battle Beta</h1>
<p class="tw-mt-0">Select up to ten companies to see their data.</p>

<!-- search -->
<mat-card class="searchCard moneyballBattleContainer tw-max-w-[1800px]">
  <mat-card-content>
    <div class="tw-flex tw-flex-wrap tw-justify-between tw-items-center">
      <div class="tw-flex tw-gap-2 tw-items-center tw-flex-wrap">
        <div
          *ngFor="let company of companiesAsArray()"
          class="company-tag-selected tw-flex tw-justify-between tw-items-center tw-bg-gray-600 tw-text-white tw-rounded tw-border tw-border-solid tw-border-gray-600 tw-text-sm tw-font-medium tw-h-10 tw-max-w-80 tw-pl-3 tw-pr-2">
          <a
            class="tw-flex tw-items-center tw-flex-1 tw-text-white tw-max-w-[17rem] tw-mr-2"
            (click)="goToMoneyball(company.id)"
            href="javascript:void(0)">
            <mat-icon
              class="tw-inline-block tw-text-base tw-flex-shrink-0"
              matTooltip="Go to company info page"
              matTooltipPosition="above">
              open_in_new
            </mat-icon>

            <span
              class="hover:tw-underline tw-whitespace-nowrap tw-overflow-hidden tw-text-ellipsis">
              {{company.name}}
            </span>
          </a>
          <!-- close button inside company pill -->
          <button
            class="icon-btn tw-text-white tw-bg-transparent tw-border tw-border-solid tw-border-transparent tw-rounded-sm hover:tw-bg-gray-700 tw-cursor-pointer focus:tw-border-dashed focus:tw-border-white focus:tw-rounded focus:tw-bg-gray-700 tw-transition-all"
            (click)="removeCompanyFromList(company.id)">
            <mat-icon
              class="close-icon tw-inline-block tw-text-base tw-leading-[26px] tw-font-semibold">
              clear
            </mat-icon>
          </button>
        </div>

        <app-company-search
          *ngIf="companiesAsArray().length < maxCompanies"
          resetAfterSelection="true"
          [labelText]="'Add a company'"
          (companySelected)="companySelected($event)" />
      </div>

      <!-- job function dropdown -->
      <app-job-function-dropdown
        [availableJobFunctions]="availableJobFunctions"
        [selectedJobFunction]="selectedJobFunction"
        (jobFunctionSelected)="onJobFunctionSelected($event)">
      </app-job-function-dropdown>
    </div>
  </mat-card-content>
</mat-card>

<div class="tw-grid tw-gap-4 tw-grid-cols-2 tw-w-full tw-mt-7">
  <!-- Chart Headcount -->
  <div
    id="chart-container-headcount"
    class="tw-flex tw-flex-col tw-col-span-2 xl:tw-col-span-1 tw-h-full tw-max-h-[500px]">
    <div class="tw-flex tw-flex-row tw-justify-end tw-mb-2">
      <div class="tw-flex tw-items-center tw-space-x-1">
        <span class="tw-text-sm tw-font-medium">Values:</span>
        <mat-button-toggle-group
          #group="matButtonToggleGroup"
          name="values"
          aria-label="Values"
          (change)="toggleHeadcountValueType($event)"
          class="moneyBattleToggle tw-h-6 tw-border tw-border-solid tw-border-gray-300">
          <mat-button-toggle
            value="percentage"
            class="tw-px-2 tw-h-6 tw-text-xs tw-leading-6 tw-min-w-[32px]"
            [checked]="selectedHeadcountChartValueType === 'percentage'">
            %
          </mat-button-toggle>
          <mat-button-toggle
            value="number"
            class="tw-px-2 tw-h-6 tw-text-xs tw-leading-6 tw-min-w-[32px]"
            [checked]="selectedHeadcountChartValueType === 'number'">
            #
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="tw-flex-1 mat-card-shadow tw-rounded">
      <div id="arrDepChartContainer" style="width: 100%; height: 100%" class="tw-rounded"></div>
    </div>
  </div>

  <!-- Chart Tenure -->
  <div
    id="chart-container-tenure"
    class="tw-flex tw-flex-col tw-col-span-2 xl:tw-col-span-1 tw-h-full tw-max-h-[500px]">
    <div class="tw-flex tw-flex-row tw-justify-end tw-mb-2 tw-mt-4 xl:tw-mt-0">
      <div class="tw-flex tw-items-center tw-space-x-1">
        <span class="tw-text-sm tw-font-medium">Employee Status:</span>
        <mat-button-toggle-group
          #group="matButtonToggleGroup"
          name="values"
          aria-label="Values"
          (change)="toggleTenureStatus($event)"
          class="moneyBattleToggle tw-h-6 tw-border tw-border-solid tw-border-gray-300">
          <mat-button-toggle
            value="current"
            class="tw-px-2 tw-h-6 tw-text-xs tw-leading-6 tw-min-w-[32px]"
            [checked]="selectedTenureChartStatus === 'current'">
            Active
          </mat-button-toggle>
          <mat-button-toggle
            value="departed"
            class="tw-px-2 tw-h-6 tw-text-xs tw-leading-6 tw-min-w-[32px]"
            [checked]="selectedTenureChartStatus === 'departed'">
            Departed
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="tw-h-full mat-card-shadow tw-rounded">
      <div id="tenureChartContainer" style="width: 100%; height: 100%" class="tw-rounded"></div>
    </div>
  </div>
</div>

<!-- Chart All Employee Movement -->
<div
  id="chart-container-movement"
  class="tw-flex tw-justify-center tw-w-full tw-h-2/3 mat-card-shadow tw-rounded tw-mt-9">
  <div id="fromToChartContainer" style="width: 100%; height: 100%" class="tw-rounded"></div>
</div>

<!-- Chart Inter-company Movement -->
<div
  id="chart-container-inter"
  class="tw-flex tw-justify-center tw-w-full tw-h-2/3 mat-card-shadow tw-rounded tw-mt-9">
  <div id="poachingChartContainer" style="width: 100%; height: 100%" class="tw-rounded"></div>
</div>
