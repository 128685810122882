import { Injectable } from '@angular/core';
import { Company, QueryDwRequest, SearchService } from 'ldt-dw-reader-service-api';
import { StreamsOnboardingService } from 'ldt-onboarding-service-api';
import { StreamsFreeTrial } from 'ldt-onboarding-service-api/model/streamsFreeTrial';
import { BehaviorSubject } from 'rxjs';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';

export interface EnrichedStream extends StreamsFreeTrial {
  enriched_companies?: Company[];
}

export interface CompanyWithCount extends Company {
  count: number;
}

@Injectable({
  providedIn: 'root',
})
export class AdminOnboardingService {
  private cachedCompanies: any[] = [];
  private streamsSource = new BehaviorSubject<EnrichedStream[] | null>(null);
  private companiesSource = new BehaviorSubject<Company[] | null>(null);
  private companyCountSource = new BehaviorSubject<CompanyWithCount[] | null>(null);
  private refreshing = new BehaviorSubject<boolean>(false);

  streams$ = this.streamsSource.asObservable();
  companies$ = this.companiesSource.asObservable();
  companyCount$ = this.companyCountSource.asObservable();
  refreshing$ = this.refreshing.asObservable();

  constructor(
    private onboardingService: StreamsOnboardingService,
    private searchService: SearchService,
    private notify: NotificationService
  ) {
    this.getStreams();
  }

  public refresh() {
    this.getStreams();
  }

  private getStreams() {
    this.refreshing.next(true);
    this.onboardingService.getStreamsTrials().subscribe((data: StreamsFreeTrial[]) => {
      this.streamsSource.next(data as EnrichedStream[]);

      const allCompanyIds = data.flatMap((c) => c.companies);
      const uniqueCompanyIds = new Set(allCompanyIds);
      const uniqueCompanyIdsArray = Array.from(uniqueCompanyIds) as string[];

      this.getCompanies(uniqueCompanyIdsArray);
    });
  }

  private getCompanies(companyIds: string[]) {
    const cachedIds = this.cachedCompanies.map((company) => company.id);
    const newIds = companyIds.filter((id) => !cachedIds.includes(id));

    const req: QueryDwRequest = {
      query: {
        query: {
          terms: {
            'id.keyword': newIds,
          },
        },
        size: newIds.length,
        _source: ['name', 'id', 'linkedin'],
      },
      index: 'companies',
    };

    this.searchService.queryDw(req).subscribe({
      next: (data: any) => {
        this.cachedCompanies = [
          ...this.cachedCompanies,
          ...data.hits.hits.map((h: any) => h._source),
        ];
        this.companiesSource.next(this.cachedCompanies);
        this.addCompanyNamesToData();
        this.generateCompanyCountData();
        this.refreshing.next(false);
      },
      error: (err) => {
        this.refreshing.next(false);
        this.notify.error('Error getting company names');
      },
    });
  }

  addCompanyNamesToData() {
    if (!this.streamsSource.value || !this.companiesSource.value) return;

    let newStreamsData = this.streamsSource.value.map((r: EnrichedStream) => {
      r.enriched_companies = r.companies!.map((c) => {
        const company = this.companiesSource.value!.find((cc) => cc.id === c);
        return company ? company : { id: c, name: c };
      });
      return r;
    }) as EnrichedStream[];

    this.streamsSource.next(newStreamsData);
  }

  generateCompanyCountData(): void {
    const counts: { [key: string]: CompanyWithCount } = {};

    this.streamsSource
      .value!.filter((row) => row.email && !row.email.endsWith('@livedatatechnologies.com'))
      .forEach((item) => {
        item.enriched_companies?.forEach((company: Company) => {
          if (counts[company.id!]) {
            counts[company.id!].count += 1;
          } else {
            counts[company.id!] = { ...company, count: 1 };
          }
        });
      });

    this.companyCountSource.next(Object.values(counts));
  }
}
