<div>Total rows evaluated: {{rowsToEval}}</div>
<div>Found in v2: {{foundInV2}}</div>
<div>Matching Data: {{matchingData}}</div>
<div class="container" *ngIf="rowData; else elseBlock"> 
    <div *ngFor="let c of v1Data">
        {{c.id}}
    </div>

    <ag-grid-angular style="height: 100%; width: 100%" class="ag-theme-alpine" [rowData]="rowData" 
        [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" >
    </ag-grid-angular>    
</div>
<ng-template #elseBlock>
    Loading...
</ng-template>