import { Component, OnInit } from '@angular/core';
import { Papa } from 'ngx-papaparse';
import { saveAs } from 'file-saver';
import { SearchService, FindRequest, FindRequestMatchesInnerFieldsInner } from 'ldt-people-api';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/service/auth.service';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';
import { from, map, mergeMap } from 'rxjs';

interface Row {
  [key: string]: string;
}

@Component({
  selector: 'app-find',
  templateUrl: './find.component.html',
  styleUrls: ['./find.component.scss'],
})
export class PeopleFindComponent implements OnInit {
  orgId: string;
  isDragOver: boolean = false;
  uploadError: string | undefined = undefined;
  uploadMessage: string | undefined = undefined;
  uploadPercent: number | undefined = undefined;

  constructor(
    private people: SearchService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private notify: NotificationService,
    private router: Router,
    private papa: Papa
  ) {}

  ngOnInit(): void {
    let orgId = this.route.parent?.snapshot.paramMap.get('orgId');
    if (!orgId) {
      orgId = this.authService.getSelectedOrgIdValue;

      if (!orgId) {
        this.notify.error('Invalid path');
        this.router.navigateByUrl('/main');
      }
    }

    this.orgId = orgId;
  }

  dragOver(evt: DragEvent) {
    if (evt.dataTransfer) evt.dataTransfer.dropEffect = 'copy';
    evt.preventDefault();
  }

  dropFiles(evt: DragEvent) {
    if (!evt.dataTransfer) return;

    evt.preventDefault();
    this.uploadFiles(evt.dataTransfer.files);
  }

  async uploadFiles(files: FileList) {
    this.uploadError = undefined;

    if (files.length !== 1) {
      this.uploadError = 'Only one file may be uploaded at a time.';
      throw new Error(`expected exactly one file, got ${files.length}`);
    }

    if (!files[0].name.endsWith('.csv')) {
      this.uploadError = 'Only CSV files may be uploaded. Please try again.';
      throw new Error(`File must be a CSV ${files[0].name}`);
    }

    const originalFileName = files[0].name.split('.').slice(0, -1).join('.');
    const date = new Date();
    const dateTime =
      date.getFullYear().toString() +
      (date.getMonth() + 1).toString().padStart(2, '0') +
      date.getDate().toString().padStart(2, '0') +
      date.getHours().toString().padStart(2, '0') +
      date.getMinutes().toString().padStart(2, '0') +
      date.getSeconds().toString().padStart(2, '0');
    const newFileName = `${originalFileName}_livedata_find_${dateTime}.csv`;

    this.uploadPercent = 1;

    const reader = new FileReader();
    reader.onload = (e) => {
      if (e.target) {
        const contents = e.target.result as string;

        const guessFieldName = (header: string): string => {
          header = header.toLowerCase();
          if (header.includes('name') && header.includes('company')) return 'company.name';
          if (header.includes('linkedin') && header.includes('company')) return 'company.linkedin';
          if (header.includes('ticker')) return 'company.ticker';
          if (header.includes('domain') || header.includes('site')) return 'company.domain';
          if (header.includes('school')) return 'education.school';
          if (header.includes('name') && !(header.includes('first') || header.includes('last')))
            return 'name';
          if (header.includes('first')) return 'first_name';
          if (header.includes('last')) return 'last_name';
          if (header.includes('title')) return 'title';
          if (header.includes('linkedin')) return 'linkedin';
          if (header.includes('email')) return 'email_address';
          if (header.includes('company')) return 'company.name';
          return '';
        };

        const results = this.papa.parse(contents, {
          header: true,
          transformHeader: guessFieldName,
          skipEmptyLines: true,
        });

        const findRequest: FindRequest = {
          matches: results.data.map((row: Row) => {
            const fields: FindRequestMatchesInnerFieldsInner[] = [];
            let firstName = '';
            let lastName = '';

            Object.entries(row).forEach(([fieldName, searchTerm]) => {
              if (!searchTerm) return;

              if (fieldName === 'first_name') {
                firstName = searchTerm;
              } else if (fieldName === 'last_name') {
                lastName = searchTerm;
              } else if (fieldName !== '') {
                // Exclude columns that weren't guessed
                fields.push({
                  field_name: fieldName,
                  search_term: searchTerm,
                });
              }
            });

            if (firstName || lastName) {
              fields.push({
                field_name: 'name',
                search_term: `${firstName} ${lastName}`.trim(),
              });
            }

            return { fields };
          }),
        };

        // Split the matches into batches of no more than 100
        const batches: any[] = [];
        while (findRequest.matches!.length) {
          batches.push(findRequest.matches!.splice(0, 100));
        }
        let completedCalls = 0;
        let totalFound = 0;

        from(batches.entries())
          .pipe(
            mergeMap(
              ([index, batch]) =>
                this.people
                  .find(this.orgId, { matches: batch })
                  .pipe(map((response) => ({ response, index }))),
              10
            )
          )
          .subscribe(({ response, index }) => {
            response.matches?.forEach((item: any, matchIndex: number) => {
              results.data[index * 100 + matchIndex]['live_data_found'] = item.found;

              // If 'found' is true, append some fields from the first item in the 'people' array
              if (item.people.length > 0) {
                totalFound++;
                results.data[index * 100 + matchIndex]['live_data_id'] = item.people[0].id;
                results.data[index * 100 + matchIndex]['live_data_name'] = item.people[0].name;
                results.data[index * 100 + matchIndex]['live_data_company'] =
                  item.people[0].position.company.name;
                results.data[index * 100 + matchIndex]['live_data_title'] =
                  item.people[0].position.title;
              } else {
                results.data[index * 100 + matchIndex]['live_data_id'] = '';
                results.data[index * 100 + matchIndex]['live_data_name'] = '';
                results.data[index * 100 + matchIndex]['live_data_company'] = '';
                results.data[index * 100 + matchIndex]['live_data_title'] = '';
              }
            });

            completedCalls++;
            this.uploadPercent = (completedCalls / batches.length) * 100;

            // If all calls are completed, generate a new CSV file
            if (completedCalls === batches.length) {
              const csv = this.papa.unparse(results.data);
              const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
              saveAs(blob, newFileName);
              this.uploadPercent = undefined;

              this.uploadMessage =
                'Find complete and file downloaded to your PC (' +
                newFileName +
                '). ' +
                totalFound +
                ' found.';
            }
          });
      }
    };
    reader.readAsText(files[0]);
  }
}
