import { Injectable } from '@angular/core';
import {
  MatLegacySnackBar as MatSnackBar,
  MatLegacySnackBarConfig as MatSnackBarConfig,
} from '@angular/material/legacy-snack-bar';

@Injectable()
export class NotificationService {
  private commonConfig: MatSnackBarConfig = {
    horizontalPosition: 'center',
    verticalPosition: 'top',
    duration: 5000,
  };
  private commonAction: string = 'Close';

  constructor(private _snackBar: MatSnackBar) {}

  error(message: string, duration?: number) {
    let cfg = this.commonConfig;
    if (duration) {
      cfg.duration = duration;
    } else {
      cfg.duration = 30000;
    }
    cfg.panelClass = 'snackbar-error';
    return this._snackBar.open(message, this.commonAction, cfg);
  }

  success(message: string, duration?: number) {
    let cfg = this.commonConfig;
    if (duration) {
      cfg.duration = duration;
    }
    cfg.panelClass = 'snackbar-success';
    return this._snackBar.open(message, this.commonAction, cfg);
  }

  info(message: string, duration?: number) {
    let cfg = this.commonConfig;
    if (duration) {
      cfg.duration = duration;
    }
    cfg.panelClass = 'snackbar-info';
    return this._snackBar.open(message, this.commonAction, cfg);
  }

  warning(message: string, duration?: number) {
    let cfg = this.commonConfig;
    if (duration) {
      cfg.duration = duration;
    }
    cfg.panelClass = 'snackbar-warning';
    return this._snackBar.open(message, this.commonAction, cfg);
  }

  // close snackbar programmatically
  close() {
    this._snackBar.dismiss();
  }
}
