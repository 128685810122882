import { Component } from '@angular/core';
import { DefaultService, GetImportsStatus200Response } from 'ldt-ledger-service-api-v2';

@Component({
  selector: 'app-admin-ledgers-import-status',
  templateUrl: './import-status.component.html',
  styleUrls: ['./import-status.component.scss'],
})
export class ImportStatusComponent {
  latestStatus: GetImportsStatus200Response;
  refreshing: boolean = false;

  constructor(private ledgerAdminService: DefaultService) {}

  ngOnInit(): void {
    this.updateStatus();
  }

  updateStatus(): void {
    this.refreshing = true;
    this.ledgerAdminService.getImportsStatus().subscribe({
      next: (data) => {
        this.latestStatus = data;
        this.refreshing = false;
      },
      error: (error) => {
        console.error('Error fetching import status:', error);
        this.refreshing = false;
      },
    });
  }
}
