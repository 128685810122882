import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { DeleteConfirmationComponent } from '../../delete-confirmation/delete-confirmation.component';
import { DatePipe } from '@angular/common';
import * as FileSaver from 'file-saver';
import * as moment from 'moment';
import { Contact, Ledger, LedgerContactsService, LedgersService } from 'ldt-ledger-service-api';
import {
  ColDef,
  ColGroupDef,
  ColumnApi,
  GridApi,
  GridReadyEvent,
  ITextFilterParams,
  SideBarDef,
} from 'ag-grid-community';
import { NotificationService } from '../../shared/notification-service/notification.service';
import { Observable } from 'rxjs';
import { AuthService } from '../../auth/service/auth.service';
import 'ag-grid-enterprise';

@Component({
  selector: 'app-ledger-contacts',
  templateUrl: './ledger-contacts.component.html',
  styleUrls: ['./ledger-contacts.component.scss'],
  providers: [DatePipe],
})
export class LedgerContactsComponent implements OnInit {
  @Output() selectedTabChange: EventEmitter<any> = new EventEmitter();
  ledger: Ledger;
  orgId: string;
  canEdit: boolean = false;
  canAdmin: boolean = false;
  downloading = false;
  viewCount = 0;
  countUpdating = true;
  refreshing: boolean = true;

  /// AG -GRID --------------
  private simpleFilterParams: ITextFilterParams = {
    filterOptions: ['contains'],
    maxNumConditions: 1,
  };
  private booleanFilterParams: any = {
    values: ['true', 'false'],
    suppressMiniFilter: true,
  };
  private emailStatusFilterParams: any = {
    filterOptions: [
      'empty',
      {
        displayKey: 'valid',
        displayName: 'Valid',
        test: function (filterValue: any, cellValue: any) {
          return cellValue === 'false';
        },
        hideFilterInput: true,
      },
      {
        displayKey: 'catch-all',
        displayName: 'Catch-all',
        test: function (filterValue: any, cellValue: any) {
          return cellValue === 'true';
        },
        hideFilterInput: true,
      },
      {
        displayKey: 'best-guess',
        displayName: 'Best Guess',
        test: function (filterValue: any, cellValue: any) {
          return cellValue === 'true';
        },
        hideFilterInput: true,
      },
    ],
    suppressAndOrCondition: true,
  };
  defaultColDef = {
    sortable: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    filterParams: this.simpleFilterParams,
    resizable: true,
    flex: 1,
    minWidth: 100,
    enablePivot: false,
    menuTabs: ['generalMenuTab', 'filterMenuTab'],
  };
  sideBar: SideBarDef = {
    defaultToolPanel: 'columns',
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressColumnFilter: true,
          suppressColumnSelectAll: true,
          // suppressColumnExpandAll: true,
        },
      },
    ],
  };
  columnDefs: (ColDef | ColGroupDef)[] = [
    {
      field: 'id',
      headerName: '',
      width: 40,
      maxWidth: 40,
      checkboxSelection: true,
      sortable: false,
      filter: false,
      cellRenderer: (params: any) => {
        return '';
      },
      headerTooltip: 'Unique ID of this contact in the Live Data system',
      suppressColumnsToolPanel: true,
    },
    {
      field: 'isVerified',
      headerName: 'Verified?',
      maxWidth: 120,
      cellRenderer: (params: { value: any }) => {
        if (params.value) {
          return '<img src="../assets/icons/verified_user_black_18dp.svg" alt="true" />';
        } else {
          return '';
        }
      },
      filterParams: this.booleanFilterParams,
      filter: 'agSetColumnFilter',
      headerTooltip: 'Indicates whether Live Data was able to locate this person on the open web',
    },
    {
      headerName: 'Last Changed (UTC)',
      children: [
        {
          field: 'lastJobChangedAt',
          headerName: 'Job',
          filter: 'agDateColumnFilter',
          filterParams: { filterOptions: ['greaterThanOrEqual'] },
          sort: 'desc',
          valueFormatter: (params: any) => {
            return this.datePipe.transform(params.value, 'yyyy-MM-dd h:mm a', 'UTC') || '';
          },
          headerTooltip:
            'The last time (in UTC) Live Data updated this persons job on this ledger. A blank value indicates there has been no change from the imported data. The filter shows those that were changed on or after the provided date.',
        },
        {
          field: 'lastInfoChangedAt',
          headerName: 'Other Info',
          filter: 'agDateColumnFilter',
          filterParams: { filterOptions: ['greaterThanOrEqual'] },
          valueFormatter: (params: any) => {
            return this.datePipe.transform(params.value, 'yyyy-MM-dd h:mm a', 'UTC') || '';
          },
          headerTooltip:
            'The last time (in UTC) Live Data updated any of this persons information on this ledger. A blank value indicates there has been no change from the imported data. The filter shows those that were changed on or after the provided date.',
        },
      ],
    },
    {
      headerName: 'Person Info',
      children: [
        {
          field: 'referenceId',
          cellRenderer: 'loadingRenderer',
          headerName: 'Ref ID',
          headerTooltip: 'Your unique/internal ID used to reference this person in your systems',
        },
        { field: 'name', headerTooltip: 'The name of this person provided during import' },
        {
          field: 'linkedin',
          cellRenderer: (params: { value: any }) => {
            if (!params.value) {
              return '';
            } else {
              return (
                '<a href="https://www.linkedin.com/in/' +
                params.value +
                '" target=_blank>' +
                params.value +
                '</a>'
              );
            }
          },
          headerTooltip:
            'The LinkedIn ID of this person. Always uses the customer-provide ID, if provided. If not provided, ID was discovered by Live Data.',
        },
        {
          field: 'hasMultipleJobs',
          headerName: 'Multiple Jobs?',
          filterParams: this.booleanFilterParams,
          headerTooltip: 'Whether this person has multiple current jobs',
        },
      ],
    },
    {
      headerName: 'Current Job',
      children: [
        {
          field: 'company',
          headerName: 'Company',
          headerTooltip: 'The verified company at which this person currently works',
        },
        {
          field: 'title',
          headerName: 'Title',
          headerTooltip: 'The verified title this person currently holds',
        },
        {
          field: 'titleLevel',
          headerName: 'Job Level',
          headerTooltip: 'The inferred level of the current job, based on the title.',
        },
        {
          field: 'titleDomain',
          headerName: 'Job Domain',
          headerTooltip: 'The inferred domain of the current job, based on the title.',
        },
        {
          field: 'jobStartedAt',
          headerName: 'Start Date',
          headerTooltip: 'The date the person started at their current job',
          filter: 'agDateColumnFilter',
          filterParams: { filterOptions: ['greaterThanOrEqual'] },
        },
      ],
    },
    {
      headerName: 'Contact Info',
      children: [
        {
          field: 'contactInfo.email',
          headerName: 'Email',
          headerTooltip: 'The current email address of the contact, if known.',
        },
        {
          field: 'contactInfo.emailStatus',
          headerName: 'Email Status',
          filterParams: this.emailStatusFilterParams,
          headerTooltip:
            'The status of the email address. "valid" denotes a valid email, "catch-all" denotes a mail server that does not validate emails, and "best-guess" denotes a likely email address that could not be positively confirmed.',
        },
      ],
    },
    {
      headerName: 'Company Info',
      children: [
        {
          field: 'companyInfo.linkedin',
          headerName: 'LinkedIn',
          headerTooltip: 'The LinkedIn URL for the current company.',
          cellRenderer: (params: { value: any }) => {
            if (!params.value) {
              return '';
            } else {
              return (
                '<a href="https://www.linkedin.com/company/' +
                params.value +
                '" target=_blank>' +
                params.value +
                '</a>'
              );
            }
          },
          sortable: false,
          filter: false,
          floatingFilter: false,
        },
        {
          field: 'companyInfo.domain',
          headerName: 'Domain',
          headerTooltip: 'The top-level domain name for the current company.',
          sortable: false,
          filter: false,
          floatingFilter: false,
        },
        {
          field: 'companyInfo.size',
          headerName: 'Size',
          headerTooltip: 'The size of the current company.',
          sortable: false,
          filter: false,
          floatingFilter: false,
        },
        {
          field: 'companyInfo.industry',
          headerName: 'Industry',
          headerTooltip: 'The industry of the current company.',
          sortable: false,
          filter: false,
          floatingFilter: false,
        },
        {
          field: 'companyInfo.location',
          headerName: 'Location',
          headerTooltip: 'The geographic location of the company headquarters.',
          sortable: false,
          filter: false,
          floatingFilter: false,
        },
      ],
    },
    {
      headerName: 'Imported Data',
      children: [
        {
          field: 'importCompany',
          headerName: 'Company',
          headerTooltip: 'The name of the company that was provided during import',
        },
        {
          field: 'importTitle',
          headerName: 'Title',
          headerTooltip: 'The title of the peron that was provided during import',
        },
        {
          field: 'importTitleLevel',
          headerName: 'Job Level',
          headerTooltip: 'The inferred level of the title this contact was imported with.',
        },
        {
          field: 'importTitleDomain',
          headerName: 'Job Domain',
          headerTooltip: 'The inferred domain of the title this contact was imported with.',
        },
      ],
    },
  ];
  rowData: Contact[];
  rowCount: any = null;
  rowsSelected = false;
  tooltipShowDelay = 200;

  saveColumnState() {
    if (this.gridColumnApi) {
      localStorage.setItem(
        'ag-grid-columns-contacts',
        JSON.stringify(this.gridColumnApi.getColumnState())
      );
    }
  }

  private gridApi: GridApi;
  rowModelType: any = 'infinite';
  infiniteInitialRowCount = 600;
  cacheBlockSize = 500;
  rowSelection = 'multiple';
  components = {
    loadingRenderer: function (params: any) {
      if (params.value !== undefined) {
        return params.value;
      } else {
        return '<img src="https://www.ag-grid.com/example-assets/loading.gif">';
      }
    },
  };
  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    // Get the saved column state from localstorage if a user has hidden certain columns
    // But also reset the sort order to last job change desc
    if (localStorage.getItem('ag-grid-columns-contacts')) {
      this.gridApi.closeToolPanel();

      if (
        this.gridColumnApi.getColumnState().length !=
        JSON.parse(localStorage.getItem('ag-grid-columns-contacts') || '{}').length
      ) {
        this.notify.info(
          'Grid columns have changed, so your previous view preferences have been reset'
        );
        this.saveColumnState();
        this.gridApi.openToolPanel('columns');
      }
    }
    // this.gridColumnApi.setColumnState(JSON.parse(localStorage.getItem('ag-grid-columns-contacts') || '{}'))
    this.gridColumnApi.applyColumnState({
      state: [{ colId: 'lastJobChangedAt', sort: 'desc' }],
      defaultState: { sort: null },
    });

    // Get any filtering specified in the querystring and apply it
    let filterModel = this.getQueryParamFilterModel();
    this.gridApi.setFilterModel(filterModel);

    params.api.setDatasource(this);
  }
  gridColumnApi: ColumnApi;

  // Apply any param in the querystring as a filter to the grid
  getQueryParamFilterModel() {
    // Model to return
    let fModel: any = {};

    // Iterate through all querystring params
    Object.keys(this.route.snapshot.queryParams).forEach((p: any) => {
      let model: any = {};
      // This comes only from a "by month" kind of chart, so we filter on the selected month
      // Else, we just assume it's a text filter
      if (p === 'jobStartedAt') {
        model['filterType'] = 'date';
        model['type'] = 'greaterThanOrEqual';
        let dateStart = this.route.snapshot.queryParams[p];
        let dateEnd = moment(dateStart).endOf('month').toISOString();
        model['dateTo'] = dateEnd;
        model['dateFrom'] = dateStart;
      } else {
        model['filterType'] = 'text';
        model['type'] = 'contains';
        model['filter'] = this.route.snapshot.queryParams[p];
      }
      fModel[p] = model;
    });

    // If there was a datepicker param included, then we have a "global" date range to apply
    // This would have gotten set in the above object as well, but doesn't match any grid keys
    //  so would be ignored. Here we create a filter on jobStartedAt using that global range
    if (this.route.snapshot.queryParams['datePickerFrom']) {
      let model: any = {};
      model['filterType'] = 'date';
      model['type'] = 'inRange';
      model['dateTo'] = this.route.snapshot.queryParams['datePickerTo'] || null;
      model['dateFrom'] = this.route.snapshot.queryParams['datePickerFrom'];
      fModel['jobStartedAt'] = model;
    }
    return fModel;
  }

  getRows(params: any): any {
    this.countUpdating = true;
    this.gridApi.hideOverlay();

    // Set the offset and limit
    var queryModel = {
      offset: params.startRow,
      limit: params.endRow - params.startRow,
    };

    var sortBy;
    if (params.sortModel.length > 0) {
      var dir = '';
      if (params.sortModel[0].sort == 'desc') {
        dir = '-';
      }
      sortBy = dir + params.sortModel[0].colId;
    }

    var verifiedFilter: boolean | undefined;
    if (params.filterModel['isVerified'] && params.filterModel['isVerified'].values.length == 1) {
      verifiedFilter = params.filterModel['isVerified'].values[0] === 'true';
    }

    // Get the data
    this.ledgerService
      .getLedgerContacts(
        this.ledger.id,
        this.orgId,
        params.filterModel['company'] ? params.filterModel['company'].filter : undefined,
        params.filterModel['title'] ? params.filterModel['title'].filter : undefined,
        params.filterModel['name'] ? params.filterModel['name'].filter : undefined,
        sortBy,
        params.endRow - params.startRow,
        params.startRow,
        params.filterModel['lastJobChangedAt']
          ? params.filterModel['lastJobChangedAt'].dateFrom
          : undefined,
        undefined,
        verifiedFilter !== undefined ? verifiedFilter : undefined,
        params.filterModel['referenceId'] ? params.filterModel['referenceId'].filter : undefined,
        params.filterModel['importCompany']
          ? params.filterModel['importCompany'].filter
          : undefined,
        params.filterModel['importTitle'] ? params.filterModel['importTitle'].filter : undefined,
        params.filterModel['linkedin'] ? params.filterModel['linkedin'].filter : undefined,
        undefined,
        params.filterModel['contactInfo.email']
          ? params.filterModel['contactInfo.email'].filter
          : undefined,
        params.filterModel['contactInfo.emailStatus']
          ? params.filterModel['contactInfo.emailStatus'].type
          : undefined,
        params.filterModel['lastInfoChangedAt']
          ? params.filterModel['lastInfoChangedAt'].dateFrom
          : undefined,
        undefined,
        params.filterModel['hasMultipleJobs']
          ? params.filterModel['hasMultipleJobs'].type
          : undefined,
        params.filterModel['jobStartedAt']
          ? params.filterModel['jobStartedAt'].dateFrom
          : undefined,
        params.filterModel['jobStartedAt'] ? params.filterModel['jobStartedAt'].dateTo : undefined,
        undefined,
        params.filterModel['titleDomain'] ? params.filterModel['titleDomain'].filter : undefined,
        params.filterModel['titleLevel'] ? params.filterModel['titleLevel'].filter : undefined,
        params.filterModel['importTitleLevel']
          ? params.filterModel['importTitleLevel'].filter
          : undefined,
        params.filterModel['importTitleDomain']
          ? params.filterModel['importTitleDomain'].filter
          : undefined
      )
      .subscribe(
        (res) => {
          let lastRow: number = -1;
          let clength = 0;
          if (res.contacts) {
            clength = res.contacts.length;
          }
          // No matching rows, show overlay
          if (params.startRow === 0 && clength === 0) {
            this.gridApi.showNoRowsOverlay();
            lastRow = 0;
          }

          // Now we know what the last row is...
          if (clength < params.endRow - params.startRow) {
            lastRow = clength + params.startRow;
          }

          this.getCounts(params);

          params.successCallback(res.contacts, lastRow);
          this.refreshing = false;
        },
        () => {
          this.notify.error(
            'Oops. There was an error during your request. Please try again later.'
          );
          params.failCallback();
          this.refreshing = false;
        }
      );
  }

  private getCounts(params: any) {
    var verifiedFilter: boolean | undefined;
    if (params.filterModel['isVerified'] && params.filterModel['isVerified'].values.length == 1) {
      verifiedFilter = params.filterModel['isVerified'].values[0] === 'true';
    }

    // Get the counts
    this.ledgerService
      .getLedgerContacts(
        this.ledger.id,
        this.orgId,
        params.filterModel['company'] ? params.filterModel['company'].filter : undefined,
        params.filterModel['title'] ? params.filterModel['title'].filter : undefined,
        params.filterModel['name'] ? params.filterModel['name'].filter : undefined,
        undefined, // sort by
        undefined, // limit and offset
        undefined,
        params.filterModel['lastJobChangedAt']
          ? params.filterModel['lastJobChangedAt'].dateFrom
          : undefined,
        undefined,
        verifiedFilter !== undefined ? verifiedFilter : undefined,
        params.filterModel['referenceId'] ? params.filterModel['referenceId'].filter : undefined,
        params.filterModel['importCompany']
          ? params.filterModel['importCompany'].filter
          : undefined,
        params.filterModel['importTitle'] ? params.filterModel['importTitle'].filter : undefined,
        params.filterModel['linkedin'] ? params.filterModel['linkedin'].filter : undefined,
        'count',
        params.filterModel['contactInfo.email']
          ? params.filterModel['contactInfo.email'].filter
          : undefined,
        params.filterModel['contactInfo.emailStatus']
          ? params.filterModel['contactInfo.emailStatus'].type
          : undefined,
        params.filterModel['lastInfoChangedAt']
          ? params.filterModel['lastInfoChangedAt'].dateFrom
          : undefined,
        undefined,
        params.filterModel['hasMultipleJobs']
          ? params.filterModel['hasMultipleJobs'].type
          : undefined,
        params.filterModel['jobStartedAt']
          ? params.filterModel['jobStartedAt'].dateFrom
          : undefined,
        params.filterModel['jobStartedAt'] ? params.filterModel['jobStartedAt'].dateTo : undefined,
        undefined,
        params.filterModel['titleDomain'] ? params.filterModel['titleDomain'].filter : undefined,
        params.filterModel['titleLevel'] ? params.filterModel['titleLevel'].filter : undefined,
        params.filterModel['importTitleLevel']
          ? params.filterModel['importTitleLevel'].filter
          : undefined,
        params.filterModel['importTitleDomain']
          ? params.filterModel['importTitleDomain'].filter
          : undefined
      )
      .subscribe(
        (res) => {
          this.viewCount = res.count || 0;
          this.countUpdating = false;
        },
        () => {
          this.notify.error(
            'Oops. There was an error during your request. Please try again later.'
          );
          this.viewCount = 0;
          this.countUpdating = false;
        }
      );
  }

  //-------------------------------------------------------------
  constructor(
    private ledgerOnlyService: LedgersService,
    private ledgerService: LedgerContactsService,
    private router: Router,
    private notify: NotificationService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private auth: AuthService
  ) {}
  isAdmin: Observable<boolean>;
  orgHasReinit: boolean = false;
  async ngOnInit() {
    // Get the ledger from the resolver and org ID from the route
    this.ledger = this.route.parent?.snapshot.data.userdata;
    let routeOrg = this.route.parent?.snapshot.paramMap.get('orgId');
    if (routeOrg) {
      this.orgId = routeOrg;
    }
    this.canEdit = this.auth.userHasRole('editor');
    this.canAdmin = this.auth.userHasRole('admin');

    this.isAdmin = this.auth.$isAdmin;

    let orgV = this.auth.getOrgsValue.find((o) => {
      return o.id === this.auth.getSelectedOrgIdValue;
    });
    this.orgHasReinit = orgV?.settings?.ledger?.allowReinit || false;
  }

  // Refresh the data grid. If the request was manual (user hit the button) we always do it. If it was requested
  //  automatically (usually from polling), then we only refresh if the user has no active filters (https://app.clickup.com/t/1wzer4f)
  refreshData(manual: boolean = true) {
    if (this.gridApi) {
      if (manual || Object.keys(this.gridApi.getFilterModel()).length == 0) {
        this.refreshing = true;
        this.gridApi.purgeInfiniteCache();
      }
    }
  }

  deleteSelectedContacts() {
    var numToDelete = this.gridApi.getSelectedRows().length;
    var ids: any = this.gridApi.getSelectedRows().map((d: any) => d.id);
    const confirmDialog = this.dialog.open(DeleteConfirmationComponent, {
      data: {
        title: 'Confirm Contact Deletion',
        message:
          'Are you sure you want to delete ' +
          numToDelete +
          ' contacts? This action is unrecoverable!',
      },
    });
    confirmDialog.afterClosed().subscribe((result: boolean) => {
      if (result === true) {
        this.ledgerService
          .bulkDeleteLedgerContacts(this.orgId, this.ledger.id, { contactIds: ids })
          .subscribe(
            (response) => {
              this.gridApi.deselectAll();
              this.gridApi.purgeInfiniteCache();
              this.notify.success(response.countRowsDeleted + ' contacts successfully deleted.');
            },
            () => {
              this.notify.error(
                'Oops. There was an error during your request. Please try again later.'
              );
            }
          );
      }
    });
  }

  onSelectionChanged(event: any) {
    var selectedRows = this.gridApi.getSelectedRows();
    this.rowsSelected = selectedRows.length !== 0;
  }

  downloadView() {
    this.downloading = true;
    var fModel = this.gridApi.getFilterModel();

    this.ledgerService
      .downloadLedgerContacts(
        this.ledger.id,
        this.orgId,
        fModel['company'] ? fModel['company'].filter : undefined,
        fModel['title'] ? fModel['title'].filter : undefined,
        fModel['name'] ? fModel['name'].filter : undefined,
        undefined,
        undefined,
        undefined,
        fModel['lastJobChangedAt'] ? fModel['lastJobChangedAt'].dateFrom : undefined,
        undefined,
        fModel['isVerified'] ? fModel['isVerified'].type : undefined,
        fModel['referenceId'] ? fModel['referenceId'].filter : undefined,
        fModel['importCompany'] ? fModel['importCompany'].filter : undefined,
        fModel['importTitle'] ? fModel['importTitle'].filter : undefined,
        fModel['linkedin'] ? fModel['linkedin'].filter : undefined,
        fModel['email'] ? fModel['email'].filter : undefined,
        fModel['emailStatus'] ? fModel['emailStatus'].filter : undefined,
        fModel['lastInfoChangedAt'] ? fModel['lastInfoChangedAt'].dateFrom : undefined,
        undefined,
        fModel['hasMultipleJobs'] ? fModel['hasMultipleJobs'].type : undefined,
        fModel['jobStartedAt'] ? fModel['jobStartedAt'].dateFrom : undefined,
        fModel['jobStartedAt'] ? fModel['jobStartedAt'].dateTo : undefined,
        undefined,
        fModel['titleDomain'] ? fModel['titleDomain'].filter : undefined,
        fModel['titleLevel'] ? fModel['titleLevel'].filter : undefined,
        fModel['importTitleLevel'] ? fModel['importTitleLevel'].filter : undefined,
        fModel['importTitleDomain'] ? fModel['importTitleDomain'].filter : undefined
      )
      .subscribe({
        next: (res: any) => {
          var data = new Blob([res], { type: 'text/csv' });
          FileSaver.saveAs(
            data,
            'livedata-' + this.ledger.name + '-' + moment().format('YYYYMMDD-HHmmss') + '.csv'
          );
          this.downloading = false;
        },
        error: () => {
          this.notify.error(
            'Oops. There was an error during your request. Please try again later.'
          );
          this.downloading = false;
        },
      });
  }

  downloadAll() {
    this.downloading = true;

    this.ledgerService.downloadLedgerContacts(this.ledger.id, this.orgId).subscribe({
      next: (res: any) => {
        var data = new Blob([res], { type: 'text/csv' });
        FileSaver.saveAs(
          data,
          'livedata-' + this.ledger.name + '-' + moment().format('YYYYMMDD-HHmmss') + '.csv'
        );
        this.downloading = false;
      },
      error: () => {
        this.notify.error('Oops. There was an error during your request. Please try again later.');
        this.downloading = false;
      },
    });
  }

  searchPerson() {
    var ids: string[] = this.gridApi.getSelectedRows().map((d: any) => d.id);
    this.router.navigate(['/admin/ledger/people'], { queryParams: { ref: ids.join(',') } });
  }

  reiniting = false;
  reinitLedger() {
    this.reiniting = true;
    this.ledgerOnlyService.reinitLedgerContacts(this.orgId, this.ledger.id).subscribe(
      () => {
        this.notify.success('All unknown contacts have been sent to the queue');
        this.reiniting = false;
      },
      (err) => {
        if (err.status && err.status === 409) {
          this.notify.error('Unable to reinit. Only allowed every 7 days per ledger');
        } else {
          this.notify.error(
            'Oops. There was an error during your request. Please try again later.'
          );
        }
        this.reiniting = false;
      }
    );
  }
}
