import { Component, OnInit } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { AdminService, ReportDefinition, ReportsService } from 'ldt-data-deliveries-api';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';

@Component({
  selector: 'app-report-definitions',
  templateUrl: './report-definitions.component.html',
  styleUrls: ['./report-definitions.component.scss'],
})
export class ReportDefinitionsComponent implements OnInit {
  refreshing: boolean = false;
  reportDefinitions: ReportDefinition[] = [];
  public colDefs: ColDef[] = [
    { field: 'id', maxWidth: 60 },
    { field: 'org_id', maxWidth: 110 },
    { field: 'created_at', maxWidth: 230 },
    { field: 'source.name', headerName: 'Name' },
    { field: 'trigger_date_fields', headerName: 'Triggers' },
  ];

  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    floatingFilter: true,
    resizable: true,
    flex: 1,
  };

  constructor(
    private reportDefinitionService: AdminService,
    private notify: NotificationService
  ) {}

  ngOnInit(): void {
    this.getReportDefinitions();
  }

  getReportDefinitions(): void {
    this.refreshing = true;
    this.reportDefinitionService.listAllReportDefinitions().subscribe({
      next: (data) => {
        this.reportDefinitions = data;
        this.refreshing = false;
      },
      error: (error) => {
        this.notify.error('Failed to load report definitions');
        this.refreshing = false;
      },
    });
  }
}
