import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Org } from 'ldt-identity-service-api';
import { AuthService } from '../auth/service/auth.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  canAdmin: boolean = false;
  orgId: string;
  org: Org | undefined;

  constructor(private auth: AuthService, private route: ActivatedRoute) { 
    this.route.paramMap.subscribe(map => {
      this.orgId = map.get('orgId') || '';
      this.org = this.auth.getSelectedOrg();
    })
  }

  ngOnInit(): void {
    this.orgId = this.auth.getSelectedOrgIdValue;
    this.org = this.auth.getSelectedOrg();
  }

}
