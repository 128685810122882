import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as Sentry from '@sentry/angular-ivy';

import { LicenseManager } from 'ag-grid-enterprise';

LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-060194}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Live_Data_Technologies}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{J._Scott_Hamilton}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{J._Scott_Hamilton}_need_to_be_licensed___{J._Scott_Hamilton}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{30_July_2025}____[v3]_[01]_MTc1MzgzMDAwMDAwMA==8e1f66aa03083a6b03dc75666c9cf4ca'
);

Sentry.init({
  dsn: 'https://9e2e8b3312584d5897f3ea0567200b7b@o1143822.ingest.sentry.io/6209459',
  integrations: [Sentry.browserTracingIntegration()],
  initialScope: {
    tags: { 'gld-ui-version': environment.appVersion },
  },
  environment: environment.production ? 'production' : 'staging',
  release: environment.appVersion,
  tracesSampleRate: 1.0,
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
