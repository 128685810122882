<h2>OpenSearch Download</h2>
Paste the query from the OpenSearch dashboard into the box below and hit `Download`

<form class="requestForm">
    <mat-form-field appearance="fill">
      <mat-label>Request</mat-label>
      <textarea matInput placeholder="" [(ngModel)]="osRequest" name="osRequestTex"></textarea>
    </mat-form-field>
    <button mat-raised-button color="primary" [class.spinner]="downloading" [disabled]="!osRequest || downloading" (click)="downloadOsQuery()">Download</button>
    <img src="../../../assets/img/get-os-request.gif" />
</form>