<h2 mat-dialog-title>Select Filter Field<span *ngIf="multi">s</span></h2>

<mat-dialog-content>
  <div class="filter-toolbar tw-flex tw-items-center">
    <mat-form-field appearance="outline" class="search-field">
      <mat-label>Find a filter...</mat-label>
      <mat-icon matPrefix class="tw-text-lg">search</mat-icon>
      <input
        matInput
        placeholder="Filter name"
        [(ngModel)]="filterText"
        class="tw-font-bold tw-text-primary_purple" />
      <button
        mat-button
        matSuffix
        mat-icon-button
        aria-label="Clear"
        *ngIf="filterText"
        (click)="clearFilter()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <!-- filter count messages -->
    <div class="tw-mb-4 tw-text-sm tw-ml-4" *ngIf="!filterText">
      <div>
        Displaying
        <span class="tw-font-bold">
          {{getTotalCount()}}
        </span>
        items.
      </div>

      <div *ngIf="multi">
        Total selected items:
        <span class="badge-checked-items">
          {{ getCheckedCount() }}
        </span>
      </div>
    </div>

    <!-- switches -->
    <div class="tw-flex tw-flex-col tw-mb-4 tw-text-sm tw-ml-auto">
      <mat-slide-toggle [(ngModel)]="classicView" color="primary" class="multi-toggle"
        >Classic view
      </mat-slide-toggle>
      <mat-slide-toggle [(ngModel)]="showDBName" color="primary" class="multi-toggle"
        >Show API names
      </mat-slide-toggle>
    </div>
  </div>

  <!-- Search Panel -->
  <div class="panel-search-results" *ngIf="filterText">
    <p *ngIf="getFilteredTotal() < 1">No matching items. Please adjust your search above.</p>

    <p>
      <span>
        Displaying
        <span *ngIf="filterText" class="tw-font-bold">{{ getFilteredTotal() }}</span>
        <span *ngIf="filterText"> out of </span>
        <span class="tw-font-bold">
          {{getTotalCount()}}
        </span>
        items.
      </span>
    </p>

    <div class="filtered-results-container">
      <ng-container *ngFor="let category of displayData | keyvalue">
        <mat-list *ngIf="category.key !== 'default' || !filterText">
          <mat-list-item *ngFor="let field of category.value | filter:filterText:'displayName'">
            <div class="field-category tw-flex tw-items-center">
              <mat-icon class="tw-mr-2 tw-flex tw-items-center tw-text-xl">filter_alt</mat-icon>
              <span>{{ category.key }}</span>
              <mat-icon>chevron_right</mat-icon>
            </div>
            <div>
              <button
                *ngIf="!multi"
                mat-button
                class="fieldValue tw-text-primary_purple"
                (click)="selectField(field.name)"
                [ngClass]="{'code-font': showDBName}">
                {{ showDBName ? field.name : field.displayName }}
              </button>
              <mat-checkbox
                class="multi-field"
                *ngIf="multi"
                color="primary"
                [(ngModel)]="returnFields[field.name]"
                [ngClass]="{'code-font': showDBName}">
                {{ showDBName ? field.name : field.displayName }}
              </mat-checkbox>
            </div>
          </mat-list-item>
        </mat-list>
      </ng-container>
    </div>
  </div>

  <!-- Vertical tabs -->
  <div class="panel-vertical-tabs" *ngIf="!filterText && !classicView">
    <mat-tab-group>
      <!-- favorites -->
      <mat-tab *ngIf="!multi && data.categories.length > 1">
        <ng-template mat-tab-label>
          <mat-icon class="tw-text-[14px] tw-w-[14px] tw-h-[14px] tw-mr-2">star</mat-icon>
          <span>Favorites</span>
          <mat-icon class="tw-ml-auto">chevron_right</mat-icon>
        </ng-template>
        <mat-list>
          <mat-list-item
            (click)="toggleField(field.name)"
            *ngFor="let field of popularFilters | filter:filterText:'displayName'"
            [ngClass]="{'checked-item': multi && returnFields[field.name]}">
            <button
              *ngIf="!multi"
              mat-button
              class="fieldValue tw-text-primary_purple"
              (click)="selectField(field.name)"
              [ngClass]="{'code-font': showDBName}">
              {{ showDBName ? field.name : field.displayName }}
            </button>
            <mat-checkbox
              class="multi-field"
              *ngIf="multi"
              color="primary"
              [(ngModel)]="returnFields[field.name]"
              (click)="$event.stopPropagation()"
              [ngClass]="{'code-font': showDBName}">
              {{ showDBName ? field.name : field.displayName }}
            </mat-checkbox>
          </mat-list-item>
        </mat-list>
      </mat-tab>

      <!-- category tabs -->
      <mat-tab *ngFor="let category of categories">
        <ng-template mat-tab-label>
          <span>{{category.displayName}}</span>
          <mat-icon
            matTooltip="{{category.description}}"
            matTooltipPosition="right"
            class="tooltip-icon tw-ml-1 tw-mr-auto tw-text-base">
            info_outline
          </mat-icon>
          <span class="tw-mr-2 badge-checked-items" *ngIf="multi">
            {{ getCheckedCount(category.name) }}
          </span>
          <span class="tw-font-bold">{{ displayData[category.name].length }}</span>
          <mat-icon>chevron_right</mat-icon>
        </ng-template>

        <!-- tabs content -->
        <mat-list>
          <mat-list-item *ngIf="multi" class="tw-h-12">
            <div class="select-all tw-flex tw-items-center">
              <button
                mat-stroked-button
                (click)="selectAll(category.name)"
                class="tw-text-sm tw-ml-4 tw-mr-3">
                Select All
              </button>
              <span class="tw-font-light"> | </span>
              <button
                mat-stroked-button
                (click)="deselectAll(category.name)"
                class="tw-text-sm tw-ml-4 tw-mr-3">
                Unselect All
              </button>
              <span class="tw-font-light"> | </span>
              <p class="tw-text-xs lg:tw-text-sm tw-ml-4">
                Total Selected:
                <span class="tw-font-bold tw-text-primary_purple">
                  {{ getCheckedCount(category.name) }}
                </span>
              </p>
            </div>
          </mat-list-item>

          <div [ngClass]="{'tw-columns-2': displayData[category.name].length > 12}">
            <mat-list-item
              (click)="toggleField(field.name)"
              *ngFor="let field of displayData[category.name] | filter:filterText:'displayName'"
              [ngClass]="{'checked-item': multi && returnFields[field.name]}">
              <button
                *ngIf="!multi"
                mat-button
                class="fieldValue tw-text-primary_purple"
                (click)="selectField(field.name)"
                [ngClass]="{'code-font': showDBName}">
                {{ showDBName ? field.name : field.displayName }}
              </button>
              <mat-checkbox
                class="multi-field"
                *ngIf="multi"
                color="primary"
                [(ngModel)]="returnFields[field.name]"
                (click)="$event.stopPropagation()"
                [ngClass]="{'code-font': showDBName}">
                {{ showDBName ? field.name : field.displayName }}
              </mat-checkbox>
            </mat-list-item>
          </div>
        </mat-list>
      </mat-tab>
    </mat-tab-group>
  </div>

  <!-- Classic view -->
  <div *ngIf="classicView && !filterText" class="tw-flex tw-flex-wrap tw-gap-10">
    <div *ngFor="let columnNum of getAllCategoryColumns()" class="tw-flex-1">
      <div *ngFor="let category of getCategoriesForColumn(columnNum)" class="tw-mt-3 tw-mb-5">
        <div class="tw-font-bold tw-text-lg tw-mb-2">{{category.displayName}}</div>
        <mat-list>
          <mat-list-item *ngIf="multi" class="tw-h-8">
            <div class="select-all tw-flex tw-items-center tw-pb-3">
              <button
                mat-stroked-button
                (click)="selectAll(category.name)"
                class="tw-text-sm tw-ml-4 tw-mr-3">
                Select All
              </button>
              <span class="tw-font-light"> | </span>
              <button
                mat-stroked-button
                (click)="deselectAll(category.name)"
                class="tw-text-sm tw-ml-4 tw-mr-3">
                Unselect All
              </button>
            </div>
          </mat-list-item>
        </mat-list>
        <mat-list>
          <mat-list-item
            class="tw-h-6 tw-text-sm"
            (click)="toggleField(field.name)"
            *ngFor="let field of displayData[category.name] | filter:filterText:'displayName'"
            [ngClass]="{'checked-item': multi && returnFields[field.name]}">
            <button
              class="tw-leading-tight"
              *ngIf="!multi"
              mat-button
              class="fieldValue tw-text-primary_purple tw-leading-6"
              (click)="selectField(field.name)"
              [ngClass]="{'code-font': showDBName}">
              {{ showDBName ? field.name : field.displayName }}
            </button>
            <mat-checkbox
              class="multi-field"
              *ngIf="multi"
              color="primary"
              [(ngModel)]="returnFields[field.name]"
              (click)="$event.stopPropagation()"
              [ngClass]="{'code-font': showDBName}">
              {{ showDBName ? field.name : field.displayName }}
            </mat-checkbox>
          </mat-list-item>
        </mat-list>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-stroked-button (click)="cancel()">Cancel</button>
  <button
    *ngIf="multi"
    mat-flat-button
    (click)="saveSelections()"
    color="primary"
    class="tw-ml-auto">
    Save Selections <span *ngIf="multi">({{ getCheckedCount() }})</span>
  </button>
</mat-dialog-actions>
