import { Component, Inject, ViewChild } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { NgForm, NgModel } from '@angular/forms';
import { DestinationsService, Destination } from 'ldt-data-deliveries-api';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';
import { Router } from '@angular/router';
import { TestStatusBadgeComponent } from 'src/app/shared/test-status-badge/test-status-badge.component';
import { timer } from 'rxjs';
import { Integration } from '../available-integrations.component';

@Component({
  selector: 'app-integration-dialog',
  templateUrl: './integration-dialog.component.html',
  styleUrls: ['./integration-dialog.component.scss'],
})
export class IntegrationDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<IntegrationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { orgId: string; integration: Integration },
    private destinationsService: DestinationsService,
    private notify: NotificationService,
    private router: Router
  ) {
    this.orgId = data.orgId; // this has to be passed from the parent component, can't be accessed directly from dialog
    this.integration = data.integration;
  }

  orgId: string;
  integration: Integration;
  stepperSteps: string[];
  activeStepperStep = 1;
  testStatus: string = 'Unknown';
  testStatusLabel: string = 'Destination Status';
  isLoading: boolean = false;
  @ViewChild(TestStatusBadgeComponent) testStatusBadgeComponent!: TestStatusBadgeComponent;
  showTimeStamp: boolean = false;
  createdDestination: Destination | undefined;
  newIntegration: boolean = true;
  creationTimeout: boolean = false;

  // form 2A (LDT S3 bucket form)
  @ViewChild('form2A') form2A!: NgForm;
  @ViewChild('roleArnInput') roleArnInput!: NgModel;
  getFormattedS3Uri(destinationId: number): string {
    const formattedOrgId = this.orgId.replace('_', '-');
    return `s3://arn:aws:s3:us-east-1:100811426269:accesspoint/${formattedOrgId}-${destinationId}/${this.orgId}/`;
  }

  showInit: boolean = false;
  showChoice1: boolean = false;
  showChoice1A: boolean = false;
  showChoice1B: boolean = false;
  showChoice2: boolean = false;
  showChoice2A: boolean = false;
  showChoice2B: boolean = false;
  successView: boolean = false;

  ngOnInit(): void {
    this.setStepperStep(this.integration.name);
    this.setFirstView(this.integration.name);
  }

  setStepperStep(destinationName: string): void {
    if (destinationName === 'AWS S3') {
      this.stepperSteps = ['Choose S3 Bucket Type', 'Configure S3 Bucket', 'Setup Complete'];
    } else {
      this.stepperSteps = ['Configure Destination', 'Setup Complete'];
    }
  }

  setFirstView(destinationName: string): void {
    if (destinationName === 'AWS S3') {
      this.showInit = true;
    } else {
      this.notify.error('Something went wrong.');
    }
  }

  // Choice 1: User S3 bucket OR LDT S3 bucket
  handleChoice1() {
    this.showChoice1 = true;
    this.showChoice2 = false;
    this.showInit = false;
    this.activeStepperStep = 1;
  }

  // Choice 1A: User S3 bucket --> Grant Access to LDT Role
  handleChoice1A() {
    this.showInit = false;
    this.showChoice1 = false;
    this.showChoice1A = true; // show this only
    this.showChoice1B = false;
    this.activeStepperStep = 2;
  }

  // Choice 1B: User S3 bucket --> Create Cross-account role & allow access to LDT
  handleChoice1B() {
    this.showInit = false;
    this.showChoice1 = false;
    this.showChoice1A = false;
    this.showChoice1B = true;
    this.activeStepperStep = 2;
  }

  // Choice 2: LDT S3 bucket form
  handleChoice2() {
    this.showChoice2 = true;
    this.showChoice1 = false;
    this.showChoice1A = false;
    this.showChoice1B = false;
    this.activeStepperStep = 2;
  }

  /**
   * Handles the form submission to crate a destination.
   *
   * @param form - The NgForm object representing the form being submitted.
   * @returns void
   */
  handleDestinationSubmit(form: NgForm, destination_type_id: number): void {
    this.showTimeStamp = false;
    let submissionData = {
      name: form.value.name, // will this work for all three forms?
      destination_type_id: destination_type_id,
      config: {},
    };

    if (destination_type_id === 1) {
      submissionData.config = {
        role_arn: form.value.role_arn,
      };
    } else if (destination_type_id === 2) {
      submissionData.config = {
        bucket_identifier: form.value.bucket_identifier,
        role_arn: form.value.role_arn,
        external_id: form.value.external_id,
      };
    } else if (destination_type_id === 3) {
      submissionData.config = {
        bucket_identifier: form.value.bucket_identifier,
      };
    }

    if (form.valid) {
      this.destinationsService.createDestination(this.orgId, submissionData).subscribe({
        next: (data: Destination) => {
          this.createdDestination = data;
          this.isLoading = false;
          this.notify.success('Data delivery destination created.');
          this.successView = true;
          this.activeStepperStep = 3;
          this.showInit = false;
          this.showChoice1 = false;
          this.showChoice1A = false;
          this.showChoice1B = false;
          this.showChoice2 = false;

          // For the cross-account type, disable the test button for a few seconds while config finishes
          if (destination_type_id === 2) {
            this.creationTimeout = true;

            timer(5000).subscribe(() => {
              this.creationTimeout = false;
            });
          }
        },
        error: (error) => {
          console.error('Error creating destination:', error);
          this.isLoading = false;
          let errorMessage = error.error?.message || 'Error creating destination.';
          if (error.status === 400 && error.error?.detail?.role_arn === 'Invalid') {
            errorMessage = 'Invalid IAM Role ARN. Are you sure this role exists?';
            // invalidate the role ARN input field so user can see the error
            this.roleArnInput.control.setErrors({ serverError: errorMessage });
          } else {
            errorMessage = error.error?.message || errorMessage;
          }

          this.notify.error(errorMessage);
        },
      });
    } else {
      console.error('Error submitting destination form:', form.errors);
      this.notify.error('Form is not valid.');
    }
  }

  /**
   * Tests the status of a destination.
   * @param orgId - The ID of the organization.
   * @param destinationData - destination object just created.
   */
  testDestinationStatus(orgId: string, destinationData: Destination) {
    this.showTimeStamp = true;
    this.isLoading = true;
    this.testStatus = 'Loading...';

    if (!orgId || !destinationData.id) {
      this.notify.error(
        'Something went wrong with getting the destination status. Please try again later.'
      );
      return;
    }

    this.destinationsService.testDestination(orgId, destinationData.id).subscribe({
      next: (data) => {
        this.isLoading = false;
        if (data.status) {
          this.testStatus = data.status;
          this.testStatusBadgeComponent.updateStatusBadge(data.status);
          this.testStatusBadgeComponent.updateTimeStamp(new Date().toISOString());
        }
      },
      error: (error) => {
        this.isLoading = false;
        this.testStatus = error.error?.message || 'Error testing destination.';
        this.testStatusBadgeComponent.updateTimeStamp(destinationData.tested_at || '');
      },
    });
  }

  closeDialog() {
    this.dialogRef.close({});
    setTimeout(() => {
      this.goToConfiguredIntegrations(); // navigate to the configured integrations page
    }, 200);
  }

  goBackToInit() {
    this.showInit = true;
    this.showChoice1 = false;
    this.showChoice1A = false;
    this.showChoice1B = false;
    this.showChoice2 = false;
    this.showChoice2A = false;
    this.showChoice2B = false;
    this.activeStepperStep = 1;
  }

  goBackToCard2() {
    this.showChoice1 = true;
    this.showChoice2 = false;
    this.activeStepperStep = 1;
  }

  goToConfiguredIntegrations(): void {
    // Construct the absolute path (easier than relative path)
    const path = `/${this.data.orgId}/integrations/configured-integrations`;
    this.router.navigate([path]);
  }
}
