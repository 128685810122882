import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-jobs-table-cell-renderer',
  templateUrl: './jobs-table-cell-renderer.component.html',
  styleUrls: ['./jobs-table-cell-renderer.component.scss']
})
export class JobsTableCellRendererComponent implements OnInit, ICellRendererAngularComp {

  jobs: any;

  // gets called once before the renderer is used
  agInit(params: ICellRendererParams): void {
    this.jobs = params?.node?.data?.dwData?.jobs || params?.node?.data?.jobs;
  }

  // gets called whenever the cell refreshes
  refresh(params: ICellRendererParams) {
    this.jobs = params?.node?.data?.dwData?.jobs || params?.node?.data?.jobs;
      
    return true;
  }

  constructor() { }

  ngOnInit(): void {
  }

}
