<div class="container">
  <div class="actionContainer">
    <form [formGroup]="inputFormGroup">
      <mat-form-field appearance="fill">
        <mat-label>Person ID</mat-label>
        <input
          matInput
          placeholder="Person ID"
          formControlName="ref"
          [value]="inputFormGroup.value.ref" />
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Previous Searches</mat-label>
        <mat-select formControlName="ref">
          <mat-option
            *ngFor="let person of personList"
            [value]="person.ref"
            >{{person.name}}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <button
        type="submit"
        mat-raised-button
        [disabled]="!inputFormGroup.valid || refreshing"
        color="primary"
        (click)="inspectInputPerson()">
        Inspect
      </button>
      <button
        mat-raised-button
        [disabled]="!inputFormGroup.valid || refreshing"
        (click)="gotoFlywheelDetails()">
        View Flywheel Details
      </button>
      <button
        mat-raised-button
        [class.spinner]="replaying"
        [disabled]="replaying"
        [matMenuTriggerFor]="replaymenu">
        Replay
        <mat-menu #replaymenu="matMenu">
          <a mat-menu-item (click)="replayIS();">Intake Sync</a>
          <a mat-menu-item (click)="replayPI('latest-loli');">Latest LOLI</a>
          <ng-container *ngIf="currentEnv !== 'staging'">
            <a *ngFor="let prov of providers" mat-menu-item (click)="replayPI(prov);"
              >Provider - {{pointLabels[prov] || prov}}</a
            >
          </ng-container>
          <ng-container *ngIf="currentEnv === 'staging'">
            <a *ngFor="let prov of providers_staging" mat-menu-item (click)="replayPI(prov);"
              >Provider - {{pointLabels[prov] || prov}}</a
            >
          </ng-container>
        </mat-menu>
      </button>
      <!-- <mat-form-field appearance="fill">
                <mat-label>Environment Override</mat-label>
                <input matInput placeholder="Env name" formControlName="env">
            </mat-form-field>         -->
      <mat-checkbox formControlName="staging">Staging</mat-checkbox>
      <button
        mat-raised-button
        [disabled]="!syncEntry?.linkedin || stagingLoading"
        [class.spinner]="stagingLoading"
        (click)="loadToStaging(syncEntry?.id)">
        Reload to Staging
      </button>
      <mat-form-field appearance="fill">
        <mat-label>IS Replay Context</mat-label>
        <input
          matInput
          placeholder="IS Replay Context"
          [(ngModel)]="isReplayContext"
          [ngModelOptions]="{standalone: true}" />
      </mat-form-field>
    </form>
  </div>
  <div *ngIf="!refreshing && currentPerson; else elseBlock">
    <div class="cols-2">
      <mat-card class="details-card">
        <mat-card-title
          >Person Details
          <mat-chip-list>
            <mat-chip
              color="primary"
              [selected]="syncEntry.values['is_auth']?.Value=='true'"
              [matTooltip]="valueAsString(syncEntry.values['is_auth'])"
              >Auth</mat-chip
            >
            <mat-chip
              color="primary"
              [selected]="syncEntry.values['in_ledgers']?.Value=='true'"
              [matTooltip]="valueAsString(syncEntry.values['in_ledgers'])"
              >Ledgers</mat-chip
            >
          </mat-chip-list>
        </mat-card-title>
        <mat-card-content>
          <div class="eav-fields">
            <div class="field">
              <strong>id: </strong>
              <span class="fieldData">{{syncEntry?.id}}</span>
              <button class="copyButton" ngxClipboard cbContent="{{syncEntry?.id?.toUpperCase()}}">
                <mat-icon>content_copy</mat-icon>
              </button>
              <span
                class="aliases"
                *ngIf="syncEntry.ldpAliases.length > 0"
                matTooltip="{{syncEntry.ldpAliases}}"
                >[+{{syncEntry.ldpAliases.length}}]</span
              >
            </div>
            <div class="field">
              <strong>linkedin: </strong>
              <span class="fieldData"
                ><a
                  href="https://linkedin.com/in/{{syncEntry?.linkedin}}"
                  target="_blank"
                  >{{syncEntry?.linkedin}}</a
                ></span
              >
              <button
                class="copyButton"
                ngxClipboard
                cbContent="{{syncEntry?.linkedin?.toUpperCase()}}">
                <mat-icon>content_copy</mat-icon>
              </button>
              <span
                class="aliases"
                *ngIf="syncEntry.lipidAliases.length > 0"
                matTooltip="{{syncEntry.lipidAliases}}"
                >[+{{syncEntry.lipidAliases.length}}]</span
              >
            </div>
            <div class="eav-field-container">
              <div class="eav-fields">
                <div class="field" *ngFor="let eav of eavPersonFields">
                  <strong>{{eav}}: </strong>
                  <span class="fieldData">{{syncEntry?.values[eav]?.Value}}</span>
                  <span
                    class="field-provider-{{syncEntry?.values[eav]?.ProviderID}}"
                    matTooltip="[{{syncEntry?.values[eav]?.UpdatedAt | date: 'yyyy/MM/dd'}}] {{pointLabels[syncEntry?.values[eav]?.ProviderID] || syncEntry?.values[eav]?.ProviderID}}"
                    >&#9679;</span
                  >
                  <button
                    *ngIf="syncEntry?.values[eav]?.Value"
                    class="copyButton"
                    ngxClipboard
                    cbContent="{{syncEntry?.values[eav]?.Value}}">
                    <mat-icon>content_copy</mat-icon>
                  </button>
                </div>
              </div>
              <div class="eav-fields">
                <div
                  class="field"
                  *ngFor="let eav of eavDateFields"
                  [ngClass]="{'eavWarning': shouldWarn(eav)}">
                  <strong>{{eav}}: </strong>
                  <span class="fieldData">{{syncEntry?.values[eav]?.Value}}</span>
                  <span
                    class="field-provider-{{syncEntry?.values[eav]?.ProviderID}}"
                    matTooltip="[{{syncEntry?.values[eav]?.UpdatedAt | date: 'yyyy/MM/dd'}}] {{pointLabels[syncEntry?.values[eav]?.ProviderID] || syncEntry?.values[eav]?.ProviderID}}"
                    >&#9679;</span
                  >
                  <button
                    *ngIf="syncEntry?.values[eav]?.Value"
                    class="copyButton"
                    ngxClipboard
                    cbContent="{{syncEntry?.values[eav]?.Value}}">
                    <mat-icon>content_copy</mat-icon>
                  </button>
                </div>
                <div class="field">
                  <strong>OS Replayed At: </strong>
                  <span class="fieldData">{{opensearchDetails?.metadata?.replayed_at}}</span>
                </div>
              </div>
            </div>
          </div>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title> In Ledgers </mat-panel-title>
              <mat-panel-description>
                {{flywheelDetails ? flywheelDetails?.ledgers ? flywheelDetails?.ledgers.length : 'none' : 'N/A'}}
              </mat-panel-description>
            </mat-expansion-panel-header>
            <mat-chip-list #chipList>
              <mat-chip *ngFor="let ledger of flywheelDetails?.ledgers">
                <a
                  href="https://gotlivedata.com/{{ledger.orgId}}/ledgers/{{ledger.ledgerId}}/details"
                  target="_blank"
                  >[{{ledger.orgName}}] - {{ledger.ledgerName}}</a
                >
              </mat-chip>
            </mat-chip-list>
          </mat-expansion-panel>
        </mat-card-content>
      </mat-card>
      <mat-card class="details-card">
        <mat-card-title
          >Jobs
          <span class="jobsSourceToggle"
            >Intake Sync<mat-slide-toggle [(ngModel)]="jobsToShow"></mat-slide-toggle>
            OpenSearch</span
          >
        </mat-card-title>
        <mat-card-content>
          <div class="job-fields" *ngIf="jobsToShow">
            <app-jobs-table [jobsList]="opensearchDetails?.jobs"></app-jobs-table>
          </div>
          <div class="job-fields" *ngIf="!jobsToShow">
            <table mat-table [dataSource]="jobsDatasource">
              <ng-container matColumnDef="company_name">
                <th mat-header-cell *matHeaderCellDef>Company</th>
                <td mat-cell *matCellDef="let element">
                  <span
                    *ngIf="element.company_linkedin"
                    class="clickableCompany"
                    (click)="showCompanyInfo(element.company_lookup)"
                    >{{element.company_name}}</span
                  >
                  <span *ngIf="!element.company_linkedin">{{element.company_name}}</span>
                  {{element.metadata?.company_source === 'name_alias' ? '*' : ''}}
                </td>
              </ng-container>

              <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef>Title</th>
                <td mat-cell *matCellDef="let element">{{element.title}}</td>
              </ng-container>

              <ng-container matColumnDef="started_at">
                <th date-col mat-header-cell *matHeaderCellDef>Started</th>
                <td mat-cell *matCellDef="let element">
                  {{element.started_at | date: element.metadata?.started_at_year_only ? 'yyyy' : 'MMM yyyy': 'UTC'}}
                  {{element.metadata?.started_at_inferred || element.metadata?.StartedAtInferred ? '*' : ''}}
                </td>
              </ng-container>

              <ng-container matColumnDef="ended_at">
                <th date-col mat-header-cell *matHeaderCellDef>Ended</th>
                <td mat-cell *matCellDef="let element">
                  {{element.ended_at | date: element.metadata?.ended_at_year_only ? 'yyyy' : 'MMM yyyy': 'UTC'}}
                  {{element.metadata?.ended_at_inferred || element.metadata?.EndedAtInferred ? '*' : ''}}
                </td>
              </ng-container>

              <ng-container matColumnDef="created">
                <th
                  date-col
                  mat-header-cell
                  *matHeaderCellDef
                  matTooltip="Was this job created in the last 24 hours?">
                  New?
                </th>
                <td mat-cell *matCellDef="let element">
                  <mat-icon *ngIf="element.newly_created">done</mat-icon>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="jobColumns"></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: jobColumns;"
                (click)="selectedJob=row"
                [class.selected-row]="row == selectedJob"
                [class.deleted-row]="row.deleted_at"></tr>
            </table>
            (* = inferred/aliased)
          </div>
          <div class="jobDetails" *ngIf="selectedJob && !jobsToShow">
            <div class="jobDetailColumn">
              <li>
                <strong>Provider:</strong>
                {{selectedJob.provider_id ? (pointLabels[selectedJob.provider_id] || selectedJob.provider_id) : 'FW'}}
                <app-field-updated-signal
                  [change]="{ providerId: (pointLabels[selectedJob.provider_id] || selectedJob.provider_id || 'FW'), updatedAt: selectedJob.created_at}"></app-field-updated-signal>
              </li>
              <li>
                <strong>Created At:</strong>
                {{selectedJob.created_at | date: 'M/d/yy H:mm:ss': 'UTC'}}
                <app-field-updated-signal
                  *ngIf="selectedJob.field_change_summary['created_at']"
                  [change]="selectedJob.field_change_summary['created_at']"></app-field-updated-signal>
              </li>
              <li>
                <strong>Updated At:</strong>
                {{selectedJob.updated_at | date: 'M/d/yy H:mm:ss': 'UTC'}}
                <app-field-updated-signal
                  *ngIf="selectedJob.field_change_summary['updated_at']"
                  [change]="selectedJob.field_change_summary['updated_at']"></app-field-updated-signal>
              </li>
              <li>
                <strong>Deleted At:</strong>
                {{selectedJob.deleted_at | date: 'M/d/yy H:mm:ss': 'UTC'}}
                <app-field-updated-signal
                  *ngIf="selectedJob.field_change_summary['deleted_at']"
                  [change]="selectedJob.field_change_summary['deleted_at']"></app-field-updated-signal>
              </li>
              <li *ngIf="selectedJob.metadata?.deleted_reason">
                <strong>Deleted Reason:</strong> {{selectedJob.metadata?.deleted_reason}}
                <app-field-updated-signal
                  *ngIf="selectedJob.field_change_summary['deleted_at']"
                  [change]="selectedJob.field_change_summary['deleted_at']"></app-field-updated-signal>
              </li>
              <li>
                <strong>Started At:</strong> {{selectedJob.started_at | date: 'MMM yyyy': 'UTC'}}
                {{selectedJob.metadata?.started_at_inferred || selectedJob.metadata?.StartedAtInferred ? '*' : ''}}
                <app-field-updated-signal
                  *ngIf="selectedJob.field_change_summary['started_at']"
                  [change]="selectedJob.field_change_summary['started_at']"></app-field-updated-signal>
              </li>
              <li>
                <strong>Ended At:</strong> {{selectedJob.ended_at | date: 'MMM yyyy': 'UTC'}}
                {{selectedJob.metadata?.ended_at_inferred || selectedJob.metadata?.EndedAtInferred ? '*' : ''}}
                <app-field-updated-signal
                  *ngIf="selectedJob.field_change_summary['ended_at']"
                  [change]="selectedJob.field_change_summary['ended_at']"></app-field-updated-signal>
              </li>
            </div>
            <div class="jobDetailColumn">
              <li>
                <strong>Location:</strong> {{selectedJob.location}}
                <app-field-updated-signal
                  *ngIf="selectedJob.field_change_summary['location']"
                  [change]="selectedJob.field_change_summary['location']"></app-field-updated-signal>
              </li>
              <li>
                <strong>Email:</strong> {{selectedJob.email}}
                <app-field-updated-signal
                  *ngIf="selectedJob.field_change_summary['email']"
                  [change]="selectedJob.field_change_summary['email']"></app-field-updated-signal>
              </li>
              <li>
                <strong>Email Status:</strong> {{selectedJob.email_status}}
                <app-field-updated-signal
                  *ngIf="selectedJob.field_change_summary['email_status']"
                  [change]="selectedJob.field_change_summary['email_status']"></app-field-updated-signal>
              </li>
              <li>
                <strong>Direct Dial:</strong> {{selectedJob.direct_dial_number}}
                <app-field-updated-signal
                  *ngIf="selectedJob.field_change_summary['direct_dial_number']"
                  [change]="selectedJob.field_change_summary['direct_dial_number']"></app-field-updated-signal>
              </li>
            </div>
            <div class="jobDetailColumn">
              <li>
                <strong>Title:</strong> {{selectedJob.title}}
                <app-field-updated-signal
                  *ngIf="selectedJob.field_change_summary['title']"
                  [change]="selectedJob.field_change_summary['title']"></app-field-updated-signal>
              </li>
              <li>
                <strong>Level:</strong> {{selectedJob.level}}
                <app-field-updated-signal
                  *ngIf="selectedJob.field_change_summary['level']"
                  [change]="selectedJob.field_change_summary['level']"></app-field-updated-signal>
              </li>
              <li>
                <strong>Function:</strong> {{selectedJob.function}}
                <app-field-updated-signal
                  *ngIf="selectedJob.field_change_summary['function']"
                  [change]="selectedJob.field_change_summary['function']"></app-field-updated-signal>
              </li>
            </div>
            <div class="jobDetailColumn">
              <li>
                <strong>Company:</strong>
                <a
                  *ngIf="selectedJob.company_linkedin; else jobCompanyValueOnly"
                  href="https://linkedin.com/company/{{selectedJob.company_linkedin}}"
                  target="_blank"
                  >{{selectedJob.company_name}}</a
                >
                <ng-template #jobCompanyValueOnly>{{selectedJob.company_name}}</ng-template>
              </li>
              <li><strong>Lookup:</strong> {{selectedJob.company_lookup}}</li>
              <li>
                <strong>LinkedIn:</strong> {{selectedJob.company_linkedin}}
                <app-field-updated-signal
                  *ngIf="selectedJob.field_change_summary['company_linkedin']"
                  [change]="selectedJob.field_change_summary['company_linkedin']"></app-field-updated-signal>
              </li>
              <li *ngIf="selectedJob.metadata?.company_source === 'name_alias'">
                <strong>Original Name:</strong> {{selectedJob.metadata?.original_company_name}}
              </li>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="timeline">
      <mat-card>
        <mat-card-title
          >Last 50 signals
          <mat-form-field class="far-right" appearance="fill">
            <mat-label>Show in timeline</mat-label>
            <mat-select [formControl]="selectedTimelineData">
              <mat-option *ngFor="let lvl of timelineOptions" [value]="lvl">{{lvl}}</mat-option>
            </mat-select>
          </mat-form-field>
        </mat-card-title>
        <mat-card-content>
          <highcharts-chart
            [Highcharts]="Highcharts"
            [options]="timelineChartOptions"
            oneToOne="true"></highcharts-chart>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="cols-2">
      <mat-card>
        <mat-card-title
          >Intake Sync Record
          <mat-slide-toggle [(ngModel)]="dataToShow"></mat-slide-toggle> OpenSearch Record
          <button class="copyButton" mat-raised-button (click)="copyDataToShow()">Copy</button>
        </mat-card-title>
        <mat-card-content>
          <ngx-json-viewer
            *ngIf="!dataToShow"
            [json]="syncEntry"
            [depth]="3"
            [expanded]="false"></ngx-json-viewer>
          <ngx-json-viewer
            *ngIf="dataToShow"
            [json]="opensearchDetails"
            [depth]="3"
            [expanded]="false"></ngx-json-viewer>
        </mat-card-content>
      </mat-card>
      <mat-card>
        <mat-card-title
          >Selected Provider/Clean Record
          <button class="copyButton" mat-raised-button (click)="copyIntakeSource()">Copy</button>
        </mat-card-title>
        <mat-card-content>
          <div>Signal Date: {{intakeSourceDate}}</div>
          <app-jobs-table *ngIf="intakeSourceJobs" [jobsList]="intakeSourceJobs"></app-jobs-table>
          <div *ngIf="tryItQuery" class="tw-pb-4">
            <div class="section-header">
              Try it Yourself:
              <a href="https://www.google.com/search?q={{tryItQuery}}" target="_blank">Google</a> |
              <a href="https://www.bing.com/search?q={{tryItQuery}}" target="_blank">Bing</a>
            </div>
          </div>
          <ngx-json-viewer [json]="intakeSource"></ngx-json-viewer>
          <div *ngIf="cleanJobItemDetails">
            <h3>Clean Job Item Details</h3>
            <h4 *ngIf="isBonus(intakeSource)">Bonus Clean - Details are for original item</h4>
            <ngx-json-viewer [json]="cleanJobItemDetails" [depth]="3"></ngx-json-viewer>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <!-- <div *ngIf="intakeRecordProd && syncEntry" class="comparePanel">
            <mat-card>
                <mat-card-title>Env Comparison. Prod on left</mat-card-title>
                <mat-card-content class="compareContent">
                    <div class="rawView">
                        <div class="rawViewColumn">
                            <h3>Prod</h3>
                            <ngx-json-viewer [json]="sortObject(intakeRecordProd)" [depth]="3" [expanded]="true"></ngx-json-viewer>
                        </div>
                        <div class="rawViewColumn">
                            <h3>{{currentEnv}}</h3>
                            <ngx-json-viewer [json]="sortObject(syncEntry)" [depth]="3" [expanded]="true"></ngx-json-viewer>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div> -->
  </div>
  <ng-template #elseBlock>
    <div *ngIf="refreshing" class="loading-container">
      <div class="loading">
        <mat-spinner diameter="60"></mat-spinner>
        <p class="loading-message">{{loadingText}}</p>
      </div>
    </div>
  </ng-template>
</div>
