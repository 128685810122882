import {
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpProgressEvent,
} from '@angular/common/http';
import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Job, JobsService } from 'ldt-clean-service-api';
import { AuthService } from 'src/app/auth/service/auth.service';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss'],
})
export class UploadFileComponent implements OnInit {
  isDragOver = false;

  title = 'Upload file:';
  caption = `(Required: name, company. Helpful: title, city, domain, Linkedin URL. Email address required if validation requested)`;
  limitErrorMessage = '';
  formTitle = 'Drag and drop CSV here';
  buttonTitle = 'Upload';
  uploadPercent: number | undefined = undefined;
  uploadRatio = 0;
  uploadError: string | undefined = undefined;
  limitError = false;
  job: Job | undefined;
  @ViewChild('fileInput', { static: false })
  myFileInput: ElementRef;
  constructor(
    private jobs: JobsService,
    private router: Router,
    private auth: AuthService,
    private notify: NotificationService
  ) {}

  orgId: string;
  ngOnInit() {
    this.orgId = this.auth.getSelectedOrgIdValue;
  }

  nextPage() {
    if (this.job) {
      this.router.navigate(['/upload', this.job.id, 'map']);
    }
  }
  dragOver(evt: DragEvent) {
    if (evt.dataTransfer) evt.dataTransfer.dropEffect = 'copy';
    evt.preventDefault();
  }

  dropFiles(evt: DragEvent) {
    if (!evt.dataTransfer) return;

    evt.preventDefault();

    this.uploadFiles(evt.dataTransfer.files);
  }
  tryAgain(event: MouseEvent) {
    event.stopPropagation();
    this.limitError = false;
    this.myFileInput.nativeElement.value = '';
  }
  async uploadFiles(files: FileList) {
    // An upload is already in progress, don't start another one
    if (this.uploadPercent !== undefined) return;

    this.uploadError = undefined;

    if (files.length !== 1) {
      this.uploadError = 'Only one file may be uploaded at a time.';
      throw new Error(`expected exactly one file, got ${files.length}`);
    }

    if (!files[0].name.endsWith('.csv')) {
      this.uploadError = 'Only CSV files may be uploaded. Please try again.';
      throw new Error(`File must be a CSV ${files[0].name}`);
    }

    this.uploadRatio = 0;
    this.uploadPercent = 0;

    this.jobs.createJob(this.auth.getSelectedOrgIdValue, files[0], 'events', true).subscribe(
      (evt: HttpEvent<any>) => {
        if (evt.type === HttpEventType.UploadProgress) {
          const prog = evt as HttpProgressEvent;

          if (prog.total) {
            this.uploadPercent = Math.round((100 * prog.loaded) / prog.total);
            this.uploadRatio = prog.loaded / prog.total;
          } else {
            this.uploadPercent = 0;
            this.uploadRatio = 0;
          }
        } else if (evt.type === HttpEventType.Response) {
          const jobId = evt.body.jobId;
          this.router.navigate([this.orgId, 'jobs', 'upload', jobId, 'map']);
        }
      },
      (err) => {
        let errMsg = 'Error submitting job. ';
        if (err.status) errMsg += '[' + err.status + ']';
        if (err.statusText) errMsg += ' ' + err.statusText;
        this.notify.error(errMsg);
      }
    );
  }
}
