import { Component, ViewEncapsulation } from '@angular/core';
import { ITooltipAngularComp } from 'ag-grid-angular';
import { ITooltipParams } from 'ag-grid-community';

@Component({
  selector: 'tooltip-component',
  template: ` <div class="alias-tooltip" [innerHTML]=params.value></div>`,
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
      :host {
        position: absolute;
        pointer-events: none;
        transition: opacity 1s;
      }

      :host.ag-tooltip-hiding {
        opacity: 0;
      }

      .alias-tooltip {
        padding: 5px 10px;
        background-color: #FAE9E9;
        border: 1px solid #c1bebe;
      }

      .alias-tooltip p {
        margin: 3px 0;
        white-space: nowrap;
      }

      .alias-tooltip p span {
        font-weight: bold;
      }
    `,
  ],  
})

export class AliasTooltip implements ITooltipAngularComp {
  public params: ITooltipParams;

  agInit(params: ITooltipParams): void {
    this.params = params;
  }
}
