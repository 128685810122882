<!-- table buttons and search result total -->
<div class="tw-flex tw-mb-3 tw-justify-between tw-mt-5">
  <button
    mat-raised-button
    [class.spinner]="refreshing"
    [disabled]="refreshing"
    (click)="refreshData()"
    class="tw-mr-2">
    Refresh
  </button>
  <button
    [class.spinner]="downloading"
    [disabled]="downloading || viewCount > 10000 || viewCount == 0"
    matTooltip="Only available for 10K or fewer records"
    mat-raised-button
    color="primary"
    (click)="downloadData()"
    class="tw-mr-4">
    Download
  </button>
  <div class="count tw-flex tw-items-center">
    <mat-progress-spinner
      mode="indeterminate"
      class="tw-mr-3"
      diameter="20"
      *ngIf="countUpdating; else contactCount">
    </mat-progress-spinner>
    <ng-template #contactCount>
      <span class="badge-checked-items tw-mr-2 tw-rounded">{{viewCount | number}}</span>
    </ng-template>
    Matching Contacts
  </div>

  <!-- info message -->
  <div class="tw-max-w-[30rem] tw-text-[12px] tw-[12px] tw-text-ellipsis tw-ml-auto">
    <!-- material info icon -->
    <mat-icon class="tw-mr-1 tw-h-4 tw-w-[16px] tw-text-[17px]" color="primary">info</mat-icon>
    <span>
      When searching "previous job" fields, ALL previous job history is searched, not just the job
      shown as the last job.
    </span>
  </div>
</div>

<!-- table -->
<div class="container ag-theme-balham">
  <ag-grid-angular
    style="height: 100%; width: 100%"
    [rowData]="rowData"
    [tooltipShowDelay]="tooltipShowDelay"
    [columnDefs]="columnDefs"
    [rowModelType]="rowModelType"
    [rowSelection]="rowSelection"
    [cacheBlockSize]="cacheBlockSize"
    [defaultColDef]="defaultColDef"
    (gridReady)="onGridReady($event)"
    [sideBar]="sideBar"
    [getRowId]="getRowId"
    enableCellTextSelection="true"
    [serverSideDatasource]="serverSideDatasource"
    (displayedColumnsChanged)="saveColumnState()">
  </ag-grid-angular>
</div>
