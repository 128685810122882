<div class="app-settings-container">
    <!-- <div class="content-title">
      {{org?.name}} Settings
    </div> -->
    <!-- <mat-tab-group class="ledger-tabs mat-tab-fill-height">
      <mat-tab label="General">
        <app-settings-general [canAdmin]="canAdmin" ></app-settings-general>
      </mat-tab>
      <mat-tab label="Billing">
        <app-settings-billing [canAdmin]="canAdmin" ></app-settings-billing>
      </mat-tab>   
    </mat-tab-group> -->
  </div>

  <app-settings-billing [canAdmin]="canAdmin" [orgId]="orgId"></app-settings-billing>
