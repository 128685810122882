// tslint:disable: max-line-length
// tslint:disable: no-non-null-assertion
// tslint:disable: object-literal-key-quotes
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { HeapService } from 'src/app/api-client/heap.service';
import { environment } from 'src/environments/environment';
import * as Sentry from "@sentry/angular-ivy";
import { ReCaptchaV3Service } from 'ng-recaptcha';

import { AuthService } from '../service/auth.service';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';
import { CreateUserRequest, Invitation, InvitationsService, UsersService } from 'ldt-identity-service-api';
declare const window: any;
@Component({
    selector: 'app-log-in-sign-up',
    templateUrl: './log-in-sign-up.component.html',
    styleUrls: ['./log-in-sign-up.component.scss']
})

export class LogInSignUpComponent implements OnInit {
    currentEmail = '';
    loginform: UntypedFormGroup;
    loginInvalid = false;
    signUpSuccess = false;
    signUpEmailError = false;
    validationError = false;
    isValidating = false;

    isInvite:boolean = false;
    inviteToken:string;
    inviteType:string = 'personal';
    invitation:Invitation;
    emailFocuse:boolean = false;

    specialCharacterCriteraMet: boolean;
    uppercaseLetterCriteriaMet: boolean;
    lowercaseLetterCriteriaMet: boolean;
    numberCriteriaMet: boolean;
    lengthCriteriaMet: boolean;
    unvalidated: '';
    pValidation = [
        {
            func: this.hasLength,
            text: 'At least 8 characters long'
        }
    ];
    form = this.fb.group({
        fullNameFormControl: new UntypedFormControl('', [
            Validators.required
        ]),
        emailFormControl: new UntypedFormControl('', {
            validators: [
                Validators.required,
                Validators.email
            ]
        }),
        passwordFormControl: new UntypedFormControl('', [
            Validators.required,
            Validators.minLength(2)
        ]),
        confirmPasswordFormControl: new UntypedFormControl('', []),
    }, { validators: this.checkPasswords });
    googleButtonValue = 'Sign up with Google';
    isActionLogin = false;
    isGoogleSignup = false;
    show = false;
    error: string | undefined = undefined;
    passwordMatchError = false;
    defaultValue = '0';
    constructor(private route: ActivatedRoute,
        private fb: UntypedFormBuilder,
        private authService: AuthService,
        private router: Router,
        private notify: NotificationService,
        private heapService: HeapService,
        private userService: UsersService,
        private inviteService: InvitationsService,
        private recaptcha: ReCaptchaV3Service) {

        // Handle redirects from the invite page
        if( window.history.state && window.history.state.inviteToken ) {
            this.inviteToken = window.history.state.inviteToken;
            if( window.history.state.type ) {
                this.inviteType = window.history.state.type;
            }
        }
    }

    ngOnInit() {
        this.formInit();
        this.route.data.subscribe(data => {
            if (data.isLogin) {
                this.googleButtonValue = 'Log in with Google';
                this.isActionLogin = true;

                if (this.form) this.form.addControl('remember', this.fb.control(false));
            }
        });
        this.loginInvalid = false;
        this.loginform.valueChanges.pipe(map(() => {
            this.showValidationTooltip();
        }));
        if( this.inviteToken ) { 
            this.isActionLogin = false;
            this.inviteService.getInvitationByToken(this.inviteToken).subscribe({
                next: (r:Invitation) => {
                    this.invitation = r;
                    this.form.patchValue({fullNameFormControl:r.inviteeName})
                    this.form.patchValue({emailFormControl:r.inviteeEmail})
                    this.isInvite = true;
                }, 
                error: (err:any) => {
                    this.isInvite = false;
                    if( err.status === 404 ) {
                        this.notify.error("Invalid invitation token provided. Please try again or create a new invitation.")
                    } else if( err.status === 409 ) {
                        this.notify.error("Invitation has expired. Please ask your administrator to send a new invitation.")
                    } else {
                        this.notify.error("Unknown error occurred. Please try again or create a new invitation.")
                    }
                }
            })
        }
    }
    update(event: any) {
        this.unvalidated = event.target.value;
    }
    formInit() {
        this.loginform = this.fb.group({
            email: [null, [Validators.required, Validators.email]],
            password: [null, [Validators.required]],
            confirmPassword: [null, [Validators.required]]
        });
    }
    showValidationTooltip() {
        this.validationError = false;
        this.isValidating = true;
    }
    checkPasswords(group: UntypedFormGroup) { // here we have the 'passwords' group
        const pass = group.get('passwordFormControl')!.value;
        const confirmPass = group.get('confirmPasswordFormControl')!.value;
        return pass === confirmPass ? null : { notSame: true };
    }
    hasLength(group: UntypedFormGroup) {
        const pass = group.get('passwordFormControl')!.value;
        this.lengthCriteriaMet = pass.length >= 8;
        return pass.length >= 8;
    }
    checkEmail(group: UntypedFormGroup) {
        const email = group.get('emailFormControl')!.value;
        const notValidEmails = ['@yahoo.com', '@aol.com', '@gmail.com', '@hotmail.com'];
        if (!notValidEmails.some(el => email.includes(el)) && this.signUpEmailError) {
            this.signUpEmailError = false;
        }
        return notValidEmails.some(el => email.includes(el));
    }
    onSubmit(event: any) {
        event.preventDefault();
        if (this.isActionLogin) {
            if (!this.loginform) return;

            if (this.isGoogleSignup) {
                // this.configureGoogleLogin();
                window.location.href = 'https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=436968108608-edemh8qtvronocf2q5ekfmj22vc9ugge.apps.googleusercontent.com&redirect_uri=http%3A%2F%2Flocalhost%3A5000%2Fapi%2Fv1%2Fsignup%2Fcallback&scope=openid+email+profile&access_type=offline';
                // window.location.href = 'https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=436968108608-edemh8qtvronocf2q5ekfmj22vc9ugge.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fgotlivedata.io%2Fapi%2Fv1%2Fsignup%2Fcallback&scope=openid+email+profile&access_type=offline';
            } else {
                this.logIn();
            }
        }
        else {
            if (!this.form) return;

            if (this.isGoogleSignup) {
                window.location.href = 'https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=436968108608-edemh8qtvronocf2q5ekfmj22vc9ugge.apps.googleusercontent.com&redirect_uri=http%3A%2F%2Flocalhost%3A5000%2Fapi%2Fv1%2Fsignup%2Fcallback&scope=openid+email+profile&access_type=offline';
                // window.location.href = 'https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=436968108608-edemh8qtvronocf2q5ekfmj22vc9ugge.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fgotlivedata.io%2Fapi%2Fv1%2Fsignup%2Fcallback&scope=openid+email+profile&access_type=offline';
            } else {
                this.lengthCriteriaMet = this.hasLength(this.form);
                this.signUp();
            }

        }
    }

    onClick() {
        this.isGoogleSignup = true;
    }

    navigateToSignup() {
        this.router.navigateByUrl('onboarding');
    }

    navigateToLogin() {
        this.isActionLogin = true;
    }

    async forgotPassword() {
        this.router.navigateByUrl('reset-password');
    }

    showPassword() {
        this.show = !this.show;
    }

    async logIn() {
        try {
            this.error = undefined;

            if (!this.loginform) return;

            const { email, password } = this.loginform.value;

            this.authService.loginV2(email.trim(), password).subscribe({
                next: () => {
                    this.heapService.identify(email.trim());
                    this.heapService.addUserProperties({"is_employee": this.authService.email ? this.authService.email.endsWith('livedatatechnologies.com') : false})
                    window['Intercom']('boot', {
                        app_id: environment.intercomAppId,
                        email: email.trim(),
                        name: this.authService.getUserValue.name || '',
                        user_id: this.authService.getUserValue.id,
                        companies: this.authService.getOrgsValue.map(o => {
                            return {
                                company_id: o.id,
                                created_at: o.createdAt,
                                name: o.name
                            }
                        })                    
                    })
                    Sentry.setUser({email: this.authService.getUserValue.email, id: this.authService.getUserValue.id});
                    if (this.authService.$redirectUrl) {
                        this.router.navigateByUrl(this.authService.$redirectUrl);
                    } else {
                        this.router.navigateByUrl(this.authService.getSelectedOrgIdValue + '/ledgers');
                    }                 
                }, 
                error: () => {
                    this.notify.error("Incorrect username or password. Please try again.")
                }
            })
        }
        catch (e) {
            this.loginInvalid = true;
        }
    }

    async signUp() {
        try {
            this.error = undefined;

            if (!this.form) return;

            const { emailFormControl, fullNameFormControl, passwordFormControl, confirmPasswordFormControl } = this.form.value;
            if (!emailFormControl ||
                !fullNameFormControl ||
                !passwordFormControl ||
                !confirmPasswordFormControl) {
                return;
            }
            if (passwordFormControl !== confirmPasswordFormControl) {
                this.passwordMatchError = true;
                return;
            }


            this.recaptcha.execute('create_account').subscribe({
                next: (token) => {
                    let body: CreateUserRequest = {
                        email: emailFormControl.trim(),
                        password: passwordFormControl,
                        name: fullNameFormControl.trim(),
                        recaptchaToken: token,
                    };

                    // This will auto-accept the invite, so just send them to the main page after creation
                    if( this.inviteToken) {
                        body.inviteToken = this.inviteToken;
                        this.authService.setRedirectUrl('/main');
                    }
        
                    this.userService.createUser(body).subscribe({
                        next: () => {
                            this.notify.success("User created successfully. Welcome to the Live Data revolution.")
                            this.loginform.patchValue({email: emailFormControl, password: passwordFormControl})
                            this.logIn();
                        }, 
                        error: (err:HttpErrorResponse) => {
                            if( err.status ) {
                                switch (err.status) {
                                    case 409: 
                                        this.notify.error("That email address is already taken. Perhaps you need to reset your password?");
                                        break;
                                    case 401:
                                        this.notify.error("Error creating user. Make sure your browser allows recaptcha and your invitation matches your email address.")
                                        break;
                                    case 400:
                                        // Surface this to Sentry. Future improvement: https://app.clickup.com/t/8684v9uw7
                                        if( err.message && err.message.includes('invitation token')) {
                                            Sentry.captureException(err);
                                        }
                                        this.notify.error("Sorry, there was a problem creating your account. Please try again later.");
                                        break;
                                    case 500:
                                        this.notify.error("Sorry, there was a problem creating your account. Please try again later.");
                                        break;
                                }
                            } else {
                                this.notify.error("Sorry, there was a problem creating your account. Please try again later.")
                            }
                        }
                    })
                }
            })


        }
        catch (e:any) {
            if (e instanceof HttpErrorResponse) {
                const err = e as HttpErrorResponse;
                this.error = err.error;
            }
            else
                this.error = e.message;
        }
    }

    onPasswordConfirmChange(val: string) {
        const { password, confirmPassword } = this.form!.value;

        // After the number of characters in the password, start checking for equality.
        if (val.length >= password.length) {
            if (confirmPassword !== password) {
                this.passwordMatchError = true;
            } else {
                this.passwordMatchError = false;
            }
        }
    }
}
