<div class="tw-h-200 tw-p-4">
  <div class="mt-4">
    <div class="tw-flex tw-flex-wrap tw--mx-6 tw-flex-col">
      <div class="tw-px-6 tw-sm:w-1/2 tw-xl:w-1/3 tw-flex">
        <div class="tw-flex tw-items-center tw-px-5 tw-py-6 tw-shadow-sm tw-rounded-md tw-bg-white">
          <div class="tw-p-3 tw-rounded-full tw-bg-orange-400 tw-bg-opacity-75"></div>

          <div class="tw-mx-5">
            <div class="tw-text-4xl tw-font-semibold tw-text-gray-700">
              {{getActiveSubscriptionCount()}}
            </div>
            <div class="tw-text-gray-500">Active Subs</div>
          </div>
        </div>
        <div class="tw-flex tw-items-center tw-px-5 tw-py-6 tw-shadow-sm tw-rounded-md tw-bg-white">
          <div class="tw-p-3 tw-rounded-full tw-bg-orange-400 tw-bg-opacity-75"></div>

          <div class="tw-mx-5">
            <div class="tw-text-4xl tw-font-semibold tw-text-gray-700">
              {{getRecentSubscriptCount()}}
            </div>
            <div class="tw-text-gray-500">New Last 7 Days</div>
          </div>
        </div>
        <div class="tw-flex tw-items-center tw-px-5 tw-py-6 tw-shadow-sm tw-rounded-md tw-bg-white">
          <div class="tw-p-3 tw-rounded-full tw-bg-orange-400 tw-bg-opacity-75"></div>

          <div class="tw-mx-5">
            <div class="tw-text-4xl tw-font-semibold tw-text-gray-700">
              {{getRecentUnsubCount()}}
            </div>
            <div class="tw-text-gray-500">Unsub Last 7 Days</div>
          </div>
        </div>
      </div>
      <!-- <div class="tw-px-6 tw-sm:w-1/2 tw-xl:w-1/3 tw-flex tw-flex-row tw-flex-wrap "> -->
      <div class="container mx-auto p-4">
        <div
          class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 md:tw-grid-cols-2 lg:tw-grid-cols-3 xl:tw-grid-cols-4 tw-gap-4">
          <div class="tw-aspect-square tw-aspect-h-1" id="dashboard-signupChartContainer"></div>
          <div class="tw-aspect-square tw-aspect-h-1" id="dashboard-cumSignupChartContainer"></div>
          <div
            class="tw-aspect-square tw-aspect-h-1"
            id="dashboard-weeklyGrowthChartContainer"></div>
          <div class="tw-aspect-square tw-aspect-h-1" id="dashboard-activeChartContainer"></div>
          <div
            class="tw-aspect-square tw-aspect-h-1"
            id="dashboard-topCompaniesChartContainer"></div>
        </div>
      </div>
    </div>
  </div>
</div>
