import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { SharedModule } from '../shared/shared.module';
import { LogInSignUpComponent } from './log-in-sign-up/log-in-sign-up.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { InvitationsComponent } from './invitations/invitations.component';
import { AgGridModule } from 'ag-grid-angular';
import { OauthComponent } from './oauth/oauth.component';
import { FullMaterialModule } from 'src/app/material/material-module';
import { ExtensionComponent } from './extension/extension.component';
import { SocialLoginModule, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import { GoogleLoginProvider } from '@abacritt/angularx-social-login';
import { environment } from 'src/environments/environment';

@NgModule({
    declarations: [
        LogInSignUpComponent,
        ResetPasswordComponent,
        InvitationsComponent,
        OauthComponent,
        ExtensionComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        MatIconModule,
        MatCardModule,
        MatSelectModule,
        MatInputModule,
        MatCheckboxModule,
        AgGridModule,
        FullMaterialModule,
        SocialLoginModule
    ],
    providers: [
      {
        provide: 'SocialAuthServiceConfig',
        useValue: {
          autoLogin: false,
          providers: [
            {
              id: GoogleLoginProvider.PROVIDER_ID,
              provider: new GoogleLoginProvider(
                environment.googleAuthClientId
              )
            }
          ],
          onError: (err) => {
            console.error(err);
          }
        } as SocialAuthServiceConfig,
      }
    ],
})
export class AuthModule { }
