<div class="app-identity-container">
  <div class="content-title">Ledger Service Administration</div>
  <mat-tab-group class="identity-tabs">
    <mat-tab label="People">
      <h2>Clean/Flywheel Lookup</h2>
      <app-ledger-people></app-ledger-people>
    </mat-tab>
    <mat-tab label="Settings">
      <ng-template matTabContent>
        <app-ledger-admin-settings></app-ledger-admin-settings>
      </ng-template>
    </mat-tab>
    <mat-tab label="Import Status">
      <ng-template matTabContent>
        <app-admin-ledgers-import-status></app-admin-ledgers-import-status>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
