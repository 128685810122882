<a routerLink="/admin/identity">Back to Identity Admin</a>
<div *ngIf="org" class="content-title">{{org.name}} ({{org.id}})</div>
<div *ngIf="org" class="content-description">
  <strong>Created:</strong> {{org.createdAt | date:'medium'}}
</div>
<hr />
<div class="section-header">Settings</div>
<form [formGroup]="form" *ngIf="!loading">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Org Status</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div>
        <mat-label>Active?</mat-label>
        <mat-slide-toggle formControlName="status" id="status"></mat-slide-toggle>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-header>
      <mat-card-title>Ledgers</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div>
        <mat-label>Enabled</mat-label>
        <mat-slide-toggle
          formControlName="ledgerCapability"
          id="ledgerCapability"></mat-slide-toggle>
      </div>
      <mat-form-field appearance="outline">
        <mat-label>Ledger Contact Limit</mat-label>
        <input matInput formControlName="contactLimit" id="contactLimit" placeholder="None" />
      </mat-form-field>
      <div>
        <mat-label>Reinit Ledger</mat-label>
        <mat-slide-toggle formControlName="ledgerReinit" id="ledgerReinit"></mat-slide-toggle>
      </div>
      <div>
        <mat-label>API Version</mat-label>
        V1
        <mat-slide-toggle
          formControlName="ledgerUiApiVersion"
          id="ledgerUiApiVersion"></mat-slide-toggle>
        V2
      </div>
      <div>
        <mat-label>Version Picker</mat-label>
        No
        <mat-slide-toggle
          formControlName="ledgerUiApiVersionSelector"
          id="ledgerUiApiVersionSelector"></mat-slide-toggle>
        Yes
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-header>
      <mat-card-title>Moneyball</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div>
        <mat-label>Enabled</mat-label>
        <mat-slide-toggle
          formControlName="moneyballCapability"
          id="moneyballCapability"></mat-slide-toggle>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-header>
      <mat-card-title>Jobs</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div>
        <mat-label>Enabled</mat-label>
        <mat-slide-toggle formControlName="jobsCapability" id="jobsCapability"></mat-slide-toggle>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-header>
      <mat-card-title>People</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div>
        <mat-label>Enabled</mat-label>
        <mat-slide-toggle
          formControlName="peopleCapability"
          id="peopleCapability"></mat-slide-toggle>
      </div>
    </mat-card-content>
  </mat-card>
  <button
    mat-raised-button
    color="primary"
    type="button"
    (click)="updateOrgSettings()"
    class="next-button">
    Save Settings
  </button>
</form>
<button mat-raised-button color="primary" type="button" (click)="observeOrg()">Observe</button>
<hr />
<div class="section-header">Users</div>
<div class="actionContainer">
  <button mat-raised-button (click)="showInviteDialog()">Invite users</button>
  <button mat-raised-button color="warn" [disabled]="!rowsSelected" (click)="removeSelectedUsers()">
    Remove Selected Users
  </button>
</div>
<div class="container" width="100%">
  <ag-grid-angular
    style="height: 100%;"
    class="ag-theme-balham"
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    accentedSort="true"
    [components]="components"
    [rowSelection]="rowSelection"
    [defaultColDef]="defaultColDef"
    enableCellTextSelection="true"
    ensureDomOrder="true"
    (gridReady)="onGridReady($event)"
    (selectionChanged)="onSelectionChanged()">
  </ag-grid-angular>
</div>

<mat-accordion>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title> Manual Add </mat-panel-title>
      <mat-panel-description> For advanced users only </mat-panel-description>
    </mat-expansion-panel-header>
    <mat-form-field class="example-full-width" appearance="fill">
      <mat-label>Email addresses</mat-label>
      <input matInput placeholder="Emails to add" [(ngModel)]="emails" />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Role</mat-label>
      <mat-select [(ngModel)]="role">
        <mat-option name="role" *ngFor="let role of roles" [value]="role">
          {{role}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button mat-raised-button [disabled]="!emails && !role" (click)="addUsers()">Add users</button>
  </mat-expansion-panel>
</mat-accordion>
