
<mat-card class="reset" *ngIf="!enterPassword">
    <h3>Forgot password</h3>
    <div class="subtitle">Don't worry! We'll send you a link to reset your password.</div>
    <form [formGroup]="form" *ngIf="!emailSent">
        <mat-form-field appearance="outline">
            <mat-label>Email address</mat-label>
            <input matInput formControlName="email" id='email' placeholder="e.g. eleanor@livedatatechnologies.com"
                [disabled]="enterPassword" [(ngModel)]='email' />
        </mat-form-field>
        <button [disabled]="emailSent" class="rounded-reset" (click)="initiateReset()" type='submit'>
            Reset
        </button>
    </form>
    <div *ngIf="emailSent" class="email-sent">
        Email sent. Please check your inbox for a reset link.
    </div>
</mat-card>
<mat-card *ngIf="enterPassword">
    <h3>{{ isOnboarding ? 'Get Started' : 'Reset Password'}}</h3>
    <div *ngIf="isOnboarding" class="subtitle">Welcome to Live Data. Set your password to login and view your sample data.</div>
    <form [formGroup]='form'>
        <mat-form-field appearance="outline">
            <mat-label>Email address</mat-label>
            <input matInput [disabled]="enterPassword" type='text' id='email' formControlName="email"
                placeholder="e.g. eleanor@livedatatechnologies.com" autocomplete="off">
        </mat-form-field>
        <mat-form-field appearance="outline" style="position: relative;">
            <mat-label>New Password</mat-label>
            <input matInput (focus)="showValidationTooltip($event);" (change)="checkingPassword($event);"
                 (blur)="isValidating=false;"
                [type]="show ? 'text' : 'password'" formControlName="passwordFormControl" id='password'
                placeholder="e.g. &bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;" type="password" autocomplete="off">
            <div class="tooltip-container" *ngIf="isValidating">
                <span class="title">Password requirements: </span>
                <div class="password-validation-container">
                    <div class="validation" *ngFor="let v of pValidation;">
                        <div [ngClass]="{'check': v.func(form), 'cancel': !v.func(form) }">
                        </div>
                        <span class="validation-text" [ngClass]="{'text-cancel': !v.func(form), 'text-success': v.func(form) }">{{v.text}}</span>
                    </div>
                </div>
            </div>
        </mat-form-field>
    </form>
    <div class="email-error signup-error" [ngClass]="{hide: !validationError }">
        Please check your password and try again
    </div>
    <button class="rounded-login" [ngClass]="{ disabled: emailSent }" (click)="completeReset()" type='submit'
        [value]='submitButtonValue'>
        {{ isOnboarding ? 'Get Started' : 'Reset Password'}}
    </button>
</mat-card>