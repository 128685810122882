import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { Org, OrganizationsService, UserRole, UserRolesService, UsersService } from 'ldt-identity-service-api';
import { ColDef, GridApi } from 'ag-grid-community';
import { DeleteConfirmationComponent } from 'src/app/delete-confirmation/delete-confirmation.component';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';

@Component({
  selector: 'app-team-users',
  templateUrl: './team-users.component.html',
  styleUrls: ['./team-users.component.scss'],
  providers: [DatePipe]
})
export class TeamUsersComponent implements OnInit {
  @Input() org: Org;
  @Input() canAdmin: boolean;

  /// AG -GRID --------------
  private simpleFilterParams: any = {
    filterOptions: ['contains'],
    suppressAndOrCondition: true    
  }
  defaultColDef:ColDef = {
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    resizable: true,
    filterParams: this.simpleFilterParams,
    menuTabs: ['generalMenuTab','filterMenuTab']
  }
  private roleFilterParams: any = {
    filterOptions: [
      'empty',
      {
        displayKey: 'viewer',
        displayName: 'Viewer',
        test: function (filterValue:any, cellValue:any) {
          return cellValue === 'viewer';
        },
        hideFilterInput: true,
      },
      {
        displayKey: 'editor',
        displayName: 'Editor',
        test: function (filterValue:any, cellValue:any) {
          return cellValue === 'editor';
        },
        hideFilterInput: true,
      },
      {
        displayKey: 'admin',
        displayName: 'Admin',
        test: function (filterValue:any, cellValue:any) {
          return cellValue === 'admin';
        },
        hideFilterInput: true,
      }
    ],
    suppressAndOrCondition: true,
  }  
  columnDefs:ColDef[] = [
    { field: 'user.id', headerName: 'ID', checkboxSelection: true, cellRenderer: 'loadingRenderer', maxWidth: 150,
      headerTooltip: 'Unique ID of this user'      
    },
    { field: 'role', maxWidth: 150,
      filterParams: this.roleFilterParams,
      headerTooltip: 'Role this user has (either admin, editor, or viewer)'      
    },
    { field: 'user.name', headerName: 'Name', sort: 'asc',
      headerTooltip: 'Name of the user'      
    },
    { field: 'user.email', headerName: 'Email',
      headerTooltip: 'Email address of the user'      
    },
    { field: 'user.createdAt', headerName: 'Created At (UTC)',
      valueFormatter: (params: any) => { return this.datePipe.transform(params.value, 'yyyy-MM-dd h:mm a', 'UTC') || ''},
      headerTooltip: 'Date user was created'      
    },
    { field: 'user.lastLoginAt', headerName: 'Last Login At (UTC)',
      valueFormatter: (params: any) => { return this.datePipe.transform(params.value, 'yyyy-MM-dd h:mm a', 'UTC') || ''},
      headerTooltip: 'Date user last logged in'      
    },    
  ];
  rowData: UserRole[];
  emails:string = "";
  rowSelection:string = 'multiple';
  tooltipShowDelay = 200;

  private gridApi: GridApi;
  private orgId:string = '';
  roles:string[] = ["viewer","editor","admin"];
  role:string;

  components = {
    loadingRenderer: function (params:any) {
      if (params.value !== undefined) {
        return params.value;
      } else {
        return "<img src=\"https://www.ag-grid.com/example-assets/loading.gif\">";
      }
    },
  };  
  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  };

  refreshing:boolean = true;

  constructor(private userRolesService: UserRolesService, private route: ActivatedRoute, private notify: NotificationService, public dialog: MatDialog, private datePipe: DatePipe) {
    this.orgId = route.snapshot.paramMap.get('orgId') || '';
  }

  ngOnInit() {
    this.refreshOrgUsers();
  }

  refreshOrgUsers() {
    this.refreshing = true;
    this.userRolesService.getOrgUsers(this.orgId).subscribe(r => {
      this.rowData = r;
      this.refreshing = false;
    }, () => {
      this.notify.error("Oops. There was an error during your request. Please try again later.")
      this.rowData = [];
      this.refreshing = false;
    })
  }

  rowsSelected:boolean = false;
  onSelectionChanged(event: any){
    var selectedRows =  this.gridApi.getSelectedRows();
    this.rowsSelected = selectedRows.length !== 0;
  }

  removeSelectedUsers() {
    var numToDelete = this.gridApi.getSelectedRows().length;
    var ids:any = this.gridApi.getSelectedRows().map((d:any) => d.user.id)

    const confirmDialog = this.dialog.open(DeleteConfirmationComponent, {
      data: {
        title: 'Confirm User Removal',
        message: 'Are you sure you want to remove ' + numToDelete + ' users from this Org?'
      }
    });
    confirmDialog.afterClosed().subscribe((result: boolean) => {
      if (result === true) {
        this.userRolesService.deleteOrgUsers(this.orgId, {userIds: ids}).subscribe((response) => {
          this.gridApi.deselectAll();
          this.refreshOrgUsers();
          this.notify.success(numToDelete + ' users successfully removed.');
        }, () => {
          this.notify.error("Oops. There was an error during your request. Please try again later.")
        })
      }
    });    
  }  
}
