<div class="app-ledger-overview-container">
    <div class="statCardContainer">
        <div class="statCard">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>
                        <div class="statCardTitle">New jobs detected</div>
                        <mat-progress-spinner *ngIf="(importsInProgress$ | async)?.processing || (importsInProgress$ | async)?.importing" matTooltip="Imports are currently in progress" mode="indeterminate" diameter="20"></mat-progress-spinner>
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <div class="headlineNumber" *ngIf="changeCount !== undefined">
                        <div>{{changeCount | number:'1.0-0'}}</div>
                        <div *ngIf="contactCount" class="pct">{{changeCount*100 / contactCount | number:'1.0-0'}}%</div>
                    </div>
                    <mat-spinner *ngIf="changeCount === undefined" [diameter]="35"></mat-spinner>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="statCard">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>
                        <div class="statCardTitle">Total contacts found</div>
                        <mat-progress-spinner *ngIf="(importsInProgress$ | async)?.processing || (importsInProgress$ | async)?.importing" matTooltip="Imports are currently in progress" mode="indeterminate" diameter="20"></mat-progress-spinner>
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <span class="headlineNumber" *ngIf="verifiedCount !== undefined">
                        {{verifiedCount | number:'1.0-0'}}
                        <span *ngIf="contactCount" class="pct">{{verifiedCount*100 / contactCount | number:'1.0-0'}}%</span>
                    </span>
                    <mat-spinner *ngIf="verifiedCount === undefined" [diameter]="35"></mat-spinner>
                </mat-card-content>         
            </mat-card>
        </div>
        <div class="statCard">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>
                        <div class="statCardTitle">Total ledger contacts</div>
                        <mat-progress-spinner *ngIf="(importsInProgress$ | async)?.importing" matTooltip="Imports are currently in progress" mode="indeterminate" diameter="20"></mat-progress-spinner>
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    {{contactCount | number:'1.0-0'}}
                    <mat-spinner *ngIf="contactCount === undefined" [diameter]="35"></mat-spinner>
                </mat-card-content>
            </mat-card>
        </div>        
    </div>
    <div class="container" width="100%">
        <ag-grid-angular style="height: 100%; width: 100%" class="ag-theme-alpine" [rowData]="rowData" [tooltipShowDelay]="tooltipShowDelay"
            [columnDefs]="columnDefs" [rowModelType]="rowModelType" [infiniteInitialRowCount]="infiniteInitialRowCount"
            [components]="components" [rowSelection]="rowSelection" [cacheBlockSize]="cacheBlockSize" [defaultColDef]="defaultColDef"
            (gridReady)="onGridReady($event)" enableCellTextSelection="true" ensureDomOrder="true"
            rowHeight="55"
        >
        </ag-grid-angular>
    </div>
</div>
