import { Component, OnDestroy, OnInit } from '@angular/core';
import { AdminOnboardingService, CompanyWithCount } from '../admin-onboarding-service.service';
import { Subject, takeUntil } from 'rxjs';
import { ColDef, GridReadyEvent } from 'ag-grid-community';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-streams-companies',
  templateUrl: './streams-companies.component.html',
  styleUrls: ['./streams-companies.component.scss'],
})
export class StreamsCompaniesComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  Highcharts: typeof Highcharts = Highcharts; // required
  companiesChart: Highcharts.Chart | undefined;

  rowData: CompanyWithCount[];

  /// AG -GRID --------------
  defaultColDef = {
    sortable: true,
    floatingFilter: true,
    resizable: true,
    filter: true,
    menuTabs: ['generalMenuTab', 'filterMenuTab'],
  };
  columnDefs: ColDef[] = [
    { field: 'id' },
    { field: 'name', filter: 'agTextColumnFilter' },
    { field: 'count', filter: 'agNumberColumnFilter', sort: 'desc' },
    {
      field: 'linkedin',
      headerName: 'LinkedIn',
      cellRenderer: (params: { value: any }) => {
        if (!params.value) return '';

        return (
          '<a href="https://www.linkedin.com/company/' +
          params.value +
          '" target=_blank>' +
          params.value +
          '</a>'
        );
      },
    },
  ];

  constructor(public streamsService: AdminOnboardingService) {}

  ngOnInit(): void {
    this.streamsService.companyCount$.pipe(takeUntil(this.destroy$)).subscribe((companies) => {
      if (!companies) return;
      this.rowData = companies;
      this.generateCompanyChartData(companies);
    });
  }

  ngAfterViewInit(): void {
    this.companiesChart = Highcharts.chart('topCompaniesChartContainer', this.companiesOptions);
    this.generateCompanyChartData(this.rowData);
  }

  onGridReady(params: GridReadyEvent) {
    params.api.sizeColumnsToFit();
  }

  generateCompanyChartData(companies: CompanyWithCount[]) {
    if (!companies) return;

    const chartData = companies.sort((a, b) => b.count - a.count).slice(0, 20);
    const categories = chartData.map((item) => item.name || '');
    const data = chartData.map((item) => item.count);

    if (this.companiesChart && this.companiesChart.series.length > 0) {
      this.companiesChart.xAxis[0].setCategories(categories);
      this.companiesChart.series[0].setData(data);
    }
  }

  refreshStreams() {
    this.streamsService.refresh();
  }

  ngOnDestroy() {
    if (this.companiesChart) {
      this.companiesChart.destroy();
      this.companiesChart = undefined;
    }
    this.destroy$.next();
    this.destroy$.complete();
  }

  companiesOptions: Highcharts.Options = {
    colors: ['rgb(31, 41, 55)'],
    credits: {
      enabled: false,
    },
    chart: {
      type: 'bar',
    },
    title: {
      text: 'Top Companies',
    },
    xAxis: {
      type: 'linear',
      labels: {
        style: {
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          width: 100,
        },
        overflow: 'justify',
      },
    },
    yAxis: {
      title: {
        text: undefined,
      },
      min: 0,
    },
    series: [
      {
        type: 'bar',
        name: 'Count',
        showInLegend: false,
      },
    ],
    exporting: {
      buttons: {
        contextButton: {
          symbol: 'menuball',
          symbolSize: 10,
        },
      },
    },
  };
}
