<div class="instructions-container">
  Locate people in the Live Data Warehouse. Upload a file and get immediate results back.
</div>
<div class="jobs-upload-container">
  <div
    class="form well drop-zone icon-upload"
    [class.drag-over]="isDragOver"
    (dragover)="isDragOver = true; dragOver($event)"
    (click)="fileInput.click()"
    (dragexit)="isDragOver = false"
    (dragend)="isDragOver = false"
    (drop)="isDragOver = false; dropFiles($event)">
    <input
      #fileInput
      id="file-input"
      type="file"
      [disabled]="uploadPercent != undefined"
      (change)="uploadFiles($event.target.files)" />
  </div>
  <div>
    <div *ngIf="!uploadPercent">
      <button class="rounded-upload" (click)="fileInput.click()">Upload match file</button>
    </div>
    <div *ngIf="uploadPercent">
      <div class="upload-progress">
        <div>Upload Progress: {{ uploadPercent | number: '2.0-0' }}%</div>
        <progress max="100" [value]="uploadPercent"></progress>
      </div>
    </div>
  </div>
  <div *ngIf="uploadError" class="upload-error">{{ uploadError }}</div>
  <div *ngIf="uploadMessage" class="upload-message">{{ uploadMessage }}</div>
</div>
