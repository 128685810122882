import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
    UrlTree
} from '@angular/router';

import { AuthService } from '../auth/service/auth.service';

@Injectable({ providedIn: 'root' })
export class DashboardGuard implements CanActivate {
    constructor(private auth: AuthService, private router: Router) { }

    async canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Promise<boolean | UrlTree> {
        if (this.auth.isLoggedInValue && await this.auth.isAdminValue) return true;
        return this.router.parseUrl('/main');
    }
}
