<div class="available-integrations-container tw-mt-6">
  <!-- Available Integrations -->
  <div>
    <div class="integrations-cards tw-mt-6 tw-flex tw-flex-wrap tw-gap-5">
      <mat-card *ngFor="let integration of availableIntegrations" class="tw-bg-white tw-relative">
        <div class="tw-absolute tw-right-2 tw-top-2">
          <span class="badge-sm tw-bg-slate-500" *ngFor="let tag of integration.tags">
            {{tag}}
          </span>
        </div>
        <mat-card-header class="tw-items-center">
          <img src="{{integration.logo}}" mat-card-avatar class="tw-object-contain" />
          <mat-card-title class="tw-mb-0">{{integration.name}}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          {{integration.description}}
        </mat-card-content>
        <mat-card-actions align="end" class="tw-pb-2">
          <button mat-raised-button color="primary" (click)="handleIntegrationClick(integration)">
            {{integration.buttonText}}
          </button>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>

  <!-- Coming Soon Integrations -->
  <div class="tw-mt-10">
    <div class="tw-flex tw-items-center">
      <h2 class="content-title tw-text-xl tw-font-semibold tw-my-0 tw-mr-4">Coming Soon</h2>
    </div>

    <div class="integrations-cards tw-mt-3 tw-flex tw-flex-wrap tw-gap-5">
      <mat-card *ngFor="let integration of comingSoonIntegrations" class="tw-bg-white">
        <mat-card-header class="tw-items-center">
          <img src="{{integration.logo}}" mat-card-avatar class="tw-object-contain" />
          <mat-card-title class="tw-mb-0">{{integration.name}}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          {{integration.description}}
        </mat-card-content>
        <mat-card-actions align="end" class="tw-pb-2">
          <button mat-raised-button color="primary" (click)="integrationClick(integration)">
            {{integration.buttonText}}
          </button>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>
</div>
