import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';

import { MxService } from './mx-service/mx.service';
import { NotificationService } from './notification-service/notification.service';
import { FilterPipe } from './pipes/filter.pipe';
import { CustomStepperComponent } from './stepper/stepper.component';
import { TestStatusBadgeComponent } from './test-status-badge/test-status-badge.component';
import { JobFunctionDropdownComponent } from './job-function-dropdown/job-function-dropdown.component';
import { BadgeComponent } from './badge/badge.component';

@NgModule({
  declarations: [
    FilterPipe,
    CustomStepperComponent,
    TestStatusBadgeComponent,
    JobFunctionDropdownComponent,
    BadgeComponent,
  ],
  imports: [CommonModule, ReactiveFormsModule, RouterModule, FormsModule, MatIconModule],
  exports: [
    ReactiveFormsModule,
    FilterPipe,
    CustomStepperComponent,
    TestStatusBadgeComponent,
    JobFunctionDropdownComponent,
    BadgeComponent,
    MatIconModule,
  ],
  providers: [NotificationService, MxService],
})
export class SharedModule {}
