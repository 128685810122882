import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ColDef, FirstDataRenderedEvent, GridReadyEvent } from 'ag-grid-community';

import { StreamsOnboardingService, UpdateStreamsTrialRequest } from 'ldt-onboarding-service-api';
import { StreamsFreeTrial } from 'ldt-onboarding-service-api/model/streamsFreeTrial';

import { Subject, takeUntil, timer } from 'rxjs';
import { DeleteConfirmationComponent } from 'src/app/delete-confirmation/delete-confirmation.component';
import { BtnCellRenderer } from 'src/app/shared/ag-grid-button/button-cell-renderer.component';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';
import { AdminOnboardingService } from '../admin-onboarding-service.service';

const CompanyIdPattern = /^LDC-1-[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}$/;

@Component({
  selector: 'app-streams-trial',
  templateUrl: './streams-trial.component.html',
  styleUrls: ['./streams-trial.component.scss'],
})
export class StreamsTrialComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  /// AG -GRID --------------
  defaultColDef = {
    sortable: true,
    floatingFilter: true,
    resizable: true,
    filter: true,
    menuTabs: ['generalMenuTab', 'filterMenuTab'],
  };
  columnDefs: ColDef[] = [
    { field: 'created_at', sort: 'desc', headerName: 'Created', filter: false },
    { field: 'name', filter: 'agTextColumnFilter' },
    { field: 'company', filter: 'agTextColumnFilter' },
    { field: 'email', filter: 'agTextColumnFilter' },
    {
      headerName: 'Companies',
      valueGetter: (r) => r.data.enriched_companies?.map((c: any) => c.name),
    },
    {
      headerName: 'Company IDs',
      editable: true,
      cellEditor: 'agLargeTextCellEditor',
      cellEditorPopup: true,
      cellEditorParams: {
        maxLength: 4300,
      },
      valueGetter: (r) => r.data.companies?.map((c: any) => c),
    },
    { field: 'departments' },
    { field: 'deleted_at', headerName: 'Deleted', maxWidth: 150 },
    {
      headerName: 'Actions',
      filter: false,
      maxWidth: 150,
      sortable: false,
      cellRenderer: BtnCellRenderer,
      cellRendererParams: {
        onClick: this.disableTrial.bind(this),
        label: 'Delete',
        display: 'compact',
      },
    },
  ];
  rowData: StreamsFreeTrial[];

  constructor(
    private onboardingService: StreamsOnboardingService,
    private dialog: MatDialog,
    private notify: NotificationService,
    public streamsService: AdminOnboardingService
  ) {}

  ngOnInit(): void {
    this.streamsService.streams$.pipe(takeUntil(this.destroy$)).subscribe((streams) => {
      if (!streams || !streams[0].enriched_companies) return;

      this.rowData = streams;
    });
  }

  refreshStreams() {
    this.streamsService.refresh();
  }

  onGridReady(params: GridReadyEvent) {
    params.api.sizeColumnsToFit();
  }

  onFirstDataRendered(params: FirstDataRenderedEvent) {
    // Default the status filter to active trials only
    var instance = params.api.getFilterInstance('deleted_at');
    instance?.setModel({ values: [''] });
    params.api.onFilterChanged();
  }

  disableTrial(evt: any) {
    const confirmDialog = this.dialog.open(DeleteConfirmationComponent, {
      data: {
        title: 'Confirm Deleting Trial Subscription',
        message: 'This will stop the subscription. It is not recoverable. Are you sure?',
      },
    });
    confirmDialog.afterClosed().subscribe((result: boolean) => {
      if (result === true) {
        this.onboardingService
          .unsubscribeStreamsTrials(evt.rowData.email, evt.rowData.unsubscribe_token)
          .subscribe({
            next: () => {
              this.notify.success('Subscription deleted.');
              timer(1000).subscribe(() => {
                this.streamsService.refresh();
              });
            },
            error: () => {
              console.error('Error unsubscribing');
            },
          });
      }
    });
  }

  onCellEditRequest(evt: any) {
    if (!evt.oldValue || !evt.newValue) return;
    if (!evt.data?.id) return;
    if (evt.newValue === evt.oldValue?.join(',')) return;

    const newCompanies = evt.newValue.split(',');

    const isValid = newCompanies.every((id: string) => CompanyIdPattern.test(id));

    if (!isValid) {
      this.notify.error('Invalid company id format');
      return;
    }

    const req: UpdateStreamsTrialRequest = {
      companies: newCompanies,
    };

    this.onboardingService.updateStreamsTrial(evt.data.id, req).subscribe({
      next: () => {
        this.notify.success('Streams trial updated');
        timer(1000).subscribe(() => {
          this.refreshStreams();
        });
      },
      error: () => {
        this.notify.error('Error when attempting to update trial');
      },
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
