import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class HeaderAcceptsInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    const isClean = request.url.includes('/api/clean/v2');

    if (request.method === 'POST' && request.body instanceof FormData && !isClean) {
      // Get the 'file' entry from the FormData
      const fileEntry = request.body.get('file');

      if (fileEntry instanceof File) {
        // Create a new FormData and copy all entries from the original FormData
        const formDataWithoutName = new FormData();
        request.body.forEach((value, key) => {
          if (key !== 'file' && key !== 'filename') {
            formDataWithoutName.append(key, value);
          }
        });

        // Remove the 'name' property from the 'file' entry
        const fileWithoutName = new File([fileEntry], '', { type: fileEntry.type });
        formDataWithoutName.append('file', fileWithoutName);

        // Create a new request with the modified FormData
        const modifiedRequest = request.clone({
          body: formDataWithoutName
        });

        return next.handle(modifiedRequest);
      }
    }

    // Workaround for this bug in swagger generated code: https://github.com/swagger-api/swagger-codegen/issues/8399
    if ((request.headers.get('Accept') === 'text/csv' || request.headers.get('Accept') === 'text/plain') && request.responseType !== 'text') {
      // console.debug(`Changed responseType from '${request.responseType}' to 'text' for url ${request.url}`);
      request = request.clone({ responseType: 'blob' });
    }

    return next.handle(request);
  }
}