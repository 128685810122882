<!-- stepper.component.html -->
<div>
  <ul class="stepper steps tw-list-none tw-flex tw-justify-between tw-font-medium tw-gap-3 tw-pl-0">
    <ng-container *ngFor="let step of steps; let i = index">
      <li
        class="step tw-flex tw-flex-col tw-items-center"
        [ngClass]="{'step-active': activeStep > i}">
        <span class="step-circle"></span>
        <span class="step-title">{{ step }}</span>
      </li>
      <!-- Connector line except after the last step -->
      <li *ngIf="i < steps.length - 1" class="step-connector"></li>
    </ng-container>
  </ul>
</div>
