import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DefaultService as CleanService } from 'ldt-clean-service-api';
import { AdministrativeService as LedgerAdminService } from 'ldt-ledger-service-api';
import { GridApi, RowClickedEvent } from 'ag-grid-community';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';
import { CleanNowDialogComponent } from './clean-now-dialog/clean-now-dialog.component';

@Component({
  selector: 'app-ledger-people',
  templateUrl: './people.component.html',
  styleUrls: ['./people.component.scss'],
})
export class PeopleComponent implements OnInit {
  /// AG -GRID --------------
  statusClassRules = {
    'status-sameco': (params: any) => params.value === 'SAME_CO',
    'status-newco': (params: any) => params.value === 'NEW_CO',
  };
  sourceClassrules = {
    'source-flywheel': (params: any) => params.value === 'flywheel_clean',
    'source-job': (params: any) => params.value === 'manual_clean',
    'source-init': (params: any) => params.value === 'flywheel_init',
  };
  defaultColDef = {
    sortable: true,
    filter: true,
    floatingFilter: false,
    resizable: true,
    menuTabs: ['generalMenuTab', 'filterMenuTab'],
  };
  columnDefs = [
    { field: 'type' },
    { field: 'chId', cellRenderer: 'loadingRenderer' },
    { field: 'dateRecorded', sort: 'desc' },
    { field: 'cleanStatus', cellClassRules: this.statusClassRules },
    { field: 'orgName' },
    { field: 'personTitle' },
    {
      field: 'personLipid',
      cellRenderer: (params: { value: any }) => {
        if (!params.value) {
          return '';
        } else if (params.value.startsWith('http')) {
          return '<a href="' + params.value + '" target=_blank>' + params.value + '</a>';
        } else {
          return (
            '<a href="https://www.linkedin.com/in/' +
            params.value +
            '" target=_blank>' +
            params.value +
            '</a>'
          );
        }
      },
    },
    { field: 'isAuthoritative' },
    { field: 'processedWith' },
    { field: 'jobSource', cellClassRules: this.sourceClassrules },
  ];
  columnDefs2 = [
    {
      field: 'finishedAt',
      cellRenderer: 'loadingRenderer',
      comparator: this.dateComparator,
      sort: 'desc',
    },
    { field: 'processedBy' },
    { field: 'provider' },
    { field: 'foundCompanyIn' },
    { field: 'company' },
    { field: 'email' },
    {
      field: 'linkedin',
      cellRenderer: (params: { value: any }) => {
        if (!params.value) {
          return '';
        } else if (params.value.startsWith('http')) {
          return '<a href="' + params.value + '" target=_blank>' + params.value + '</a>';
        } else {
          return (
            '<a href="https://www.linkedin.com/in/' +
            params.value +
            '" target=_blank>' +
            params.value +
            '</a>'
          );
        }
      },
    },
    { field: 'domain' },
    { field: 'title' },
    { field: 'result' },
  ];
  rowData: any[] = [];

  private gridApi: GridApi;

  components = {
    loadingRenderer: function (params: any) {
      if (params.value !== undefined) {
        return params.value;
      } else {
        return '<img src="https://www.ag-grid.com/example-assets/loading.gif">';
      }
    },
  };
  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  onGridReady2(params: any) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  dateComparator(date1: string, date2: string): number {
    return new Date(date1) < new Date(date2) ? -1 : 1;
  }

  getTypeFromId(id: string): string {
    const prefix = id.split('-')[0];
    switch (prefix) {
      case 'c':
        return 'standard';
      case 'bc':
        return 'bonus';
      case 'q':
        return 'query';
      default:
        return 'unknown';
    }
  }

  refreshing: boolean = true;

  personList: any[] = [];

  queueClassMapping = {
    c3: 'currently in clean queue; reserved for manually-set, urgent cleans',
    c2: 'currently in clean queue; reserved for new contacts needing initialization',
    c1: 'currently in clean queue; reserved for routine cleaning of persons',
    'c0-d1':
      'currently in clean queue; reserved for routine cleaning of persons that have been delayed once',
    'c0-d1+':
      'currently in clean queue; reserved for routine cleaning of persons that have been delayed more than once',
    init: 'Not in queue because the contact has already been initialized',
    disabled: 'disabled from queue until manually enabled otherwise (only marked manually)',
    d0: 'delayed for one month past the date_updated (normal)',
    d1: 'delayed for 7 days past the date_updated (happens after 1 MSR or UNK)',
    'd1+': 'delayed for 60 days past the date_updated (happens after 2 consecutive MSR or UNK)',
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private notify: NotificationService,
    private cleanService: CleanService,
    private peopleService: LedgerAdminService,
    private dialog: MatDialog
  ) {
    this.personList = JSON.parse(sessionStorage.getItem('cleanHistoryList') || '[]');
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if ('ref' in params) {
        this.addToPersonList(params.ref);
        let ids = params.ref.split(',');
        this.personRef = ids[0];
        this.updateHistory();
      } else {
        this.refreshing = false;
      }
    });
  }

  addToPersonList(refs: string) {
    let persons = refs.split(',');
    persons.forEach((p) => {
      let exists = this.personList.find((op) => {
        return op.ref === p;
      });
      if (!exists) {
        this.personList.push({ ref: p, name: null });
      }
    });
    sessionStorage.setItem('cleanHistoryList', JSON.stringify(this.personList));
  }

  removeFromPersonList(people: string) {
    const refs = people.split(',');
    refs.forEach((ref) => {
      this.personList = this.personList.filter((p) => p.ref !== ref);
    });
    sessionStorage.setItem('cleanHistoryList', JSON.stringify(this.personList));
  }
  clearPersonList() {
    this.personList = [];
    sessionStorage.setItem('cleanHistoryList', JSON.stringify(this.personList));
  }

  personName: string = '';
  personId: string;
  updateHistory() {
    this.refreshing = true;
    this.rowData = [];
    this.cjiSteps = [];
    this.cjiDetail = null;
    this.personDetail = null;
    this.snippet = '';

    this.peopleService.getContactCleanHistory(this.personRef).subscribe({
      next: (res) => {
        if (res.length > 0) {
          this.personId = res[0].personId || '';
        }
        res = res.map((r: any) => {
          r.type = this.getTypeFromId(r.chId);
          return r;
        });
        this.rowData = res;
        this.refreshing = false;

        this.peopleService.getPersonDetails(this.personId).subscribe({
          next: (r: any) => {
            this.personDetail = r;
            if (r.name) {
              this.personName = r.name || '';
              this.personList.find((p) => p.ref === this.personRef).name = this.personName;
              sessionStorage.setItem('cleanHistoryList', JSON.stringify(this.personList));
            }
          },
          error: () => {
            this.notify.info('This contact was never found and linked to an LDP.');
          },
        });
      },
      error: () => {
        this.notify.error('No clean history for the provided ID');
        this.refreshing = false;
      },
    });
  }

  personRef: any;
  searchId(person: string) {
    if (person) {
      this.personRef = person;
    }
    this.router.navigateByUrl('/admin/ledger/people?ref=' + this.personRef);
  }

  cjiDetail: any = null;
  cjiSteps: any[] = [];
  selectedCji: any;
  cjiDetailError: boolean = false;
  snippet: string;
  onRowClicked(event: RowClickedEvent) {
    const selectedItem = event.data;
    if (!this.selectedCji || selectedItem.chId !== this.selectedCji.chId) {
      this.cjiDetailError = false;
      this.cjiDetail = null;
      this.cjiSteps = [];
      this.selectedCji = selectedItem;
      this.cleanService.getCleanJobItem(this.selectedCji.chId).subscribe({
        next: (r) => {
          this.cjiDetail = r;
          this.cjiSteps = this.cjiDetail.intermediateResults;
          this.snippet = '';
        },
        error: () => {
          this.cjiDetailError = true;
        },
      });
    }
  }

  onStepRowClicked(event: RowClickedEvent) {
    this.snippet = event.data.foundCompanyInSnippet;
  }

  gotoDWDetails() {
    this.router.navigate(['/datawarehouse/inspect'], { queryParams: { ref: this.personRef } });
  }

  generateQueryString() {
    if (this.selectedCji.type === 'query') {
      return this.cjiDetail.inputs.query;
    }

    let query =
      this.cjiDetail.inputs.name?.toLowerCase() +
      '+' +
      this.cjiDetail.inputs.company?.toLowerCase();
    query += '+' + (this.cjiDetail.inputs.linkedin || 'linkedin').toLowerCase();
    return query;
  }

  cleanNow() {
    let nameParts = this.personDetail.name.split(' ');
    let firstName = nameParts[0];
    let lastName = nameParts.slice(1).join(' ');
    let company = this.personDetail.company || '';
    let lipid = this.personDetail.linkedin || '';
    let htmlSource = 'dan';
    let scraper = 'google';
    let dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '500px';
    dialogConfig.data = {
      firstName: firstName,
      lastName: lastName,
      company: company,
      lipid: lipid,
      htmlSource: htmlSource,
      scraper: scraper,
    };
    this.dialog.open(CleanNowDialogComponent, dialogConfig);
  }

  personDetail: any = null;
}
