import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { CleanService } from 'ldt-clean-service-api';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';

@Component({
  selector: 'app-clean-now-dialog',
  templateUrl: './clean-now-dialog.component.html',
  styleUrls: ['./clean-now-dialog.component.scss'],
})
export class CleanNowDialogComponent implements OnInit {
  form: UntypedFormGroup;
  firstName: string;
  lastName: string;
  company: string;
  lipid: string;
  htmlSource: string;
  scraper: string;

  htmlSources: string[] = ['dan', 'ldtSerp', 'serp']; // "serpapi", "spider", "serpwow", "oxylabs", "smartproxy", "luminati"
  scrapers: string[] = ['google', 'bing'];
  cleaning: boolean = false;
  cleanResults: any = null;

  constructor(
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<CleanNowDialogComponent>,
    private cleanService: CleanService,
    private notify: NotificationService,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.company = data.company;
    this.lipid = data.lipid;
    this.htmlSource = data.htmlSource;
    this.scraper = data.scraper;
  }

  ngOnInit() {
    this.form = this.fb.group({
      firstName: [this.firstName, [Validators.required]],
      lastName: [this.lastName, [Validators.required]],
      company: [this.company],
      lipid: [this.lipid],
      htmlSource: [this.htmlSource, [Validators.required]],
      scraper: [this.scraper, [Validators.required]],
    });
  }

  cleanNow() {
    console.log('cleanNow', this.form.value);
    this.cleaning = true;
    this.cleanService.serp(this.form.value).subscribe(
      (r) => {
        this.cleanResults = r;
      },
      () => {
        this.notify.error('Error cleaning person');
      }
    );
  }

  close() {
    this.dialogRef.close();
  }
}
