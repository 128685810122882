<div class="content-title">Clean Jobs</div>
<div class="jobs-upload-container">
  <div class="instructions-container">
    <h3 class="title">{{ title }}</h3>
    <p class="caption">{{ caption }}</p>
  </div>
  <div
    class="form well drop-zone icon-upload"
    [class.drag-over]="isDragOver"
    (dragover)="isDragOver = true; dragOver($event)"
    (click)="fileInput.click()"
    (dragexit)="isDragOver = false"
    (dragend)="isDragOver = false"
    (drop)="isDragOver = false; dropFiles($event)">
    <div class="limit-container mat-elevation-z4" *ngIf="limitError && !uploadError">
      <div class="top" (click)="tryAgain($event);">
        <mat-icon>close</mat-icon>
      </div>
      <div class="bottom">
        <div class="limit-title">Upload Failed</div>
        <div class="limit-subtitle">
          File was not uploaded. "File Name" exceeds the maximum upload size for this site.
        </div>
        <button (click)="tryAgain($event);" style="font-size: 12px;">Try Again</button>
      </div>
    </div>
    <input
      #fileInput
      id="file-input"
      type="file"
      [disabled]="uploadPercent != undefined"
      (change)="uploadFiles($event.target.files)" />
  </div>
  <div>
    <div *ngIf="!uploadPercent">
      <button class="rounded-upload" (click)="fileInput.click()">{{buttonTitle}}</button>
    </div>
    <div *ngIf="uploadPercent">
      <div class="upload-progress">
        <div>Upload Progress: {{ uploadPercent }}%</div>
        <progress max="1" [value]="uploadRatio"></progress>
      </div>
    </div>
  </div>
  <div *ngIf="uploadError && !limitError" class="upload-error">{{ uploadError }}</div>
</div>
