import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';
import { AuthService } from '../service/auth.service';
import { NgLedgerService } from 'src/app/ledger/ledger.service';

@Component({
  selector: 'app-oauth',
  templateUrl: './oauth.component.html',
  styleUrls: ['./oauth.component.scss'],
})
export class OauthComponent implements OnInit {
  gwEndpoint = 'https://4wybllqq01.execute-api.us-east-1.amazonaws.com/slack';

  constructor(
    private route: ActivatedRoute,
    private notify: NotificationService,
    private auth: AuthService,
    private http: HttpClient,
    private router: Router,
    private ledgerService: NgLedgerService
  ) {}

  params: any = [];

  ngOnInit(): void {
    if (!this.route.snapshot.queryParams['code'] || !this.route.snapshot.queryParams['state']) {
      this.notify.error('Failed to get auth data from Slack');
      this.router.navigate(['/' + this.auth.getSelectedOrgIdValue + '/integrations']);
    }

    // This check _should_ be done by the backend of course
    const state = this.route.snapshot.queryParams['state'];
    const stateOrg = state.split('|')[0];
    const stateUser = state.split('|')[1];

    if (stateOrg != this.auth.getSelectedOrgIdValue || stateUser != this.auth.getUserValue.id) {
      this.notify.error('Invalid auth data received from Slack');
      this.router.navigate(['/' + this.auth.getSelectedOrgIdValue + '/integrations']);
    }

    const body = {
      org_id: this.auth.getSelectedOrgIdValue,
      user_id: this.auth.getUserValue.id,
      ledger_version: this.ledgerService.getApiVersionValue() ? '2' : '1',
      code: this.route.snapshot.queryParams['code'],
    };

    this.http.post(this.gwEndpoint, body).subscribe({
      next: () => {
        this.notify.success('Succesfully connected Slack to Live Data');
        this.router.navigate(['/' + this.auth.getSelectedOrgIdValue + '/integrations']);
      },
      error: () => {
        this.notify.error('Error submitting oauth info to Live Data');
        this.router.navigate(['/' + this.auth.getSelectedOrgIdValue + '/integrations']);
      },
    });
  }
}
