import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Ledger, LedgersService } from 'ldt-ledger-service-api';
import { EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NotificationService } from '../shared/notification-service/notification.service';

@Injectable({
  providedIn: 'root'
})

export class LedgerResolverService implements Resolve <Ledger>{

  constructor(private ledgerService: LedgersService, private notify: NotificationService, private router: Router) {  
  }

  resolve(route: ActivatedRouteSnapshot){
    const ledgerId: any = route.paramMap.get('ledgerId');
    const orgId: any = route.paramMap.get('orgId');
    return this.ledgerService.getLedger(ledgerId, orgId).pipe(
      catchError(err => {
        if( err.status === 404 ) {
          this.notify.error("The requested ledger (" + ledgerId + ") doesn't exist");
          this.router.navigate(['/']);
        }
        return EMPTY;
      })
    );
  }
}