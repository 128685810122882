import { Component, OnInit } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { Observable, Observer, Subject } from 'rxjs';
import { AnonymousSubject } from 'rxjs/internal/Subject';
import { map } from 'rxjs/operators';

const CHAT_URL = "wss://9d8dcmijye.execute-api.us-east-1.amazonaws.com/Prod";

@Component({
  selector: 'app-firehose',
  templateUrl: './firehose.component.html',
  styleUrls: ['./firehose.component.scss']
})
export class FirehoseComponent implements OnInit {
  eventsToShow:any[] = [];
  htmlContent:string = "";

  public defaultColDef: ColDef = {
    flex: 1,
    enableCellChangeFlash: true,
    resizable: true
  };
  public columnDefs: ColDef[] = [
    { headerName: 'Type', field: 'change_type' },
    { field: 'name' },
    { field: 'linkedin', 
      cellRenderer: (params: { value: any; }) => {
        if (!params.value) {
          return '';
        } else {
          return '<a href="https://www.linkedin.com/in/' + params.value + '" target=_blank>' + params.value + '</a>';
        }
      },
    },    
    { headerName: 'Current Company', field: 'current_job.company.name' },
    { headerName: 'Current Title', field: 'current_job.title' },
    { headerName: 'Start Date', field: 'current_job.start_date' },
    { headerName: 'Email', field: 'current_job.email' },
    { headerName: 'Previous Company', valueGetter: (params:any) => {
      return params.data.previous_jobs[0].company.name;
    }},    
    { headerName: 'Previous Title', valueGetter: (params:any) => {
      return params.data.previous_jobs[0].title;
    }},
 
  ];  

  constructor() { }

  ngOnInit(): void {
    this.eventsToShow = [];
    this.messages = <Subject<any>>this.connect(CHAT_URL).pipe(
      map(
          (response: MessageEvent): any => {
              let data = JSON.parse(response.data)
              return data;
          }
      )
    );

    this.messages.subscribe(m => {
      m.forEach((msg:any) => {
        const pContent = JSON.parse(msg.Sns.Message);
        this.eventsToShow.unshift(pContent);
        this.eventsToShow = this.eventsToShow.slice(0,50);
        // this.htmlContent += "<p>" + pContent.name + " moved from " + pContent.previous_job.company.name + " to " + pContent.current_job.company.name + "</p>";

      })
    })
  }

  private subject: AnonymousSubject<MessageEvent>;
  public messages: Subject<any>;

  public connect(url:string): AnonymousSubject<MessageEvent> {
      if (!this.subject) {
          this.subject = this.create(url);
          console.log("Successfully connected: " + url);
      }
      return this.subject;
  }

  private create(url:string): AnonymousSubject<MessageEvent> {
      let ws = new WebSocket(url);
      let observable = new Observable((obs: Observer<MessageEvent>) => {
          ws.onmessage = obs.next.bind(obs);
          ws.onerror = obs.error.bind(obs);
          ws.onclose = obs.complete.bind(obs);
          return ws.close.bind(ws);
      });

      return new AnonymousSubject<MessageEvent>(undefined, observable);
  }
  
}
