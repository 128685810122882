import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Job, JobsService } from 'ldt-clean-service-api';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';
import { SetJobMappingRequest } from 'ldt-clean-service-api/model/setJobMappingRequest';
import { GetJobHeaders200Response } from 'ldt-clean-service-api/model/getJobHeaders200Response';
import { GetJobMapping200ResponseColumnsInner } from 'ldt-clean-service-api/model/getJobMapping200ResponseColumnsInner';

class MapFieldControl {
  // [previous value, current value]
  readonly $clientColumn: BehaviorSubject<[string | undefined, string]>;

  get clientColumn(): string {
    return this.$clientColumn.value[1];
  }
  set clientColumn(val: string) {
    if (this.$clientColumn.value[1] === val) return;

    const last = this.$clientColumn.value[1];
    this.$clientColumn.next([last, val]);
  }

  constructor(public readonly col: Readonly<GetJobMapping200ResponseColumnsInner>) {
    this.$clientColumn = new BehaviorSubject<[string | undefined, string]>([
      undefined,
      col.clientColumn,
    ]);
    this.clientColumn = col.clientColumn;
  }
}

@Component({
  selector: 'app-map-fields',
  templateUrl: './map-fields.component.html',
  styleUrls: ['./map-fields.component.scss'],
})
export class MapFieldsComponent implements OnInit, OnDestroy {
  title = 'Map your fields';
  caption = `(First/Last or Full Name and Company fields required)`;
  error = undefined;
  selector = { fullname: '', lastname: '', firstname: '', email: '' };

  job: Job | undefined;
  description: string[] | undefined;
  controls: MapFieldControl[] | undefined;
  reverseMap: Map<string, string[]> = new Map(); // clientColumn -> cleanColumn[] - want cleanColumn[] to have length 1
  head: GetJobHeaders200Response | undefined; // column headers for the csv file
  orgId: string;
  jobId: string;

  paramSub: Subscription | undefined;
  hasRequiredValues: boolean;
  canSubmit(): boolean {
    const used: Map<string, boolean> = new Map();
    if (!this.controls) return true;
    const requiredFields: Map<string, boolean> = new Map();
    requiredFields.set('First Name', false);
    requiredFields.set('Last Name', false);
    requiredFields.set('Full Name', false);
    requiredFields.set('Company', false);
    if (this.head) {
      const headers = this.head.columnHeaders || [];

      // for each column in the csv file, try to match it with one of our columns
      headers.forEach((columnName: any) => {
        const potentialValue = this.getMappedColumn(columnName);
        if (potentialValue !== undefined) {
          requiredFields.set(potentialValue, true);
        }
      });
    }
    // Needs to have First+last name + company OR fullname + company
    if (
      !(
        requiredFields.get('First Name') &&
        requiredFields.get('Last Name') &&
        requiredFields.get('Company')
      ) &&
      !(requiredFields.get('Full Name') && requiredFields.get('Company'))
    ) {
      this.hasRequiredValues = false;
      return false;
    }
    return this.controls.every((col) => {
      if (!col.clientColumn) return false;
      if (col.clientColumn !== '' && used.has(col.clientColumn)) {
        return false;
      }
      if (!this.controls) return true;
      if (this.controls.length === this.reverseMap.size) return false;
      used.set(col.clientColumn, true);
      return true;
    });
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private clean: JobsService,
    private notify: NotificationService
  ) {
    let activeSubs: Subscription[] = [];

    this.paramSub = route.paramMap.subscribe(async (map) => {
      try {
        activeSubs.forEach((s) => s.unsubscribe());
        activeSubs = [];

        if (map.has('orgId')) this.orgId = map.get('orgId') as string;
        if (map.has('jobId')) this.jobId = map.get('jobId') as string;

        if (!this.jobId || !this.orgId) return;

        // const job = this.job = client.crmJobFromId(id);
        const job = clean.getJobDetails(this.orgId, this.jobId).subscribe((res) => {
          this.job = res;
        });

        activeSubs.push(
          clean.getJobMapping(this.orgId, this.jobId).subscribe((mapping) => {
            this.controls = mapping.columns.map((c) => new MapFieldControl(c));
            // gets the column headers of csv file
            clean.getJobHeaders(this.orgId, this.jobId).subscribe((res) => {
              this.head = res;
            });
            // for each control, subscribe to the clientColumn value
            this.controls.forEach((c) => {
              activeSubs.push(
                c.$clientColumn.subscribe((cols) => {
                  const [lastCol, col] = cols;
                  // lastEntry is either lastCol or undefined
                  const lastEntry = lastCol ? this.reverseMap.get(lastCol) : undefined;
                  // lastIdx is either index of the last entry or -1 -> doesn't exist
                  const lastIdx = lastEntry ? lastEntry.indexOf(c.col.cleanColumn) : -1;
                  // remove the last entry item if it exists
                  if (lastEntry && lastIdx >= 0) lastEntry.splice(lastIdx, 1);
                  // get string[] for the column
                  if (col === '') {
                    // reset the client column
                    this.reverseMap.set('', [c.col.cleanColumn]);
                  }
                  if (col) {
                    const arr = this.reverseMap.get(col);
                    // pushes the our column to array for clientColumn
                    if (arr) {
                      arr.push(c.col.cleanColumn);
                    } else this.reverseMap.set(col, [c.col.cleanColumn]);
                  }
                })
              );
            });
          })
        );

        this.error = undefined;
      } catch (e: any) {
        this.error = e.message;
        console.error(e);
      }
    });
  }
  // tslint:disable: use-life-cycle-interface
  async ngOnInit() {
    if (this.job) {
      this.clean.getJobHeaders(this.orgId, this.jobId).subscribe((res) => {
        if (res.columnHeaders) {
          res.columnHeaders.forEach((columnName) => {
            const potentialValue = this.getMappedColumn(columnName);
            if (potentialValue !== undefined) {
              requiredFields.set(potentialValue, true);
            }
          });
        }
      });
      const requiredFields: Map<string, boolean> = new Map();
      requiredFields.set('First Name', false);
      requiredFields.set('Last Name', false);
      requiredFields.set('Full Name', false);
      requiredFields.set('Company', false);

      // Needs to have First+last name + company OR fullname + company
      if (
        !(
          requiredFields.get('First Name') &&
          requiredFields.get('Last Name') &&
          requiredFields.get('Company')
        ) &&
        !(requiredFields.get('Full Name') && requiredFields.get('Company'))
      ) {
        this.hasRequiredValues = false;
      } else {
        this.hasRequiredValues = true;
      }
    }
  }
  ngOnDestroy() {
    if (this.paramSub) this.paramSub.unsubscribe();
  }
  async check() {
    if (this.job) {
      // const latest = await this.job.getHead();

      const requiredFields: Map<string, boolean> = new Map();
      requiredFields.set('First Name', false);
      requiredFields.set('Last Name', false);
      requiredFields.set('Full Name', false);
      requiredFields.set('Company', false);

      this.clean.getJobHeaders(this.orgId, this.jobId).subscribe((res) => {
        if (res.columnHeaders) {
          res.columnHeaders.forEach((columnName) => {
            const potentialValue = this.getMappedColumn(columnName);
            if (potentialValue !== undefined) {
              requiredFields.set(potentialValue, true);
            }
          });
        }

        // Needs to have First+last name + company OR fullname + company
        if (
          !(
            requiredFields.get('First Name') &&
            requiredFields.get('Last Name') &&
            requiredFields.get('Company')
          ) &&
          !(requiredFields.get('Full Name') && requiredFields.get('Company'))
        ) {
          this.hasRequiredValues = false;
        } else {
          this.hasRequiredValues = true;
        }
      });
    }
  }
  async confirm() {
    if (!(this.hasRequiredValues && this.job && this.controls)) return;
    try {
      const mapping: SetJobMappingRequest = {
        columns: this.controls.map((c) => {
          const returnCleanColumn = c.col.cleanColumn;
          let returnClientColumn = c.col.clientColumn;
          if (this.head) {
            this.head.columnHeaders.forEach((key: any) => {
              if (this.getMappedColumn(key)) {
                const value = this.getMappedColumn(key);
                if (value === c.col.cleanColumn) {
                  returnClientColumn = key;
                }
              }
            });
          }

          return {
            cleanColumn: returnCleanColumn,
            clientColumn: returnClientColumn,
          };
        }),
      };

      this.clean.setJobMapping(this.orgId, this.jobId, mapping).subscribe((res) => {
        this.notify.success('Job created');
        this.router.navigate([this.orgId, 'jobs']);
      });
    } catch (e: any) {
      this.error = e.message;
      console.error(e);
    }
  }

  getMappedColumn(clientColumn: string): string | undefined {
    const list = this.reverseMap.get(clientColumn);

    if (!list) return undefined;

    return list[0];
  }
}
