<div class="header">
  <button
    type="button"
    class="link clean-button"
    [class.disabled]="!hasRequiredValues"
    [disabled]="!hasRequiredValues"
    (click)="confirm()">
    Clean List
  </button>
</div>
<div class="container">
  <div class="mapping-container">
    <h3 class="title">{{ title }}</h3>
    <p class="caption">{{ caption }}</p>
  </div>
  <div class="form">
    <div class="form-row form-row--titles">
      <div class="form-title form-column-1">Our columns</div>
      <div class="form-title form-column-2">Your columns</div>
    </div>

    <div class="form-row" *ngFor="let control of controls">
      <div class="form-column-1 input-title" [title]="control.col.desc">
        {{ control.col.cleanColumn }}
        <span class="icon-arrow-right"></span>
      </div>
      <div class="form-column-2">
        <select [(ngModel)]="control.clientColumn" (change)="check($event)">
          <option [value]=""></option>
          <option [value]="header" *ngFor="let header of head?.columnHeaders">
            {{ header }}
          </option>
        </select>
        <!-- <span class="triangle"></span> -->
      </div>
    </div>
  </div>
</div>
<div class="table-container">
  <table *ngIf="head">
    <tbody>
      <tr>
        <td class="active-td">Our Columns:</td>
        <td class="active-td" *ngFor="let header of head.columnHeaders; index as i">
          {{ header }}
        </td>
      </tr>
      <tr>
        <td class="title-td">Your Columns:</td>
        <td class="title-td" *ngFor="let header of head.columnHeaders; index as i">
          {{ getMappedColumn(header) }}
        </td>
      </tr>
      <tr *ngFor="let row of head.rows">
        <td></td>
        <td *ngFor="let col of row">
          {{ col }}
        </td>
      </tr>
    </tbody>
  </table>
</div>
