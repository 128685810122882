<div class="tw-p-4 tw-flex tw-flex-col tw-gap-2">
  <div>
    <button
      [class.spinner]="refreshing"
      [disabled]="refreshing"
      mat-raised-button
      (click)="updateStatus()">
      Refresh
    </button>
  </div>

  <div class="mt-4">
    <div class="tw-flex tw-flex-wrap">
      <div class="tw-sm:w-1/2 tw-xl:w-1/3 tw-flex tw-gap-2">
        <div class="tw-flex tw-items-center tw-px-5 tw-py-6 tw-shadow tw-rounded-md tw-bg-white">
          <div class="tw-p-3 tw-rounded-full tw-bg-orange-400 tw-bg-opacity-75"></div>

          <div class="tw-mx-5">
            <div class="tw-text-4xl tw-font-semibold tw-text-gray-700">
              {{latestStatus?.redis_keys}}
            </div>
            <div class="tw-text-gray-500">Redis Keys</div>
          </div>
        </div>
        <div class="tw-flex tw-items-center tw-px-5 tw-py-6 tw-shadow tw-rounded-md tw-bg-white">
          <div class="tw-p-3 tw-rounded-full tw-bg-orange-400 tw-bg-opacity-75"></div>

          <div class="tw-mx-5">
            <div class="tw-text-4xl tw-font-semibold tw-text-gray-700">
              {{latestStatus?.insert_queue_length}}
            </div>
            <div class="tw-text-gray-500">Insert Queue Length</div>
          </div>
        </div>
        <div class="tw-flex tw-items-center tw-px-5 tw-py-6 tw-shadow tw-rounded-md tw-bg-white">
          <div class="tw-p-3 tw-rounded-full tw-bg-orange-400 tw-bg-opacity-75"></div>

          <div class="tw-mx-5">
            <div class="tw-text-4xl tw-font-semibold tw-text-gray-700">
              {{latestStatus?.active_imports}}
            </div>
            <div class="tw-text-gray-500">Active Imports</div>
          </div>
        </div>
        <div class="tw-flex tw-items-center tw-px-5 tw-py-6 tw-shadow tw-rounded-md tw-bg-white">
          <div class="tw-p-3 tw-rounded-full tw-bg-orange-400 tw-bg-opacity-75"></div>

          <div class="tw-mx-5">
            <div class="tw-text-4xl tw-font-semibold tw-text-gray-700">
              {{latestStatus?.is_draining ? 'Yes' : 'No'}}
            </div>
            <div class="tw-text-gray-500">Is draining?</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
