import { CdkStepper } from '@angular/cdk/stepper';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';

type Step = 'profile' | 'persona' | 'goals' | 'details' | 'summary';
@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  providers: [{ provide: CdkStepper, useExisting: StepperComponent }],
})
export class StepperComponent extends CdkStepper implements OnInit {
  @Output() gotoStep = new EventEmitter<number>();

  private currentStep = 0;
  private stepIndex = [0, 0, 0, 1, 2];
  private stepMapping: { [key in Step]: number } = {
    profile: 0,
    persona: 0,
    goals: 0,
    details: 1,
    summary: 2,
  };

  ngOnInit(): void {}

  selectStepByIndex(index: number): void {
    this.gotoStep.emit(index);
    this.selectedIndex = index;
  }

  nextStep(step: string): void {
    this.selectedIndex = this.stepMapping[step as Step];
  }
}
