<section class="example-container">
  
    <header class="example-step-navigation-bar">
      <div
        class="example-step"
        [class.example-active]="selectedIndex === i"
        *ngFor="let step of steps; let i = index"
      >
        {{ step.label }}
    </div>
    </header>

    <div [ngTemplateOutlet]="selected ? selected.content : null"></div>

  </section>
  