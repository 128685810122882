
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { InjectionToken, Injector } from '@angular/core';

export const BASE_PATH = new InjectionToken<string>('');

@Injectable()
export class GrowService {

  constructor(
    private http: HttpClient,
    private injector: Injector
  ) {
  }

  grow(role: string, company: string, industry: string, tags: string): Observable<any> {
    const basePath = this.injector.get(BASE_PATH);
    const url = `${basePath}/grow?`;
    const params = new URLSearchParams({
      role: role,
      company: company,
      industry: industry,
      tags: tags
    }).toString()

    return this.http.get(url + params);
  }
}
