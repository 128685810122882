import { Component, Input } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

interface BtnCellRendererParams extends ICellRendererParams {
  onClick: any;
  label?: string;
  btnColor?: string;
  display?: string;
}

@Component({
  selector: 'app-button-renderer',
  template: `
    <button *ngIf="visible" mat-flat-button color="{{btnColor}}" [ngClass]="{'compact': compact}" (click)="onClick($event)">{{label}}</button>
    `,
  styles: [
    `.compact {
      line-height: 20px;
    }`,
  ],
})
export class BtnCellRenderer implements ICellRendererAngularComp {
  @Input() params: BtnCellRendererParams;
  label: string | null;
  btnColor: string;
  visible: boolean = true;
  compact: boolean = false;

  agInit(params: BtnCellRendererParams & { label?: string }): void {
    this.params = params;
    this.label = this.params.label || '';
    this.btnColor = this.params.btnColor || 'primary';
    this.compact = params.display === 'compact';
    this.setupVisibility();
  }

  setupVisibility(): void {
    const type = this.params?.data?.type;
    const status = this.params?.data?.status;
    const expiresAt = this.params?.data?.expiresAt;
    const state = this.params?.data?.status?.state;

    if (
      type === 'identity#invitation' &&
      (status !== 'pending' || new Date(expiresAt) < new Date())
    ) {
      this.visible = false;
    }

    if (type === 'crm' && state !== 'done') {
      this.visible = false;
    }
  }

  refresh(): boolean {
    return true;
  }

  onClick($event: MouseEvent): void {
    const params = {
      event: $event,
      rowData: this.params.node.data,
    };
    this.params.onClick(params);
  }
}
