import {
  Component,
  EventEmitter,
  Inject,
  Input,
  LOCALE_ID,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { Ledger, ContactImportsService } from 'ldt-ledger-service-api';
import * as FileSaver from 'file-saver';
import { NotificationService } from '../../shared/notification-service/notification.service';
import { DeleteConfirmationComponent } from '../../delete-confirmation/delete-confirmation.component';
import { DatePipe, formatNumber } from '@angular/common';
import { AuthService } from 'src/app/auth/service/auth.service';

@Component({
  selector: 'app-ledger-import',
  templateUrl: './ledger-import.component.html',
  styleUrls: ['./ledger-import.component.scss'],
  providers: [DatePipe],
})
export class LedgerImportComponent implements OnInit {
  @Output() refreshContactRequest: EventEmitter<any> = new EventEmitter();
  canEdit: boolean = false;

  // TODO

  rowsSelected = false;

  private simpleFilterParams: any = {
    filterOptions: ['contains'],
    suppressAndOrCondition: true,
  };
  defaultColDef = {
    resizable: true,
    sortable: false,
    filter: false,
    floatingFilter: true,
    filterParams: this.simpleFilterParams,
    menuTabs: ['generalMenuTab', 'filterMenuTab'],
  };
  private statusFilterParams: any = {
    filterOptions: [
      'empty',
      {
        displayKey: 'importing',
        displayName: 'Importing',
        test: function (filterValue: any, cellValue: any) {
          return cellValue === 'false';
        },
        hideFilterInput: true,
      },
      {
        displayKey: 'processing',
        displayName: 'Processing',
        test: function (filterValue: any, cellValue: any) {
          return cellValue === 'true';
        },
        hideFilterInput: true,
      },
      {
        displayKey: 'completed',
        displayName: 'Completed',
        test: function (filterValue: any, cellValue: any) {
          return cellValue === 'true';
        },
        hideFilterInput: true,
      },
      {
        displayKey: 'deleted',
        displayName: 'Deleted',
        test: function (filterValue: any, cellValue: any) {
          return cellValue === 'true';
        },
        hideFilterInput: true,
      },
      {
        displayKey: 'failed',
        displayName: 'Failed',
        test: function (filterValue: any, cellValue: any) {
          return cellValue === 'true';
        },
        hideFilterInput: true,
      },
    ],
    suppressAndOrCondition: true,
  };
  private typeFilterParams: any = {
    filterOptions: [
      'empty',
      {
        displayKey: 'fileUpload',
        displayName: 'File Upload',
        test: function (filterValue: any, cellValue: any) {
          return cellValue === 'false';
        },
        hideFilterInput: true,
      },
      {
        displayKey: 'manualInput',
        displayName: 'Manual Input',
        test: function (filterValue: any, cellValue: any) {
          return cellValue === 'true';
        },
        hideFilterInput: true,
      },
      {
        displayKey: 'apiImport',
        displayName: 'API',
        test: function (filterValue: any, cellValue: any) {
          return cellValue === 'true';
        },
        hideFilterInput: true,
      },
    ],
    suppressAndOrCondition: true,
  };
  columnDefs = [
    {
      field: 'id',
      headerName: '',
      width: 40,
      checkboxSelection: true,
      sortable: false,
      cellRenderer: (params: any) => {
        return '';
      },
    },
    {
      field: 'importStartedAt',
      sortable: true,
      headerName: 'Started At (UTC)',
      sort: 'desc',
      valueFormatter: (params: any) => {
        return this.datePipe.transform(params.value, 'yyyy-MM-dd h:mm a', 'UTC');
      },
      headerTooltip: 'Time that the import was started',
    },
    {
      field: 'status',
      maxWidth: 100,
      filter: 'agTextColumnFilter',
      sortable: true,
      cellRenderer: 'loadingRenderer',
      filterParams: this.statusFilterParams,
      headerTooltip: 'Imports are in progress until showing "completed"',
    },
    {
      headerName: 'Import Details',
      children: [
        {
          field: 'originalRows',
          headerName: 'File Rows',
          maxWidth: 120,
          headerTooltip: 'The number of rows that were in the uploaded file',
          valueFormatter: (params: any) => {
            return formatNumber(params.value, this.locale);
          },
        },
        {
          field: 'contactsNum',
          headerName: 'Attempted',
          maxWidth: 120,
          headerTooltip:
            'The number of file rows that passed initial validation and were submitted to Live Data',
          valueFormatter: (params: any) => {
            return formatNumber(params.value, this.locale);
          },
        },
        {
          field: 'countDuplicates',
          headerName: 'Duplicates',
          maxWidth: 120,
          headerTooltip:
            'The number of people that were detected as already on the ledger and ignored from this import job',
          valueFormatter: (params: any) => {
            return formatNumber(params.value, this.locale);
          },
        },
        {
          field: 'countFailures',
          headerName: 'Failed',
          maxWidth: 120,
          headerTooltip: 'The number of records that failed to import',
          valueFormatter: (params: any) => {
            return formatNumber(params.value, this.locale);
          },
        },
        {
          headerName: 'Added',
          maxWidth: 120,
          valueGetter: (params: any) => {
            if (
              params.data &&
              (params.data.status == 'completed' ||
                params.data.status == 'processing' ||
                params.data.status == 'deleted')
            ) {
              return formatNumber(
                params.data.contactsNum - params.data.countDuplicates - params.data.countFailures,
                this.locale
              );
            } else {
              return '';
            }
          },
          headerTooltip: 'The number of new records added to the ledger from this import job',
        },
      ],
      headerTooltip: 'Details of this import job',
    },
    {
      field: 'fileName',
      filter: 'agTextColumnFilter',
      sortable: true,
      headerTooltip: 'The name of the file uploaded to Live Data',
    },
    {
      field: 'importType',
      filter: 'agTextColumnFilter',
      sortable: true,
      filterParams: this.typeFilterParams,
      headerTooltip: 'The type of import (file, manual, or API)',
    },
    {
      field: 'importFinishedAt',
      headerName: 'Finished At (UTC)',
      headerTooltip: 'Time at which the import finished',
      valueFormatter: (params: any) => {
        return this.datePipe.transform(params.value, 'yyyy-MM-dd h:mm a', 'UTC');
      },
    },
    {
      field: 'importedByUser.name',
      headerName: 'Imported By',
      headerTooltip: 'Name of the user that submitted this import job',
    },
    {
      headerName: 'Errors',
      filter: false,
      maxWidth: 150,
      cellRenderer: (params: any) => {
        if (params.data && ['completed', 'deleted'].includes(params.data.status)) {
          if (
            (params.data.countDuplicates > 0 || params.data.countFailures > 0) &&
            params.data.status == 'completed'
          ) {
            return '<a>Download</a>';
          } else {
            return '';
          }
        } else {
          return 'pending...';
        }
      },
      onCellClicked: this.downloadErrors.bind(this),
      headerTooltip: 'Download a list of all the errors and duplicates from this import job',
    },
  ];
  tooltipShowDelay = 200;

  gridApi: any;
  rowModelType: any = 'infinite';
  paginationPageSize = 100;
  infiniteInitialRowCount = 1;
  components = {
    loadingRenderer: function (params: any) {
      if (params.value !== undefined) {
        return params.value;
      } else {
        return '<img src="https://www.ag-grid.com/example-assets/loading.gif">';
      }
    },
  };

  refreshing: boolean = true;

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    public importService: ContactImportsService,
    public dialog: MatDialog,
    public route: ActivatedRoute,
    private notify: NotificationService,
    private datePipe: DatePipe,
    private auth: AuthService
  ) {}

  ledger: Ledger;
  orgId: string;
  async ngOnInit() {
    // Get the ledger from the resolver and org ID from the route
    this.ledger = this.route.parent?.snapshot.data.userdata;
    let routeOrg = this.route.parent?.snapshot.paramMap.get('orgId');
    if (routeOrg) {
      this.orgId = routeOrg;
    }
    this.canEdit = this.auth.userHasRole('editor');
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    params.api.setDatasource(this);
    this.gridApi.sizeColumnsToFit();
  }

  isRowSelectable(rowNode: any) {
    // backwards compatibility for status value
    return rowNode.data
      ? rowNode.data.status === 'completed' ||
          rowNode.data.status === 'complete' ||
          rowNode.data.status === 'processing'
      : false;
  }

  getRows(params: any): any {
    // Set the offset and limit
    var queryModel = {
      offset: params.startRow,
      limit: params.endRow - params.startRow,
    };

    var sortBy;
    if (params.sortModel.length > 0) {
      var dir = '';
      if (params.sortModel[0].sort == 'desc') {
        dir = '-';
      }
      sortBy = dir + params.sortModel[0].colId;
    }

    // Get the data
    this.importService
      .getImportJobs(
        this.ledger.id,
        this.orgId,
        params.filterModel['status'] ? params.filterModel['status'].type : undefined,
        params.filterModel['fileName'] ? params.filterModel['fileName'].filter : undefined,
        sortBy,
        params.endRow - params.startRow,
        params.startRow,
        params.filterModel['importType'] ? params.filterModel['importType'].type : undefined
      )
      .subscribe(
        (res) => {
          var lastRow = -1;
          if (res.imports.length < queryModel.limit) {
            lastRow = queryModel.offset + res.imports.length;
          }
          params.successCallback(res.imports, lastRow);
          this.refreshing = false;
        },
        () => {
          params.failCallback();
          this.notify.error(
            'Oops. There was an error during your request. Please try again later.'
          );
          this.refreshing = false;
        }
      );
  }

  refreshData() {
    this.refreshing = true;
    this.gridApi.purgeInfiniteCache();
  }

  deleteSelectedImport() {
    var ids: any = this.gridApi.getSelectedRows().map((d: any) => d.id);
    const confirmDialog = this.dialog.open(DeleteConfirmationComponent, {
      data: {
        title: 'Confirm Contacts Deletion',
        message:
          'Are you sure you want to delete ALL contacts imported by this import? This action is unrecoverable!',
      },
    });
    confirmDialog.afterClosed().subscribe((result: boolean) => {
      if (result === true) {
        this.importService.deleteLedgerImport(this.ledger.id, ids[0], this.orgId).subscribe(
          (response) => {
            this.gridApi.deselectAll();
            this.gridApi.purgeInfiniteCache();
            this.refreshContactRequest.emit();
            this.notify.success(response.countContactsDeleted + ' contacts successfully deleted.');
          },
          () => {
            this.notify.error(
              'Oops. There was an error during your request. Please try again later.'
            );
          }
        );
      }
    });
  }

  onSelectionChanged(event: any) {
    var selectedRows = this.gridApi.getSelectedRows();
    this.rowsSelected = selectedRows.length !== 0;
  }

  downloadErrors(e: any) {
    if (
      (e.data.status == 'completed' || e.data.status == 'completed') &&
      (e.data.countDuplicates > 0 || e.data.countFailures > 0)
    ) {
      this.importService.getImportErrors(this.ledger.id, e.data.id, this.orgId).subscribe({
        next: (res: any) => {
          var data = new Blob([res], { type: 'text/csv' });
          FileSaver.saveAs(data, e.data.fileName + '-livedata-import-errors' + '.csv');
        },
        error: () => {
          this.notify.error(
            'Oops. There was an error during your request. Please try again later.'
          );
        },
      });
    }
  }
}
