import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Contact as ContactV2 } from 'ldt-ledger-service-api-v2';

@Component({
  selector: 'app-ledger-job-cell-renderer',
  templateUrl: './ledger-job-cell-renderer.component.html',
  styleUrls: ['./ledger-job-cell-renderer.component.scss']
})
export class LedgerJobCellRendererComponent implements OnInit, ICellRendererAngularComp {
  company: string;
  title: string;
  email: string; 
  linkedin: string;
  headlineColor: string = 'default';
  emailStatus: string;
  jobStartedAt: string;

  // gets called once before the renderer is used
  agInit(params: ICellRendererParams): void {
    this.setVars(params);
  }

  // gets called whenever the cell refreshes
  refresh(params: ICellRendererParams) {
      // set value into cell again
      this.setVars(params);
      return true;
  }

  setVars(params:any) {
    if( params.data && params.colDef ) {
      switch (params.colDef.field) {
        case 'company': 
          this.company = params.data.company;
          this.title = params.data.title;
          this.email = params.data.contactInfo.email;
          this.emailStatus = params.data.contactInfo.emailStatus;
          if( params.data.jobStartedAt ) this.jobStartedAt = params.data.jobStartedAt;
          if( params.data.lastJobChangedAt && params.data.company !== params.data.importCompany) this.headlineColor = 'good';
          break;
        case 'importCompany':
          this.company = params.data.importCompany;
          this.title = params.data.importTitle;
          if( params.data.company && params.data.company !== params.data.importCompany ) this.headlineColor = 'warn';
          if( params.data.lastJobChangedAt ) this.headlineColor = 'bad';
          break;
        case 'current_position.company.name':
          this.company = params.data.current_position.company.name;
          this.title = params.data.current_position.title;
          this.email = params.data.current_position.email;
          this.emailStatus = params.data.current_position.email_status;
          if( params.data.current_position.started_at ) this.jobStartedAt = params.data.current_position.started_at;
          if( params.data.last_company_changed_at && params.data.current_position.company.name !== params.data.imported.company) this.headlineColor = 'good';
          break;
        case 'imported.company':
          this.company = params.data.imported.company;
          this.title = params.data.imported.title;
          if( params.data.current_position.company.name && params.data.current_position.company.name !== params.data.imported.company ) this.headlineColor = 'warn';
          if( params.data.last_company_changed_at ) this.headlineColor = 'bad';
          break;          
        case 'name':
          this.company = params.data.name;
          this.linkedin = params.data.linkedin;
          break;
      }
    }
  }


  constructor() { }

  ngOnInit(): void {
  }

}
