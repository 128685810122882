<div class="container">
    <div>
        <mat-card class="savedSearchesCard">
            <mat-card-title>Saved Searches</mat-card-title>
            <mat-card-content>
                <div class="savedSearchRow">
                    <mat-form-field appearance="fill">
                        <mat-label>Select a saved search</mat-label>                
                        <mat-select [(value)]="selectedSearch">
                            <mat-option *ngFor="let search of savedSearches" value="{{search.id}}">
                                {{search.name}} ({{search.created_by}})
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <button mat-raised-button color="primary" (click)="loadSavedSearch()" [disabled]="!this.selectedSearch">Load</button>
                    <button mat-raised-button color="warn" (click)="deleteSearch()" [disabled]="!this.selectedSearch">Delete</button>
                </div>
                <hr style="width: 100%;"/>
                <div class="savedSearchRow">
                    <mat-form-field> 
                        <mat-label>Name to Save As...</mat-label>        
                        <input matInput type="text" [(ngModel)]="newSavedSearchName">
                    </mat-form-field>
                    <button mat-raised-button color="primary" (click)="saveSearch()" [disabled]="!this.newSavedSearchName">Save New</button>
                </div>                    
            </mat-card-content>
        </mat-card>

        <mat-card class="searchCard">
            <mat-card-title>Search Parameters</mat-card-title>
            <mat-card-content>
                <!-- <mat-expansion-panel hideToggle>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        Return type
                      </mat-panel-title>
                      <mat-panel-description>
                        {{returnType}}
                      </mat-panel-description>
                    </mat-expansion-panel-header>
                    <mat-form-field appearance="fill">
                        <mat-select [(value)]="returnType">
                            <mat-label>Type of data to return</mat-label>                
                            <mat-option value="data">Data</mat-option>
                            <mat-option value="counts">Counts</mat-option>
                        </mat-select>
                    </mat-form-field>
                </mat-expansion-panel> -->

                <mat-expansion-panel hideToggle>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        Only show confident data
                      </mat-panel-title>
                      <mat-panel-description>
                        {{confidentOnly}}
                      </mat-panel-description>
                    </mat-expansion-panel-header>
                    <mat-checkbox [(ngModel)]="confidentOnly">
                        only with recent positive signal from Clean
                    </mat-checkbox><br />
                    Adds a filter for `last_success_at` in the last 90 days.
                </mat-expansion-panel>                

                <mat-expansion-panel hideToggle [disabled]="returnType=='counts'">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        Number of past jobs to include
                      </mat-panel-title>
                      <mat-panel-description>
                        {{pastJobs}}
                      </mat-panel-description>
                    </mat-expansion-panel-header>
                    <mat-slider
                        [max]="20"
                        [min]="1"
                        [step]="1"
                        discrete="true"
                        showTickMarks="true"
                        thumbLabel="true"
                        [(ngModel)]="pastJobs">
                    </mat-slider>
                </mat-expansion-panel>

                <mat-expansion-panel hideToggle [disabled]="returnType=='counts'">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        Fields to return
                      </mat-panel-title>
                      <mat-panel-description>
                        {{getReturnFieldsCount()}} selected
                      </mat-panel-description>
                    </mat-expansion-panel-header>
                    <button mat-raised-button (click)="showSelectorModalMulti()">Select fields</button>
                </mat-expansion-panel>

                <mat-expansion-panel hideToggle [disabled]="returnType=='counts'">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        Filters
                      </mat-panel-title>
                      <mat-panel-description>
                        {{getFilterPanelDescription()}}
                      </mat-panel-description>
                    </mat-expansion-panel-header>

                    <div *ngFor="let filter of filters; index as i" class="filterPanel">
                        <div class="filterControlRow">
                            <a (click)="removeFilter(i)"><mat-icon>delete</mat-icon></a>
                        </div>
                        <div class="filterRow">
                            <!-- modal trigger -->
                            <mat-form-field (click)="showSelectorModal(event, i)"> 
                                <mat-label>Select field</mat-label>        
                                <input matInput type="text" multiple [(ngModel)]="filter.field">
                            </mat-form-field>
                            <mat-form-field class="smallInput" *ngIf="filter.field.startsWith('jobs.')"> 
                                <mat-label>Job Group</mat-label>        
                                <input matInput type="text" [(ngModel)]="filter.job_group">
                            </mat-form-field>                            
                        </div>                                             
                        <div *ngIf="filter.field" class="filterRow">
                            <mat-form-field class="filterTypeSelector">
                                <mat-label>Type</mat-label>                
                                <mat-select [(value)]="filter.type">
                                    <mat-option *ngFor="let typ of typesAsObject()" [value]="typ">
                                        {{typ}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field  class="filterTypeSelector">
                                <mat-label>Match</mat-label>                
                                <mat-select [(value)]="filter.match_type">
                                    <mat-option *ngFor="let typ of matchTypesAsObject(filter.field)" [value]="typ">
                                        {{typ}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div *ngIf="filter.field && filter.match_type != 'exists'" class="filterRow">
                            <mat-form-field *ngIf="!columnByName(filter.field).type || columnByName(filter.field).type=='string'">
                                <mat-label>Values (comma-separated)</mat-label>
                                <input matInput type="text" multiple [(ngModel)]="filter.value">
                            </mat-form-field>                        
                            <mat-form-field *ngIf="columnByName(filter.field).type=='number'">
                                <mat-label>At least</mat-label>
                                <input matInput type="text" [(ngModel)]="filter.value">
                            </mat-form-field>
                            <mat-form-field *ngIf="columnByName(filter.field).type == 'date'" >
                                <mat-label>Select date range (dbl-click)</mat-label>
                                <input type="text" matInput drops="up" [showDropdowns]="true" autoApply="true" ngxDaterangepickerMd class="form-control" [(ngModel)]="filter.value"/>
                            </mat-form-field>                       
                            <mat-form-field *ngIf="columnByName(filter.field).type == 'joblevel'" appearance="fill">
                                <mat-label>Select values</mat-label>                
                                <mat-select [(value)]="filter.value" multiple>
                                    <mat-option *ngFor="let lvl of levelsAsObject()" [value]="lvl">
                                        {{lvl}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>            
                            <mat-form-field *ngIf="columnByName(filter.field).type == 'jobfunction'" appearance="fill">
                                <mat-label>Select values</mat-label>                
                                <mat-select [(value)]="filter.value" multiple>
                                    <mat-option *ngFor="let fnct of functionsAsObject()" [value]="fnct">
                                        {{fnct}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field *ngIf="columnByName(filter.field).type == 'boolean'" appearance="fill">
                                <mat-label>Yes/No</mat-label>                
                                <mat-select [(value)]="filter.value">
                                    <mat-option [value]="true">Yes</mat-option>
                                    <mat-option [value]="false">No</mat-option>
                                </mat-select>
                            </mat-form-field>                            
                        </div>
                    </div>
                    <button mat-raised-button primary (click)="addFilter()">Add filter</button>
                </mat-expansion-panel>     
                
                <mat-expansion-panel hideToggle [disabled]="returnType=='counts'">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        Match file
                      </mat-panel-title>
                      <mat-panel-description>
                        {{files ? files[0].name : 'none'}}
                      </mat-panel-description>
                    </mat-expansion-panel-header>
                    <input #fileInput id="file-input" type="file" (change)="uploadFiles($event.target.files)">
                    
                </mat-expansion-panel>

                <!-- <div *ngIf="returnType=='counts'" class="countList">
                    <mat-form-field appearance="fill" *ngFor="let cnt of countsList; index as i">
                        <mat-select [(value)]="countsList[i]">
                            <mat-label>Select field</mat-label>                
                            <mat-option *ngFor="let col of columns" [value]="col.name">
                                {{col.displayName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <button mat-raised-button primary (click)="addCount()">Add field</button>
                </div> -->

            </mat-card-content>
        </mat-card>
    </div>
    <div class="rightColumn">
        <mat-card>
            <mat-card-content>
                <button [class.spinner]="searching" [disabled]="searching" mat-raised-button color="primary" (click)="performSearch()">Search</button>                
            </mat-card-content>
        </mat-card>
        <mat-tab-group>
            <mat-tab label="Results">
                <mat-card>
                    <mat-card-title>
                        <div *ngIf="results" class="resultsCount">
                            Showing {{results.hits.hits.length}} of {{count}}
                        </div>
                    </mat-card-title>
                    <mat-card-content>
                        <div *ngIf="results">
                            <button [class.spinner]="downloading" [disabled]="downloading" mat-raised-button color="primary" (click)="downloadData()">Download all results as CSV</button>
                            <ngx-json-viewer [json]="results.hits.hits" [depth]="2"></ngx-json-viewer>
                        </div>
                    </mat-card-content>
                </mat-card>                 
            </mat-tab>
            <mat-tab label="Queries">
                <mat-card>
                    <mat-card-content>
                        <mat-form-field>
                            <mat-label>Query</mat-label>                
                            <mat-select [(value)]="selectedQuery">
                                <mat-option *ngFor="let query of queries; index as i" [value]="query">
                                    Query {{i}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <button class='copyButton' ngxClipboard cbContent="{{toJson(selectedQuery)}}"><mat-icon>content_copy</mat-icon></button>
                        <ngx-json-viewer [json]="selectedQuery" [depth]="2"></ngx-json-viewer>
                    </mat-card-content>
                </mat-card>
            </mat-tab>
          </mat-tab-group>     
    </div>
</div>