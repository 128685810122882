<h2 mat-dialog-title>Add Employee</h2>
<mat-dialog-content [formGroup]="form">
    <mat-form-field>
        <input matInput placeholder="Email Address" formControlName="employeeEmail">
    </mat-form-field>         
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button (click)="close()">Cancel</button>
    <button mat-raised-button color="primary" [disabled]="!form.valid" (click)="save()">Create</button>
</mat-dialog-actions>