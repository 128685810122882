<h1 mat-dialog-title>
    {{data.title}}
</h1>

<div mat-dialog-content>
    <p>{{data.message}}</p>
</div>

<div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="false">No</button>
    <button mat-raised-button color="warn" [mat-dialog-close]="true" cdkFocusInitial>Yes</button>
</div>