<div class="actionContainer">
  <button mat-raised-button color="primary" (click)="showCreateDialog()">Create Org</button>
  <button
    [class.spinner]="refreshing"
    [disabled]="refreshing"
    mat-raised-button
    (click)="updateOrgs()">
    Refresh
  </button>
  <span class="ledgerSize">{{rowData.length | number}} total Orgs</span>
</div>
<div class="container" width="100%">
  <ag-grid-angular
    style="height: 100%;"
    class="ag-theme-balham"
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    accentedSort="true"
    [components]="components"
    [defaultColDef]="defaultColDef"
    (rowClicked)="onRowClicked($event)"
    enableCellTextSelection="true"
    ensureDomOrder="true"
    (firstDataRendered)="onFirstDataRendered($event)"
    (gridReady)="onGridReady($event)">
  </ag-grid-angular>
</div>
