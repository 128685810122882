<div class="app-settings-billing-container">

    <div class="stepper-container" *ngIf="sliderProducts && !subscription">
      <mat-card class="mat-elevation-z4 slider-card">
        <mat-card-header>
            <mat-card-title>Select your plan</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="slider-header">Number of contacts in your CRM:</div>
          <ngx-slider [options]="sliderOptions" [(value)]="sliderSelected"></ngx-slider>
          <div class="stepper-price" *ngIf="sliderSelected > 0 && sliderSelected < sliderOptions.ceil">{{(sliderProducts[sliderSelected].price.year.amount | currency:'USD':'symbol':'1.0-0')}}</div>
          <div class="stepper-price" *ngIf="sliderSelected == 0">Free</div>
          <div class="stepper-price" *ngIf="sliderSelected == sliderOptions.ceil">Contact Us</div>
          <button *ngIf="sliderSelected != sliderOptions.ceil" [disabled]="sliderSelected == 0" class="signup-button" mat-raised-button color="accent" (click)="selectPlan()">
            {{subscription ? 'Change Plan' : 'Choose Plan'}}
          </button>
          <button *ngIf="sliderSelected == sliderOptions.ceil"  class="signup-button" mat-raised-button color="accent" (click)="openIntercom()">
            Contact Us
          </button>          
        </mat-card-content>
      </mat-card>      
    </div>    
    <div *ngIf="!subscription && !loading" class="features" style="margin: 0 auto">
      <strong style="font-size: x-large;">All Plans Include:</strong>
      <ul class="feature-list">
        <li>One (1) year subscription to all job changes for all contacts</li>
        <li>Integrations with supported CRMs (Hubspot, Salesforce)</li>
        <li>Real-time notifications via Slack or Webhook</li>
        <li>Unlimited users</li>
        <li>Full API Access</li>
        <li>No overage charges</li>
        <li>LinkedIn URL append</li>
        <li>Emails for new job changes</li>
        <li>Firmographics for new job changes</li>
      </ul>
    </div>
    <div class="subscription-container" *ngIf="subscription">
      <mat-card class="mat-elevation-z4 slider-card">
        <mat-card-header>
            <mat-card-title>Your Current Subscription</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="subscription-line"><strong>Contact Limit:</strong> {{this.subscription.product.metadata.contactLimit | number:'1.0-0'}}</div>
          <div class="subscription-line"><strong>Term:</strong> {{this.subscription.product.price.term}}</div>
          <div class="subscription-line"><strong>Price:</strong> {{this.subscription.product.price.unitAmount / 100 | currency:'USD':'symbol':'1.0-0'}}</div>
          <div class="subscription-line"><strong>Status:</strong> {{this.subscription.status}}</div>
        </mat-card-content>
        <mat-card-actions>
          <button class="signup-button" mat-raised-button color="primary" (click)="selectPlan()">
            {{'Update/Cancel Plan'}}
          </button>       
        </mat-card-actions>           
      </mat-card>        
    </div>
    <div class="loading-container" *ngIf="loading">
        <div class="loading">
            <p *ngIf="!fromPurchase" class="loading-message">Loading...</p>
            <p *ngIf="fromPurchase" class="loading-message">Updating subscription details...</p>
        </div>
    </div>
</div>
