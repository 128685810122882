<h1 class="content-title">Live Data People</h1>
<div class="content tw-flex-col">
  <nav mat-tab-nav-bar>
    <a mat-tab-link routerLinkActive="active-tab-nav" routerLink="./details" class="tw-text-base">
      Search
    </a>
    <a
      *ngIf="isAdmin | async"
      mat-tab-link
      routerLinkActive="active-tab-nav"
      routerLink="./filters"
      class="tw-text-base">
      Explore
    </a>
    <a
      *ngIf="isAdmin | async"
      mat-tab-link
      routerLinkActive="active-tab-nav"
      routerLink="./find"
      class="tw-text-base">
      Find
    </a>
  </nav>
  <router-outlet></router-outlet>
</div>
