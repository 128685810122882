import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-create-ledger-dialog',
  templateUrl: './create-ledger-dialog.component.html',
  styleUrls: ['./create-ledger-dialog.component.scss']
})
export class CreateLedgerDialogComponent implements OnInit {

  form: UntypedFormGroup;
  name: string;
  description: string;

  constructor(
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<CreateLedgerDialogComponent>
  ) { 
  }

  ngOnInit() {
    this.form = this.fb.group({
      name: [this.name, [Validators.required]],
      description: [this.description, []]
    })
  }

  save() {
    this.dialogRef.close(this.form.value);
  }

  close() {
    this.dialogRef.close();
  }

}
