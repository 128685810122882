<div class="actionContainer">
    <button mat-raised-button color="warn" [disabled]="!rowsSelected || !canEdit" (click)="deleteSelectedImport()">Delete Contacts From Import</button>
    <button mat-raised-button [class.spinner]="refreshing" [disabled]="refreshing" (click)="refreshData()">Refresh</button>
</div>
<div class="container" width="100%">
    <ag-grid-angular
    style="height: 100%;"
    class="ag-theme-alpine"
    [columnDefs]="columnDefs"
    [rowModelType]="rowModelType"
    [paginationPageSize]="paginationPageSize"    
    [infiniteInitialRowCount]="infiniteInitialRowCount"  
    [components]="components"
    [defaultColDef]="defaultColDef"
    [isRowSelectable]="isRowSelectable"
    [tooltipShowDelay]="tooltipShowDelay"
    (gridReady)="onGridReady($event)"
    (selectionChanged)='onSelectionChanged($event)'
    enableCellTextSelection="true" ensureDomOrder="true"
>
</ag-grid-angular>
</div>