<mat-chip-list #chipList>
  <mat-chip
    (click)="searchId(person.ref)"
    *ngFor="let person of personList"
    (removed)="removeFromPersonList(person.ref)">
    {{person.name || person.ref}}
    <button matChipRemove>
      <mat-icon>cancel</mat-icon>
    </button>
  </mat-chip>
</mat-chip-list>
<div class="actionContainer">
  <mat-form-field class="example-full-width" appearance="fill">
    <mat-label>Person or Contact ID</mat-label>
    <input matInput placeholder="Person or Contact ID" [(ngModel)]="personRef" />
  </mat-form-field>
  <button mat-raised-button [disabled]="!personRef" (click)="searchId(personRef)">
    Get Clean History
  </button>
  <button
    mat-raised-button
    color="warn"
    [disabled]="personList.length==0"
    (click)="clearPersonList()">
    Clear Person List
  </button>
  <button mat-raised-button (click)="gotoDWDetails()">View in DW Inspect</button>
  <button mat-raised-button (click)="cleanNow()">Clean (Serp) Now</button>
</div>
<div *ngIf="rowData.length>0; else elseBlock" class="container" width="100%">
  <div *ngIf="personDetail">
    <hr />
    <div class="section-header">Person Details</div>
    <div class="person-details">
      <div class="detailsContainer">
        <div class=".content-description">
          <strong>Name:</strong> {{personDetail.name}}
          <button ngxClipboard cbContent="{{personDetail.name}}">
            <mat-icon>content_copy</mat-icon>
          </button>
        </div>
        <div class=".content-description">
          <strong>Person ID:</strong> {{personId}}
          <button ngxClipboard cbContent="{{personId}}"><mat-icon>content_copy</mat-icon></button>
        </div>
        <div class=".content-description">
          <strong>LinkedIn:</strong>
          <a
            href="https://www.linkedin.com/in/{{personDetail.linkedin}}"
            target="_blank"
            >{{personDetail.linkedin}}</a
          >
          <button ngxClipboard cbContent="{{personDetail.linkedin}}">
            <mat-icon>content_copy</mat-icon>
          </button>
        </div>
        <div class=".content-description"><strong>Active?:</strong> {{personDetail.isActive}}</div>
        <div class=".content-description" [matTooltip]="queueClassMapping[personDetail.queueClass]">
          <strong>Queue Class:</strong> {{personDetail.queueClass}}
        </div>
      </div>
      <div class="detailsContainer">
        <div class=".content-description">
          <strong>Company:</strong> {{personDetail.company}}
          <button ngxClipboard cbContent="{{personDetail.company}}">
            <mat-icon>content_copy</mat-icon>
          </button>
        </div>
        <div class=".content-description"><strong>Title:</strong> {{personDetail.title}}</div>
        <div class=".content-description">
          <strong>Job Started:</strong> {{personDetail.jobStartedAt}}
        </div>
        <div class=".content-description">
          <strong>Email:</strong> {{personDetail.contactInfo.email}}
          <button ngxClipboard cbContent="{{personDetail.contactInfo.email}}">
            <mat-icon>content_copy</mat-icon>
          </button>
        </div>
        <div class=".content-description">
          <strong>Email Status:</strong> {{personDetail.contactInfo.emailStatus}}
        </div>
        <div class=".content-description" [class.error-message]="personDetail.hasMultipleJobs">
          <strong>Mult. Jobs?:</strong> {{personDetail.hasMultipleJobs}}
        </div>
      </div>
      <div class="detailsContainer">
        <div class=".content-description">
          <strong>Domain:</strong> {{personDetail.companyInfo.domain}}
          <button ngxClipboard cbContent="{{personDetail.companyInfo.domain}}">
            <mat-icon>content_copy</mat-icon>
          </button>
        </div>
        <div class=".content-description">
          <strong>Size:</strong> {{personDetail.companyInfo.size}}
        </div>
        <div class=".content-description">
          <strong>Industry:</strong> {{personDetail.companyInfo.industry}}
        </div>
        <div class=".content-description">
          <strong>Location:</strong> {{personDetail.companyInfo.location}}
        </div>
      </div>
      <div class="detailsContainer">
        <div class=".content-description">
          <strong>Inserted:</strong>
          {{personDetail.dateInserted}} ({{personDetail.dateInserted | timeago:live}})
        </div>
        <div class=".content-description">
          <strong>Updated:</strong>
          {{personDetail.dateUpdated}} ({{personDetail.dateUpdated | timeago:live}})
        </div>
        <div class=".content-description">
          <strong>Changed:</strong>
          <span *ngIf="personDetail.dateChanged">
            {{personDetail.dateChanged}} ({{personDetail.dateChanged | timeago:live}})</span
          >
          <span *ngIf="!personDetail.dateChanged"> N/A </span>
        </div>
      </div>
    </div>
    <hr />
    <div class="section-header">In Ledgers</div>
    <div>
      <mat-chip-list #chipList>
        <mat-chip *ngFor="let ledger of personDetail.ledgers">
          {{ledger.orgName}} - {{ledger.ledgerName}}
        </mat-chip>
      </mat-chip-list>
    </div>
  </div>
  <hr />
  <div class="section-header">Clean Job History</div>
  <ag-grid-angular
    style="height: 400px;"
    class="ag-theme-alpine"
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    rowSelection="single"
    enableCellTextSelection="true"
    ensureDomOrder="true"
    [components]="components"
    [defaultColDef]="defaultColDef"
    (rowClicked)="onRowClicked($event)"
    enableCellTextSelection="true"
    ensureDomOrder="true"
    (gridReady)="onGridReady($event)">
  </ag-grid-angular>
  <hr />
  <div *ngIf="!cjiDetail">
    <div class="section-header">Clean Job Details</div>
    <div *ngIf="cjiDetailError; else jobDetailsHint" class="error-message">
      Problem retrieving details for the selected job. It's probably too old.
    </div>
    <ng-template #jobDetailsHint> Select an item above to see the job details </ng-template>
  </div>

  <div *ngIf="cjiDetail" class="person-details">
    <div>
      <div class="section-header">Clean Job Details</div>
      <div class=".content-description"><strong>ID:</strong> {{cjiDetail.cleanJob.id}}</div>
      <div class=".content-description"><strong>Source:</strong> {{cjiDetail.cleanJob.source}}</div>
      <div class=".content-description">
        <strong>Process Used:</strong> {{cjiDetail.cleanJob.processUsed}}
      </div>
      <div class=".content-description"><strong>Status:</strong> {{cjiDetail.cleanJob.status}}</div>
      <div class=".content-description">
        <strong>Started At:</strong> {{cjiDetail.cleanJob.startedAt}}
      </div>
      <div class=".content-description">
        <strong>Finished At:</strong> {{cjiDetail.cleanJob.finishedAt}}
      </div>
    </div>
    <div>
      <div class="section-header">Final Job Result</div>
      <div class=".content-description">
        <strong>Processed By:</strong> {{cjiDetail.finalResult.processedBy}}
      </div>
      <div class=".content-description">
        <strong>Result:</strong> {{cjiDetail.finalResult.result}}
      </div>
      <div class=".content-description">
        <strong>Reference ID:</strong> {{cjiDetail.finalResult.referenceId}}
      </div>
      <div class=".content-description">
        <strong>Status:</strong> {{cjiDetail.finalResult.status}}
      </div>
      <div class=".content-description">
        <strong>Company:</strong> {{cjiDetail.finalResult.company}}
      </div>
      <div class=".content-description">
        <strong>Title:</strong> {{cjiDetail.finalResult.title}}
      </div>
      <div class=".content-description">
        <strong>LinkedIn:</strong>
        <a
          href="https://LinkedIn.com/in/{{cjiDetail.finalResult.linkedin}}"
          target="_blank"
          >{{cjiDetail.finalResult.linkedin}}</a
        >
      </div>
      <div class=".content-description">
        <strong>Company LinkedIn:</strong>
        <a
          href="https://www.linkedin.com/company/{{cjiDetail.finalResult.orgLipid}}"
          target="_blank"
          >{{cjiDetail.finalResult.orgLipid}}</a
        >
      </div>
      <div class=".content-description">
        <strong>Domain:</strong> {{cjiDetail.finalResult.domain}}
      </div>
      <div class=".content-description">
        <strong>Email:</strong> {{cjiDetail.finalResult.email}}
      </div>
      <div class=".content-description">
        <strong>Start Date:</strong> {{cjiDetail.finalResult.jobStartedAt}}
      </div>
    </div>
    <div *ngIf="selectedCji.type === 'standard' || selectedCji.type === 'bonus'">
      <div class="section-header">Item Inputs</div>
      <div class=".content-description"><strong>Name:</strong> {{cjiDetail.inputs.name}}</div>
      <div class=".content-description"><strong>Company:</strong> {{cjiDetail.inputs.company}}</div>
      <div class=".content-description"><strong>Title:</strong> {{cjiDetail.inputs.title}}</div>
      <div class=".content-description">
        <strong>LinkedIn:</strong>
        <ng-container
          *ngIf="cjiDetail.inputs.linkedin && cjiDetail.inputs.linkedin.indexOf('http') == 0"
          ><a
            href="{{cjiDetail.inputs.linkedin}}"
            target="_blank"
            >{{cjiDetail.inputs.linkedin}}</a
          ></ng-container
        >
        <ng-container
          *ngIf="cjiDetail.inputs.linkedin && cjiDetail.inputs.linkedin.indexOf('http') != 0"
          ><a
            href="https://www.linkedin.com/in/{{cjiDetail.inputs.linkedin}}"
            target="_blank"
            >{{cjiDetail.inputs.linkedin}}</a
          ></ng-container
        >
      </div>
      <div class=".content-description"><strong>Domain:</strong> {{cjiDetail.inputs.domain}}</div>
      <div class=".content-description"><strong>Email:</strong> {{cjiDetail.inputs.email}}</div>
    </div>
    <div *ngIf="selectedCji.type === 'query'">
      <div class="section-header">Input Query</div>
      <div class=".content-description">
        <code>{{cjiDetail.inputs.query}}</code>
      </div>
    </div>
    <div>
      <div class="section-header">Try it Yourself</div>
      <div>
        <a href="https://www.google.com/search?q={{generateQueryString()}}" target="_blank"
          >Google</a
        >
      </div>
      <div>
        <a href="https://www.bing.com/search?q={{generateQueryString()}}" target="_blank">Bing</a>
      </div>
    </div>
  </div>
  <div *ngIf="cjiDetail">
    <div class="section-header">Clean Steps</div>
    <ag-grid-angular
      style="height: 200px;"
      class="ag-theme-alpine"
      [rowData]="cjiSteps"
      [columnDefs]="columnDefs2"
      [components]="components"
      [defaultColDef]="defaultColDef"
      (rowClicked)="onStepRowClicked($event)"
      enableCellTextSelection="true"
      ensureDomOrder="true"
      (gridReady)="onGridReady2($event)">
    </ag-grid-angular>
  </div>
  <div *ngIf="snippet">
    <div class="section-header">Selected Step Snippet</div>
    <pre>{{snippet}}</pre>
  </div>
</div>
<ng-template #elseBlock>
  <div *ngIf="refreshing" class="loading-container">
    <div class="loading">
      <mat-spinner class="spinner" diameter="60"></mat-spinner>
      <p class="loading-message">Loading history...</p>
    </div>
  </div>
</ng-template>
