<div *ngIf="!isChangePass" class="change-container">
    <h3 class="title">{{ title }}</h3>
    <label for="email">Name</label>
    <div *ngIf="name">
        <span class="user" id="name">{{ name }}</span>
    </div>
    <label for="email">Email</label>
    <div *ngIf="currentEmail as email">
        <span class="user" id="email">{{ email }}</span>
    </div>
    <div class="controls">
        <button class="control control--bordered" (click)="toggleForm()"
            (keyup.enter)="toggleForm()">{{ borderedBtnTitle }}</button>
    </div>
</div>
<mat-card *ngIf="isChangePass">
    <h3>Reset Password</h3>
    <form [formGroup]="form" class="inputs" id="change" (submit)="onSubmit()">
        <mat-form-field appearance="outline">
            <mat-label>Email address</mat-label>
            <input matInput formControlName="email" placeholder="e.g. eleanor@livedatatechnologies.com" autocomplete="off"
                [(ngModel)]="currentEmail" readonly />
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Current Password</mat-label>
            <input matInput formControlName="password" placeholder="e.g. &bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;" type="password" autocomplete="off">
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>New Password</mat-label>
            <input matInput formControlName="passwordNew"
            (focus)="showValidationTooltip($event);"
            (blur)="isValidating=false;"
            (change)="update($event);"
            placeholder="e.g. &bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;" type="password" autocomplete="off">
            <div class="tooltip-container" *ngIf="isValidating">
                <span class="validation-title">Password requirements: </span>
                <div class="password-validation-container">
                    <div class="validation" *ngFor="let v of pValidation;">
                        <div [ngClass]="{'cancel': !v.func(form), 'check': v.func(form) }">
                        </div>
                        <span class="validation-text" [ngClass]="{'text-cancel': !v.func(form), 'text-success': v.func(form) }">{{v.text}}</span>
                    </div>
                </div>
            </div>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Confirm Password</mat-label>
            <input matInput formControlName="passwordConfirm" placeholder="e.g. &bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;" type="password" autocomplete="off"
                [ngClass]="f.passwordConfirm.value.length > 2 && f.passwordConfirm.errors ? 'input-invalid' : 'input-valid'">
        </mat-form-field>
        <div *ngIf="passwordChangeSuccessful">
            Password Successfully Changed
        </div>
        <div *ngIf="invalidAttempt">
            Invalid Password.
        </div>
        <div class="email-error signup-error" [ngClass]="{hide: !validationError }">
            Please check your password and try again
        </div>
        <mat-error *ngIf="checkPasswords(form)">
            Passwords do not match
        </mat-error>
        <button class="rounded-login" [ngClass]="{ disabled: !form.valid }">
            Reset Password
        </button>
    </form>
    <div class='password'>
        <a (click)="forgotPassword()" (keyup.enter)="forgotPassword()">Forgot password?</a>
    </div>
</mat-card>