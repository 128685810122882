<div class="container">
    <div class="content-title">Staging Search-Sync Firehose</div>
    <div class="descriptionText">Items will appear at the top as they come in. Expect periods of long silence...</div>
    <div style="flex-grow: 1;">
        <ag-grid-angular
        style="width: 100%; height: 100%;"
        class="ag-theme-alpine"
        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        [rowData]="eventsToShow"
        ></ag-grid-angular>
    </div>
</div>