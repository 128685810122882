import { Component, Inject, OnInit } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { ColumnCategory, DWColumn } from 'src/app/data-warehouse/dw-column';

interface DisplayData {
  default: DWColumn[];
  personal: DWColumn[];
  metadata: DWColumn[];
  'current job': DWColumn[];
  'past jobs': DWColumn[];
  education: DWColumn[];
}

interface DialogData {
  categories: ColumnCategory[];
  fields: DWColumn[];
  multi?: boolean;
  returnFields?: ReturnFields;
}

interface ReturnFields {
  [key: string]: boolean;
}

@Component({
  selector: 'app-field-selector-dialog',
  templateUrl: './field-selector-dialog.component.html',
  styleUrls: ['./field-selector-dialog.component.scss'],
})
export class FieldSelectorDialogComponent implements OnInit {
  categories: ColumnCategory[];
  fields: DWColumn[];
  multi: boolean = false;
  returnFields: ReturnFields = {};
  filterText: string = '';
  showDBName: boolean = false;
  classicView: boolean = false;
  popularFilters: DWColumn[] = [];
  displayData: DisplayData = {
    default: [],
    personal: [],
    metadata: [],
    'current job': [],
    'past jobs': [],
    education: [],
  };

  constructor(
    public dialogRef: MatDialogRef<FieldSelectorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.categories = data.categories;
    this.fields = data.fields;
    if (data.multi) {
      this.multi = data.multi;
    }
    if (data.returnFields) {
      this.returnFields = { ...data.returnFields };
    }
    this.popularFilters = this.fields.filter((f) => f.isPopular);
    this.loadViewPrefs();
  }

  ngOnInit(): void {
    this.formatData();
  }

  formatData() {
    const grouped = this.fields.reduce(
      (map, f) => ({
        ...map,
        [f.group || 'default']: [...(map[f.group || 'default'] ?? []), f],
      }),
      {} as any
    );

    this.displayData = grouped;
  }

  // count filtered items
  getFilteredTotal() {
    let total = 0;
    if (this.filterText) {
      total += this.filterItems(this.displayData.default, this.filterText).length;
      total += this.filterItems(this.displayData.personal, this.filterText).length;
      total += this.filterItems(this.displayData.metadata, this.filterText).length;
      total += this.filterItems(this.displayData['current job'], this.filterText).length;
      total += this.filterItems(this.displayData['past jobs'], this.filterText).length;
      total += this.filterItems(this.displayData['education'], this.filterText).length;
    }
    return total;
  }

  getCheckedCount(group?: string) {
    let count = 0;
    this.fields.forEach((f) => {
      if ((!group || f.group === group) && this.returnFields[f.name]) {
        count++;
      }
    });
    return count;
  }

  getTotalCount(): number {
    return (
      (this.displayData.default?.length || 0) +
      (this.displayData.personal?.length || 0) +
      (this.displayData.metadata?.length || 0) +
      (this.displayData['current job']?.length || 0) +
      (this.displayData['past jobs']?.length || 0) +
      (this.displayData.education?.length || 0)
    );
  }

  // helper for filtering items
  private filterItems(items: DWColumn[], filterText: string): DWColumn[] {
    if (!items) return [];
    return items.filter((item) => item.name.includes(filterText));
  }

  selectField(name: string) {
    this.saveViewPrefs();
    this.dialogRef.close(name);
  }

  toggleField(fieldName: string) {
    this.returnFields[fieldName] = !this.returnFields[fieldName];
  }

  saveSelections() {
    this.saveViewPrefs();
    this.dialogRef.close(this.returnFields);
  }

  saveViewPrefs() {
    localStorage.setItem(
      'dwFieldSelectorPrefs',
      JSON.stringify({ classicView: this.classicView, showDBName: this.showDBName })
    );
  }

  loadViewPrefs() {
    const viewPrefs = JSON.parse(localStorage.getItem('dwFieldSelectorPrefs') || '{}');
    this.classicView = viewPrefs.classicView || false;
    this.showDBName = viewPrefs.showDBName || false;
  }

  cancel() {
    this.dialogRef.close();
  }

  selectAll(group: string) {
    this.fields.forEach((f) => {
      if (f.group === group) {
        this.returnFields[f.name] = true;
      }
    });
  }

  deselectAll(group: string) {
    this.fields.forEach((f) => {
      if (f.group === group) {
        this.returnFields[f.name] = false;
      }
    });
  }

  clearFilter(): void {
    this.filterText = '';
  }

  getCategoriesForColumn(columnNumber: number): ColumnCategory[] {
    return this.categories.filter((c) => c.displayColumn === columnNumber);
  }

  getAllCategoryColumns(): number[] {
    return Array.from(new Set(this.categories.map((c) => c.displayColumn)));
  }
}
