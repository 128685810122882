import { Component, ViewChildren, QueryList } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { PersonSearchFilter, SearchPersonsDataRequest, SearchService } from 'ldt-people-api';
import { AuthService } from 'src/app/auth/service/auth.service';
import { ColumnCategory, ColumnType, DWColumn } from 'src/app/data-warehouse/dw-column';
import { FieldSelectorDialogComponent } from 'src/app/shared/field-selector-dialog/field-selector-dialog.component';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';
import { PeopleColumns } from '../people-columns';
import { SearchFilter } from 'ldt-dw-reader-service-api';
import { SimpleFilterComponent } from './simple-filter/simple-filter.component';
import { CategoryFilterComponent } from './category-filter/category-filter.component';

import * as FileSaver from 'file-saver';
import * as dayjs from 'dayjs';

enum MatchType {
  Fuzzy = 'fuzzy',
  Exact = 'exact',
  Exists = 'exists',
}

export interface UIFilter {
  field: DWColumn;
  filter: PersonSearchFilter;
}

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent {
  @ViewChildren(SimpleFilterComponent) simpleFilterComponents: QueryList<SimpleFilterComponent>;
  @ViewChildren(CategoryFilterComponent)
  categoryFilterComponents: QueryList<CategoryFilterComponent>;

  orgId: string;
  showFilters: boolean = true;
  refreshing: boolean = false;
  countUpdating: boolean = false;
  searchCount: number = 0;
  noActiveFilters: boolean = true;
  downloading: boolean = false;

  uiFilters: UIFilter[] = [];
  PAST_JOBS = 'past jobs';
  CURRENT_JOB = 'current job';
  columns: DWColumn[] = PeopleColumns;
  categories: ColumnCategory[] = [
    {
      name: 'personal',
      displayName: 'Personal',
      description: 'Personal information',
      displayColumn: 1,
    },
    {
      name: 'current job',
      displayName: 'Current Job',
      description: 'Information about the current job',
      displayColumn: 2,
    },
    {
      name: 'past jobs',
      displayName: 'Past Jobs',
      description: 'Information about past jobs',
      displayColumn: 3,
    },
    {
      name: 'education',
      displayName: 'Education',
      description: 'Information about education',
      displayColumn: 1,
    },
    {
      name: 'metadata',
      displayName: 'Timestamps',
      description: 'Timestamps for this record',
      displayColumn: 1,
    },
  ];

  constructor(
    private dialog: MatDialog,
    private notify: NotificationService,
    private peopleService: SearchService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService
  ) {}

  ngOnInit() {
    let orgId = this.route.parent?.snapshot.paramMap.get('orgId');
    if (!orgId) {
      orgId = this.authService.getSelectedOrgIdValue;
      if (!orgId) {
        this.notify.error('Invalid path');
        this.router.navigateByUrl('/main');
      }
    }
    this.orgId = orgId;
  }

  ngAfterViewInit() {
    this.categoryFilterComponents.changes.subscribe(() => {
      this.toggleCategoryFilterClass();
    });

    // Initial check
    this.toggleCategoryFilterClass();
  }

  /**
   * Helper for showSelectorModal: Handles data returned from the field selector dialog, updates all filter arrays
   * @param filterName - filterName to be handled, e.g. 'position.company.name'.
   * @param mainBtn - A boolean indicating whether the main button is clicked.
   * @param groupIndex - (Optional) The index of the group.
   */
  handleData(filterName: string, mainBtn: boolean, groupIndex?: number) {
    const foundFilter = this.columns.find((f) => f.name === filterName);

    if (foundFilter) {
      let newFilter: UIFilter = {
        field: foundFilter,
        filter: {
          field: foundFilter.name,
          type: SearchFilter.TypeEnum.Must,
          match_type: this.matchTypesAsObject(foundFilter.name)[0],
        },
      };

      if (foundFilter.group === this.PAST_JOBS) {
        let groupId: number;

        if (mainBtn) {
          groupId = this.getAllJobGroups().length + 1; // Determine a new group id
          newFilter.filter.job_group = groupId;
        } else if (groupIndex !== undefined) {
          groupId = groupIndex; // Use the provided group index
          newFilter.filter.job_group = groupId;
        }
      }

      this.uiFilters.push(newFilter);
      this.noActiveFilters = false;

      // bring focus to the input field
      this.focusNewFilterByName(foundFilter.name);
    } else {
      console.error(`Filter ${filterName} does not exist.`);
    }
  }

  focusNewFilterByName(filterName: string) {
    setTimeout(() => {
      // Try to find the filter in simple filters
      const matchingSimpleFilterComponent = this.simpleFilterComponents.find(
        (filterComponent) => filterComponent.filter.field.name === filterName
      );
      if (matchingSimpleFilterComponent && matchingSimpleFilterComponent.firstInputElem) {
        matchingSimpleFilterComponent.firstInputElem.nativeElement.focus();
        return;
      }

      // If not found in simple filters, try to find in category filters
      this.categoryFilterComponents.forEach((categoryFilter) => {
        const matchingCategorySimpleFilterComponent = categoryFilter.simpleFilters.find(
          (filterComponent) => filterComponent.filter.field.name === filterName
        );
        if (
          matchingCategorySimpleFilterComponent &&
          matchingCategorySimpleFilterComponent.firstInputElem
        ) {
          matchingCategorySimpleFilterComponent.firstInputElem.nativeElement.focus();
        }
      });
    }, 0);
  }

  /**
   * Opens a modal dialog to select filter based on the given category, then updates filter arrays to add this filter.
   * @param category - The category for which the selector modal is opened. Null if main button (no category)
   * @param groupIndex - The group id from which the filter is being added.
   */
  showSelectorModal(category: string | null, groupIndex?: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '1200px';
    dialogConfig.height = '80%';

    try {
      dialogConfig.data = this.setDialogConfigData(category, groupIndex);
      const dialogRef = this.dialog.open(FieldSelectorDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe((data) => {
        if (data) {
          this.handleData(data, category === null, groupIndex);
        }
      });
    } catch (err) {
      console.error(err);
      this.notify.error('Error adding filter. Please try again later.');
    }
  }

  /**
   * Removes a filter from the active filters list and updates the corresponding filter arrays.
   * @param filter - The UI filter to be removed.
   */
  removeFilter(filter: UIFilter): void {
    this.uiFilters = this.uiFilters.filter((f) => !this.filtersAreEqual(f, filter));
    this.noActiveFilters = this.uiFilters.length === 0 ? true : false;
  }

  deleteAllFilters() {
    this.uiFilters = [];
    this.noActiveFilters = true;
  }

  removeCategoryFilter(groupOrCategory: string | number): void {
    if (typeof groupOrCategory === 'number') {
      // Remove filters by group index for past jobs
      this.uiFilters = this.uiFilters.filter((f) => f.filter.job_group !== groupOrCategory);
    } else {
      // Remove filters by category name for current job
      this.uiFilters = this.uiFilters.filter((f) => f.field.group !== groupOrCategory);
    }
    this.noActiveFilters = this.uiFilters.length === 0 ? true : false;
  }

  runSearch() {
    this.refreshing = true;
    this.countUpdating = true;

    const req: SearchPersonsDataRequest = {
      filters: this.uiFilters.map((f) => f.filter),
      size: 0,
    };

    this.peopleService.searchPersonsData(this.orgId, req).subscribe({
      next: (data) => {
        this.searchCount = data.count || 0;
        this.refreshing = false;
        this.countUpdating = false;
      },
      error: (err) => {
        console.error('Error searching for people:', err);
        this.notify.error('Error searching for people');
        this.refreshing = false;
        this.countUpdating = false;
      },
    });
  }

  download() {
    if (this.searchCount > 10000) {
      this.notify.error(
        'You can only download up to 10,000 records at a time. Please narrow your search.'
      );
      return;
    }

    this.downloading = true;

    const req: SearchPersonsDataRequest = {
      filters: this.uiFilters.map((f) => f.filter),
      size: 0,
    };

    this.peopleService.downloadPersonsData(this.orgId, req).subscribe({
      next: (response: any) => {
        var data = new Blob([response], { type: 'text/csv' });
        FileSaver.saveAs(data, 'livedata-download-' + dayjs().format('YYYYMMDD-HHmmss') + '.csv');
        this.downloading = false;
      },
      error: (err) => {
        console.error('Error searching for people:', err);
        this.notify.error('Error searching for people');
        this.refreshing = false;
        this.countUpdating = false;
      },
    });
  }

  copyFiltersToClipboard() {
    const filters = this.uiFilters.map((f) => {
      let filter = { ...f.filter };
      if (filter.date_from) {
        filter.date_from = filter.date_from.split('T')[0];
      }
      if (filter.date_to) {
        filter.date_to = filter.date_to.split('T')[0];
      }
      return filter;
    });

    const filtersString = JSON.stringify(filters, null, 2);
    navigator.clipboard.writeText(filtersString).then(
      () => {
        this.notify.success('Filters copied to clipboard');
      },
      () => {
        this.notify.error('Error copying filters to clipboard');
      }
    );
  }

  /**
   * Helper methods
   */

  focusInput(inputField: HTMLInputElement) {
    inputField.focus();
  }

  addCategoryFilter(event: { category: string; groupIndex?: number }) {
    const { category, groupIndex } = event;
    this.showSelectorModal(category, groupIndex);
  }

  getAllJobGroups(): number[] {
    return [
      ...new Set(
        this.uiFilters
          .filter((f) => f.field.group === this.PAST_JOBS)
          .map((f) => f.filter.job_group!)
      ),
    ];
  }

  getFiltersForJobGroup(group: number): UIFilter[] {
    return this.uiFilters.filter((f) => f.filter.job_group === group);
  }

  getPositionFilters(): UIFilter[] {
    return this.uiFilters.filter((f) => f.field.group === this.CURRENT_JOB);
  }

  getSimpleFilters(): UIFilter[] {
    return this.uiFilters.filter(
      (f) => f.field.group !== this.CURRENT_JOB && f.field.group !== this.PAST_JOBS
    );
  }

  isSimpleFilter(group: string): boolean {
    return ['default', 'personal', 'metadata', 'education'].includes(group);
  }

  /**
   * Helper for showSelectorModal: Sets the dialog configuration data based on the provided category.
   * @param category - The category to filter the data by.
   * @returns An object containing the filtered categories and fields.
   */
  setDialogConfigData(category: string | null, groupIndex: number | undefined) {
    // main button is clicked, so show all categories inside the dialog
    if (!category) {
      return { categories: this.categories, fields: this.columns };
    }

    // current job child btn clicked
    if (category === this.CURRENT_JOB) {
      const categories = this.categories.filter((c) => c.name === this.CURRENT_JOB);
      const fieldsToShow = this.columns.filter((f) => f.group === this.CURRENT_JOB);
      return { categories, fields: fieldsToShow };
    }

    // past jobs child btn clicked
    if (category === this.PAST_JOBS) {
      const categories = this.categories.filter((c) => c.name === this.PAST_JOBS);
      const fieldsToShow = this.columns.filter((f) => f.group === this.PAST_JOBS);
      return { categories, fields: fieldsToShow };
    }

    throw new Error('Invalid category');
  }

  /**
   * Retrieves the display name of a category based on its group name.
   * @param groupName - The group name of the category.
   * @returns The display name of the category (string), or undefined if no matching category is found.
   */
  getCategoryDisplayName = (groupName: string) =>
    this.categories.find((category) => category.name === groupName)?.displayName;

  getGroupedFilters(): [string, UIFilter[]][] {
    const groupedFilters = new Map<string, any[]>();

    this.uiFilters.forEach((filter) => {
      const groupName = filter.field.group || '';
      if (!groupedFilters.has(groupName)) {
        groupedFilters.set(groupName, []);
      }
      groupedFilters.get(groupName)?.push(filter);
    });

    // Sort each group alphabetically by the filter's displayName
    groupedFilters.forEach((filtersArray, groupName) => {
      filtersArray.sort((a, b) => a.field.displayName.localeCompare(b.field.displayName));
    });

    return Array.from(groupedFilters.entries());
  }

  loadQuickBuild($event: UIFilter[]) {
    this.uiFilters = $event;
    this.noActiveFilters = this.uiFilters.length === 0 ? true : false;
    this.runSearch();
  }

  /**
   * Returns an array of match types as objects for the specified field.
   * @param field - The field for which to retrieve the match types.
   * @returns An array of match types as objects.
   */
  matchTypesAsObject(field: string): MatchType[] {
    let values = Object.values(MatchType);
    const col = this.columns.find((c) => c.name === field);

    // Date fields don't use `exact` match type
    if (col && col.type === ColumnType.date) {
      values = values.filter((v) => v !== MatchType.Exact);
    }

    // Enum and boolean fields don't use `fuzzy` match type
    if (
      col &&
      (col.type === ColumnType.jobfunction ||
        col.type === ColumnType.joblevel ||
        col.type === ColumnType.boolean)
    ) {
      values = values.filter((v) => v !== MatchType.Fuzzy);
    }

    return values;
  }

  toggleCategoryFilterClass() {
    const filtersExpanded = document.getElementById('filters-expanded');
    if (filtersExpanded) {
      if (this.categoryFilterComponents.length > 0) {
        filtersExpanded.classList.add('category-filter-added');
      } else {
        filtersExpanded.classList.remove('category-filter-added');
      }
    }
  }

  toggleFiltersContent() {
    this.showFilters = !this.showFilters;
  }

  trackByGroupName(index: number, group: [string, UIFilter[]][]): [string, UIFilter[]] {
    return group[0]; // Ensure this uniquely identifies the group
  }

  // to replace simple filter when user clicks on it
  updateFilter(event: { filter: UIFilter }) {
    const filterIndex = this.uiFilters.findIndex((f) => f === event.filter);
    if (filterIndex !== -1) {
      this.uiFilters.splice(filterIndex, 1);
    }
    this.showSelectorModal(null);
  }

  addBadgeToFilter(event: { filter: UIFilter }) {
    const filterIndex = this.uiFilters.findIndex((f) => f === event.filter);
    if (filterIndex !== -1) {
      this.uiFilters[filterIndex] = event.filter;
    }
  }

  removeBadgeFromFilter(event: { filter: UIFilter }) {
    const filterIndex = this.uiFilters.findIndex((f) => f === event.filter);
    if (filterIndex !== -1) {
      this.uiFilters[filterIndex] = event.filter;
    }
  }

  // helper to add appropriate symbol and tooltip to filter chips with the same text value
  // TODO: add more filter types for boolean, number, date, etc.
  // TODO: update filter TS type when operators are fixed
  getFilterSymbol(filter: any): { symbol: string; tooltip: string } {
    const type = filter.filter.type;
    const matchType = filter.filter.match_type;

    // string values filters only for now
    switch (type) {
      case 'must':
        if (matchType === 'fuzzy') {
          return { symbol: '≈', tooltip: 'Matches (fuzzy)' };
        } else if (matchType === 'exists') {
          return { symbol: '✓', tooltip: 'Not blank' };
        } else {
          return { symbol: '=', tooltip: 'Equals' };
        }
      case 'must_not':
        if (matchType === 'fuzzy') {
          return { symbol: '≉', tooltip: 'Does not match (fuzzy)' };
        } else if (matchType === 'exists') {
          return { symbol: '∅', tooltip: 'Blank' };
        } else {
          return { symbol: '≠', tooltip: 'Does not equal' };
        }
      default:
        return { symbol: '', tooltip: '' };
    }
  }

  isFilterNameRepeated(currentFilter: any, groupFilters: any[]): boolean {
    return (
      groupFilters.filter((filter) => filter.field.displayName === currentFilter.field.displayName)
        .length > 1
    );
  }

  filtersAreEqual(filter1: UIFilter, filter2: UIFilter): boolean {
    return (
      filter1.filter.field === filter2.filter.field &&
      filter1.filter.type === filter2.filter.type &&
      filter1.filter.match_type === filter2.filter.match_type &&
      filter1.filter.job_group === filter2.filter.job_group &&
      filter1.filter.string_values === filter2.filter.string_values &&
      filter1.filter.number_min === filter2.filter.number_min &&
      filter1.filter.number_max === filter2.filter.number_max &&
      filter1.filter.date_from === filter2.filter.date_from &&
      filter1.filter.date_to === filter2.filter.date_to
    );
  }
}
