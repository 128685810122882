<div class="custom-preflight tw-w-[300px]">
  <label
    id="listbox-label"
    class="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
    Job Function
  </label>
  <div class="tw-relative">
    <button
      type="button"
      (click)="toggleSelect()"
      class="tw-relative tw-w-full tw-cursor-default tw-rounded-md tw-bg-white tw-py-1.5 tw-pl-3 tw-pr-10 tw-text-left tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
      aria-haspopup="listbox"
      aria-expanded="true"
      aria-labelledby="listbox-label">
      <span class="tw-block tw-truncate">{{ selectedJobFunctionLabel }}</span>
      <mat-icon
        class="tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-2 tw-text-gray-400 tw-w-[19px] tw-h-[36px]"
        style="font-size: 19px;">
        unfold_more
      </mat-icon>
    </button>

    <!-- unordered list of options -->
    <ul
      *ngIf="isSelect"
      class="tw-absolute tw-z-10 tw-mt-1 tw-max-h-[360px] tw-w-full tw-overflow-auto tw-rounded-md tw-bg-white tw-py-1 tw-text-base tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none sm:tw-text-sm tw-list-none tw-p-0"
      tabindex="-1"
      role="listbox"
      aria-labelledby="listbox-label"
      aria-activedescendant="listbox-option-3">
      <!-- hardcoded 'All' option -->
      <li
        (click)="select('all')"
        (mouseover)="setHighlight('all')"
        [ngClass]="{
          'tw-text-white': highlight === 'all',
          'tw-bg-indigo-600': highlight === 'all',
          'tw-text-gray-900': highlight !== 'all'
        }"
        class="tw-text-gray-900 tw-relative tw-cursor-default tw-select-none tw-py-2 tw-pl-3 tw-pr-9"
        id="listbox-option-0"
        role="option">
        <span
          [ngClass]="{
            'tw-font-semibold': selectedJobFunction === undefined,
            'tw-font-normal': selectedJobFunction !== undefined
          }"
          class="tw-font-normal tw-block tw-truncate">
          All
        </span>
        <span
          *ngIf="selectedJobFunction === undefined"
          [ngClass]="{
            'tw-text-white': highlight === 'all',
            'tw-text-indigo-600': highlight !== 'all'
          }"
          class="tw-text-indigo-600 tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-4">
          <mat-icon class="tw-h-5 tw-w-5" style="font-size: 20px;"> check </mat-icon>
        </span>
      </li>

      <!-- dynamic options from data -->
      <li
        *ngFor="let item of availableJobFunctions"
        (click)="select(item)"
        (mouseover)="setHighlight(item)"
        [ngClass]="{
          'tw-text-white': highlight === item,
          'tw-bg-indigo-600': highlight === item,
          'tw-text-gray-900': highlight !== item
        }"
        class="tw-text-gray-900 tw-relative tw-cursor-default tw-select-none tw-py-2 tw-pl-3 tw-pr-9"
        id="listbox-option-0"
        role="option">
        <span
          [ngClass]="{
            'tw-font-semibold': selectedJobFunction === item,
            'tw-font-normal': selectedJobFunction !== item
          }"
          class="tw-font-normal tw-block tw-truncate">
          {{ item }}
        </span>
        <span
          *ngIf="item === selectedJobFunction"
          [ngClass]="{
            'tw-text-white': highlight === item,
            'tw-text-indigo-600': highlight !== item
          }"
          class="tw-text-indigo-600 tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-4">
          <mat-icon class="tw-h-5 tw-w-5" style="font-size: 20px;"> check </mat-icon>
        </span>
      </li>
    </ul>
  </div>
</div>
