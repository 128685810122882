<div *ngIf="orgs.length == 0">
<p>You successfully logged in, but you don't have access to any orgs. If your company is already using Live Data, please ask your administrator to invite you to the account.</p>
<p>If your company is not yet using Live Data and you'd like to learn more, please <a href="mailto:sales@livedatatechnologies.com">contact our sales team</a>.</p>
</div>
<div class="container" width="100%">
    <div class="content-title">
        Your Invitations
    </div>    
    <ag-grid-angular style="height: 500px;" class="ag-theme-alpine" [rowData]="rowData"
        [columnDefs]="columnDefs"
        [components]="components" [rowSelection]="rowSelection" [defaultColDef]="defaultColDef"
        enableCellTextSelection="true" ensureDomOrder="true"
        (gridReady)="onGridReady($event)" (selectionChanged)='onSelectionChanged($event)'>
    </ag-grid-angular>
</div>