import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Invitation, InvitationsService, Org } from 'ldt-identity-service-api';
import { ColDef, GridApi } from 'ag-grid-community';
import { BtnCellRenderer } from 'src/app/shared/ag-grid-button/button-cell-renderer.component';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';

@Component({
  selector: 'app-team-invitations',
  templateUrl: './team-invitations.component.html',
  styleUrls: ['./team-invitations.component.scss'],
  providers: [DatePipe]
})
export class TeamInvitationsComponent implements OnInit {  
  @Input() org: Org;
  @Input() canAdmin: boolean;

  /// AG -GRID --------------
  private simpleFilterParams: any = {
    filterOptions: ['contains'],
    suppressAndOrCondition: true    
  }  
  defaultColDef:ColDef = {
    sortable: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    resizable: true,
    filterParams: this.simpleFilterParams,
    menuTabs: ['generalMenuTab','filterMenuTab']
  }
  private roleFilterParams: any = {
    filterOptions: [
      'empty',
      {
        displayKey: 'viewer',
        displayName: 'Viewer',
        test: function (filterValue:any, cellValue:any) {
          return cellValue === 'viewer';
        },
        hideFilterInput: true,
      },
      {
        displayKey: 'editor',
        displayName: 'Editor',
        test: function (filterValue:any, cellValue:any) {
          return cellValue === 'editor';
        },
        hideFilterInput: true,
      },
      {
        displayKey: 'admin',
        displayName: 'Admin',
        test: function (filterValue:any, cellValue:any) {
          return cellValue === 'admin';
        },
        hideFilterInput: true,
      }
    ],
    suppressAndOrCondition: true,
  } 
  private statusFilterParams: any = {
    filterOptions: [
      'empty',
      {
        displayKey: 'pending',
        displayName: 'Pending',
        test: function (filterValue:any, cellValue:any) {
          return cellValue === 'pending';
        },
        hideFilterInput: true,
      },
      {
        displayKey: 'expired',
        displayName: 'Expired',
        test: function (filterValue:any, cellValue:any) {
          return cellValue === 'expired';
        },
        hideFilterInput: true,
      },
      {
        displayKey: 'revoked',
        displayName: 'Revoked',
        test: function (filterValue:any, cellValue:any) {
          return cellValue === 'revoked';
        },
        hideFilterInput: true,
      },
      {
        displayKey: 'declined',
        displayName: 'Declined',
        test: function (filterValue:any, cellValue:any) {
          return cellValue === 'declined';
        },
        hideFilterInput: true,
      },
      {
        displayKey: 'accepted',
        displayName: 'Accepted',
        test: function (filterValue:any, cellValue:any) {
          return cellValue === 'accepted';
        },
        hideFilterInput: true,
      }
    ],
    suppressAndOrCondition: true,
  }  
  columnDefs:ColDef[] = [
    { field: 'id', headerName: 'ID', cellRenderer: 'loadingRenderer', maxWidth: 150,
      headerTooltip: 'Unique ID of this invitation'      
    },
    { field: 'inviteeEmail',
      headerTooltip: 'Only a person with this email address can accept the invitation'      
    },
    { field: 'status',
      filterParams: this.statusFilterParams,
      headerTooltip: 'The status of the invite, one of: pending, expired, revoked, accepted, declined'      
    },
    { field: 'inviteeRole', maxWidth: 150,
      filterParams: this.roleFilterParams,
      headerTooltip: 'The role associated with this invitation'      
    },
    { field: 'invitedBy',
      headerTooltip: 'Name of the person that created this invitation'      
    },
    { field: 'createdAt', headerName: 'Created (UTC)', sort: 'desc',
      headerTooltip: 'Time the invitation was created',
      valueFormatter: (params: any) => { return this.datePipe.transform(params.value, 'yyyy-MM-dd h:mm a', 'UTC') || ''},            
    },
    { field: 'expiresAt', headerName: 'Expires (UTC)',
      headerTooltip: 'Time the invitation expires',
      valueFormatter: (params: any) => { return this.datePipe.transform(params.value, 'yyyy-MM-dd h:mm a', 'UTC') || ''},
    },
    { headerName: 'Resend',
      filter: false,
      maxWidth: 150,
      sortable: false,
      cellRenderer: BtnCellRenderer, 
      cellRendererParams: {
        onClick: this.resendInvitation.bind(this),
        label: 'Resend'
      },
      headerTooltip: 'Resend a pending or expired invitation to send a new email to the invitee'      
    },
    { headerName: 'Revoke',
      filter: false,
      maxWidth: 150,
      sortable: false,
      cellRenderer: BtnCellRenderer, 
      cellRendererParams: {
        onClick: this.revokeInvitation.bind(this),
        label: 'Revoke',
      },
      headerTooltip: 'Revoke a pending invitation to prevent the user from accepting it'      
    }   
  ];
  rowData: Invitation[];
  rowSelection:string = 'multiple';
  tooltipShowDelay = 200;

  private gridApi: GridApi;
  private orgId:string = '';
  roles:string[] = ["viewer","editor","admin"];
  role:string;

  components = {
    loadingRenderer: function (params:any) {
      if (params.value !== undefined) {
        return params.value;
      } else {
        return "<img src=\"https://www.ag-grid.com/example-assets/loading.gif\">";
      }
    },
  };  
  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  };
  
  refreshing:boolean = true;

  constructor(private invService: InvitationsService, private route: ActivatedRoute, private notify: NotificationService, private datePipe: DatePipe) {
    this.orgId = route.snapshot.paramMap.get('orgId') || '';
  }

  ngOnInit() {
    this.refreshOrgInvitations();
  }

  refreshOrgInvitations() {
    this.refreshing = true;
    this.invService.getInvitations(this.org.id).subscribe(r => {
      this.rowData = r;
      this.refreshing = false;
    }, () => {
      this.notify.error("Oops. There was an error during your request. Please try again later.")
      this.refreshing = false;
      this.rowData = [];
    })
  }

  revokeInvitation(e:any) {
    let inv: Invitation = e.rowData;
    this.invService.deleteInvitation(inv.id, this.org.id).subscribe(r => {
      this.notify.success("Invitation revoked");
      this.refreshOrgInvitations();
    }, () => {
      this.notify.error("Oops. There was an error during your request. Please try again later.")
    })
  }

  resendInvitation(e:any) {
    let inv: Invitation = e.rowData;

    this.invService.resendInvitation(inv.id, this.org.id).subscribe(r => {
      this.notify.success("Invitation resent and extended");
      this.refreshOrgInvitations();
    }, () => {
      this.notify.error("Oops. There was an error during your request. Please try again later.")
    })
  }

  rowsSelected:boolean = false;
  onSelectionChanged(event: any){
    var selectedRows =  this.gridApi.getSelectedRows();
    this.rowsSelected = selectedRows.length !== 0;
  }
 
}