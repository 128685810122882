<mat-card class="searchCard">
    <mat-card-content>
        <div class="searchFields">
            <mat-form-field>
                <mat-label>Role/Expertise</mat-label>
                <input matInput placeholder="demand generation" [(ngModel)]="role" (keydown.enter)="getResults()">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Company</mat-label>
                <input matInput placeholder="procore" [(ngModel)]="company" (keydown.enter)="getResults()">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Industry</mat-label>
                <input matInput placeholder="insurance" [(ngModel)]="industry" (keydown.enter)="getResults()">
            </mat-form-field>
            <mat-form-field class="tags">
                <mat-label>Tags</mat-label>
                <input matInput placeholder="(fintech OR strategist) AND GTM" [(ngModel)]="tags"
                    (keydown.enter)="getResults()">
            </mat-form-field>
            <div>
                <button mat-raised-button color="primary" (click)="getResults()">Get Results</button>
            </div>
            <div>
                <button mat-raised-button color="secondary" (click)="downloadResults()">Download</button>
            </div>
        </div>
    </mat-card-content>
</mat-card>
<div *ngIf="loading">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>
<mat-card *ngIf="(dataSource && (dataSource.data.length > 0))">
    <mat-card-title>Results</mat-card-title>
    <mat-card-content>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
            <ng-container matColumnDef="checked">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    <mat-checkbox (change)="toggleCheckAll($event)">
                    </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let element">
                    <mat-checkbox [(ngModel)]="element.checked">
                    </mat-checkbox>
                </td>
            </ng-container>
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                <td mat-cell *matCellDef="let element">{{element.name}}</td>
            </ng-container>
            <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Title </th>
                <td mat-cell *matCellDef="let element"> {{element.title}} </td>
            </ng-container>
            <ng-container matColumnDef="company">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Company </th>
                <td mat-cell *matCellDef="let element"> {{element.company}} </td>
            </ng-container>
            <ng-container matColumnDef="tenure_avg">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Avg Tenure </th>
                <td mat-cell *matCellDef="let element"> {{element.tenure_avg | number: '1.0-0'}} </td>
            </ng-container>
            <ng-container matColumnDef="tenure_current">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Current Tenure </th>
                <td mat-cell *matCellDef="let element"> {{element.tenure_current | number: '1.0-0'}} </td>
            </ng-container>
            <ng-container matColumnDef="linkedIn">
                <th mat-header-cell *matHeaderCellDef> LinkedIn </th>
                <td mat-cell *matCellDef="let element">
                    <a href="https://linkedin.com/in/{{element.lipid}}" target="_blank">
                        <img src="assets/img/linkedin.png">
                    </a>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="resultsColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: resultsColumns;"></tr>
        </table>
    </mat-card-content>
</mat-card>