<h2 mat-dialog-title>Create User</h2>
<div class="descriptionText">You should really consider "inviting" a user instead of creating them. This creates the user, but gives them no access. Invites do both AND sends them an email letting them know.</div>
<mat-dialog-content [formGroup]="form">
    <mat-form-field>
        <input matInput placeholder="Name" formControlName="name">
    </mat-form-field>
    <mat-form-field>
        <input matInput placeholder="Email Address" formControlName="email">
    </mat-form-field>    
    <mat-form-field>
        <input type="password" matInput placeholder="Password" formControlName="password">
    </mat-form-field>       
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button (click)="close()">Cancel</button>
    <button mat-raised-button [disabled]="!form.valid" color="primary" (click)="save()">Create</button>
</mat-dialog-actions>