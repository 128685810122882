<div class="actionContainer">
    <button mat-raised-button [class.spinner]="refreshing" [disabled]="refreshing" (click)="refreshOrgInvitations()">Refresh</button>
</div>
<div class="container" width="100%">
    <ag-grid-angular style="height: 100%;" class="ag-theme-alpine" [rowData]="rowData"
        [columnDefs]="columnDefs"
        [components]="components" [rowSelection]="rowSelection" [defaultColDef]="defaultColDef"
        [tooltipShowDelay]="tooltipShowDelay" enableCellTextSelection="true" ensureDomOrder="true"
        (gridReady)="onGridReady($event)" (selectionChanged)='onSelectionChanged($event)'>
    </ag-grid-angular>
</div>