<div
  class="field-wrapper filter-name tw-relative tw-w-[190px] tw-h-6 tw-bg-indigo-500 tw-text-white tw-flex tw-items-center tw-justify-between tw-rounded tw-py-1 tw-pl-2 tw-mx-3 tw-border tw-border-solid tw-border-indigo-500 hover:tw-underline hover:tw-decoration-dotted hover:tw-bg-indigo-600 hover:tw-border-indigo-600 hover:tw-underline-offset-4 tw-cursor-pointer focus:tw-outline-dotted focus:tw-bg-indigo-600 focus:tw-border-indigo-600 tw-transition-all"
  (click)="onUpdate()"
  tabindex="0">
  <span class="tw-font-semibold tw-leading-[.9rem]">{{filter.field.displayName}}</span>
  <button
    class="delete-icon-btn tw-relative tw-text-white hover:tw-bg-gray-300 hover:tw-text-gray-700 hover:tw-border-transparent hover:tw-font-bold tw-transition-all tw-mr-1"
    title="Delete this filter"
    (click)="onRemove()">
    <mat-icon class="delete-icon tw-absolute hover:tw-font-bold">close</mat-icon>
  </button>
</div>

<!-- operators -->
<div
  [ngClass]="{ 'field-wrapper-last': selectedOperator.inputsRequired < 1 }"
  class="field-wrapper tw-relative tw-h-[34px] tw-rounded tw-flex tw-items-center tw-border tw-border-solid tw-border-neutral-400 tw-mx-2 tw-pl-2">
  <mat-select
    [(ngModel)]="selectedOperator"
    (ngModelChange)="setFilterParams()"
    class="operator-dropdown tw-w-40 tw-mr-3">
    <mat-option *ngFor="let item of availableOperators" [value]="item">
      {{item.displayName}}
    </mat-option>
  </mat-select>
</div>

<!-- strings display -->
<div
  *ngIf="filter.field.type === ColumnType.string  && selectedOperator.inputsRequired >= 1"
  class="field-wrapper field-wrapper-last tw-relative tw-rounded tw-flex tw-items-center tw-border tw-border-solid tw-border-neutral-400 tw-mx-2 tw-pr-1 tw-pl-2 tw-py-[4px]">
  <div
    *ngIf="displayedBadges && displayedBadges.length > 0"
    class="tw-flex tw-flex-wrap tw-max-w-[360px] tw-gap-1 tw-items-center tw-mr-1">
    <!-- light gray or dark gray badges -->
    <app-badge
      *ngFor="let value of displayedBadges"
      [value]="value"
      [color]="'dark'"
      (remove)="removeBadge(value)">
    </app-badge>

    <!-- conditional btn to show/hide too many filter chips -->
    <button
      mat-stroked-button
      color="primary"
      *ngIf="filter.filter.string_values && filter.filter.string_values.length > displayBadgeLimit"
      (click)="toggleShowMore()"
      class="tw-h-[24px] tw-leading-[20px] transition duration-300 tw-ml-1 tw-px-[5px]"
      [ngClass]="{'animate-hover-effect': animateNum }">
      <ng-container *ngIf="showMoreBadges; else showMoreLabel">
        <span class="tw-flex tw-items-center">
          <mat-icon class="tw-text-[20px] tw-w-[20px] tw-h-[20px]"> keyboard_arrow_left </mat-icon>
          <span>Show fewer</span>
        </span>
      </ng-container>
      <ng-template #showMoreLabel>
        <span [ngClass]="{'tw-animate-pulse': animateNum }">
          ...and
          <span id="num-badges" class="tw-font-semibold tw-transition-all tw-duration-300">
            {{ filter.filter.string_values.length - displayBadgeLimit }}
          </span>
          more
        </span>
      </ng-template>
    </button>
  </div>

  <div class="tw-ml-1">
    <input
      type="text"
      #firstInputElem
      class="tw-w-32 tw-text-sm tw-border-0 tw-border-b-[1px] tw-border-solid tw-border-b-indigo-500 tw-bg-transparent tw-min-w-[139px] tw-mr-1 tw-pl-0"
      placeholder="Add value & hit Enter"
      (keyup.enter)="addBadge($event)"
      (blur)="addBadge($event)" />
  </div>
</div>

<!-- number_min value -->
<div
  *ngIf="filter.field.type === ColumnType.number && selectedOperator.inputsRequired >= 1"
  [ngClass]="{ 'field-wrapper-last': !(filter.field.type === ColumnType.number && selectedOperator.inputsRequired >= 2) }"
  class="field-wrapper tw-relative tw-border tw-border-solid tw-border-neutral-400 tw-rounded tw-flex tw-items-center tw-mx-2 tw-pl-2 tw-max-w-28">
  <input
    #firstInputElem
    type="number"
    [(ngModel)]="firstInput"
    (ngModelChange)="setFilterParams()"
    class="tw-border-0 tw-pb-1 tw-pr-1 tw-max-w-16 tw-bg-transparent" />
</div>

<!-- number_max value -->
<div
  *ngIf="filter.field.type === ColumnType.number && selectedOperator.inputsRequired >= 2"
  class="field-wrapper field-wrapper-last tw-relative tw-mx-2 tw-pl-2 tw-border tw-border-solid tw-border-neutral-400 tw-rounded tw-flex tw-items-center tw-max-w-28">
  <input
    type="number"
    [(ngModel)]="secondInput"
    (ngModelChange)="setFilterParams()"
    class="tw-border-0 tw-pb-1 tw-pr-1 tw-max-w-16 tw-bg-transparent" />
</div>

<!-- date value picker -->
<div
  *ngIf="filter.field.type === ColumnType.date && selectedOperator.inputsRequired >= 1"
  class="field-wrapper tw-relative tw-mx-2 tw-pl-2 tw-rounded tw-flex tw-items-center tw-border tw-border-solid tw-border-neutral-400"
  [ngClass]="{ 'field-wrapper-last': !(filter.field.type === ColumnType.date && selectedOperator.inputsRequired >= 2) }">
  <span class="tw-inline-block tw-mr-1">From:</span>
  <input
    type="date"
    #firstInputElem
    [(ngModel)]="firstInput"
    (ngModelChange)="setFilterParams()"
    class="tw-border-0 tw-pr-1 tw-min-w-16 tw-bg-transparent" />
</div>

<div
  *ngIf="filter.field.type === ColumnType.date && selectedOperator.inputsRequired >= 2"
  class="field-wrapper field-wrapper-last tw-relative tw-mx-2 tw-pl-2 tw-rounded tw-flex tw-items-center tw-border tw-border-solid tw-border-neutral-400">
  <span class="tw-inline-block tw-mr-1">To:</span>
  <input
    type="date"
    [(ngModel)]="secondInput"
    (ngModelChange)="setFilterParams()"
    class="tw-border-0 tw-pr-1 tw-min-w-16 tw-bg-transparent" />
</div>

<!-- job level value -->
<div
  *ngIf="filter.field.type === ColumnType.joblevel && selectedOperator.inputsRequired >= 1"
  class="field-wrapper field-wrapper-last tw-relative tw-rounded tw-flex tw-items-center tw-border tw-border-solid tw-border-neutral-400 tw-min-w-[146px] tw-mx-2 tw-pl-2">
  <mat-select [(ngModel)]="listInput" (ngModelChange)="setFilterParams()" multiple>
    <mat-option *ngFor="let item of jobLevelOptions" [value]="item">{{item}}</mat-option>
  </mat-select>
</div>

<!-- job functions value -->
<div
  *ngIf="filter.field.type === ColumnType.jobfunction && selectedOperator.inputsRequired >= 1"
  class="field-wrapper field-wrapper-last tw-relative tw-rounded tw-flex tw-items-center tw-border tw-border-solid tw-border-neutral-400 tw-min-w-48 tw-max-w-[600px] tw-mx-2 tw-pl-2">
  <mat-select [(ngModel)]="listInput" (ngModelChange)="setFilterParams()" multiple>
    <div id="job-function-dropdown">
      <div class="select-all tw-flex tw-items-center tw-py-4">
        <button mat-stroked-button class="tw-text-sm tw-ml-4 tw-mr-3" (click)="selectAllOptions()">
          Select All
        </button>
        <span class="tw-font-light"> | </span>
        <button
          mat-stroked-button
          class="tw-text-sm tw-ml-4 tw-mr-3"
          (click)="deselectAllOptions()">
          Unselect All
        </button>
      </div>

      <div class="tw-columns-2 tw-pb-4">
        <mat-option *ngFor="let item of jobFunctionOptions" [value]="item">{{item}}</mat-option>
      </div>
    </div>
  </mat-select>
</div>

<!-- boolean value -->
<div
  *ngIf="filter.field.type === ColumnType.boolean && selectedOperator.inputsRequired >= 1"
  class="field-wrapper field-wrapper-last tw-relative tw-mx-2 tw-pl-2 tw-rounded tw-flex tw-items-center tw-border tw-border-solid tw-border-neutral-400 tw-w-48">
  <mat-select [(ngModel)]="firstInput" (ngModelChange)="setFilterParams()" multiple>
    <mat-option [value]="true">Yes</mat-option>
    <mat-option [value]="false">No</mat-option>
  </mat-select>
</div>

<div class="connector-and">and</div>
