<div>
  <div
    class="filter-wrapper-category tw-relative tw-flex tw-items-center tw-justify-between tw-w-[7rem] tw-h-6 tw-rounded tw-border tw-border-solid tw-border-gray-300 tw-py-1 tw-pl-2 tw-mx-3">
    <span class="tw-font-semibold">{{categoryName}}</span>
    <button
      class="delete-icon-btn tw-relative  tw-text-gray-500 hover:tw-bg-gray-400 hover:tw-text-gray-700 hover:tw-border-transparent tw-mr-1"
      title="Delete this category filter"
      (click)="onDeleteCategory()">
      <mat-icon class="delete-icon tw-absolute hover:tw-font-semibold">close</mat-icon>
    </button>
  </div>
</div>

<div>
  <ng-container *ngFor="let filter of filters">
    <app-simple-filter
      [filter]="filter"
      (remove)="onRemove(filter)"
      (update)="onUpdate($event)"
      class="category-filter-child"></app-simple-filter>
  </ng-container>
  <div class="filter-row query-item tw-relative tw-flex">
    <div class="field-wrapper field-wrapper-btn tw-relative tw-mx-3">
      <button
        mat-stroked-button
        color="primary"
        (click)="addCategoryFilter(category)"
        class="tw-p-0 tw-min-w-2 tw-w-8 tw-leading-6">
        <!-- <mat-icon class="tw-text-[20px] tw-leading-6">add_circle</mat-icon> -->
        <mat-icon class="tw-text-[20px] tw-leading-6">add</mat-icon>
      </button>
    </div>
  </div>
</div>

<div class="connector-and">and</div>
