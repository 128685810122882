<h2 mat-dialog-title>Create Org</h2>
<div class="descriptionText">This will create the org and invite the user as an administrator on the org. If provided, the org will be limited to the contact count specified.</div>
<mat-dialog-content [formGroup]="form">
    <mat-form-field>
        <mat-label>Org Name</mat-label>
        <input matInput placeholder="Acme" formControlName="orgName">
        <mat-error *ngIf="form.get('orgName')?.invalid">Must be at least 2 characters long</mat-error>                  
    </mat-form-field> 
    <mat-form-field>
        <mat-label>User Email</mat-label>
        <input matInput placeholder="jane@acme.com" formControlName="userEmail">
        <mat-error *ngIf="form.get('userEmail')?.invalid">Must be a valid email address</mat-error>                  
    </mat-form-field>
    <mat-slide-toggle formControlName="ledgerCapability">Ledger Access</mat-slide-toggle>
    <mat-form-field *ngIf="form.get('ledgerCapability')?.value">
        <mat-label>Contact Limit (blank = no limit)</mat-label>
        <input matInput placeholder="" formControlName="contactLimit" value="500">
    </mat-form-field>
    <mat-slide-toggle formControlName="moneyballCapability">Moneyball Access</mat-slide-toggle>
    <mat-slide-toggle formControlName="jobsCapability">Jobs Access</mat-slide-toggle>
    <mat-slide-toggle formControlName="peopleCapability">People Access</mat-slide-toggle>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button (click)="close()">Cancel</button>
    <button mat-raised-button [disabled]="!form.valid" color="primary" (click)="save()">Create</button>
</mat-dialog-actions>