import { Component, Inject, Input, LOCALE_ID, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';
import { ProductsService, CustomerSubscriptionsService } from 'ldt-billing-service-api';
import { AuthService } from 'src/app/auth/service/auth.service';
import { Options } from '@angular-slider/ngx-slider';
import { formatNumber } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { delay, take, tap } from 'rxjs/operators';
import { timer } from 'rxjs';

@Component({
  selector: 'app-settings-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss'],
})
export class SettingsBillingComponent implements OnInit {
  @Input() orgId: string;
  @Input() canEdit: boolean;

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private route: ActivatedRoute,
    private notify: NotificationService,
    private productsService: ProductsService,
    private subscriptionService: CustomerSubscriptionsService,
    private auth: AuthService
  ) {}

  annual: boolean = true;
  uiSelector: boolean = true;
  loading: boolean = true;

  // TODO: type
  products: any[];
  subscription: any;
  currentProduct: any;

  sliderProducts: any[];
  sliderMin: number = 0;
  sliderMax: number;
  sliderStep: number = 1;
  sliderSelected: number = 0;
  sliderOptions: Options = {
    floor: 0,
    ceil: 10,
    step: 1,
    showTicks: true,
    showTicksValues: true,
  };

  // Re-init the page if someone switches org. This needs to be done better.
  ngOnChanges() {
    this.subscription = undefined;
    this.currentProduct = undefined;
    this.loading = true;

    this.ngOnInit();
  }

  fromPurchase = false;

  ngOnInit(): void {
    // See if we're coming back from Stripe
    const params = this.route.snapshot.queryParams;
    if ('stripeResults' in params) {
      if (params['stripeResults'] == 'success') {
        this.fromPurchase = true;
        this.notify.success('Thanks for your subscription! Welcome to Live Data!');

        timer(3 * 1000).subscribe(() => {
          // Refresh the session to get the new limits
          this.auth.refreshSession().subscribe(() => {
            // nothing to do
          });
          if (!this.subscription) this.getSubscription();
        });
      }
    }

    // Get the current subscription. If this doesn't return anything, it will trigger a GET /products
    this.getSubscription();
  }

  getProducts() {
    this.productsService.getProducts().subscribe(
      (res) => {
        // TODO: make it.products
        // Re-format the response to make it easier on the UI to find the right price
        let tempProducts: any[] = res.products;
        tempProducts.forEach((p) => {
          p.price = {};
          p.stripeProduct.prices.forEach((pr: any) => {
            p.price[pr.term] = {};
            p.price[pr.term].amount = pr.unitAmount / 100;
            p.price[pr.term].id = pr.priceId;
          });
        });

        // Add the 2 "static" products (free and 20M+), and sort the products for the UI
        tempProducts.push({
          stripeProduct: {
            active: true,
            id: 'free',
            metadata: { contactLimit: 500 },
            name: 'Free',
          },
          price: { month: { amount: 0 }, year: { amount: 0 } },
        });
        tempProducts.push({
          stripeProduct: {
            active: true,
            id: 'custom',
            metadata: { contactLimit: 20000000 },
            name: 'Custom',
          },
          price: { month: { amount: 0 }, year: { amount: 0 } },
        });
        this.sliderProducts = tempProducts.sort((a, b) => {
          return a.stripeProduct.metadata.contactLimit - b.stripeProduct.metadata.contactLimit;
        });

        // Reconfigure the slider for presentation purposes
        const newOpts = Object.assign({}, this.sliderOptions);
        newOpts.ceil = this.sliderProducts.length - 1;
        newOpts.translate = (val: number): string => {
          const thisVal = this.sliderProducts[val].stripeProduct.metadata.contactLimit;
          if (thisVal == 20000000) {
            return 'Up to 20M';
          } else if (thisVal < 10000) {
            return formatNumber(thisVal, this.locale, '1.0-0');
          } else {
            return this.shortNumber(thisVal);
          }
        };
        this.sliderOptions = newOpts;
        this.loading = false;
      },
      () => {
        this.notify.error('Oops. Unable to get available plans. Please try again later.');
      }
    );
  }

  getSubscription() {
    this.subscriptionService.getOrgSubscription(this.orgId).subscribe(
      (res) => {
        this.subscription = res;
        if (this.subscription.product?.price?.term == 'month') this.annual = false;
        this.loading = false;
      },
      (err) => {
        this.loading = false;
        if (err.status === 404) {
          this.getProducts();
          // No current subscription
        } else {
          this.notify.error('Oops. Unable to get your current plan. Please try again later.');
        }
      }
    );
  }

  // Currently unused
  // updateCurrentProduct() {
  //   if( this.products && this.subscription ) {
  //     this.currentProduct = this.products.find(p => p.stripeProduct.id == this.subscription.product.id);

  //     if( this.currentProduct ) {
  //       this.sliderSelected
  //     }

  //     const newOpts = Object.assign({}, this.sliderOptions);
  //     newOpts.getTickColor = (val: number): string => {
  //       if( this.currentProduct && this.sliderProducts[val].stripeProduct.id == this.currentProduct.stripeProduct.id ) {
  //         return "rgb(255, 64, 129)";
  //       }
  //       return "darkblue";
  //     }
  //     this.sliderOptions = newOpts;
  //   }
  // }

  selectPlan() {
    const link = document.createElement('a');
    // If they have a subscription, send to Stripe Portal
    if (this.subscription) {
      this.subscriptionService.createPortalSession(this.orgId).subscribe(
        (res) => {
          link.href = res.sessionUrl;
          link.click();
        },
        () => {
          this.notify.error('Oops. Problem redirecting you to Stripe. Please try again later.');
        }
      );
    } else {
      const checkoutBody = {
        priceId: this.annual
          ? this.sliderProducts[this.sliderSelected].price.year.id
          : this.sliderProducts[this.sliderSelected].price.month.id,
      };
      this.subscriptionService.createCheckoutSession(this.orgId, checkoutBody).subscribe(
        (res) => {
          link.href = res.sessionUrl;
          link.click();
        },
        () => {
          this.notify.error('Oops. Problem redirecting you to Stripe. Please try again later.');
        }
      );
    }
  }

  openIntercom() {
    (window as any)['Intercom']('showNewMessage');
  }

  shortNumber(value: number): string {
    if (value === 0) return '0';
    if (value < 10000) return value.toString();
    var fractionSize = 1;
    var abs = Math.abs(value);
    var rounder = Math.pow(10, fractionSize);
    var isNegative = value < 0;
    var key = '';
    var powers = [
      { key: 'Q', value: Math.pow(10, 15) },
      { key: 'T', value: Math.pow(10, 12) },
      { key: 'B', value: Math.pow(10, 9) },
      { key: 'M', value: Math.pow(10, 6) },
      { key: 'k', value: 1000 },
    ];
    for (var i = 0; i < powers.length; i++) {
      var reduced = abs / powers[i].value;
      reduced = Math.round(reduced * rounder) / rounder;
      if (reduced >= 1) {
        abs = reduced;
        key = powers[i].key;
        break;
      }
    }
    return (isNegative ? '-' : '') + abs + key;
  }
}
