import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-field-updated-signal',
  templateUrl: './field-updated-signal.component.html',
  styleUrls: ['./field-updated-signal.component.scss']
})
export class FieldUpdatedSignalComponent {
  @Input() change: { providerId: string, updatedAt: string };
  
  constructor() { }

}
