import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AdministrativeService, SetMaintenanceModeRequest } from 'ldt-ledger-service-api';
import { DeleteConfirmationComponent } from 'src/app/delete-confirmation/delete-confirmation.component';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';

@Component({
  selector: 'app-admin-ledger',
  templateUrl: './ledger.component.html',
  styleUrls: ['./ledger.component.scss'],
})
export class AdminLedgerComponent implements OnInit {
  mxMode: boolean = false;

  constructor(
    private adminService: AdministrativeService,
    private notify: NotificationService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.getMxModeStatus();
  }

  getMxModeStatus() {
    this.adminService.getMaintenanceMode().subscribe({
      next: (r) => {
        this.mxMode = r.ledgerMaintenance === 'on';
      },
      error: () => {
        this.notify.error('Error getting maintenance mode status');
      },
    });
  }

  mxToggleChange() {
    let message = 'Are you sure you want to turn maintenance mode ' + (this.mxMode ? 'ON' : 'OFF');

    const confirmDialog = this.dialog.open(DeleteConfirmationComponent, {
      data: {
        title: 'Confirm Maintenance Mode',
        message: message,
      },
    });

    confirmDialog.afterClosed().subscribe((result: boolean) => {
      if (result === true) {
        let body: SetMaintenanceModeRequest = {
          maintenance: this.mxMode
            ? SetMaintenanceModeRequest.MaintenanceEnum.On
            : SetMaintenanceModeRequest.MaintenanceEnum.Off,
        };
        this.adminService.setMaintenanceMode(body).subscribe({
          next: (r) => {
            this.notify.success('Successfully turned MX Mode ' + body.maintenance);
            this.getMxModeStatus();
          },
          error: () => {
            this.notify.error('Failed to set MX mode');
            this.getMxModeStatus();
          },
        });
      }
      this.getMxModeStatus();
    });
  }
}
