import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class HeaderAppVersionInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // Add some custom headers when calling our APIs
    if( request.url.indexOf('gotlivedata.io') > -1 ) {
      let newHeaders = request.headers.append('gld-ui-version', environment.appVersion);
      newHeaders = newHeaders.append('LDT-App-Name', 'Angular UI');
      newHeaders = newHeaders.append('LDT-App-Version', environment.appVersion);
      request = request.clone({ headers: newHeaders})
    }
    return next.handle(request);
  }
}