import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styles: [
    `
  .badge-container {
    @apply tw-rounded-sm tw-leading-4 tw-py-1 tw-pl-2 tw-pr-1;
  }
  `,
  ],
})

// Dark-gray or Light-gray badge
export class BadgeComponent {
  @Input() value: string;
  @Input() color: 'dark' | 'light' = 'dark'; // Default to dark
  @Output() remove = new EventEmitter<string>();

  /**
   * Emits an event to remove the badge.
   */
  removeBadge() {
    this.remove.emit(this.value);
  }
}
