<h2 mat-dialog-title>Create Managed Job</h2>
<div class="descriptionText">Enter the Job ID to have it configured as a Managed Job</div>
<mat-dialog-content [formGroup]="form">
    <mat-form-field>
        <input matInput placeholder="Job ID" formControlName="jobId">
    </mat-form-field>  
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button (click)="close()">Cancel</button>
    <button mat-raised-button [disabled]="!form.valid" color="primary" (click)="save()">Create</button>
</mat-dialog-actions>