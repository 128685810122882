import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';

@Component({
  selector: 'app-jobs-table',
  templateUrl: './jobs-table.component.html',
  styleUrls: ['./jobs-table.component.scss']
})
export class JobsTableComponent implements OnInit {

  @Input() jobsList: any;
  @Input() view: string = 'normal';
  jobsDatasource: MatTableDataSource<any>;
  selectedJob:any;
  
  jobColumns = ['company_name', 'title', 'started_at', 'ended_at', 'created'];

  constructor() { 
  }

  ngOnInit(): void {
    this.updateData();
  }

  ngOnChanges(): void {
    this.updateData();
  }

  private updateData():void {
    const now = new Date().getTime();

    // if( this.view === 'compact' ) {
    //   this.jobColumns.splice(this.jobColumns.indexOf('created'), 1);
    // }

    if( this.jobsList ) {
      let jobsToShow = this.jobsList.map((j:any) => {
        j['newly_created'] = new Date(j.created_at).getTime() > (now - 1000 * 60 * 60 * 24);
        return j;
      }).sort((a:any,b:any) => b.ordering - a.ordering);

      if( this.view === 'compact' ) {
        jobsToShow = jobsToShow.slice(0, 5);
      }
      this.jobsDatasource = new MatTableDataSource(jobsToShow);    
    }
  }

}
