import { SocialAuthService } from '@abacritt/angularx-social-login';
import { Component, OnInit } from '@angular/core';
import { SessionService } from 'ldt-identity-service-api';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';

@Component({
  selector: 'app-extension',
  templateUrl: './extension.component.html',
  styleUrls: ['./extension.component.scss']
})
export class ExtensionComponent implements OnInit {

  constructor(
    private socialAuthService: SocialAuthService,
    private sessionService: SessionService,
    private notify: NotificationService
  ) { }

  session:any;

  ngOnInit(): void {
    this.socialAuthService.authState.subscribe({
      next: (user) => {
        if( user ) {
          this.sessionService.postSessionGoogle({idToken: user.idToken}).subscribe({
            next: (r) => this.session = JSON.stringify(r),
            error: (err) => {
              if( err.status ) {
                switch (err.status) {
                  case 409: 
                    this.notify.error("That email address is already taken. Perhaps you need to reset your password?");
                    break;
                  case 401:
                    this.notify.error("Error creating user. Make sure your browser allows recaptcha.")
                    break;
                  case 422:
                    this.notify.error("Can not create an account with a personal email address. Please use a work email address.")
                    break;
                  case 400:
                    this.notify.error("Sorry, there was a problem creating your account. Please try again later.");
                    break;
                  case 500:
                    this.notify.error("Sorry, there was a problem creating your account. Please try again later.");
                    break;
                }
              } else {
                  this.notify.error("Sorry, there was a problem creating your account. Please try again later.")
              }              
              this.socialAuthService.signOut();
            }
          })
        }
      }
    });
  }
}
