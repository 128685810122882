import { Injectable } from '@angular/core';
import { SessionService, User, Org, UsersService, PostSessionRequest, ValidateUserEmailRequest } from 'ldt-identity-service-api';
import { BehaviorSubject, EMPTY,Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';

@Injectable({ providedIn: 'root' })
export class AuthService {
    public redirectUrl = '/main';

    private loggedIn = new BehaviorSubject<boolean>(false);
    get $isLoggedIn(): Observable<boolean> {
        this.checkLogin();
        return this.loggedIn.asObservable();
    }
    public get isLoggedInValue(): boolean {
        this.checkLogin();
        return this.loggedIn.value;
    }
    private checkLogin() {
        // If we have the required items in localstorage, the user is logged in. 
        // Their session _may_ be expired, but session handling will try to renew or
        //  invalidate their session. For purposes of this function, we don't deal 
        //  with valid session, only that they have the required session properties in storage

        if (localStorage.getItem('accessToken') === null ||
            localStorage.getItem('user') === null ||
            localStorage.getItem('refreshToken') === null ) {

            this.loggedIn.next(false);
        } else {
            this.loggedIn.next(true);
        }      
    }

    private user = new BehaviorSubject<User>({id:'none',email:'none',createdAt:(new Date()).toISOString(),type:'none',status:'inactive'});
    get $getUser(): Observable<User> {
        return this.user.asObservable();
    }
    public get getUserValue(): User {
        return this.user.value;
    }

    private selectedOrgId = new BehaviorSubject<string>('');
    get $getSelectedOrgId(): Observable<string> {
        return this.selectedOrgId.asObservable();
    }
    public get getSelectedOrgIdValue(): string {
        return this.selectedOrgId.value;
    }
    public setSelectedOrgId(orgId:string) {
        localStorage.setItem('selectedOrgId', orgId);
        this.selectedOrgId.next(orgId);
        this.updateIsOrgAdmin();
        this.updateIsOrgActive();
        this.updateOrgCapabilites();
        this.updateSelectedOrg();
    }    

    private selectedOrg = new BehaviorSubject<Org|undefined>(undefined);
    get $getSelectedOrg(): Observable<Org|undefined> {
        return this.selectedOrg.asObservable();
    }
    public get getSelectedOrgValue(): (Org|undefined) {
        return this.selectedOrg.value;
    }
    private updateSelectedOrg() {
        let org = this.getOrgsValue.find(o => {return o.id === this.getSelectedOrgIdValue});
        this.selectedOrg.next(org);
    }        

    private accessToken = new BehaviorSubject<string>('');
    get $getAccessToken(): Observable<string> {
        return this.accessToken.asObservable();
    }
    public get getAccessTokenValue(): string {
        return this.accessToken.value;
    }

    private orgs = new BehaviorSubject<Org[]>([]);
    get $getOrgs(): Observable<Org[]> {
        return this.orgs.asObservable();
    }
    public get getOrgsValue(): Org[] {
        return this.orgs.value;
    }

    private roles = new BehaviorSubject<String[]>([]);
    get $getRoles(): Observable<String[]> {
        return this.roles.asObservable();
    }
    public get getRolesValue(): String[] {
        return this.roles.value;
    }
    
    private isAdmin = new BehaviorSubject<boolean>(false);
    get $isAdmin(): Observable<boolean> {
        return this.isAdmin.asObservable();
    }
    public get isAdminValue(): boolean {
        return this.isAdmin.value;
    }    
        
    private isOrgAdmin = new BehaviorSubject<boolean>(false);
    get $isOrgAdmin(): Observable<boolean> {
        return this.isOrgAdmin.asObservable();
    }
    public get isOrgAdminValue(): boolean {
        return this.isOrgAdmin.value;
    }
    private updateIsOrgAdmin() {
        this.isOrgAdmin.next(this.userHasRole("admin"));
    }

    private isOrgActive = new BehaviorSubject<boolean>(true);
    get $isOrgActive(): Observable<boolean> {
        return this.isOrgActive.asObservable();
    }
    public get isOrgActiveValue(): boolean {
        return this.isOrgActive.value;
    } 
    private updateIsOrgActive() {
        let active = false;
        const thisOrg = this.getSelectedOrg();
        if( thisOrg && thisOrg.status === 'active' ) {
            active = true;
        }
        this.isOrgActive.next(active);
    }  
    
    private orgCapabilites = new BehaviorSubject<String[]>([]);
    get $getOrgCapabilites(): Observable<String[]> {
        return this.orgCapabilites.asObservable();
    }
    public get getOrgCapabilitesValue(): String[] {
        return this.orgCapabilites.value;
    }
    private updateOrgCapabilites() {
        let capabilities:String[] = [];
        const thisOrg = this.getSelectedOrg();
        Object.keys(thisOrg?.settings || {}).forEach(key => {
            if( thisOrg?.settings?.[key]?.capability ) {
                capabilities.push(key);
            }
        });
        this.orgCapabilites.next(capabilities);
    }

    get $redirectUrl(): string {
        return this.redirectUrl;
    }

    public getSelectedOrg(): Org | undefined {
        let thisOrg = this.getOrgsValue.find(o => o.id === this.getSelectedOrgIdValue);

        // If the selected org couldn't be found, choose the first org available. If no 
        //  orgs, this will return undefined
        if( !thisOrg ) {
            if( this.getOrgsValue.length > 0 ) {
                this.setSelectedOrgId(this.getOrgsValue[0].id);
                thisOrg = this.getOrgsValue[0];
            }
        }
        return thisOrg;
    }

    get email(): (string | undefined) {
        var user = localStorage.getItem('user');
        if (user) {
            var u = JSON.parse(user);
            var email:string = u.email;
            return email;
        } else {
            return undefined;
        }
    }

    adminHidden:boolean = false;
    constructor(
        private sessionService: SessionService, 
        private router: Router, 
        private notify: NotificationService, 
        private userService: UsersService,
        private _hotkeysService: HotkeysService ) { 

        this._hotkeysService.add(new Hotkey('alt+shift+a', (event: KeyboardEvent): boolean => {
            // If either of these is true, the person is an admin (though we might be hiding that right now)
            if( this.isAdminValue || this.adminHidden ) {
                // So swap the values
                this.isAdmin.next(this.adminHidden)
                this.adminHidden = !this.adminHidden;
            }
            return true;
        }));
            
        // If the user is logged in, grab everything from local storage
        if (this.isLoggedInValue) {

                // Try to grab everything from local storage
                this.accessToken = new BehaviorSubject<string>(localStorage.getItem('accessToken') || '');
                this.user = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user') || '{}'));
                this.selectedOrgId = new BehaviorSubject<string>(localStorage.getItem('selectedOrgId') || '');
                this.orgs = new BehaviorSubject<Org[]>(JSON.parse(localStorage.getItem('orgs') || '[]'));
                this.roles = new BehaviorSubject<String[]>(JSON.parse(localStorage.getItem('roles') || '[]'));

                this.updateIsOrgAdmin();
                this.updateIsOrgActive();
                this.updateOrgCapabilites();

                // Check if the user has internal ledger roles
                var services = JSON.parse(localStorage.getItem('services') || '[]');
                if (services.length > 0) {
                    this.isAdmin.next(true)
                }
        } else {
            this.clearLocalStorage();
        }
    }

    public refreshSession() {
        let rt = localStorage.getItem('refreshToken');
        if (rt) {
            var sessionBody:PostSessionRequest = {
                grantType: "refreshToken",
                email: this.getUserValue.email,
                refreshToken: rt
            }
            // We do this in case angular decides to call this twice in quick succession. If that happens,
            // the 2nd call will fail. But if we remove this, the 2nd call won't happen.
            localStorage.removeItem('refreshToken');

            return this.sessionService.postSession(sessionBody).pipe(map(r => {
                this.processNewSession(r);
            }),);
        } else {
            return EMPTY;
        }
    }

    private clearLocalStorage() {
        localStorage.removeItem('user');
        localStorage.removeItem('accessToken');        
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('orgs');        
        localStorage.removeItem('expiresAt');   
        localStorage.removeItem('services');
        localStorage.removeItem('roles');
        this.orgs.next([]);
    }

    public resp: Observable<any>;
    loginV2(email: string, password: string) {
        var sessionBody:PostSessionRequest = {
            grantType: "password",
            email: email,
            password: password
        }

        return this.sessionService.postSession(sessionBody).pipe(map(resp => {
            this.clearLocalStorage();
            this.processNewSession(resp);
        }))
    }   
    
    private processNewSession(session:any) {
        // Set a bunch of stuff in local storage
        localStorage.setItem('accessToken', session.accessToken);
        localStorage.setItem('refreshToken', session.refreshToken);
        localStorage.setItem('user', JSON.stringify(session.user));
        if (session.services) localStorage.setItem('services', JSON.stringify(session.services));

        var orgsToSet = [];
        // Sort the orgs 
        if (session.orgs) {
            orgsToSet = session.orgs.sort((a:Org, b:Org) => a.name.localeCompare(b.name));
            localStorage.setItem('orgs', JSON.stringify(orgsToSet));
        }

        // If the user has any administrative orgs in the list (internal only), add them to the current orgs list
        this.getOrgsValue.forEach(org => {
            if (org.name.endsWith("(observing)")) {
                orgsToSet.push(org)
            }
        })

        // Update relevant observables
        this.accessToken.next(session.accessToken);
        this.orgs.next(orgsToSet);
        this.user.next(session.user);
        
        // Check if the selected org is one the user still has access to            
        if (localStorage.getItem('selectedOrgId')) {
            if (orgsToSet && !orgsToSet.some((o:any) => {
                return o.id == localStorage.getItem('selectedOrgId')
            })) {
                localStorage.removeItem('selectedOrgId');
            }
        }

        // Set the roles array from the JWT
        const helper = new JwtHelperService();
        const decodedToken = helper.decodeToken(this.getAccessTokenValue);
        if (decodedToken.roles) {
            localStorage.setItem('roles', JSON.stringify(decodedToken.roles));
            this.roles.next(decodedToken.roles)
        }

        // Ensure selected org is set
        if (!localStorage.getItem('selectedOrgId')) {
            if (session.orgs && session.orgs.length > 0) {
                localStorage.setItem('selectedOrgId', session.orgs[0].id);
                this.setSelectedOrgId(session.orgs[0].id);
            }
        } else {
            this.setSelectedOrgId(localStorage.getItem('selectedOrgId') || '');
        }

        // Check if the user has internal ledger roles
        // TOOD: make this more flexible in case users have admin on other services
        if (session.services) {
            this.isAdmin.next(true)
        }        
    }

    // Check if a user has a given role on the current org
    // Deals with hierarchy of roles, so you can call this with "viewer" and it will also check higher-level roles
    public userHasRole(requiredRole: string): boolean {
        let roles:string[] = [requiredRole];
        if (requiredRole === "viewer") {
            roles.push("admin");
            roles.push("editor");
        } else if (requiredRole === "editor") {
            roles.push("admin");
        }
        return this.userHasRoles(roles);
    }   

    // Check if a user has one of any allowed roles, on an org or globally
    private userHasRoles(requiredRoles: Array<string>): boolean {
        return requiredRoles.some(role => this.getRolesValue.includes(this.getSelectedOrgIdValue + ":" + role) ||
        this.getRolesValue.includes("*:" + role));
    }    

    // Adds an "observed" org to the current user's org list so they can interact with it
    public setAdministrativeOrg(org: Org) {
        // Change the name so we know we're observing, and push it to the org list for the user
        org.name = org.name + " (observing)";
        this.getOrgsValue.push(org);
        this.orgs.next(this.getOrgsValue);
    }

    public validateUserEmail(token:string) {
        let body:ValidateUserEmailRequest = {token: token};
        this.userService.validateUserEmail(this.getUserValue.id, body).subscribe(r => {
            // Nothing to do here
        }, () => {
            // Fail silently
        });
    }

    setRedirectUrl(redirectUrl: string): void {
        if (redirectUrl !== '/login' && redirectUrl !== '/signup' && redirectUrl !== '/onboarding') {
            this.redirectUrl = redirectUrl;
        } else {
            this.redirectUrl = '/main';
        }
    }
    public logOut() {
        this.accessToken.next('');
        this.isAdmin.next(false);
        this.clearLocalStorage();
        
        // this.loggedIn.next(false);
        this.notify.success("You have been logged out. See you soon.")
        this.router.navigateByUrl('/login')
    }
}
