import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CompletePasswordResetRequest, UsersService } from 'ldt-identity-service-api';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';
import { AuthService } from '../service/auth.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  submitButtonValue = 'Reset';
  email = '';
  password = '';
  resetId = '';
  emailSent = false;
  enterPassword = false;
  validationError = false;
  isValidating = false;
  lengthCriteriaMet: boolean;
  unvalidated: '';
  form = this.fb.group({
    email: new UntypedFormControl('', {
      validators: [
        Validators.required,
        Validators.email
      ]
    }),
    passwordFormControl: new UntypedFormControl('', [
      Validators.required,
      Validators.minLength(2)
    ]),
  });
  pValidation = [
    {
      func: this.hasLength,
      text: 'Between 8 and 64 characters'
    }
  ];
  constructor(private route: ActivatedRoute,
    private authService: AuthService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private usersService: UsersService,
    private notify: NotificationService) {
    this.email = '';
    this.route.queryParams.subscribe(params => {
      if ('email' in params && 's' in params) {
        this.form.controls['email'].setValue(params.email);
        this.resetId = params.s;
        this.enterPassword = true;
      }
      if ('source' in params && params.source === 'onboarding') {
        this.isOnboarding = true;
      }
    });
  }

  isOnboarding: boolean = false;
  
  ngOnInit() {
  }

  hasLength(pass: string) {
    return pass.length >= 8 && pass.length <= 64;
  }

  showValidationTooltip() {
    this.validationError = false;
    this.isValidating = true;
  }
  checkingPassword(event: any) {
    this.password = event.target.value;
  }

  initiateReset() {
    if (!this.email) return;
    this.usersService.initiatePasswordReset({email: this.email}).subscribe({
      next: () => {
        this.notify.success("Email sent. Please check your inbox.", 30000)
        this.emailSent = true;
      }, 
      error: () => {
        this.notify.error("Error resetting your password. Please try again.")
      }
    }) 
  }

  completeReset() {

    const { email, passwordFormControl } = this.form.value;

    if (!email) return;
    if (!this.hasLength(passwordFormControl)) {
      this.validationError = true;
      return;
    }
    let rBody:CompletePasswordResetRequest = {
      resetId: this.resetId,
      email: email,
      password: passwordFormControl
    }

    this.usersService.completePasswordReset(rBody).subscribe({
      next: () => {
        this.notify.success("Password reset successful. Please login.")
        this.router.navigate(['/login']);
      }, 
      error: () => {
        this.notify.error("Oops. There was an error during your request. Please try again later.")
      }
    })
  }

}
