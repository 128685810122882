<ul>
    <li *ngFor="let field of company | keyvalue">
        <span *ngIf="field.key === 'linkedin'; else notlinkedin">
            <strong>{{ field.key }}:</strong> <a href="https://linkedin.com/company/{{ field.value }}" target="_blank">{{ field.value }}</a>
        </span>
        <ng-template #notlinkedin>
            <strong>{{ field.key }}:</strong> {{ field.value }}
        </ng-template>
    </li>
</ul>
<div class="buttonContainer">
    <button mat-raised-button (click)="close()">Close</button>
</div>
