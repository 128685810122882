<div class="actionContainer" *ngIf="canAdmin">
    <button mat-raised-button color="warn" [disabled]="!rowsSelected" (click)="removeSelectedUsers()">Remove Selected Users</button>
    <button mat-raised-button [class.spinner]="refreshing" [disabled]="refreshing" (click)="refreshOrgUsers()">Refresh</button>
</div>
<div class="container" width="100%">
    <ag-grid-angular style="height: 100%;" class="ag-theme-alpine" [rowData]="rowData"
        [columnDefs]="columnDefs" [tooltipShowDelay]="tooltipShowDelay" accentedSort=true
        [components]="components" [rowSelection]="rowSelection" [defaultColDef]="defaultColDef"
        enableCellTextSelection="true" ensureDomOrder="true"
        (gridReady)="onGridReady($event)" (selectionChanged)='onSelectionChanged($event)'>
    </ag-grid-angular>
</div>