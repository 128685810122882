import { Component } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { AdminService, Destination } from 'ldt-data-deliveries-api';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';

@Component({
  selector: 'app-destinations',
  templateUrl: './destinations.component.html',
  styleUrls: ['./destinations.component.scss'],
})
export class DestinationsComponent {
  refreshing: boolean = false;

  constructor(
    private destinationsService: AdminService,
    private notify: NotificationService
  ) {}

  ngOnInit(): void {
    this.getDestinations();
  }

  /// AG-GRID to display all destination types --------------
  public destinations: Destination[];
  public colDefs: ColDef[] = [
    { field: 'id', maxWidth: 60 },
    { field: 'org_id', maxWidth: 110 },
    { field: 'destination_type_id', headerName: 'Type', maxWidth: 80 },
    { field: 'status', floatingFilter: true },
    { field: 'description' },
    { field: 'created_at', maxWidth: 230 },
  ];

  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    floatingFilter: true,
    resizable: true,
    flex: 1,
  };

  getDestinations(): void {
    this.refreshing = true;
    this.destinationsService.listAllDestinations().subscribe({
      next: (data) => {
        this.destinations = data;
        this.refreshing = false;
      },
      error: (error) => {
        this.notify.error('Error fetching destinations');
        this.refreshing = false;
      },
    });
  }
}
