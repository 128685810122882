import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-data-deliveries',
  templateUrl: './data-deliveries.component.html',
  styleUrls: ['./data-deliveries.component.scss'],
})
export class DataDeliveriesComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
