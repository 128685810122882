import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-custom-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
})
export class CustomStepperComponent {
  @Input() steps: string[] = [];
  @Input() activeStep: number = 1;

  ngOnInit(): void {}
}
