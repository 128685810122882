<div class="integrations-list-container">
  <h1 class="content-title">Live Data Integrations</h1>
  <div class="content-description">
    Live Data integrations provide an easy way to get data into, and export data from, your ledgers
    in a variety of systems.
  </div>
  <div class="content tw-mt-6 tw-flex-col">
    <nav mat-tab-nav-bar>
      <a
        mat-tab-link
        routerLinkActive="active-tab-nav"
        routerLink="./available-integrations"
        class="tw-text-base">
        Available Integrations
      </a>
      <a
        mat-tab-link
        routerLinkActive="active-tab-nav"
        routerLink="./configured-integrations"
        class="tw-text-base">
        Configured Integrations
      </a>
    </nav>
    <router-outlet></router-outlet>
  </div>
</div>
