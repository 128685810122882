import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { InvitationsService, Org } from 'ldt-identity-service-api';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth/service/auth.service';
import { NotificationService } from '../shared/notification-service/notification.service';
import { InvitationsCreateDialogComponent } from './invitations-create-dialog/invitations-create-dialog.component';
import { TeamInvitationsComponent } from './team-invitations/team-invitations.component';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit {
  @ViewChild(TeamInvitationsComponent, {static: true}) inviteComponent: TeamInvitationsComponent;

  org: Org;
  canAdmin: boolean = false;

  constructor(private router: Router, private invService: InvitationsService, public dialog: MatDialog, private route: ActivatedRoute, private auth: AuthService, private notifyService: NotificationService ) {
    const thisOrg = this.auth.getSelectedOrg();
    if( !thisOrg ) {
      // In theory this should never happen cause if there are no orgs the OrgsGuard will redirect to a diff page
      this.router.navigateByUrl('/main')
    } else {
      this.org = thisOrg;
    }
    this.canAdmin = this.auth.userHasRole("admin");
  }

  async ngOnInit() {
  }

  showInviteDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "500px";
    const dialogRef = this.dialog.open(InvitationsCreateDialogComponent, dialogConfig)
    dialogRef.afterClosed().subscribe(data => {
      if (data && data.emails) {
        data.emails.split(",").forEach((email:string) => {
          let body = {
            inviteeEmail: email.trim(),
            inviteeRole: data.role
          }
          this.invService.createInvitation(this.org.id, body).subscribe(r => {
            this.notifyService.success(email + " successfully invited.");
            if (this.inviteComponent) {
              this.inviteComponent.refreshOrgInvitations();
            }          
          }, err => {
            if( err.status && err.status === 409 ) {
              this.notifyService.error("User already has access to this org");
            } else {
              this.notifyService.error("Failed to invite " + email + ". Please try again later.");
            }
          })
        });
      }
    })
  }   
}
