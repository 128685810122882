import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HeapService } from './api-client/heap.service';
import { AuthService } from './auth/service/auth.service';
import { NotificationService } from './shared/notification-service/notification.service';
import { MxService } from './shared/mx-service/mx.service';
import { Org } from 'ldt-identity-service-api';
import * as Sentry from '@sentry/angular-ivy';
import { MatSidenav } from '@angular/material/sidenav';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild(MatSidenav) sidenav: MatSidenav;
  @ViewChild('logo') logo: ElementRef;
  get email(): string | undefined {
    return this.auth.email;
  }
  $isLoggedIn: Observable<boolean>;

  adminRoutes = [
    {
      path: 'admin/clean',
      name: 'Clean Service',
    },
    {
      path: 'admin/identity',
      name: 'Identity Service',
    },
    {
      path: 'admin/ledger',
      name: 'Ledger Service',
    },
    {
      path: 'admin/firehose',
      name: 'Firehose',
    },
    {
      path: 'admin/staging-firehose',
      name: 'Staging Search Sync',
    },
    {
      path: 'admin/audits',
      name: 'Audits',
    },
    {
      path: 'admin/data-deliveries/destinations',
      name: 'Data Deliveries',
    },
    {
      path: 'datawarehouse/stats',
      name: 'Data Warehouse',
    },
    {
      path: 'admin/streams/dashboard',
      name: 'Streams',
    },
    {
      path: 'grow',
      name: 'Grow',
    },
    {
      path: 'techno-target',
      name: 'Techno Target',
    },
  ];
  showNav: boolean = false;
  showAdmin: boolean = false;
  $showAdmin: Observable<boolean>;
  appVersion: string;

  constructor(
    heapService: HeapService,
    private auth: AuthService,
    private router: Router,
    private notify: NotificationService,
    private mx: MxService,
    private _clipboardService: ClipboardService
  ) {
    heapService.load({
      app_id: environment.heapAppId,
      force_ssl: true,
      secure_cookie: true,
      disable_text_capture: false,
      cookie_path: '/',
    });
    this.appVersion = environment.appVersion;
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        if (this.auth.$isLoggedIn) {
          (window as any)['Intercom']('update', {
            app_id: environment.intercomAppId,
            email: this.auth.email,
            user_id: this.auth.getUserValue.id,
            last_request_at: new Date().getTime() / 1000,
            alignment: 'left',
            horizontal_padding: 170,
            is_employee: this.auth.email
              ? this.auth.email.endsWith('livedatatechnologies.com')
              : false,
          });
          Sentry.setUser({ email: this.auth.getUserValue.email, id: this.auth.getUserValue.id });
          heapService.identify(this.auth.email || '');
          heapService.addUserProperties({
            is_employee: this.auth.email
              ? this.auth.email.endsWith('livedatatechnologies.com')
              : false,
          });
        }
      });

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (let registration of registrations) {
          registration.unregister();
        }
      });
      caches.keys().then(function (cacheNames) {
        return Promise.all(
          cacheNames.map(function (cacheName) {
            return caches.delete(cacheName);
          })
        );
      });
    }
  }
  $orgs: Observable<any>;
  orgs: Org[] = [];
  selectedOrg: any;
  $selectedOrg: Observable<string>;
  $isOrgAdmin: Observable<boolean> = of(false);
  $isOrgActive: Observable<boolean> = of(false);
  $orgCapabilities: Observable<String[]> = of([]);
  isOrgAdmin: boolean = false;
  $isMxMode: Observable<boolean> = of(false);
  selectedOrgContactLimit: number;
  selectedOrgContactCount: number;

  toggleSideNav() {
    this.sidenav.toggle();
    if (this.sidenav.opened) {
      this.logo.nativeElement.style.setProperty('padding-left', '16px');
    } else {
      this.logo.nativeElement.style.setProperty('padding-left', '0px');
    }
    // emit resize event to other components
    setTimeout(() => window.dispatchEvent(new Event('resize')), 200);
  }

  async ngOnInit() {
    this.$isLoggedIn = this.auth.$isLoggedIn;
    this.$selectedOrg = this.auth.$getSelectedOrgId;
    this.$isOrgAdmin = this.auth.$isOrgAdmin;
    this.$isOrgActive = this.auth.$isOrgActive;
    this.$orgCapabilities = this.auth.$getOrgCapabilites;
    this.$showAdmin = this.auth.$isAdmin;
    this.auth.$getOrgs.subscribe(() => {
      this.orgs = this.auth.getOrgsValue || [];
      this.selectedOrg = this.auth.getSelectedOrgIdValue;
    });
    this.$isLoggedIn.subscribe(() => {
      this.orgs = this.auth.getOrgsValue || [];
      this.selectedOrg = this.auth.getSelectedOrgIdValue;
    });
    this.$selectedOrg.subscribe(() => {
      this.selectedOrg = this.auth.getSelectedOrgIdValue;

      let org = this.orgs.find((o) => {
        return o.id === this.selectedOrg;
      });
      this.selectedOrgContactCount = org?.contactCount || 0;
      let settings: any = org?.settings;
      this.selectedOrgContactLimit = settings?.ledger?.contactLimit || 100000000000000;
    });
    this.$isOrgAdmin.subscribe(() => {
      this.isOrgAdmin = this.auth.isOrgAdminValue;
    });
    this.$isMxMode = this.mx.$getMxMode;
  }
  onOrgSelected() {
    this.auth.setSelectedOrgId(this.selectedOrg);
    this.router.navigate([this.selectedOrg + '/ledgers']);
  }

  async logout() {
    this.auth.logOut();
    this.notify.info('Successfully loggedd out.');
  }

  triggerRefresh() {
    this.auth.refreshSession().subscribe({
      next: () => {
        this._clipboardService.copyFromContent(this.auth.getAccessTokenValue);
        this.notify.success('Refreshed and copied to clipboard');
      },
      error: () => {
        this.notify.error('Error');
      },
    });
  }

  handleNavigation(url: string): void {
    this.router.navigateByUrl(url);
  }

  intercomTargetName = 'Sales Inquiry';
  showSupportWindow() {
    (window as any)['Intercom']('showNewMessage');
  }

  startIntercomSurvey() {
    (window as any)['Intercom']('showNewMessage');
  }

  reloadApp() {
    window.location.reload();
  }
}
