import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { DestinationsService, Destination, DestinationConfig } from 'ldt-data-deliveries-api';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';
import { TestStatusBadgeComponent } from 'src/app/shared/test-status-badge/test-status-badge.component';

@Component({
  selector: 'app-test-status-dialog',
  templateUrl: './test-status-dialog.component.html',
  styles: [],
})
export class TestStatusDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<TestStatusDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { destinationData: Destination },
    private destinationsService: DestinationsService,
    private notify: NotificationService
  ) {}

  orgId: string = this.data.destinationData.org_id;
  destinationData: Destination = this.data.destinationData;
  destinationTypeId: number = this.data.destinationData.destination_type_id;
  destinationConfig: DestinationConfig = this.data.destinationData.config;

  testStatus: string;
  testStatusLabel: string = 'Destination Status';
  isLoading: boolean = false;
  @ViewChild(TestStatusBadgeComponent) testStatusBadgeComponent!: TestStatusBadgeComponent;

  ngOnInit(): void {}

  /**
   * Tests the status of a destination.
   * @param orgId - The ID of the organization.
   * @param destinationData - destination object data
   */
  testDestinationStatus(orgId: string, destinationData: Destination) {
    this.isLoading = true;
    this.testStatus = 'Loading...';

    if (!orgId || !destinationData.id) {
      console.error('Invalid orgId or destinationId:', orgId, destinationData.id);
      this.notify.error(
        'Something went wrong with getting the destination status. Please try again later.'
      );
      return;
    }

    this.destinationsService.testDestination(orgId, destinationData.id).subscribe({
      next: (data) => {
        this.isLoading = false;
        if (data.status) {
          this.testStatus = data.status;
          this.testStatusBadgeComponent.updateTimeStamp(new Date().toISOString());
          this.testStatusBadgeComponent.updateStatusBadge(data.status);
        }
      },
      error: (error) => {
        console.error('Error testing destination:', error);
        this.isLoading = false;
        this.testStatus = error.error?.message || 'Error testing destination.';
        this.testStatusBadgeComponent.updateTimeStamp(destinationData.tested_at || '');
      },
    });
  }

  getFormattedS3Uri(destinationId: number): string {
    const formattedOrgId = this.orgId.replace('_', '-');
    return `s3://arn:aws:s3:us-east-1:100811426269:accesspoint/${formattedOrgId}-${destinationId}/${this.orgId}/`;
  }

  closeDialog(): void {
    this.dialogRef.close({ status: this.testStatus });
  }
}
