import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-mj-create-dialog',
  templateUrl: './mj-create-dialog.component.html',
  styleUrls: ['./mj-create-dialog.component.scss']
})
export class MjCreateDialogComponent implements OnInit {

  form: UntypedFormGroup;
  jobId: string;

  constructor(
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<MjCreateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data:any
  ) { 
  }

  ngOnInit() {
    this.form = this.fb.group({
      jobId: [this.jobId, [Validators.required]],
    })
  }

  save() {
    this.dialogRef.close(this.form.value);
  }

  close() {
    this.dialogRef.close();
  }

}
