<div class="tw-flex tw-flex-col tw-h-full">
  <div class="tw-flex tw-flex-row">
    <form [formGroup]="inputFormGroup">
      <mat-form-field appearance="fill">
        <mat-label>Company ID</mat-label>
        <input
          matInput
          placeholder="Company ID"
          formControlName="id"
          [value]="inputFormGroup.value.id" />
      </mat-form-field>
      <button
        type="submit"
        mat-raised-button
        [disabled]="!inputFormGroup.valid || refreshing"
        color="primary"
        [class.spinner]="refreshing"
        (click)="getCompany(inputFormGroup.value.id)">
        Inspect
      </button>
    </form>
  </div>
  <div class="tw-flex tw-flex-row tw-h-full">
    <div class="tw-w-full tw-h-full">
      <ngx-json-viewer
        *ngIf="companyJson"
        [json]="companyJson"
        [depth]="3"
        [expanded]="true"></ngx-json-viewer>
    </div>
  </div>
</div>
